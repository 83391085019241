import { useHistory } from 'react-router-dom';
import Translator from '../../classes/Translator/Translator';

const ComingLogic = () => {
  const { translator } = Translator();
  const heading = translator('coming.heading');

  const body = translator('coming.body');
  let history = useHistory();
  return {
    heading,
    body,
    history,
    translator,
  };
};

export default ComingLogic;
