import { useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';
import { termsConditionsEsp, termsConditionsEng } from './footer.module';

const FooterLogic = () => {
  const { translator, isEnglish } = Translator();
  const [values, setValues] = useState({
    pdf: isEnglish() ? termsConditionsEng : termsConditionsEsp,
  });

  useEffect(() => {
    setValues({
      ...values,
      pdf: isEnglish() ? termsConditionsEng : termsConditionsEsp,
    });
  }, [isEnglish()]);

  return {
    translator,
    values,
  };
};

export default FooterLogic;
