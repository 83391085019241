import { useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const ContractLogic = () => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    token: '0x388D969EEF6ECAD3C29',
    buttonLabel: 'contract_copy',
  });

  const handleButtonCopyClicked = (e) => {
    e.preventDefault();
    setValues({
      ...values,
      buttonLabel: 'contract_copied',
    });
    navigator.clipboard.writeText(values.token);
    setTimeout(function () {
      setValues({
        ...values,
        buttonLabel: 'contract_copy',
      });
    }, 1000);
  };

  return {
    values,
    handleButtonCopyClicked,
    translator,
  };
};

export default ContractLogic;
