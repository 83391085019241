import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  carbonCredit: {
    marginTop: '123px',
    paddingBottom: 0,
    marginBottom: 0,
    // background:'red'
  },
  heading: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '62px',
    lineHeight: '76px',
    color: theme.palette.text.nonary,
    maxWidth: '879px',
    marginBottom: '104px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '35px',
      lineHeight: '50px',
    },
  },
  subtitle: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'capitalize',
    maxWidth: '964px',
    color: theme.palette.text.nonary,
  },
  text: {
    fontFamily: 'MontserratMedium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    textTransform: 'capitalize',
    maxWidth: '1093px',
    marginBottom: '40px',
    color: theme.palette.text.secondary,
  },
  textHeader: {
    fontFamily: 'MontserratMedium',
    fontSize: '72px',
    lineHeight: '48px',
    letterSpacing: '1.42px',
    textAlign: 'left',
    color: theme.palette.text.denary,
    '@media screen and (min-width:576px)': {},
    '@media screen and (max-width:992px)': {
      lineHeight: '66px',
    },
  },
  thirdSection: {
    marginTop: '124px',
    padding: '30px',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '25px',
    marginBottom: '80px',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  fourthSection: {
    padding: '30px',
    marginTop: '100px',
  },
  graphicContainer: {
    marginTop: '42px',
    marginBottom: '25px',
  },
  graphic: {
    maxWidth: '100%',
    height: 'auto',
  },
  banner: {
    marginTop: '34px',
    marginBottom: '57px',
  },
  voluntaryMarketProjects:{
    marginBottom: 50
  }
}));
export default useStyles;
