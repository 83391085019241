import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import CustomError from '../../classes/CustomError/CustomError';
import Response from '../../classes/Response/Response';
import Keys from '../../classes/Project/ProjectKey';

const KeyIndicatorsService = () => {
  const getData = async (id) => {
    const api = new API(`${URL.URL_SERVER}/projects/${id}/keys`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Keys);
      const { resources: keys } = response.wasSuccessfull().multiple();
      return keys;
    }
  };

  return {
    getData,
  };
};

export default KeyIndicatorsService;
