class Project {
  constructor({
    _id,
    id,
    available_nfts,
    project_name,
    country,
    region,
    province,
    latitude,
    longitude,
    contract_start_date,
    contract_end_date,
    green_project_type,
    description,
    area,
    biomass,
    ton_co2_issued,
    ton_price,
    technology,
    images = [],
    co2Goal,
    real_name,
    commercial_name,
    contacts,
    publicKey,
    enabled,
    info,
    main_image,
    co2_annual_estimated,
    data,
    ipfs,
    impacts = [],
    sdgs = [],
    is_available_for_egrn,
    is_available_for_nft,
    is_available_for_sbt,
    only_showing,
    version = 'v1',
    forwards_value,
    forwards_equivalent,
    forwards_minimum,
    fee_egrn,
    url_external_info,
  }) {
    this.project_name = project_name;
    this.country = country;
    this.region = region;
    this.province = province;
    this.latitude = latitude;
    this.longitude = longitude;
    this.contract_start_date = contract_start_date;
    this.contract_end_date = contract_end_date;
    this.green_project_type = green_project_type;
    this.description = description;
    this.area = area;
    this.biomass = biomass;
    this.ton_co2_issued = ton_co2_issued;
    this.ton_price = ton_price;
    this.price = Number(ton_price) / 100;
    this.forwards_price = Number(forwards_value) / 100;
    this.co2Goal = co2Goal;
    this.real_name = real_name;
    this.commercial_name = commercial_name;
    this.contacts = contacts;
    this.publicKey = publicKey;
    this.enabled = enabled;
    this.id = _id || id;
    this.info = info;
    this.main_image = main_image;
    this.co2_annual_estimated = co2_annual_estimated;
    this.images = this.gallery(images);
    this.available_nfts = available_nfts || 0;
    this.data = data;
    this.available_bonds = data.available_bonds || 0;
    this.ipfs = ipfs;
    this.impacts = impacts;
    this.sdgs = sdgs;
    this.is_available_for_egrn = is_available_for_egrn;
    this.is_available_for_nft = is_available_for_nft;
    this.is_available_for_sbt = is_available_for_sbt;
    this.only_showing = only_showing;
    this.version = version;
    this.technology = technology;
    this.forwards_value = forwards_value;
    this.forwards_equivalent = forwards_equivalent;
    this.forwards_minimum = forwards_minimum;
    this.fee_egrn = fee_egrn;
    this.url_external_info = url_external_info;
  }

  gallery(images = []) {
    return images.map((image) => ({
      original: image.url,
      thumbnail: image.url,
    }));
  }

  get image() {
    return this.main_image
      ? this.main_image
      : 'https://cdn.impactinit.com/resizenp/600x600/x@7c62feb0ce/smss53/smsimg27/pv1000/isignstockcontributors/iss_2756_00053.jpg';
  }

  get title_project() {
    return this.project_name.toUpperCase();
  }

  get textForButton() {
    if (this.only_showing) return 'projectDetail.coming_soon';
    if (
      this.is_available_for_egrn &&
      !this.is_available_for_nft &&
      !this.is_available_for_sbt
    ) {
      return 'projectDetail.forward';
    }

    if (
      (this.is_available_for_sbt || this.is_available_for_nft) &&
      !this.is_available_for_egrn
    ) {
      return 'projectDetail.invest';
    }
    return 'projectDetail.forward';
  }
}

export default Project;
