import React from 'react';
import FrameServices from '../shared/panelServices/frameServices';
import { Container, Grid } from '@material-ui/core';
import {
  useStyles,
  layer_4,
  Layer_ug,
  Layer_5,
  Layer_6,
  OurServicesLogic,
} from './ourServices.module';
import ButtonDefault from '../shared/button/button';

const OurServices = () => {
  const classes = useStyles();
  const { translator, goTo } = OurServicesLogic();

  const frameServicesData = [
    {
      srcFrame: Layer_ug,
      altFrame: 'Layer_ug',
      subtitleFrame: {
        paragraph1: translator('ourServices.subtitleFrame'),
        paragraph2: translator('ourServices.subtitleFrameTwo'),
      },
      bodyFrame: translator('ourServices.bodyFrameTwo'),
      button: (
        <ButtonDefault
          type="backgroundMintGreen"
          text={translator('learn_more')}
          changeView={goTo('/carbonCredits')}
        />
      ),
    },
    {
      srcFrame: Layer_6,
      altFrame: 'Layer_6',
      subtitleFrame: {
        paragraph1: translator('ourServices.subtitleFrameFour'),
        paragraph2: translator('ourServices.subtitleFrameFourTwo'),
      },
      bodyFrame: translator('ourServices.bodyFrameFour'),
      button: (
        <ButtonDefault
          type="backgroundMintGreen"
          text={translator('learn_more')}
          changeView={goTo('/projects')}
        />
      ),
    },
    {
      srcFrame: layer_4,
      altFrame: 'layer_4',
      subtitleFrame: {
        paragraph1: translator('ourServices.subtitleFrameOne'),
        paragraph2: translator('ourServices.subtitleFrameOneTwo'),
      },
      bodyFrame: translator('ourServices.bodyFrameOne'),
      button: (
        <ButtonDefault
          type="backgroundMintGreen"
          text={translator('learn_more')}
          changeView={goTo('/carbonManagement')}
        />
      ),
    },

    {
      srcFrame: Layer_5,
      altFrame: 'Layer_5',
      subtitleFrame: {
        paragraph1: translator('ourServices.subtitleFrameThree'),
        paragraph2: translator('ourServices.subtitleFrameThreeTwo'),
      },
      bodyFrame: translator('ourServices.bodyFrameThree'),
      button: (
        <ButtonDefault
          type="backgroundMintGreen"
          text={translator('learn_more')}
          changeView={goTo('/pricing')}
        />
      ),
    },
  ];

  return (
    <Container maxWidth="lg" className={classes.containerServices}>
      <div className={classes.containerTitleServices}>
        <h1 className={classes.titleOur}>
          <span className={classes.titleOur}>
            {translator('ourServices.titleOur')}
          </span>
          {'  '}
          <span className={classes.titleServices}>
            {translator('ourServices.titleServices')}
          </span>
        </h1>
      </div>
      <Grid container spacing={3}>
        {frameServicesData.map((data, index) => (
          <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}
            className={classes.containerFrameServicesData}
          >
            <FrameServices
              key={index}
              srcFrame={data.srcFrame}
              altFrame={data.altFrame}
              subtitleFrame={
                <React.Fragment>
                  {data.subtitleFrame.paragraph1}
                  <br />

                  {data.subtitleFrame.paragraph2}
                </React.Fragment>
              }
              bodyFrame={data.bodyFrame}
              button={data.button}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OurServices;
