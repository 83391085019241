import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  Typography,
  Container,
  Box,
  Grid,
  CardMedia,
  CircularProgress,
} from '@material-ui/core';

import ButtonDefault from '../shared/button/button';
import Footer from '../footer/Footer';
import Translator from '../../classes/Translator/Translator';

import { ConfirmationViewLogic } from './confirmationView.module';

import { ProjectDetailService } from '../ProjectDetail/projectDetail.module';
import { BillingDetailService } from '../billingDetail/billingDetail.module';
import { PaymentMethodService } from '../shared/paymentMethod/paymentMethod.module';

import { useStyles, image } from './confirmationView.module';

const ConfirmationView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { values, error, translator, isLoading } = ConfirmationViewLogic(
    ProjectDetailService().getProject,
    () => BillingDetailService().getTransaction(id),
    PaymentMethodService().checkPay
  );

  return (
    <>
      <Grid container alignItems="center">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CardMedia className={classes.image} image={image} title={image} />
        </Grid>
      </Grid>
      <Container>
        {isLoading ? (
          <Box className={classes.circularProgress}>
            <CircularProgress style={{ color: '#04679C' }} />
            <Typography className={classes.loaderText}>
              {translator('confirmation_loader')}
            </Typography>
          </Box>
        ) : (
          <Grid>
            <main>
              <Grid container alignItems="center">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box mt={2}>
                    {!error && (
                      <Typography
                        className={classes.message}
                        align="center"
                        variant="subtitle1"
                      >
                        {translator('confirmation_message')}
                      </Typography>
                    )}
                    {/* TODO: Incorporar GET user by ID
                      <Typography
                        className={classes.text}
                        align="center"
                        variant="body1"
                      >
                        Te enviamos el detalle de tu inversión al correo:{" "}
                        <b>{email}</b>
                      </Typography> */}
                  </Box>
                </Grid>
              </Grid>
            </main>

            {!error && <BiomassEnergy {...values} />}

            {!error ? (
              <ButtonConfirmationView />
            ) : (
              <Grid className={classes.errorContainer}>
                {error && (
                  <Typography
                    className={classes.error}
                    align="center"
                    variant="body1"
                  >
                    {translator('confirmation_error_message')}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ConfirmationView;

const BiomassEnergy = (values) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.container}>
        <Grid
          className={classes.biomassEnergyContainer}
          item
          lg={12}
          sm={12}
          xs={12}
        >
          <TotalPrice {...values} />
        </Grid>
      </Grid>
    </>
  );
};

const TotalPrice = ({ project, transaction }) => {
  const classes = useStyles();
  const { translator } = Translator();

  return (
    <Grid container spacing={4}>
      <Grid item lg={5} md={5} sm={12} xs={12}>
        <img
          src={project.main_image}
          className={classes.projectImage}
          alt="project_image"
        />
      </Grid>
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Grid container spacing={0}>
          <Grid item lg={12} sm={12} xs={12}>
            <Typography
              className={classes.projectName}
              align="left"
              variant="subtitle1"
            >
              {project.project_name}
            </Typography>
            <Typography
              className={classes.projectDescription}
              align="justify"
              variant="body2"
            >
              {project.description}
            </Typography>
          </Grid>
          <Grid
            className={classes.greenTextContainer}
            item
            lg={12}
            sm={12}
            xs={12}
          >
            <Box pt={1} className={classes.containerGreenText}>
              <Typography className={classes.greenText}>
                {translator('confirmation_project_total_price')}
              </Typography>
              <Typography className={classes.greenText}>
                {`USD ${transaction.total}`}
              </Typography>
            </Box>
            <Box className={classes.containerText}>
              <Typography className={classes.textEgreen}>
                {translator('confirmation_project_egrn_coins')}
              </Typography>
              <Typography className={classes.textEgreen}>
                {`${transaction.total_coins} ${translator(
                  'confirmation_project_coin'
                )}`}
              </Typography>
            </Box>
            {/*<Box className={classes.containerText}>
              <Typography className={classes.textEgreen}>
                {translator('confirmation_project_co2_emission')}
              </Typography>
              <Typography className={classes.textEgreen}>
                {transaction.carbon_credit_invested} Tn
              </Typography>
            </Box> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ButtonConfirmationView = () => {
  const classes = useStyles();
  const { translator } = Translator();

  return (
    <Grid
      container
      className={classes.containerButtons}
      spacing={0}
      justifyContent="center"
    >
      <Grid item>
        <Link to="/" className={classes.link}>
          <ButtonDefault
            type="whitegreen"
            text={translator('confirmation_return_btn')}
          />
        </Link>
      </Grid>
      <Grid item>
        <Link to="/dashboard" className={classes.link}>
          <ButtonDefault
            text={translator('confirmation_profile_btn')}
            type="whitegreen"
          />
        </Link>
      </Grid>
    </Grid>
  );
};
