import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/CustomError/CustomError';
import Transaction from '../../../classes/Transaction/Transaction';

const ReloadTransactionButtonService = () => {
  const updateTransaction = async (id) => {
    const body = [
      {
        op: 'replace',
        path: '/status',
        value: 'accepted',
      },
    ];
    const api = new API(`${URL.URL_SERVER}/transactions/${id}`);
    const http_response = await api.patch(body);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Transaction);
      return response.wasSuccessfull().single();
    }
  };

  return {
    updateTransaction,
  };
};

export default ReloadTransactionButtonService;
