import React from 'react';
import {
    Button
} from '@material-ui/core'
import {useStyles, ReloadTransactionButtonLogic} from './reloadTransactionButton.module'
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function ReloadTransactionButton({id}) {
  const classes = useStyles();
  const { reloadTransaction, loading} = ReloadTransactionButtonLogic(id);

  const handleReloadClick = () => {
    reloadTransaction(id);
  };
  
  return (
        <Button onClick={handleReloadClick} className={classes.customButton}>
        {loading ? (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
          />
        ) : (
          <RefreshIcon/>
        )}</Button>
);
}  