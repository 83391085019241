import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import { useStyles } from './frameServices.module';

const FrameServices = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.rootFrameService}>
      <CardActionArea>
        <CardContent className={classes.cardContentImage}>
          <img
            className={classes.imageFrameService}
            src={props.srcFrame}
            alt={props.altFrame}
          />
        </CardContent>
        <CardContent className={classes.cardContentSubtitleFrame}>
          <Typography className={classes.subtitleFrame}>
            {props.subtitleFrame}
          </Typography>
        </CardContent>
        <CardContent className={classes.cardContentBodyFrame}>
          <Typography className={classes.bodyFrame}>
            {props.bodyFrame}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActionsButton}>
        <div className={classes.fixedButton}>{props.button}</div>
      </CardActions>
    </Card>
  );
};
export default FrameServices;
