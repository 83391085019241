import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles, climateTech } from './teamExperts.module';
import TeamMember from '../teamMember/teamMember';

const experts = [
  {
    photo: climateTech,
    name: 'Lisbeth Kaufman',
    role: 'Directora de Climate Tech',
    linkedin: 'https://www.linkedin.com/in/lisbeth-kaufman/',
  }
];

const TeamExperts = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        {experts.map((data, index) => (
          <Grid className={classes.item} item xs={12} sm={12} md={12} lg={12}>
            <TeamMember
              photo={data.photo}
              role={data.role}
              name={data.name}
              href={data.linkedin}
              key={index}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TeamExperts;