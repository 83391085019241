import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';
import { useHistory } from 'react-router-dom';

const CardUsdLogic = (card) => {
  console.log(card);
  const { translator } = Translator();
  const [values, setValues] = useState({ value: '0.00', available: '0.00' });
  const history = useHistory();

  const goToNewOffer = async () => {
    history.push('/newOffer?type=BUY');
  };

  const goToTransferToBuy = async () => {
    history.push('/transfertobuy');
  };

  useEffect(() => {
    setValues({ ...values, ...card });
  }, [card]);

  return {
    values,
    goToNewOffer,
    goToTransferToBuy,
    translator,
  };
};

export default CardUsdLogic;
