import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Step,
  Stepper,
  StepLabel,
} from '@material-ui/core';
import CardDashboard from '../shared/cardDashboard/cardDashboard';
import User from '../shared/user/user';
import Footer from '../footer/Footer';
import {
  useStyles,
  DashboardLogic,
  DashboardService,
} from './dashboard.module';
import VerifiedProjects from '../verifiedProjects/verifiedProjects';
import EgreenCoinsSoon from '../egreenCoinsSoon/egreenCoinsSoon';

const Dashboard = (props) => {
  const classes = useStyles();

  const service = DashboardService();
  const { values, translator, nfts, sbts, goToProjects } = DashboardLogic(
    service.getUser,
    service.getHistoryOfOperations,
    service.getHistoryOfOffers,
    service.getHistoryOfNFTs,
    props
  );
  const steps = [
    translator('carbonCredits.subtitle1'),
    translator('carbonCredits.subtitle2'),
    translator('carbonCredits.subtitle3'),
  ];

  const NFTInfoText =
    'Carbon Credits that have already been purchased and that can be used to offset emissions. These Carbon Credits are non-fungible tokens (NFTs) that have been issued for a ton of CO₂ captured from the atmosphere, a unique virtual digital asset that can be safely purchased and traded.';
  const SBTInfoText =
    'Carbon Credits that have been used to offset emissions. Also known as Carbon Offsets, these are soulbound tokens (SBTs) equivalent to a ton of CO₂ captured from the atmosphere, a unique virtual digital asset that cannot be purchased and traded again, therefore retired permanently from the market.';
  return (
    <div className={classes.dashboardStyle}>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <User user={values.user} />
          </Grid>
        </Grid>
      </Container>

      <Grid container className={classes.containerCabonCredits}>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Grid container spacing={3} className={classes.rootCabonCredits}>
            <Grid item lg={7} md={7} sm={7} xs={12}>
              <Typography className={classes.carbonCreditsTitle}>
                {translator('carbonCredits.title1')}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <Stepper orientation="vertical" className={classes.timeline}>
                {steps.map((label, index) => (
                  <Step key={label} className={classes.stepDashboard}>
                    <StepLabel>
                      <Typography className={classes.subtitleCabonCredits}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>

      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                {translator('dashboard.your_investments')}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <CardDashboard
                  titleAmount={translator('dashboard.egreen_coins')}
                  amount={values.user.availableNFT + values.user.availableSBT}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CardDashboard
                  tooltip={NFTInfoText}
                  changeView={goToProjects}
                  title={translator('dashboard.available')}
                  value={values.user.availableNFT}
                  showModal={true}
                  nfts={nfts}
                  buy={translator('market.button')}
                  user={values.user._id}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CardDashboard
                  tooltip={SBTInfoText}
                  title={translator('dashboard.converted')}
                  value={values.user.availableSBT}
                  showModal={true}
                  nfts={sbts}
                  blockchain={'View'}
                  user={values.user._id}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // spacing={3}
          className={classes.coinSoon}
        >
          <EgreenCoinsSoon className={classes.coinSoonBanner} />
        </Grid>
        <Grid item lg={12} sm={12} xs={12} className={classes.contentDashboard}>
          <VerifiedProjects />
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};
export default Dashboard;
