import React from 'react';
import { Badge, Fab, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { UserLogic, UserService, useStyles } from './user.module';
import LinkIcon from '@material-ui/icons/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ButtonDefault from '../button/button';
import { Link } from 'react-router-dom';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';
// import Mati from '../../mati/mati';

const User = (props) => {
  const { uploadProfilePicture, getProfilePicture } = UserService();
  const { values, upload } = UserLogic(
    props,
    uploadProfilePicture,
    getProfilePicture
  );
  const classes = useStyles();
  console.log(values.user);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className={classes.containerUserAvatar}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={upload}
                  />
                  <Fab
                    color="primary"
                    size="small"
                    component="span"
                    aria-label="add"
                  >
                    <CloudUploadRoundedIcon />
                  </Fab>
                </label>
              }
            >
              <Avatar
                className={classes.avatar}
                variant="circular"
                src={values.user.profile_picture}
              />
            </Badge>
          </div>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={classes.userData}>
            <Typography className={classes.userHello}>
              Account Holder:
            </Typography>
            <Typography className={classes.email}>
              {values.user.username}
            </Typography>
            <Typography className={classes.userHello}>Public Key:</Typography>

            <div className="my-div">
              <Typography component="span" className={classes.publicKey}>
                {values.user.publicKey}
              </Typography>
              <IconButton
                href={`https://horizon.stellar.org/accounts/${values.user.publicKey}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.iconButton}
                style={{ marginLeft: '6px' }}
              >
                <Tooltip title="Open in Stellar Horizon">
                  <OpenInNewIcon />
                </Tooltip>
              </IconButton>

              <IconButton
                onClick={() =>
                  navigator.clipboard.writeText(values.user.publicKey)
                }
                className={classes.iconButton}
                style={{ marginLeft: '6px' }}
              >
                <Tooltip title="Copy to Clipboard">
                  <LinkIcon />
                </Tooltip>
              </IconButton>
            </div>

            <Typography className={classes.userHello}>Email:</Typography>
            <Typography className={classes.email}>
              {values.user.email}
            </Typography>
            <Link to="/profile" style={{ textDecoration: 'none' }}>
              <ButtonDefault text="Edit Profile" />
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default User;
