import { useEffect, useState } from 'react';

const TeamMemberLogic = (props) => {
  const [values, setValues] = useState({
    ...props,
  });

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    values,
  };
};

export default TeamMemberLogic;
