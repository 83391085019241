import User from './User';

class UserBuilder {
  constructor() {
    this.type = 'person';
  }

  setPassword(password) {
    this.password = password;
    return this;
  }

  setName(name) {
    this.name = name;
    return this;
  }

  setLastname(lastname) {
    this.lastname = lastname;
    return this;
  }

  setEmail(email) {
    this.email = email;
    return this;
  }

  setType(type) {
    this.type = type;
    return this;
  }

  setAccessLevel(access_level) {
    this.access_level = access_level;
    return this;
  }

  setHash(hash) {
    this.hash = hash;
    return this;
  }

  setPublicKey(publicKey) {
    this.publicKey = publicKey;
    return this;
  }

  setRollbackCode(rollback_code) {
    this.rollback_code = rollback_code;
    return this;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
    return this;
  }

  setVerified(verified) {
    this.verified = verified;
    return this;
  }

  build() {
    return new User(
      {
        name: this.name,
        lastname: this.lastname,
        email: this.email,
        type: this.type,
        access_level: this.access_level,
        hash: this.hash,
        publicKey: this.publicKey,
        rollback_code: this.rollback_code,
        enabled: this.enabled,
        verified: this.verified
      }
    );
  }

  buildToRegister() {
    return new User({}).toRegister({
      name: this.name,
      lastname: this.lastname,
      email: this.email,
      password: this.password,
      type: this.type,
    });
  }
}

export default UserBuilder;
