import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  imageInfoSensors: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  thirdImageInfoSensors: {
    width: '90%',
    height: '50%',
    borderRadius: '20px',
    marginTop: '40%',
  },
  containerInfoSensors: {
    borderRadius: '20px',
    border: '2px solid #00DD9E',
    margin: '40px 0px 70px 0px',
    padding: 7,
    [theme.breakpoints.down('md')]: {
      padding: 3,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  titleInfoSensors: {
    fontFamily: 'MontserratBold',
    fontSize: '35px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.text.denary,
    marginBottom: 40,
    marginTop: 15,
  },
  subtitleInfoSensors: {
    fontFamily: 'Montserrat',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '0.05em',
    textAlign: 'left',
    color: theme.palette.text.denary,
    display: 'flex',
    marginBottom: 40,
  },
  bodyInfoSensors: {
    fontFamily: 'MontserratLight',
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'justify',
    textJustify: 'inter-word',
    color: theme.palette.text.denary,
  },
  iconInfoSensors: {
    fontSize: '51px !important',
    color: '#00DD9E !important',
  },
  itemInfoSensors: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 5,
    marginBottom: 50,
  },
  itemImageInfoSensors: {
    marginTop: 5,
    marginBottom: 50,
  },
}));

export default useStyles;
