import { useTranslation } from 'react-i18next';
import cookie from 'js-cookie';

const Translator = () => {
  const language = cookie.get('i18next') || 'en';
  const { t: translator } = useTranslation();
  const isEnglish = () => language === 'en';
  const isSpanish = () => language === 'es';
  const isFrench = () => language === 'fr';

  return {
    language,
    translator,
    isEnglish,
    isSpanish,
    isFrench,
  };
};

export default Translator;
