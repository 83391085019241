import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
  },
  iconContainer_item: {
    marginBottom: '34px',
  },
  iconText_container: {
    margin: 0,
    padding: 0,
  },
  Title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: 20,
    lineHeight: '28px',
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
    pading: 0,
    margin: 0,
  },
  textData: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    pading: 0,
    margin: 0,
    fontWeight: 300,
    marginBottom: '16px',
  },
}));

export default useStyles;
