import React from 'react';
import { Grid, InputLabel, Typography, TextField } from '@material-ui/core';
import {
  MoneyConverterLogic,
  validator,
  UseStyles,
} from './moneyConverter.module';
import Translator from '../../../classes/Translator/Translator';

const MoneyConverter = () => {
  const classes = UseStyles();
  const { translator } = Translator();
  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item lg={12} sm={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.title} align="left">
            {translator('billing_your_money_converter_title')}
          </Typography>
        </Grid>
        <Grid className={classes.whiteContainer} item lg={12} sm={12} xs={12}>
          <WhiteContainer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MoneyConverter;

const WhiteContainer = () => {
  const { values, errors, handleChange, result, translator } =
    MoneyConverterLogic(validator);
  const classes = UseStyles();

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <InputLabel className={classes.subtitle}>
          {translator('billing_your_money_converter_currency')}
        </InputLabel>
        <select
          className={classes.select}
          id="currency"
          name="currency"
          onChange={handleChange}
        >
          <option value="CLP">CLP</option>
          <option value="EUR">EUR</option>
          <option value="MXN">MXN</option>
          <option value="GBP">GBP</option>
        </select>
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InputLabel className={classes.label}>
          {translator('billing_your_money_converter_amount')}
        </InputLabel>
        <TextField
          placeholder=""
          variant="outlined"
          fullWidth
          required
          id="usd"
          name="usd"
          type="text"
          InputProps={{
            className: classes.textbox,
          }}
          onChange={handleChange}
          value={values.usd}
          error={errors && errors.error_usd}
          helperText={errors && errors.error_usd}
        />
      </Grid>

      <Grid item lg={6} md={12} sm={12} xs={12}>
        <InputLabel className={classes.label}>
          {translator('billing_your_money_converter_output')}
        </InputLabel>
        <TextField
          placeholder=""
          variant="outlined"
          fullWidth
          required
          className={classes.textbox}
          disabled
          id="result"
          name="result"
          type="text"
          value={result}
        />
      </Grid>
    </Grid>
  );
};
