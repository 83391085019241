import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  error: {
    width: '100%',
    height: '30px',
    // color: '#B00020',
    color: '#ca4c4c',
    margin: '0px',
    padding: '0px',
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    background: 'transparent !important',
  },
  success: {
    width: '100%',
    margin: '0px',
    padding: '0px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    background: 'transparent !important',

  },
  [theme.breakpoints.down('sm')]: {
    error: {
      width: '100%',
    },
    success: {
      width: '100%',
    },
  },
  [theme.breakpoints.down('xs')]: {
    error: {
      width: '100%',
    },
    success: {
      width: '100%',
    },
  },
}));

export default useStyles;
