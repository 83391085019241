import React from 'react';
import { Card } from '@material-ui/core';

import { useStyles, AlliesCardLogic, PropTypes } from './alliesCard.module';

const AlliesCard = (props) => {
  const classes = useStyles();
  const { values } = AlliesCardLogic(props);

  return (
    <Card className={classes.root}>
      <img className={classes.media} src={values.img} alt="Partner-logo" />
    </Card>
  );
};

AlliesCard.propTypes = {
  ally: PropTypes.string,
};

export default AlliesCard;
