import React from 'react'
import Translator from '../../classes/Translator/Translator';

const InfoSensorsLogic = () => {
    const { translator } = Translator();
    return {
        translator
    }
}

export default InfoSensorsLogic