import React from 'react'
import CardStandard from '../shared/CardStandard/cardStandard';
import { Container } from '@material-ui/core';
import {
    useStyles,
    MarketLogic,
    market_fondo_1,
    market_group_1360,
    market_group_1355,
    market_pict,
    market_pict_2,
    market_pict_1,
    market_cryptocurrency,
    market_pict_3,
    market_pict_4,
    market_grafico_EGRN_coin,
    market_grafico_EGRN_coin_1,
    market_grafico_EGRN_coin_2,
    market_1_info2_1,
    market_1_info3_1,
    market_Group_26621,
    market_Group_26622,
    market_Group_26623,
    market_1_info5_1,
    market_1_info4_1
} from './market.module';
import HomeInfoBar from '../HomeInfoBar/HomeInfoBar';
import ButtonDefault from '../shared/button/button';
import InfoQuestionSection from '../infoQuestionsSection/infoQuestionsSection';
import BottomBanner from '../bottomBanner/bottomBanner';
import BannerInterface from '../shared/bannerInterface/bannerInterface'
import Footer from '../footer/Footer';

const Market = () => {
    const classes = useStyles();
    const { translator } = MarketLogic();

    const cardsMarket = [
        {
            image: `${market_group_1360}`,
            title: `${translator('market.title_group_1360')}`,
            text: `${translator('market.text_group_1360')}`
        },
        {
            image: `${market_group_1355}`,
            title: `${translator('market.title_group_1355')}`,
            text: `${translator('market.text_group_1355')}`
        },
        {
            image: `${market_pict}`,
            title: `${translator('market.title_pict')}`,
            text: `${translator('market.text_pict')}`
        },
        {
            image: `${market_pict_2}`,
            title: `${translator('market.title_pict_2')}`,
            text: `${translator('market.text_pict_2')}`
        },
        {
            image: `${market_pict_1}`,
            title: `${translator('market.title_pict_1')}`,
            text: `${translator('market.text_pict_1')}`
        },
        {
            image: `${market_cryptocurrency}`,
            title: `${translator('market.title_cryptocurrency')}`,
            text: `${translator('market.text_cryptocurrency')}`
        },
        {
            image: `${market_pict_3}`,
            title: `${translator('market.title_pict_3')}`,
            text: `${translator('market.text_pict_3')}`
        },
        {
            image: `${market_pict_4}`,
            title: `${translator('market.title_pict_4')}`,
            text: `${translator('market.text_pict_4')}`
        },

    ];
    return (
        <>
            <BannerInterface
                text={translator('market.banner_text')}
                subtitle={translator('market.banner_subtitle')}
                backgroundImage={market_fondo_1}

            />
            <Container maxWidth="md" className={classes.containerMarket}>
                <div className={classes.gridMarket}>
                    {cardsMarket.map((values, id) => (
                        <div>
                            <CardStandard
                                image={values.image}
                                titleCenter={values.title}
                                textCenter={values.text}
                            />
                        </div>
                    ))}
                </div>
            </Container>
            <HomeInfoBar />
            <Container className={classes.containerClear}>
                <div className={classes.textCenter}>
                    <h1 className={classes.titleSimple} >{translator('market.simple_title')}</h1>
                </div>
                <div className={classes.textLeft}>
                    <p className={classes.textOur}>
                        {translator('market.simple_text_one')}</p>
                    <p className={classes.textOur}>{translator('market.simple_text_two')}</p>
                </div>
            </Container>
            <Container maxWidth="md" className={classes.containerOur}>
                <div className={classes.gridMarket}>
                    <div>
                        <CardStandard
                            image={market_grafico_EGRN_coin_1}
                            titleOnly='1 EGRN Coin'
                        />
                    </div>
                    <div >
                        <CardStandard
                            image={market_grafico_EGRN_coin_2}
                            titleOnly='1 Carbon Offset'
                        />
                    </div>
                    <div >
                        <CardStandard
                            image={market_grafico_EGRN_coin}
                            titleOnly='1 Tons Or CO2 Reduced'
                        />
                    </div>
                </div >

                <div className={classes.textLeftSubtitle}>
                    <h2 className={classes.titleForescat}>{translator('market.Growth_forescat')}</h2>
                </div>
                <div className={classes.gridOnlyCard}>
                    <img src={market_1_info2_1} alt={"Projected Growth"} className={classes.gridItem} />
                </div>

                    <div className={classes.textStellar}>{translator('market.source')}
 {/* : https://www.climatepolicyinitiative.org/delicious-and-abundant-yes-were-talking-about-voluntary-carbon-markets/ */}</div>
            
                <div className={classes.titleCenter}>
                    <ButtonDefault
                        type="buttonGreen"
                        text={translator('market.button')}
                    />
                </div>
            </Container>
  

       
            <Container className={classes.containerVoluntary}>
                <div className={classes.titleCenter}>
                    <h1 className={classes.titleEgren}>{translator('market.title_beginning')}</h1>

                </div >

                <div className={classes.textLeft}>
                    <p className={classes.textOur}>
                        {translator('market.carbon_credits_one')}
                    </p>
                    <p className={classes.textOurn}>{translator('market.carbon_credits_two')}</p>
                    <p className={classes.textOur}>{translator('market.carbon_credits_thre')}</p>
                    <p className={classes.textOur}> {translator('market.carbon_credits_four')}</p>
                    <p className={classes.textOur}>{translator('market.carbon_credits_five')}</p>

                </div>
            </Container>
            <Container maxWidth="md" className={classes.containerOur}>
                <div className={classes.titleCenter}>
                    <h3 className={classes.titleVoluntary} >{translator('market.voluntary')}</h3>
                </div>
                <div className={classes.gridOnlyCard}>
                    <img src={market_1_info3_1} alt={"Voluntary Carbon Markets"} className={classes.gridItem} />
                </div>
                <div className={classes.titleCenter}>
                    <p className={classes.textStellar} >{translator('market.source')}:
                        {/* https://www.climatepolicyinitiative.org/delicious-and-abundant-yes-were-talking-about-voluntary-carbon-markets/ */}
                    </p>
                </div>
                <div className={classes.titleCenter}>
                    <ButtonDefault
                        type="buttonGreen"
                        text={translator('market.button')}
                    />
                </div>
            </Container>
            <Container maxWidth="md" className={classes.containerSimple}>
                <div className={classes.titleCenter}>
                    <h1 className={classes.titleEgren} > {translator('market.EGRN_Coin_Generastes')}</h1>
                </div>
                <div className={classes.gridMarket}>
                    <div>
                        < CardStandard
                            image={market_Group_26621}
                            text={translator('market.Group_26621')}
                        />
                    </div>
                    <div>
                        <CardStandard
                            image={market_Group_26622}
                            text={translator('market.Group_26622')}
                        />
                    </div>
                    <div>
                        <CardStandard
                            image={market_Group_26623}
                            text={translator('market.Group_26623')}
                        />
                    </div>
                </div>
            </Container>
            <Container className={classes.containerVoluntary}>
                <div className={classes.titleCenter}>
                    <h1 className={classes.titleEgren}>{translator('market.titleSustainableOne')}</h1>
                </div>
                <div className={classes.textLeft}>
                    <p className={classes.textOur}>{translator('market.textSustainable')}</p>
                </div>
            </Container>
            <InfoQuestionSection />
            <Container maxWidth="md" className={classes.containerGraphic}>
                <div className={classes.gridOnlyCard}>
                    <img src={market_1_info4_1} alt={"Comparison of electricity"} className={classes.gridItem} />
                </div>
                <div>
                    <p className={classes.textStellar}>{translator('market.source')} : https://stellar.org/blog/diving-into-energy-use-on-stellar-blockchain-payment-efficiency-examined?locale=en</p>
                </div>
                <div className={classes.gridOnlyCard}>
                    <img src={market_1_info5_1} alt={"Energy consumption"} className={classes.gridItem} />
                </div>
                <div className={classes.titleCenter}>
                    <p className={classes.textStellar}>
                        {translator('market.source')}:
                        {/* https://www.reddit.com/r/hashgraph/comments/mfbdmm/energy_consumption_of_hedera_hashgraph/ */}
                    </p>

                </div>
            </Container>

            <BottomBanner title={translator('market.BottomBanner')} />
            <div className={classes.titleCenter}>
                <h4 className={classes.textDisclaimer} >{translator('market.disclaimer')}</h4>
            </div >
            <Footer />
        </>
    )
}

export default Market