import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  questions: {
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: '#333333',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '30px',

  },
  answers: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: '#333333',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  },
  root: {
    borderBottom: '2px solid #04679C',
  },
  expandedPanel: {
    color: '#04679C',
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white',
    },
  },
  removeCircleOutlineIcon: {
    color: '#04679C'
  },
  addCircleOutlineIcon: {
    color: '#006F56 '
  },
  link: {
    textDecoration: 'none'
  },
}));
export default useStyles;
