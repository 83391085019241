import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  button: {
    fontFamily: 'MontserratBold',
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'white',
    textAlign: 'center',
    width: '100%',
    height: '50px',
    background: '#04679C',
    fontStyle: 'normal',
    lineHeight: '20px'
  },
  buttonContainer: {
    background: '#04679C',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
  },
  mainLabel: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: 14,
    color: '#333333',
    textDecoration: 'none',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    marginLeft: 4
  },
  numberLabel: {
    fontFamily: 'MontserratRegular',
    fontWeight: 400,
    fontSize: 14,
    color: '#333333',
    textDecoration: 'none',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center'
  },
  numberLabelContainer: {
    background: '#FAFAFA',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
    alignItems: 'center',
    paddingLeft: 8,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: 550,
    },
    border: '1px solid #8edcb9',
    borderRadius: '5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
    alignItems: 'center',
  },
}));

export default useStyles;
