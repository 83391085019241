import Translator from '../../classes/Translator/Translator';
const CarbonOffsetLogic = () => {
    const { translator } = Translator();

    return {
        translator,
    };
};
export default CarbonOffsetLogic;


