import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardImage: {
    margin: `${theme.spacing(1)}px auto`,
    overflow: 'hidden',
    position: 'relative',
    objectFit: 'contain',
    height: 112,
    width: 112,
    objectPosition: 'center',
  },
  title: {
    fontFamily: 'MontserratSemiBold',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '28px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: 25,
    whiteSpace: 'pre-wrap',
  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 15,
    lineHeight: '26px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    whiteSpace: 'pre-wrap',
  },
  animation: {
    borderRadius: 16,
    border: '3px solid rgba(0, 111, 86, 0.5)',
    boxSizing: 'border-box',
    height: 380,
    width: '336px',
    margin: 'auto',
  },
  cardHovered: {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
}));
export default useStyles;
