import { makeStyles } from '@material-ui/core';
import {  forestMain1x } from './partners.module';

const useStyles = makeStyles((theme) => ({
  partnersContainer:{
    backgroundImage: `url(${forestMain1x})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: '211px',
      [theme.breakpoints.down('xs')]: {
        height: '122px',
        marginTop:'50px',
      },
    
  },
  swiperContainer:{
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  }
}));

export default useStyles;
