import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({


  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.text.nonary,
    '@media screen and (min-width:576px)': {
      fontSize: '30px',
    },
    '@media screen and (max-width:992px)': {
      fontSize: '16px',
      lineHeight: '42px',
    },
  },

  link: {
    textDecoration: 'none',
  },
  containerMoreInformationDemo: {
   marginTop: 50, marginBottom: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'
  }
}));

export default useStyles;
