import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    heading: {
        fontFamily: 'MontserratBold',
        fontWeight: 'bold',
        color: '#04679C',
        fontSize: 56,
        marginTop: 76,
    },
    body: {
        fontFamily: 'Montserrat',
        fontSize: 18,
        marginBottom: 90
    },
    title: {
        fontFamily: 'MontserratBold',
        fontWeight: 'bold',
        fontSize: 32,
        marginTop: 46,

    },
    buttonsContainer: {
        marginTop: '88px',
    },
    image: {
        display: 'block',
        margin: `${theme.spacing(1)}px auto`,
        overflow: 'hidden',
        position: 'relative',
        objectFit: 'contain',
        height: 450,
        width: 450,
        objectPosition: 'center',
    },
    link: {
        textDecoration: 'none'
    },
    [theme.breakpoints.down("md")]: {
        body: {
            textAlign: 'center',
        },
        title: {
            textAlign: 'center',
            marginTop: 30,
        },
        image: {
            display: 'none',
        },
        buttonsContainer: {
            marginTop: '40px',
        },
        containerLeft: {
            margin: 'auto',
        },
    },

    [theme.breakpoints.down("sm")]: {
        body: {
            textAlign: 'center',
        },
        title: {
            textAlign: 'center',
            marginTop: 30,
        },
        image: {
            display: 'none',
        },
        buttonsContainer: {
            marginTop: '40px',
        },
        containerLeft: {
            margin: 'auto',
        },

    },
}));
export default useStyles;
