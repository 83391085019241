import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  
      cardXchange: {
        padding: theme.spacing(2),
        borderRadius: 6,
        backgroundColor: '#fff',
        boxShadow: theme.shadows[3], 
        textAlign: 'center',
      },
      titleXchange: {
        fontSize: '2em',
        fontWeight: 500,
        color: '#020C72', 
        fontFamily:'MontserratMedium',
      },
    
}));

export default useStyles;
