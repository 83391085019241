import {
  co2,
  world,
  footprintValue,
  transaction,
  stellar,
  stableCoin,
  company,
  egrnXchange,
  coins_info1en,
  coins_info1es,
  coins_info2,
  coins_info3,
  coins_info4,
  coins_info5,
  white_paper,
} from './coins.module';
import Translator from '../../classes/Translator/Translator';
import { useEffect, useState } from 'react';

const CoinsLogic = () => {
  const { translator, isEnglish } = Translator();
  const [values, setValues] = useState({
    coins_info1: isEnglish() ? coins_info1en : coins_info1es,
    coins_info2,
    coins_info3,
    coins_info4,
    coins_info5,
    white_paper,
  });
  const heading = 'EGRN COIN';

  useEffect(() => {
    setValues({
      ...values,
      coins_info1: isEnglish() ? coins_info1en : coins_info1es,
    });
  }, [isEnglish()]);

  const mediaCards = [
    {
      image: stableCoin,
      title: 'cards.stableCoinTitle',
      description: 'cards.stableCoinDescription',
    },
    {
      image: world,
      title: 'cards.worldTitle',
      description: 'cards.worldDescription',
    },
    {
      image: footprintValue,
      title: 'cards.footprintValueTitle',
      description: 'cards.footprintValueDescription',
    },
    {
      image: stellar,
      title: 'cards.stellarTitle',
      description: 'cards.stellarDescription',
    },
    {
      image: company,
      title: 'cards.companyTitle',
      description: 'cards.companyDescription',
    },
    {
      image: egrnXchange,
      title: 'cards.egrnXchangeTitle',
      description: 'cards.egrnXchangeDescription',
    },
    {
      image: co2,
      title: 'cards.co2Title',
      description: 'cards.co2Description',
    },
    {
      image: transaction,
      title: 'cards.transactionTitle',
      description: 'cards.transactionDescription',
    },
  ];

  return {
    heading,
    mediaCards,
    translator,
    values,
  };
};

export default CoinsLogic;
