import { Grid, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ButtonDefault from '../shared/button/button';
import { useStyles, BannerEgreenLogic,} from './banner.module';
import { useHistory } from 'react-router-dom';

export default function BannerEgreen() {
  const classes = useStyles();
  return (
    <Grid container className={classes.mainContainer}>
      <BannerContainer />
    </Grid>
  );
}

const BannerContainer = () => {
  const { translator } = BannerEgreenLogic();
  const classes = useStyles();
  const { push } = useHistory();

  return (
    <Container maxWidth="lg">
      <Grid container className={classes.BannerContainer}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.titleAndDescription}
        >
          <Typography variant="h4" className={classes.bannerTitle}>
            {' '}
            {translator('with_egreen1')}
            <br></br>
            {translator('with_egreen2')}
          </Typography>
          <Typography variant="h1" className={classes.bannerSlogan}>
            {translator('description_banner')}
          </Typography>
        </Grid>
        <Grid
          item
          className={classes.mainButton}
          lg={8}
          md={12}
          sm={12}
          xs={12}
        >
          <ButtonDefault
            type="buttonGreen"
            text={translator('learn_more')}
            className={classes.bannerLink}
            changeView={() => push('/CarbonManagement')}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

