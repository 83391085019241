class Commission {
  static get USD() {
    return 'USD_RATE';
  }

  static get SELL_OFFER_RATE() {
    return 'SELL_OFFER_RATE';
  }

  static get BUY_OFFER_RATE() {
    return 'BUY_OFFER_RATE';
  }

  static get BUY_BONDS_RATE() {
    return 'BUY_BONDS_RATE';
  }

  constructor({ rate, type }) {
    this.rate = rate;
    this.type = type;
    this.parseRate = rate / 100;
    this.rateForCalculation = 1 + this.parseRate;
  }
}

export default Commission;
