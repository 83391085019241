import API from '../../../classes/Api/Api';
import Formatter from '../../../classes/Formatter/Formatter';

const MoneyConverterService = () => {
  const convert = async (amount = 0, currency) => {
    const url =
      'https://openexchangerates.org/api/latest.json?app_id=ee8d0b47c87f45b2acc2fb60ed9928a2&base=USD';
    const api = new API(url);
    const response = await api.get();
    const rates =
      response.data && response.data.rates ? response.data.rates : {};
    return Formatter.round(Number(amount) * Number(rates[currency]));
  };

  return { convert };
};

export default MoneyConverterService;
