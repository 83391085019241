import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: 'auto',
    marginTop: 48,
    padding: 24,
    width: '90%',
    maxWidth: '1024px',
    height: 'auto',
    background: '#FFF',
    boxShadow: '0 0 4px 4px rgba(0,0,0,0.1)',
    borderRadius: 28,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  infoBarContainer: {
    margin: 12,
  },
}));
export default useStyles;
