import React from 'react';
import Chart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import {
  GraphicExchangeLogic,
  GraphicExchangeService,
  useStyles,
} from './GraphicExchange.module';

const GraphicExchange = ({ series, customOptions, customClass, foreColor }) => {
  const { options, seriesValue, actualValue, translator } =
    GraphicExchangeLogic({
      series,
      customOptions,
      customClass,
      foreColor,
      GraphicsService: GraphicExchangeService,
    });

  const classes = useStyles();

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.mainContainer}
      >
        <Grid item xs={12} className={classes.textContainer}>
          <span className={classes.mainText}>
            {`${translator(
              'graphicExchange.actual_value'
            )} ${actualValue} (USD) `}
          </span>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${classes.chartContainer} ${customClass}`}
        >
          <Chart
            options={options}
            series={seriesValue}
            type="line"
            width="100%"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GraphicExchange;
