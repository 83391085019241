import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  imgOurServices: {
    width: '100%',
    '@media screen and (min-width:576px)': {
      width: '100%',
      height: '100%',
    },
    '@media screen and (max-width:992px)': {
      width: '100%',
      height: '404px',
    },
  },
  containerTitleServices: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin:20
  },
  titleOur:{
    fontFamily: 'MontserratBold',
    fontSize: '60px',
    fontWeight: 700,
    lineHeight: '66px',
    letterSpacing: '-3px',
    textAlign: 'center',
    color: theme.palette.text.nonary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '56px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '56px',
    },

  },
  titleServices: {
    fontFamily: 'MontserratBold',
    fontSize: '60px',
    fontWeight: 700,
    lineHeight: '66px',
    letterSpacing: '-3px',
    textAlign: 'center',
    color: "#06E89F",
    [theme.breakpoints.down('xs')]: {
      fontSize: '56px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '56px',
    },
  },
  containerServices:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  containerFrameServicesData:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

}));
export default useStyles;




