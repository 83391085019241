class Storage {
  constructor(key, value = null) {
    this.key = key;
    this.value = value;
  }

  setKey(key) {
    this.key = key;
    return this;
  }

  setValue(value) {
    this.value = value;
    return this;
  }

  setItem() {
    return localStorage.setItem(this.key, this.value);
  }

  getItem() {
    return localStorage.getItem(this.key);
  }

  removeItem() {
    return localStorage.removeItem(this.key);
  }

  removeAllItems() {
    return localStorage.clear();
  }
}

export default Storage;
