import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  Box,
  Breadcrumbs,
  Container,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { NavigateNext, Check } from '@material-ui/icons';
import YourInvestment from '../shared/yourInvestment/yourInvestment';
import MoneyConverter from '../shared/moneyConverter/moneyConverter';
import PaymentMethod from '../shared/paymentMethod/paymentMethod';
import Footer from '../footer/Footer';
import Form from './form';

import {
  BillingDetailLogic,
  validator,
  BillStyles,
  BillingDetailService,
  PropTypes,
} from './billingDetail.module';
import { ProjectDetailService } from '../ProjectDetail/projectDetail.module';
import { DashboardService } from '../dashboard/dashboard.module';
function QontoStepIcon(props) {
  const { useQontoStepIconStyles } = BillStyles();
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

function ColorlibStepIcon(props) {
  const { useColorlibStepIconStyles } = BillStyles();
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: '1',
    2: '2',
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export default function BillingDetail() {
  const { id } = useParams();
  const { useStyles, ColorlibConnector } = BillStyles();
  const classes = useStyles();
  const service = BillingDetailService();
  const service_for_project = ProjectDetailService();
  const service_for_user = DashboardService();
  const {
    errors,
    values,
    activeStep,
    steps,
    data,
    onHandleChange,
    handleNext,
    handleBack,
    handleReset,
    handleSubmit,
    translator,
  } = BillingDetailLogic(
    () => console.log('formulario enviado'),
    validator,
    () => service.getTransaction(id),
    service_for_project.getProject,
    service_for_user.getUser
  );

  const props = {
    errors,
    values,
    handleSubmit,
    handleNext,
    handleBack,
    handleReset,
    onHandleChange,
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box>
            <Form {...props} />
          </Box>
        );
      case 1:
        return (
          <Box>
            <PaymentMethod
              id={id}
              available={data.user.availableUSD}
              total={values.transaction.total}
              type={values.transaction.type}
              transaction={values.transaction}
            />
          </Box>
        );
      case 2:
        return '';
      default:
        return 'Unknown step';
    }
  }

  return (
    <>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_home')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to="/projects">
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_projects')}
            </Typography>
          </Link>
          <Link
            className={classes.customLink}
            to={`/investment/${values.project.id}`}
          >
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_product_selection')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to={`/billing/${id}`}>
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_payment')}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Box>
      <Container>
        <Box pt={5} mt={5}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <div className={classes.root}>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<ColorlibConnector />}
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12} />

            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div>
                    {activeStep === steps.length ? (
                      <div />
                    ) : (
                      <div>
                        <div className={classes.instructions}>
                          {getStepContent(activeStep)}
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} />
              </Grid>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <YourInvestment {...values} />
                </Grid>

                <Grid
                  className={classes.moneyConverter}
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <MoneyConverter />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
