import React from 'react';
import BlogPostsDetailsLogic from './BlogPostsDetails.logic';
import useStyles from './BlogpostDetails.styles';
import DOMPurify from 'dompurify';
import { Container, Grid } from '@material-ui/core';

export default function BlogPostsDetails(props) {
  const { values } = BlogPostsDetailsLogic(props);
  const classes = useStyles();
  const htmlContent = values.content;
  return (
    <>
      <Container>
        <Grid container spacing={0} >
          <Grid item lg={12} md={12} sm={12} xs={12} align="left" >

            <div className={classes.article} style={{
              fontSize: '15px', fontFamily: 'Montserrat',
            }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlContent) }}></div>

          </Grid>
        </Grid>
      </Container>
    </>
  );
}
