import { useState, useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';
import Storage from '../../classes/Storage/Storage';

const BillingDetailLogic = (
  callback,
  validate,
  getTransaction,
  getProject,
  getUser
) => {
  const { translator } = Translator();
  const [data, setData] = useState({ user: {} });
  const initialValues = {
    name: '',
    lastName: '',
    country: '',
    dni: '',
    city: '',
    address: '',
    code: '',
    prefix: '+56 Chile',
    phone: '',
    email: '',
    project: {},
    transaction: {},
  };
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const id = new Storage('id').getItem();
    getUser(id).then((user) => {
      setData({ ...data, user });
    });
  }, []);

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
    setErrors(validate(values));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
  };

  function getSteps() {
    return [
      `${translator('billing_step_label_billing')}`,
      `${translator('billing_step_label_payment')}`,
    ];
  }
  const [activeStep, setActiveStep] = useState(1);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, isSubmitting, callback]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getTransaction().then((transaction) => {
      getProject(transaction.project_id).then((project) => {
        setValues({ ...values, transaction, project });
      });
    });
  }, []);

  return {
    onHandleChange,
    values,
    activeStep,
    steps,
    handleNext,
    handleBack,
    handleReset,
    handleSubmit,
    errors,
    translator,
    data,
  };
};

export default BillingDetailLogic;
