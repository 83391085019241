import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#04679C',
    alignItems: 'center',
    borderRadius: 16,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 446,
      marginLeft: 36,
    },
  },
  whiteContainer: {
    background: '#FFFFFF',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: 16,
  },
  whiteContainerPayment: {
    background: '#FFFFFF',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 16,
  },
  titleContainer: {
    background: '#04679C',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  titleContainerPayment: {
    background: '#04679C',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  header: {
    color: '#333333',
    fontFamily: 'MontserratBold',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '26px',
    flexDirection: 'column',
    textAlign: 'left',
    marginBottom: 6,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
  },
  headerProject: {
    color: '#333333',
    fontFamily: 'MontserratRegular',
    fontWeight: 400,
    fontSize: 16,
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
  },
  subtitle: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: 16,
    flexDirection: 'row',
    textAlign: 'left',
    color: '#333333',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
    },
  },
  yourInvestementTitle: {
    color: '#FFF',
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: 32,
  },
  yourInvestementSubTitle: {
    color: '#FFF',
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: 18,
  },
  text: {
    margin: 2,
    fontFamily: 'MontserratRegular',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '26px',
    color: '#333333',
  },
  total: {
    fontFamily: 'MontserratLight',
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '19,5px',
    fontStyle: 'normal',
    margin: 0,
    padding: 0,
    color: '#333333',
  },
  projectimg: {
    width: 97,
    height: 97,
    borderRadius: 16,
  },
  containerTotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    marginBottom: 3,
  },
}));

export default useStyles;
