import React from 'react';
import { Link } from 'react-router-dom';
import {
    Typography,
    Container,
    Grid,
    CardMedia
} from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import {
    NotFoundLogic,
    useStyles
} from './notFound.module';
const NotFound = () => {
    const {
        heading,
        title,
        body,
        image,
        history,
        translator
    } = NotFoundLogic();

    const classes = useStyles();


    return (
        <Container maxWidth="md">
            <Grid container align="center" spacing={4}>
                <Grid item lg={12} sm={12} xs={12}>
                    <Typography
                        className={classes.heading}
                        paragraph
                        align="center"
                    >
                        {heading}
                    </Typography>
                </Grid>
                <Grid item lg={7} md={7} sm={9} xs={12} className={classes.containerLeft}>
                    <Typography
                        className={classes.title}
                        paragraph
                        align="left"
                        variant="h5"
                    >
                        {title}
                    </Typography>
                    <Typography
                        className={classes.body}
                        align="justify"
                        variant="body1"
                        gutterBottom
                    >
                        {body}
                    </Typography>
                    <Grid
                        justify="space-between"
                        container
                        spacing={2}
                    >
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Link to="/" className={classes.link}>

                                <ButtonDefault
                                    text={translator('notFoundGoHome')}
                                    type="whitegreen"
                                    fullWidth>

                                </ButtonDefault>
                            </Link>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12} >
                            <ButtonDefault
                                text={translator('notFoundToReturn')}
                                type="blue"
                                fullWidth
                                changeView={history.goBack}>

                            </ButtonDefault>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item lg={5} md={6} sm={12} xs={12}>
                    <CardMedia className={classes.image} image={image} title={image} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFound;
