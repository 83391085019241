import Translator from '../../../classes/Translator/Translator';

const LoadingScreenLogic = () => {
    const { translator } = Translator();

    return {
        translator,
    };
};

export default LoadingScreenLogic;
