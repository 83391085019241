import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Container,
  Box,
  Breadcrumbs,
  Grid,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import Accordions from '../shared/accordions/accordions';
import Footer from '../footer/Footer';

import { useStyles, FrecuentQuestionsLogic } from './frequentQuestions.module';

const FrecuentQuestions = () => {
  const classes = useStyles();
  const { translator, questions } = FrecuentQuestionsLogic();

  const heading = `${translator('frequentQuestions.title')}`;
  const subtitle = `${translator('frequentQuestions.text')}`;

  return (
    <>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>
              {translator('frequentQuestions.Home')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to="/questions">
            <Typography className={classes.fontLink}>
              {translator('frequentQuestions.frequentQuestions')}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Box>
      <Container maxWidth="md" className={classes.container}>
        <main>
          <Grid container alignItems="center">
            <Grid item lg={12} sm={12} xs={12}>
              <Box mt={8}>
                <Typography
                  className={classes.heading}
                  variant="h2"
                  paragraph
                  align="center"
                >
                  {heading}
                </Typography>
              </Box>
              <Box mb={8}>
                <Typography
                  className={classes.subtitle}
                  variant="body1"
                  align="center"
                >
                  {subtitle}
                </Typography>
              </Box>
              <Box mb={8}>
                {questions.map((accordion) => {
                  const {
                    ariaControls,
                    id,
                    questions,
                    answers,
                    answers1,
                    answers2,
                  } = accordion;
                  return (
                    <div key={accordion.id}>
                      <Accordions
                        aria-controls={ariaControls}
                        id={id}
                        questions={questions}
                        answers={answers}
                        answers1={answers1}
                        answers2={answers2}
                        children={
                          <Typography
                            variant="body1"
                            color="initial"
                            align="left"
                            className={classes.answers}
                          >
                            {translator('frequentQuestions.childrenOne')}{' '}
                            <Link className={classes.link} to="/restore">
                              <strong>
                                {' '}
                                {translator(
                                  'frequentQuestions.childrenTwo'
                                )}{' '}
                              </strong>
                            </Link>
                            {translator('frequentQuestions.childrenThree')}
                          </Typography>
                        }
                      />
                    </div>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </>
  );
};
export default FrecuentQuestions;
