import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(8),
  },
  breadcrumbContainer: {
    margin: '75px auto -40px auto',
    maxWidth: '1350px',
    display: 'flex',
  },
  customLink: {
    textDecoration: 'none',
    color: theme.palette.text.tertiary,
    '&:hover': {
      color: theme.palette.text.tertiary,
      textDecoration: 'none',
    },
  },
  customLinkBody: {
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.text.tertiary,
    '&:hover': {
      color: theme.palette.text.tertiary,
      textDecoration: 'none',
    },
  },
  fontLink: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
  },
  howtoStart: {
    marginTop: `${theme.spacing(10)}px`,
  },
  heading: {
    fontFamily: 'MontserratExtraBold',
    fontWeight: 800,
    fontStyle: 'normal',
    fontSize: 56,
    lineHeight: '64px',
    color: theme.palette.tertiary.main,
    marginTop: theme.spacing(3),
    marginBottom: 70,
  },
  subtitle: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 32,
    lineHeight: '40px',
    color: theme.palette.tertiary.main,
    marginBottom: 32,
  },
  message: {
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: '26px',
    color: theme.palette.text.primary,
    whiteSpace: 'pre-wrap',
  },
  subtitleCards: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontStyle: 'norma',
    fontSize: 32,
    lineHeight: '40px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginBottom: 45,
  },
  cardCoins: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    padding: 0,
    margin: 0,
  },
}));
export default useStyles;
