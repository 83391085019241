import {Typography} from '@material-ui/core';
import {useStyles, arrowBackLogic}  from './arrowBack.module'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Link } from 'react-router-dom';

export default function ArrowBack({to}) {

    const classes = useStyles();
    const {translator} = arrowBackLogic();

    return(
        <div className={classes.breadcrumbContainer}>
        <Link to={to}>
        <KeyboardArrowLeftIcon
           style={{
            fontSize: 42,
            fontFamily: 'Montserrat',
            color: '#05649c',
            cursor: 'pointer',
          }} 
        />
        </Link>
        <Typography className={classes.textBack}>
          {translator('confirmOffer.back')}
        </Typography>
      </div>
    )
}