import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Session from '../../../classes/Session/Session';
import Translator from '../../../classes/Translator/Translator';

const ProjectCardLogic = (props) => {
  const { translator } = Translator();
  const history = useHistory();
  const { project = {} } = props;
  const [values, setValues] = useState({
    project,
  });
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });
  const goToProject = () => {
    history.push(`/project/${values.project.id}`);
    window.scrollTo(0, 0);
    window.location.reload();
  };

  const handleInvest = (e) => {
    e.preventDefault();
    const has_session = new Session({}).hasSession();
    const { id } = e.currentTarget;
    if (has_session) {
      return history.push(`/project/${values.project.id}`);
    } else {
      return history.push('/login', {
        callback: `/project/${values.project.id}`,
      });
    }
  };
  const goToProjects = (e) => {
    e.preventDefault();
    const has_session = new Session({}).hasSession();
    const { id } = e.currentTarget;
    if (has_session) {
      return history.push(`/projects`);
    } else {
      return history.push('/login', {
        callback: `/projects`,
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      project: props.project,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return {
    goToProject,
    handleInvest,
    values,
    translator,
    state,
    setState,
    goToProjects
  };
};

export default ProjectCardLogic;
