import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '272px',
    height: '432px',
    background: theme.palette.background.cardTeamMember,
    borderRadius: '8px',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
  image: {
    maxWidth: '100%',
    padding: 5,
    height: 280,
    marginBottom: '-5px',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px'
  },
  name: {
    fontFamily: 'MontserratSemiBold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    marginTop: '18px',
    marginBottom: '17px',
  },
  role: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'left',
    color: theme.palette.text.role,
    marginTop: '0px',
    marginBottom: '17px',
  },
  linkedinIcon: {
      width: '40px',
     height: '40px',

  },
  containerMember: {
    marginLeft: 10,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
}));

export default useStyles;
