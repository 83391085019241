import { useState, useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';

const DemoFormLogic = (validate, callback, props) => {
  const { translator } = Translator();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState({
    fullName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    websiteURL: '',
    numberEmployees: 1,
    blogSubscribe: false,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleCheckbox = (e) => {
    const _checked = e.target.checked;
    setValues({ ...values, blogSubscribe: _checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
      setIsSubmitting(false);
    }
  }, [errors, callback, isSubmitting]);

  return {
    checked,
    errors,
    handleChange,
    handleCheckbox,
    handleSubmit,
    isSubmitting,
    isValid,
    setErrors,
    setValues,
    translator,
    values,
  };
};
export default DemoFormLogic;
