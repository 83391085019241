import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spinner: {
    border: '4px solid rgba(0, 0, 0, .1)',
    width: '2.25rem',
    height: '2.25rem',
    borderRadius: '50%',
    borderLeftColor: '#09f',
    animation: 'spin 1s linear infinite',
    '@keyframe spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
});

export default useStyles;
