import React from 'react';
import { Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ButtonDefault from '../shared/button/button';
import {
  MoreInformationDemoLogic,
  useStyles,
} from './MoreInformationDemo.module';

const MoreInformationDemo = ({ key_translator, go_to }) => {
  const classes = useStyles();
  const { translator } = MoreInformationDemoLogic();
  const { push } = useHistory();

  return (
    <Container maxWidth="md" className={classes.containerMoreInformationDemo}>
      <p className={classes.title}>{key_translator}</p>
      <ButtonDefault
        type="defaultGreenMint"
        text={translator('pricing.buttonText')}
        changeView={() => push(go_to)}
      />
    </Container>
  );
};

export default MoreInformationDemo;
