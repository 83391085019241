import React from 'react';
import { Container, Typography } from '@material-ui/core';
import Footer from '../footer/Footer';
import CardProjects from '../shared/cardProjects/CardProjects';
import { ProjectsLogic, useStyles } from './projects.module';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import bannerImg from '../../assets/images/bannerImg/forest_projects.webp';
const Projects = () => {
  const { translator } = ProjectsLogic();
  const classes = useStyles();

  return (
    <>
      <BannerInterface
        text={translator('projects.textBanner')}
        backgroundImage={bannerImg}
      />
      <Container>
        <div className={classes.flexContainer}>
          <Typography className={classes.textHeader}>
            {translator('projects.textHeader')}
          </Typography>
        </div>
        <CardProjects type={'Full'} text={translator('invest')} />
      </Container>
      <Footer />
    </>
  );
};

export default Projects;
