import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    marginTop:110,
    marginBottom:50,
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: 170,
    width: 189,
  },
 
  message: {
    marginTop:10,
    marginBottom:10,
    fontFamily: 'MontserratSemiBold',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    color: theme.palette.text.quaternary,
  },


  
}));
export default useStyles;
