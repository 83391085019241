import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';

const ConfirmOfferService = () => {
  const sendProcessOffers = async (values) => {
    const api = new API(`${URL.URL_SERVER}/process-offers`);
    const http_response = await api.post(values);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return response.wasSuccessfull() ? response.response : new CustomError('Error');
    }
  };
  const sendConfirmOffer = async (data) => {
    const api = new API(`${URL.URL_SERVER}/confirm-offers`);
    const http_response = await api.post(data);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return !response.hasContent();
    }
  };
  return {
    sendProcessOffers,
    sendConfirmOffer,
  };
};

export default ConfirmOfferService;
