import Translator from '../../classes/Translator/Translator';

const ProjectsLogic = () => {


  const { translator } = Translator();

  return {
    translator,
  };
};

export default ProjectsLogic;
