import Translator from '../../classes/Translator/Translator';

const questions = [
    {
        "id": 1,
        "ariaControls": "panel1",
        "questions": "questionsOne",
        "answers": "questionsAnswersOne",
        "answers1": "",
        "answers2": "",

    },
    {
        "id": 2,
        "ariaControls": "panel2",
        "questions": "questionsTwo",
        "answers": "questionsAnswersTwo",
        "answers1": "",
        "answers2": "",

    },
    {
        "id": "3",
        "ariaControls": "panel3",
        "questions": "questionsThree",
        "answers": "questionsAnswersThree",
        "answers1": "",
        "answers2": "",

    },
    {
        "id": "4",
        "ariaControls": "panel4",
        "questions": "questionsFour",
        "answers": "questionsAnswersFour",
        "answers1": "",
        "answers2": "",

    },
    {
        "id": "5",
        "ariaControls": "panel5",
        "questions": "questionsFive",
        "answers": "questionsAnswersFive",
        "answers1": "questionsAnswers1Five",
        "answers2": "questionsAnswers2Five",
    },
    {
        "id": "6",
        "ariaControls": "panel6",
        "questions": "questionsSix",
        "answers": "questionsAnswersSix",
        "answers1": "",
        "answers2": "",

    },
    {
        "ariaControls": "panel7",
        "id": "question7",
        "questions": "questionsSeven",
        "answers": "questionsAnswersSeven",
        "answers1": "",
        "answers2": "",

    },
    {
        "id": "8",
        "ariaControls": "panel8",
        "questions": "questionsEight",
        "answers": "questionsAnswersEight",
        "answers1": "",
        "answers2": "",


    }
];
const FrecuentQuestionsLogic = () => {
    const { translator } = Translator();

    return {
        translator,
        questions
    };
};

export default FrecuentQuestionsLogic;
