import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const CardcurrentBalanceLogic = (card) => {
  const { translator } = Translator();
  const [values, setValues] = useState({ value: '0.00', available: '0.00' });
  useEffect(() => {
    setValues({ ...values, ...card });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);
  return {
    translator,
    values
  };
};
export default CardcurrentBalanceLogic;
