import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    margin: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
