import React from 'react';
import { useEffect } from 'react';
import { Container, Grid } from '@material-ui/core';
import Footer from '../footer/Footer';
import {
  useStyles,
  AboutUsSectionLogic,
  mission,
  vision,
  purpose,
  aboutUs,
} from './aboutUsSection.module';
import CssBaseline from '@material-ui/core/CssBaseline';
import TeamMembers from '../shared/teamMembers/teamMembers';
import CardStandard from '../shared/CardStandard/cardStandard';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import TeamExperts from '../shared/teamExperts/teamExperts';
import BottomBanner from '../bottomBanner/bottomBanner';

const AboutUsSection = () => {
  const classes = useStyles();
  const { translator } = AboutUsSectionLogic();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BannerInterface text={translator('aboutUsSection.title')} backgroundImage={aboutUs} />
      <Container maxWidth="lg">
        <div className={classes.root}>
          <CssBaseline />
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <section className={classes.workBased}>
                <h4 className={classes.centralTitle_section}>
                  {translator('aboutUsSection.centralTitle')}
                </h4>
                <Grid className={classes.cardsContainer} container spacing={5}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className={classes.cardContainer}
                  >
                    <CardStandard
                      image={mission}
                      title={'aboutUsSection.titleMision'}
                      text={'aboutUsSection.textMision'}
                    ></CardStandard>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className={classes.cardContainer}
                  >
                    <CardStandard
                      image={vision}
                      title={'aboutUsSection.titleVision'}
                      text={'aboutUsSection.textVision'}
                    ></CardStandard>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    className={classes.cardContainer}
                  >
                    <CardStandard
                      image={purpose}
                      title={'aboutUsSection.titleProposito'}
                      text={'aboutUsSection.textProposito'}
                    ></CardStandard>
                  </Grid>
                </Grid>
              </section>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.teamCards}
            >
              <h4 className={classes.centralTitle_section}>
                {translator('aboutUsSection.titleAbout')}
              </h4>
              <p className={classes.aboutUs_text}>
                {translator('aboutUsSection.textAbout')}
              </p>
              <TeamMembers />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.teamCards}
            >
              <h4 className={classes.centralTitle_section}>
                {translator('aboutUsSection.titleExperts')}
              </h4>
              <TeamExperts />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.bannerContainer}
            >
              <BottomBanner title={translator('aboutUsSection.textBanner')} />
            </Grid>
          </Grid>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default AboutUsSection;
