import React from 'react';
import { useStyles } from './GraphicExchangePage.module';
import GraphicExchange from '../shared/graphicExchange/GraphicExchange';
import { Grid } from '@material-ui/core';

const GraphicExchangePage = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.cardXchange}
      >
        <div className={classes.titleXchange}>eGrnXchange</div>
        <GraphicExchange />
      </Grid>
    </Grid>
  );
};
export default GraphicExchangePage;
