import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
  rootCard: {
    background: '#007aff',
    display: 'grid',
    justifyItems: 'center',
    padding:15,
    gap:10,
    alignItems: 'flex-start',
    '@media screen and (min-width:576px)': {
      gridTemplateColumns: 'auto 1fr',
    },
    '@media screen and (max-width:992px)': {
      gridTemplateColumns: '1fr',
      
    },
    
  },

  media: {
    width: 240,
    height: 203,
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 240,
    },

  },
  cardHovered: {
    transform: 'scale3d(1.05, 1.05, 1)',
  },
  actions: {
    paddingLeft:6,
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end', 
    flexDirection: 'column',
    '@media screen and (min-width:576px)': {
        alignItems: 'flex-start',
        height: '100%',
    },
    '@media screen and (max-width:992px)': {
        alignItems: 'center',
        height: '100%',
    },
  },
  cardText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
},
cardActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
},
  textNews: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '29px',
    color: theme.palette.text.nonary,
    marginBottom: '1em',
    '@media screen and (min-width:576px)': {
      textAlign: 'left',
    },
    '@media screen and (max-width:992px)': {
      textAlign: 'center',
    },
    title: {
      fontFamily: 'MontserratBold',
      fontSize: '25px',
      fontWeight: 700,
      lineHeight: '27.5px',
      textAlign: 'left',
      color: 'red',
    },
  },
}));
export default useStyles;
