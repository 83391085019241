import React from 'react';
import useStyles from './projectItem.styles';
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import Counter from '../counter/counter';
import ProjectItemLogic from './projectItem.logic';
import Formatter from '../../../classes/Formatter/Formatter';

const { ProjectItemService } = require('./projectItem.module');

const ProjectItem = (props) => {
  const classes = useStyles();

  const service = ProjectItemService();
  const { values, handleSubtract, handleRadioChange, handleAdd, translator } =
    ProjectItemLogic(props, service.getCommissionByType);
    console.log(
      JSON.stringify(values.project.is_available_for_egrn) + 'Forwards'
    );
    console.log(JSON.stringify(values.project.is_available_for_nft) + 'Credit ');
    console.log(JSON.stringify(values.type) + 'type');
  return (
    <Grid container className={classes.container}>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography
            className={classes.columnTitles_product}
            variant="subtitle1"
          >
            {translator('investment_project')}
          </Typography>
        </div>
        <div className={classes.projectContainer}>
          <IconButton className={classes.iconContainer} />
          <img
            className={classes.projectImg}
            src={values.project.main_image}
            alt="main_image_project"
          />
          <div className={classes.projectName}>
            <Typography variant="subtitle2">
              {values.project.title_project}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography
            className={classes.columnTitles_product}
            variant="subtitle1"
          >
            {translator('investment_product')}
          </Typography>
        </div>
        <div className={classes.container2}>
          <div className={classes.radioContainer}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="credit_type"
                name="credit"
                value={values.type}
                onChange={handleRadioChange}
              >
                {values.project.is_available_for_egrn && (
                  <FormControlLabel
                    value="buy_bonds"
                    control={<Radio />}
                    label="Forwards"
                  />
                )}
                {values.project.is_available_for_nft && (
                  <FormControlLabel
                    value="buy_nft"
                    control={<Radio />}
                    label="Carbon Credit"
                  />
                )}
                {values.project.is_available_for_sbt && (
                  <FormControlLabel
                    value="buy_sbt"
                    control={<Radio />}
                    label="Carbon Offset"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography
            className={classes.columnTitles_amount}
            variant="subtitle1"
          >
            {translator('investment_amount')}
          </Typography>
        </div>
        <div className={classes.container2}>
          <Counter
            className={classes.counterContainer}
            type="small"
            increment={handleAdd}
            decrement={handleSubtract}
            counter={values.counter}
          />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography
            className={classes.columnTitles_price}
            variant="subtitle1"
          >
            {translator('investment_price')}
          </Typography>
        </div>
        <div className={classes.container2}>
          <div className={classes.pricesContainer}>
            <Typography className={classes.price} variant="body1">
              USD {parseFloat(values.price).toFixed(2)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography
            className={classes.columnTitles_subtotal}
            variant="subtitle1"
          >
            {translator('investment_subtotal')}
          </Typography>
        </div>
        <div className={classes.container2}>
          <div className={classes.pricesContainer}>
            <Typography className={classes.priceItem} variant="body1">
              USD {parseFloat(values.subtotal).toFixed(2)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography className={classes.columnTitles_fee} variant="subtitle1">
            {translator('investment_fee')}
          </Typography>
        </div>
        <div className={classes.container2}>
          <div className={classes.pricesContainer}>
            <Typography className={classes.priceItem} variant="body1">
              USD {Formatter.round(values.fee)}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        <div className={classes.columnTitles_container}>
          <Typography
            className={classes.columnTitles_total}
            variant="subtitle1"
          >
            {translator('investment_total')}
          </Typography>
        </div>
        <div className={[classes.container2 + ' ' + classes.container3]}>
          <div className={classes.pricesContainer}>
            <Typography className={classes.totalPrice} variant="body1">
              USD {parseFloat(values.total).toFixed(2)}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ProjectItem;
