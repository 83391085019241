class ProjectIpfs {
  constructor({ _id, name, hash, type, url, description }) {
    this.id = _id;
    this.name = name;
    this.hash = hash;
    this.type = type;
    this.url = url;
    this.description = description;
  }
}

export default ProjectIpfs;
