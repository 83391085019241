import React, { useState } from 'react';
import { Box, IconButton } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles, CounterLogic, PropTypes } from './counter.module';
import Popover from '@material-ui/core/Popover';

const Counter = (props) => {
  const classes = useStyles();
  const { values } = CounterLogic(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [info] = useState('Minimum for purchase agreement.');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={classes[values.isBackgroundWhite || values.rootClass]}>
      <IconButton
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className={classes[values.buttonClass]}
        onClick={props.decrement}
      >
        <RemoveIcon />
      </IconButton>
      <div className={classes[values.counterClass]}>
        <p>{props.counter}</p>
        <p>{values.text}</p>
      </div>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {info}
      </Popover>

      <IconButton
        className={classes[values.buttonClass]}
        onClick={props.increment}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

Counter.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  counter: PropTypes.number,
  increment: PropTypes.func,
  decrement: PropTypes.func,
  isBackgroundWhite: PropTypes.bool,
};

export default Counter;
