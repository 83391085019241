import Translator from '../../classes/Translator/Translator';
import { BlogService } from '../blog/blog.module';
import { useEffect, useState } from 'react';

const labels = {
  es: '#spanish',
  en: '#english',
};

const NewsSectionLogic = () => {
  const { translator, language } = Translator();
  const [photo, setPhoto] = useState();
  const [posts, setPosts] = useState();

  async function start() {
    const { resources: posts } = await BlogService().getPosts({});
    const filtered_posts = await posts.filter((card) => {
      const cardLabelsString = card.labels.join(' ');
      return cardLabelsString.includes(labels[language]);
    });
    const image = filtered_posts[0].image;
    setPhoto(image);
    setPosts(filtered_posts.slice(0, 3));
  }

  useEffect(() => {
    start();
  }, []);

  return {
    translator,
    photo,
    posts,
  };
};

export default NewsSectionLogic;
