import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@material-ui/core';
import { carouselLogic, useStyles } from './Carousel.module';

export default function Banner({ props }) {
  const classes = useStyles();
  const { items } = carouselLogic(props);

  return (
    <Carousel
      navButtonsAlwaysInvisible={false}
      animation="slide"
      className={classes.containerCarrousel}
      indicatorContainerProps={{ style: { display: 'none' } }}
    >
      {items.map((item, i) => (
        <Box key={i} className={classes.carousel}>
          <Item key={i} item={item} />
        </Box>
      ))}
    </Carousel>
  );
}

const Item = ({ item }) => {
  return item;
};
