import React from 'react';
import { useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';
import { termsConditionsEsp, termsConditionsEng } from './cookie.module';

const ButtonCokkies = () => {
  const { translator, isEnglish } = Translator();
  const getCookie = localStorage.getItem('cookies');
  const [cookie, setCookie] = React.useState(getCookie);
  const [values, setValues] = useState({
    pdf: isEnglish() ? termsConditionsEng : termsConditionsEsp,
  });

  const rejectCookie = () => {
    localStorage.setItem('cookies', false);
    setCookie(true);
  };

  const acceptCookie = () => {
    localStorage.setItem('cookies', true);
    setCookie(true);
  };

  useEffect(() => {
    setValues({
      ...values,
      pdf: isEnglish() ? termsConditionsEng : termsConditionsEsp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnglish()]);

  return {
    acceptCookie,
    rejectCookie,
    cookie,
    translator,
    values,
  };
};

export default ButtonCokkies;
