import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/CustomError/CustomError';
import Commission from '../../../classes/Commission/Commission';

const ProjectItemService = () => {
  const getCommissionByType = async (type) => {
    const api = new API(`${URL.URL_SERVER}/commissions/${type}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;

    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Commission);
      return response.wasSuccessfull().single();
    }
  };

  return {
    getCommissionByType,
  };
};
export default ProjectItemService;
