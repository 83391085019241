import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f2f2f2',
    borderRadius: '15px',
    height: '150px',
    width: '320px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  counter: {
    fontFamily: 'Montserrat',
    marginRight: '35px',
    marginLeft: '35px',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    '& p': {
      margin: '0px',
    },
  },
  button: {
    height: '30px',
    width: '30px',
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid black',
  },
  smallRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.projectItem,
    height: '30px',
    width: '117px',
  },
  backgroundWhiteRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'ffffff',
    height: '30px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      width: '117px',
    },
  },
  smallCounter: {
    fontFamily: 'Montserrat',
    marginRight: '25px',
    marginLeft: '25px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '700',
    color: theme.palette.text.terciary,
  },
  smallButton: {
    height: '30px',
    width: '30px',
    backgroundColor: 'white',
    color:'black',
    border: '1px solid rgba(51, 51, 51, 1)',
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      width: '130px',
    },
    counter: {
      fontFamily: 'Montserrat',
      marginRight: '8px',
      marginLeft: '8px',
      fontSize: '17px',
    },
  },

  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default useStyles;
