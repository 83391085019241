import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    minWidth: '320px',
    marginTop: '80px',
    marginBottom:'80px',
    [theme.breakpoints.up('lg')]: {
      width: '1175px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
}));

export default useStyles;
