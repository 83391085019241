import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  OutlinedInput,
  Grid,
  Typography,
  FormControl,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useStyles, ProfileLogic, validator } from './profile.module';
import ButtonDefault from '../shared/button/button';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import { Select as AntSelect } from 'antd';
import Flags from '../../classes/Flags/Flags';
import 'antd/dist/antd.css';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import {
  ProfileVerifyLogic,
  validateVerifyInformation,
} from './profile.module';
import 'date-fns';
import AlertMessage from '../shared/alert/alert';
import './custom-styles.css';
import 'antd/dist/antd.css';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const VerifyInformationAccordion = () => {
  const { current, changeState, handleChange } = ProfileLogic(() => validator);

  const {
    formData,
    handleChangeForm,
    handleChangeDate,
    handleChangeCountry,
    buttonDisabledVerify,
    name,
    lastname,
    handleVerifySubmit,
    loading,
    showSuccess,
    errors,
  } = ProfileVerifyLogic(validateVerifyInformation);
  const classes = useStyles();

  return (
    <Accordion onChange={changeState(0)}>
      <AccordionSummary
        className={classes.root}
        expandIcon={
          current === 0 ? (
            <RemoveCircleOutlineIcon
              className={classes.removeCircleOutlineIcon}
            />
          ) : (
            <AddCircleOutlineIcon className={classes.addCircleOutlineIcon} />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h5" className={classes.questionsWhite}>
          Verify Information
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <FormControl className={classes.inputField}>
              <label className={classes.labels}>First Name</label>
              <OutlinedInput
                type="text"
                value={name}
                name="firstName"
                onChange={handleChange}
                disabled={true}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <FormControl className={classes.inputField}>
              <label className={classes.labels}>Last Name</label>
              <OutlinedInput
                value={lastname}
                name={'confirmNewPassword'}
                onChange={handleChange}
                labelWidth={30}
                disabled={true}
              />
            </FormControl>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Grid container direction="column" alignItems="flex-start">
              <label className={classes.labels}>Date of Birth</label>
              <Grid item className={classes.datePickerContainer}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    value={formData ? formData.dateOfBirth : null}
                    onChange={handleChangeDate}
                    name="dateOfBirth"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      className: classes.icon,
                    }}
                    DialogProps={{
                      className: classes.calendarPopup,
                    }}
                    className={classes.datePickerInput}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            style={{
              border: '1px solid #E0E0E0', //
              borderRadius: '8px', //
              paddingLeft: '5px',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            <label className={classes.labels}>Address</label>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <FormControl className={classes.inputField} fullWidth>
                <OutlinedInput
                  label="Address"
                  value={formData?.address || ''}
                  onChange={handleChangeForm}
                  name="address"
                  placeholder="Address"
                />
              </FormControl>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <FormControl className={classes.inputField} fullWidth>
                <OutlinedInput
                  label="Unit Number"
                  value={formData?.unit || ''}
                  onChange={handleChangeForm}
                  name="unit"
                  placeholder="Unit Number"
                />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <FormControl className={classes.inputField} fullWidth>
                <OutlinedInput
                  label="City"
                  value={formData?.locality || ''}
                  onChange={handleChangeForm}
                  name="locality"
                  placeholder="City / Town / District"
                />
              </FormControl>
            </Grid>

            <Grid item lg={7} md={7} sm={7} xs={12}>
              <FormControl className={classes.inputField} fullWidth>
                <OutlinedInput
                  label="Postal Code"
                  value={formData?.postalCode || ''}
                  onChange={handleChangeForm}
                  name="postalCode"
                  placeholder="Postal Code"
                />
              </FormControl>
            </Grid>

            <Grid item lg={5} md={5} sm={5} xs={12}>
              {/* <AntSelect
                showSearch
                placeholder="Select Country"
                onChange={handleChangeCountry}
                name="country"
                value={formData?.country || ''}
                className={classes.countryDropdown}
                bordered={false}
                filterOption={(input, option) => {
                  const { children } = option.props;
                  const value = Array.isArray(children)
                    ? children[children.length - 1]
                    : children;
                  return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {Flags.flagData(classes.dropdownOption)}
              </AntSelect> */}
              <Autocomplete
                variant="standar"
                className={classes.dropdownOption}
                align="left"
                fullWidth
                // freeSolo
                onChange={handleChangeCountry}
                options={Flags.flagDataArray()}
                getOptionLabel={(option) => option.label}
                renderOption={(option) => (
                  <Grid container alignItems="center">
                    <img
                      src={option.flag}
                      alt={option.label}
                      style={{ width: 35, height: 30, marginRight: 15 }}
                    />
                    {option.label}
                  </Grid>
                )}
                renderInput={(params) => <TextField placeholder="Select a country"  {...params} />}
                getOptionSelected={(option, value) => option.value === value.value}
              />

            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '10px',
              flexDirection: 'column',
            }}
          >
            <label className={classes.labels}>
              WHY ARE YOU INTERESTED IN EGREEN?
            </label>
            <Select
              value={formData?.whyEgreen || ''}
              onChange={handleChangeForm}
              name="whyEgreen"
              input={<BootstrapInput />}
              align="left"
              fullWidth
              color="theme.palette.text.tertiary"
            >
              <MenuItem value="">
                <em>Select an option</em>{' '}
              </MenuItem>
              <MenuItem value="invest_profit">Invest For Profit</MenuItem>
              <MenuItem value="environmental_impact">
                Environmental Impact
              </MenuItem>
              <MenuItem value="help_green_projects">
                Help green projects
              </MenuItem>
            </Select>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '10px',
              flexDirection: 'column',
            }}
          >
            <label className={classes.labels}>SOURCE INCOME</label>
            <Select
              value={formData?.sourceIncome || ''}
              onChange={handleChangeForm}
              name="sourceIncome"
              input={<BootstrapInput />}
              align="left"
              fullWidth
            >
              <MenuItem value="">
                <em>Select an option</em>{' '}
              </MenuItem>
              <MenuItem value="job_income">Job Income</MenuItem>
              <MenuItem value="real_estate">Real Estate</MenuItem>
              <MenuItem value="investment_family">Investment Family</MenuItem>
            </Select>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: '2px',
              flexDirection: 'column',
            }}
          >
            <label className={classes.labels}>EMPLOYMENT STATUS</label>
            <Select
              value={formData?.employmentStatus || ''}
              onChange={handleChangeForm}
              name="employmentStatus"
              align="left"
              input={<BootstrapInput />}
              fullWidth
            >
              <MenuItem value="">
                <em>Select an option</em>{' '}
              </MenuItem>
              <MenuItem value="independent_contractor">
                Independent Contractor
              </MenuItem>
              <MenuItem value="payroll">Payroll</MenuItem>
              <MenuItem value="student">Student</MenuItem>
              <MenuItem value="unemployed">Unemployed</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </Grid>

          <Grid container justifyContent="center" alignItems="center">
            <div className={classes.container}>
              <ButtonDefault
                type="blue"
                text="continue"
                changeView={handleVerifySubmit}
                disabled={buttonDisabledVerify}
                align="center"
              ></ButtonDefault>

              {showSuccess && (
                <AlertMessage
                  text={'La información se actualiza con éxito'}
                  type={'success'}
                  className={classes.alertMessage}
                />
              )}
            </div>
            {errors &&
              Object.keys(errors).length > 0 &&
              Object.keys(errors).map((errorKey) => (
                <AlertMessage
                  key={errorKey}
                  type="error"
                  text={
                    Array.isArray(errors[errorKey])
                      ? errors[errorKey].join(', ')
                      : typeof errors[errorKey] === 'object'
                      ? JSON.stringify(errors[errorKey])
                      : errors[errorKey]
                  }
                />
              ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default VerifyInformationAccordion;
