import React from 'react';
import {
  TermsAndConditionsLogic,
  useStyles,
} from './TermsAndConditions.module';
import { Container, Grid, Typography } from '@material-ui/core';
import Footer from '../footer/Footer';

export default function TermsAndConditions() {
  const classes = useStyles();
  const { translator } = TermsAndConditionsLogic();
  return (
    <>
      <Container container maxWidth="md" className={classes.main}>
        <Grid container align="center" spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.heading} paragraph>
              {translator('termsAndConditions.title')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.text1')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.text2')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.firstTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography paragraph className={classes.section}>
              {translator('termsAndConditions.firstText')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.firstText1')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.secondTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.secondText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.secondText2')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.secondText3')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.thirdTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirdText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirdText1')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirdText2')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirdText3')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirdText4')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.fourTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fourText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fourText1')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fourText2')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fourText3')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.fiveTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText1')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText2')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText3')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText4')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText5')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText6')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText7')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.fiveText8')}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.sixTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.sixText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.sixText1')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.sevenTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.sevenText')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.eightTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.eightText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.eightText1')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.nineTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.nineText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.nineText1')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.nineText2')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.tenTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.tenText')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.elevenTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.elevenText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.elevenText1')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.elevenText2')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.elevenText3')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.twelveTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.twelveText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.twelveText1')}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.sectionTitle}>
              <strong className={classes.text}>
                {translator('termsAndConditions.thirteenTitle')}
              </strong>
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirteenText')}
            </Typography>
            <Typography className={classes.section}>
              {translator('termsAndConditions.thirteenText1')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}
