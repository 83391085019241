import Translator from '../../classes/Translator/Translator';
import { useHistory } from 'react-router-dom';
const VerifiedProjectsLogic = () => {
  const { translator } = Translator();
  const history = useHistory();
  const goToProjects = () => {
    history.push('/projects');
  };

  return {
    translator,
    goToProjects,
  };
};
export default VerifiedProjectsLogic;
