import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Box,
  OutlinedInput,
  useTheme,
} from '@material-ui/core';
import DefaultTooltip from '../shared/tooltips/toolTip';
import Button from '@material-ui/core/Button';
import Footer from '../footer/Footer';
import {
  useStyles,
  TransferToBuyLogic,
  creditCard,
  TransferToBuyService,
} from './transferToBuy.module';
import Formatter from '../../classes/Formatter/Formatter';
import ArrowBack from '../shared/arrowBack/arrowBack';

const Exchange = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const service = TransferToBuyService();
  const { translator, onChange, buyUsd, values, setValues, feeMath } =
    TransferToBuyLogic(props, service.getData);

  return (
    <div className={classes.dashboardStyle}>
      <Container>
        {/* <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <User user={values.user} />
          </Grid>
        </Grid> */}
      </Container>

      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                Transfer by
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container spacing={2}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.contentDashboard}
              >
                <Card
                  className={
                    theme.palette.mode === 'light'
                      ? classes.root
                      : classes.rootLight
                  }
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DefaultTooltip tooltip={'Egrn coins'} />
                  </div>

                  <CardContent className={classes.cardContainer}>
                  <ArrowBack to="/exchange"/>
                    <Typography variant="h5" className={classes.title}>
                      {translator('transfertobuy.creditCard')}
                    </Typography>
                    <Typography className={classes.value}>
                      {values.value}
                    </Typography>
                    <img
                      src={creditCard}
                      className={classes.creditImg}
                      alt="credit_card"
                    />
                    <Typography className={classes.title}>
                      {' '}
                      {translator('transfertobuy.amounttotransfer')}
                    </Typography>

                    <Box className={classes.MoneyBox}>
                      <OutlinedInput
                        variant="outlined"
                        defaultValue={0}
                        className={classes.inputField}
                        value={values.amount}
                        onChange={onChange}
                        name="amount"
                        onBlur={() => {
                          const roundedValue = Formatter.round(
                            parseFloat(values.amount || 0)
                          );
                          setValues({ ...values, amount: roundedValue });
                        }}
                        fullWidth
                        inputProps={{
                          inputMode: 'decimal',
                          step: 'any',
                        }}
                      />
                    </Box>

                    <Typography className={classes.title}>
                      {' '}
                      {translator('transfertobuy.please')} :{' '}
                      <span className={classes.feeMath}>{feeMath}</span>
                    </Typography>

                    <Button
                      onClick={buyUsd}
                      className={classes.customButton}
                      disabled={
                        parseFloat(values.amount) <= 0 ||
                        isNaN(parseFloat(values.amount))
                      }
                    >
                      {translator('transfertobuy.gotocreditcard')}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.coinSoon}
          ></Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};
export default Exchange;
