import { useState, useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';
import { BlogService } from './blog.module';

const labels = {
  es: '#spanish',
  en: '#english',
  fr: '#french',
};

const BlogLogic = () => {
  const [search, setSearch] = useState('');
  const [news, setNews] = useState([]);
  const [filteredNews, setFilteredNews] = useState([]);
  const [pageToken, setPageToken] = useState(null);
  const { translator, language } = Translator();

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const onSearchValueChange = (event) => {
    setSearch(event.target.value);
  };

  const filterNews = (posts, resetPosts) => {
    const initial_data = posts || news;
    const labelsString = labels[language];
    let inner_news = initial_data.filter((card) => {
      const cardLabelsString = card.labels.join(' ');
      return cardLabelsString.includes(labelsString);
    });
    if (search.length > 0) {
      inner_news = inner_news.filter((card) => {
        return card.title.toLowerCase().includes(search.toLowerCase());
      });
    }
    setFilteredNews((prePosts) => resetPosts ? inner_news: prePosts.concat(inner_news));

  };

  const getPosts = async (data) => {
    setPageToken(null);
    const { resources: posts, pageTokenResponse } =
      await BlogService().getPosts(data);
    setNews([...posts]);
    // setFilteredNews([...posts]);
    filterNews([...posts], data.pageToken ? false: true);
    setPageToken(pageTokenResponse);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPosts();
  }, []);

  useEffect(() => {
    filterNews();
  }, [search, language, translator]);

  return {
    filteredNews,
    search,
    translator,
    handleSearch,
    onSearchValueChange,
    getPosts,
    pageToken,
  };
};

export default BlogLogic;
