import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const YourInvestmentLogic = (props) => {
  const { translator } = Translator();
  let history = useHistory();
  const [values, setValues] = useState({
    ...props,
  });

  const handleInvest = (e) => {
    e.preventDefault();
    return history.push('/projects');
  };

  useEffect(() => {
    setValues({
      ...values,
      project: props.project,
      transaction: props.transaction,
    });
  }, [props]);

  return {
    values,
    handleInvest,
    translator,
  };
};

export default YourInvestmentLogic;
