import { useEffect, useState, useRef, useContext } from 'react';
import Translator from '../../classes/Translator/Translator';
import GeneralContext from '../../context/GeneralContext';

const ConfirmCodeLogic = (callback, validate, resendCode, emailValidator) => {
  const { email } = useContext(GeneralContext);
  const { translator } = Translator();
  const formRef = useRef();
  const [values, setValues] = useState({
    email: email,
    code: '',
    verifyCode: false,
  });
  const [data, setData] = useState({
    email,
    resendCode: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForwarding, setIsForwarding] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value }) ||
      setData({ ...data, [name]: value });
  };

  const handleSendCode = () => {
    setErrors(validate(values));
    setIsSubmitting(true);
    formRef.current.reset();
  };

  const handleResendCode = () => {
    setErrors(emailValidator(data));
    setIsForwarding(true);
    formRef.current.reset();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const buttonName = e.nativeEvent.submitter.name;
    if (buttonName === 'sendCode') handleSendCode();
    if (buttonName === 'resendCode') handleResendCode();
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, isSubmitting]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isForwarding) {
      resendCode();
    }
  }, [errors, isForwarding]);

  return {
    formRef,
    values,
    errors,
    data,
    setValues,
    setErrors,
    setData,
    handleChange,
    handleSubmit,
    translator,
  };
};

export default ConfirmCodeLogic;
