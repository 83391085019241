import Joi from 'joi';

const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();

export default function validator(values) {
  const errors = {};
  const { error: errorEmail } = emailSchema.validate(values.email);

  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido.';
  }
  return errors;
}
