import { useState, useEffect } from 'react';
import Translator from '../../../classes/Translator/Translator';

const PricesCardLogic = (props) => {
  const [values, setValues] = useState({
    ...props,
  });
  const { translator } = Translator();
  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });

  useEffect(() => {
    if (props) {
      setValues(props);
    }
  }, [props]);
  return {
    values,
    translator,
    state,
    setState

  };
};
export default PricesCardLogic;
