import { makeStyles } from '@material-ui/core';
import { slide3Img } from './Slide3.module';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${slide3Img})`,
    width: '100%',
    height: '880px',
    background:
      'radial-gradient(100% 100% at 13.65% 88.98%, #C1E9FE 0%, rgba(255, 255, 255, 0.0001) 100%), radial-gradient(100% 100% at 25.26% 21.66%, rgba(0, 221, 158, 0.6) 0%, rgba(255, 255, 255, 0.0001) 100%), #FFFFFF',
    borderTop: '123px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    /* transform: 'rotate(-180deg)' */
  },
  title: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: '62px',
    lineHeight: '75.58px',
    color: '#FFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px',
      lineHeight: '24px',
    },
  },
  subtitle: {
    width: '80%',
    color: '#FFF',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: 18,
    lineHeight: '32px',
  },
  img: {
    width: '80%',
  },
  buttonContainer: {
    width: '50%',
    display: 'flex',
    height: 'auto',
    padding: '8px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  infoBarContainer: {
    width: '80%',
  },
}));

export default useStyles;
