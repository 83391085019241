import { useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const CardOfferSelectionLogic = () => {
  const { translator } = Translator();
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    amount: '',
    price: '',
    id_user: '',
    type: '',
  });

  return {
    translator,
    errors,
    setErrors,
    setValues,
    values,
  };
};
export default CardOfferSelectionLogic;
