import Translator from '../../classes/Translator/Translator';

const PrivacyPolicyLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};

export default PrivacyPolicyLogic;
