import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import Session from '../../classes/Session/Session';
import CustomError from '../../classes/CustomError/CustomError';
import Permission from '../../classes/Permission/Permission';
import ProjectIpfs from '../../classes/Project/ProjectIpfs';

const documentationService = () => {
  const createPermission = async (data) => {
    const api = new API(`${URL.URL_SERVER}/permissions`);
    const http_response = await api.post(data);
    const has_error = http_response instanceof CustomError;
    if (!has_error) {
      const response = new Response(http_response, Session);
      const data = response.wasSuccessfull().single();
      console.log({ data });
    } else {
      return http_response;
    }
  };

  const getPermission = async (id_user, id_project) => {
    const api = new API(
      `${URL.URL_SERVER}/permissions/${id_user}/projects/${id_project}`
    );
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Permission);
      return response.wasSuccessfull().single();
    }
  };

  const getFolderByHash = async ({ id_project, hash }) => {
    const api = new API(
      `${URL.URL_SERVER}/projects/${id_project}/ipfs/${hash}`
    );
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, ProjectIpfs);
      return response.wasSuccessfull().single();
    }
  };

  return {
    createPermission,
    getPermission,
    getFolderByHash,
  };
};

export default documentationService;
