import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography, Container, Grid } from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import Footer from '../footer/Footer';
import Translator from '../../classes/Translator/Translator';
import { TransactionInformationLogic } from './TransactionInformation.module';
import { useStyles, image } from './TransactionInformation.module';
import { BillingDetailService } from '../billingDetail/billingDetail.module';
import { PaymentMethodService } from '../shared/paymentMethod/paymentMethod.module';

const TransactionInformation = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { translator } = TransactionInformationLogic(
    () => BillingDetailService().getTransaction(id),
    PaymentMethodService().checkPay
  );

  return (
    <>
      <Container className={classes.circularProgress}>
        <div>
          <img className={classes.image} src={image} alt={image} />
        </div>
        <div>
          <Typography className={classes.message}>
            {translator('transactionInformation_message')}
          </Typography>
        </div>
        <div>
          <ButtonConfirmationView />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default TransactionInformation;

const ButtonConfirmationView = () => {
  const classes = useStyles();
  const { translator } = Translator();

  return (
    <Grid
      container
      className={classes.containerButtons}
      spacing={0}
      justifyContent="center"
    >
      <Grid item>
        <Link to="/" className={classes.link}>
          <ButtonDefault
            type="whitegreen"
            text={translator('confirmation_return_btn')}
          />
        </Link>
      </Grid>
      <Grid item>
        <Link to="/dashboard" className={classes.link}>
          <ButtonDefault
            text={translator('confirmation_profile_btn')}
            type="whitegreen"
          />
        </Link>
      </Grid>
    </Grid>
  );
};
