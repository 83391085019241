import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    containerFormControlFirst: {
        display: 'flex',
        alignItems: 'center',
    },
    formControlFirst: {
        margin: theme.spacing(1),
        minWidth: 120,
        // border: `0.1px solid ${theme.palette.background.selectProjects}`,
        background: theme.palette.background.selectProjects,

        fontSize: 14,
        '& .MuiSelect-select': {
            color: theme.palette.text.selectProjects,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontFamily: 'MontserratRegular',

        },
    },
    containerTextFormControlFirst: {
        height: '100%',
        display: 'flex',
        '@media screen and (min-width:576px)': {
            alignItems: 'center',
        },
        '@media screen and (max-width:992px)': {
            alignItems: 'center',

        }
    },
    formControlSecond: {
        margin: theme.spacing(1),
        minWidth: 152,
        border: `0.1px solid ${theme.palette.borderSelect.main}`,
        color: theme.palette.text.selectProjects,
        fontSize: 14,
        padding: '0px 8px 0x 3px',
        fontFamily: 'MontserratRegular',
        '& .MuiSelect-select': {
            color: theme.palette.text.selectProjects,
            fontFamily: 'MontserratRegular',
        },
    },
    textFormControlFirst: {
        color: theme.palette.text.selectProjects,
        fontSize: 14,
        fontFamily: 'MontserratRegular',

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    selectContainer: {
        display: 'flex',

        '@media screen and (min-width:576px)': {
            justifyContent: 'space-between',
            marginBottom: 45,
            marginTop: 15
        },
        '@media screen and (max-width:992px)': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
        }
    },
    contentProjectCard: {
        marginBottom: '10px'
    }
}));

export default useStyles;
