import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';

const TransferToBuyService = () => {
  async function checkout(data) {
    const api = new API(`${URL.URL_SERVER}/create-checkout-session`);
    const http_response = await api.post(data);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return response.response.data;
    }
  }

  // async function checkPay(data) {
  //   const api = new API(`${url}/check-pay-session`);
  //   const response = new Response(await api.post(data)).wasSuccessfull().getSingle();
  //   return response;
  // }

  async function create(data) {
    const api = new API(`${URL.URL_SERVER}/transactions`);
    const http_response = await api.post(data);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return response.response.data;
    }
    
  }

  return {
    // checkPay,
    checkout,
    create
  };
};

export default TransferToBuyService;
