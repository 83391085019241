import React from 'react';
import { CardMedia, CardContent, Typography } from '@material-ui/core';
import { CardCoinsLogic, PropTypes, useStyles } from './cardCoins.module';

const CardsCoins = (props) => {
  const classes = useStyles();
  const { values, state, setState, translator } = CardCoinsLogic(props);

  return (
    <CardContent
      className={classes.animation}
      classes={{ root: state.raised ? classes.cardHovered : '' }}
      onMouseOver={() => setState({ raised: true, shadow: 3 })}
      onMouseOut={() => setState({ raised: false, shadow: 1 })}
      zdepth={state.shadow}
    >
      <CardMedia className={classes.cardImage} image={values.image} />
      <Typography
        className={classes.title}
        variant="h6"
        color="initial"
        align="center"
        paragraph
      >
        {translator(`${values.title}`)}
      </Typography>
      <Typography
        className={classes.text}
        variant="h6"
        color="initial"
        align="center"
        paragraph
      >
        {translator(`${values.description}`)}
      </Typography>
    </CardContent>
  );
};

CardsCoins.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};
export default CardsCoins;
