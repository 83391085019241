import Storage from '../Storage/Storage';

class Session {
  static get TOKEN() {
    return 'token';
  }

  static get REFRESH_TOKEN() {
    return 'refreshToken';
  }

  static get ID() {
    return 'id';
  }

  constructor({ token, refreshToken, id }) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.id = id;
    this.storage = new Storage(Session.TOKEN);
  }

  saveInStorage() {
    if (this.token) {
      this.storage.setKey(Session.TOKEN);
      this.storage.setValue(this.token);
      this.storage.setItem();
    }

    if (this.id) {
      this.storage.setKey(Session.ID);
      this.storage.setValue(this.id);
      this.storage.setItem();
    }

    if (this.refreshToken) {
      this.storage.setKey(Session.REFRESH_TOKEN);
      this.storage.setValue(this.refreshToken);
      this.storage.setItem();
    }

    return this;
  }

  hasSession() {
    return !!this.getToken();
  }

  getToken() {
    this.storage.setKey(Session.TOKEN);
    return this.storage.getItem()
      ? JSON.stringify(this.storage.getItem())
      : null;
  }

  logout() {
    this.storage.setKey(Session.TOKEN).removeItem();
    this.storage.setKey(Session.ID).removeItem();
  }
}

export default Session;
