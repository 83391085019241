import React, { useEffect, useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

const ButtonLogic = (props) => {
  const { type, isSubmit, icon } = props;
  const [text, setText] = useState([]);
  const [values, setValues] = useState({
    ...props,
    buttonClass: 'buttonWhiteBlue',
    isSubmit: isSubmit ? 'submit' : '',
    endIcon: icon ? <GetAppIcon /> : null,
    href: icon ? 'https://egreen.com/pdf/eGreen_white_paper.pdf' : null,
    target: icon ? '_blank' : null,
  });

  const setClass = () => {
    switch (type) {
      case 'whiteblue':
        setValues({ ...values, buttonClass: 'buttonWhiteBlue' });
        break;
      case 'whitegreen':
        setValues({ ...values, buttonClass: 'buttonWhiteGreen' });
        break;
      case 'whiteborderblue':
        setValues({ ...values, buttonClass: 'buttonWhiteBorderBlue' });
        break;
      case 'backgroundgreen':
        setValues({ ...values, buttonClass: 'buttonBackgroundGreen' });
        break;
      case 'backgroundwhite':
        setValues({ ...values, buttonClass: 'buttonBackgroundWhite' });
        break;
      case 'blue':
        setValues({ ...values, buttonClass: 'buttonBlue' });
        break;
      case 'greenwhite':
        setValues({ ...values, buttonClass: 'buttonGreenWhite' });
        break;
      case 'bluewhite':
        setValues({ ...values, buttonClass: 'buttonBlueWhite' });
        break;
      case 'carbonfootprint':
        setValues({ ...values, buttonClass: 'buttonCarbonFootprint' });
        break;
      case 'buttontransparent':
        setValues({ ...values, buttonClass: 'buttonTransparent' });
        break;
      case 'buttonPay':
        setValues({ ...values, buttonClass: 'buttonPay' });
        break;
      case 'buttonGreen':
        setValues({ ...values, buttonClass: 'buttonGreen' });
        break;
      case 'buttonGreenLarge':
        setValues({ ...values, buttonClass: 'buttonGreenLarge' });
        break;
      case 'blueLarge':
        setValues({ ...values, buttonClass: 'buttonBlueLarge' });
        break;
      case 'buttonAutoTransparent':
        setValues({
          ...values,
          buttonClass: 'buttonBackgroundGreenTransparent',
        });
        break;
      case 'backgroundSoftGreen':
        setValues({ ...values, buttonClass: 'buttonBackgroundSoftGreen' });
        break;
      case 'buttonBarnerMain':
        setValues({ ...values, buttonClass: 'buttonBarnerMain' });
        break;
      case 'backgroundTurquoise':
        setValues({ ...values, buttonClass: 'buttonBackgroundTurquoise' });
        break;
      case 'backgroundMintGreen':
        setValues({ ...values, buttonClass: 'buttonBackgroundMintGreen' });
        break;
      case 'greenBlueFont':
        setValues({ ...values, buttonClass: 'buttonGreenBlueFont' });
        break;
      case 'greenMint':
        setValues({ ...values, buttonClass: 'buttonBackgroundGreenMint' });
        break;
      case 'defaultGreenMint':
        setValues({ ...values, buttonClass: 'buttonDefaultGreenMint' });
        break;
          
      default:
        setValues({ ...values, buttonClass: 'buttonWhiteBlue' });
        break;
    }
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.text) {
      setText(props.text);
    }
  }, [props]);

  return {
    values,
    text,
  };
};

export default ButtonLogic;
