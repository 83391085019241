import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Translator from '../../classes/Translator/Translator';

const ResetPasswordLogic = (callback, validate) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    email: '',
    code: '',
    new_password: '',
    repeatedPassword: '',
    showPassword: false,
    showRepeatedPassword: false,

  });
  const { translator } = Translator();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, callback, isSubmitting]);

  let history = useHistory();
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowRepeatedPassword = () => {
    setValues({
      ...values,
      showRepeatedPassword: !values.showRepeatedPassword,
    });
  };
  return {
    errors,
    handleChange,
    handleSubmit,
    values,
    history,
    translator,
    setErrors,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowRepeatedPassword
  };
};

export default ResetPasswordLogic;
