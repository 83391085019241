import { useHistory } from 'react-router-dom';
import Translator from '../../classes/Translator/Translator';

const NotFoundLogic = () => {
  const { translator } = Translator();
  const heading = translator('notFound.heading');
  const title = translator('notFound.title');
  const body = translator('notFound.body');
  const image =
    'https://t3.ftcdn.net/jpg/02/23/18/82/360_F_223188293_pAE8UqKfqTpgGr2QoBoxXtL5YU6UpjPU.jpg';

  let history = useHistory();

  return {
    heading,
    title,
    body,
    image,
    history,
    translator
  };
};

export default NotFoundLogic;
