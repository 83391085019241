import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: 0,
    margin: 0,
    borderRadius: '16px',
    background: '#F7F7F7',
    padding: '20px 10px 30px 8px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      textAlign: 'left',
      marginLeft: '13px',
      float: 'left'
    },

  },
  circle: {
    background: 'rgba(0, 146, 61, 1)',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
  },
  number: {
    fontHeight: '21px',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 1)',
    paddingTop: '3px',
  },
  title: {
    fontWeight: '700',
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontSize: '32px',
    lineHeight: '40px',
    color: 'rgba(4, 103, 156, 1)',
    margin: '12px 0px 0px 4px',
  },
  radioAndCheckbox_container: {
    marginTop: '20px',
    float: 'left',
    flexDirection: 'row',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      marginLeft: theme.spacing(6),
    },
  },
  radio: {
    '& .MuiRadio-root': {
      color: '#333333',
    },
    '& .MuiIconButton-label': {
      color: '#333333',
    }
  },
  checkbox: {
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',
    '& .MuiSvgIcon-root': {
      color: '#333333',
    }
  },
  optionContainer: {
    marginLeft: '33px',
    marginTop: '-50px',
    marginBottom: '21px',
  },
  optionContainer1: {
    marginLeft: '33px',
    flexDirection: 'row',
    marginTop: '-30px',
    marginBottom: '21px',

  },
  optionIconsText: {
    display: 'flex',
    alignItems: 'center',
  },
  iconsContainer: {
    width: '107px',
  },
  iconSpace: {
    marginLeft: '7px',
  },

  optionText: {
    fontFamily: 'MontserratRegular',
    fontSize: 16,
    lineHeight: '26px',
    fontWeight: 400,
    fontStyle: 'normal',
    marginTop: '-10px',
    textAlign: 'left',
    height: '50px',
    color: '#333333',
    '& p': {
      margin: '0px',
    }

  },

  conditions: {
    marginLeft: '33px',
    textAlign: 'left',
    marginTop: '-34px',
    '& p': {
      margin: '0px'
    },
    fontFamily: 'MontserratSemiBold',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: '24px',
    color: '#333333',
    marginBottom: '8px'
  },
  paymentMethod_text: {
    color: '#333333',
    fontWeight: '700',
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
  },
  conditionsContainer: {
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    marginTop: '-15px',
    marginBottom: '18px',
    float: 'left',
    flexDirection: 'row',
    textAlign: 'left',

    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      textAlign: 'left',
      marginLeft: theme.spacing(6),
      float: 'left'

    },
  },

  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    'input:disabled ~ &': {
      background: 'rgba(206,217,224,.5)',
    },
  },

  optionTextDisabled: {
    fontFamily: 'MontserratRegular',
    fontSize: 16,
    lineHeight: '26px',
    fontWeight: 400,
    fontStyle: 'normal',
    marginTop: '-20px',
    textAlign: 'left',
    height: '50px',
    color: 'rgba(0, 0, 0, 0.53)',
    marginLeft: '33px',
    marginBottom: '21px',
    padding: 0

  },
  formControlLabel: {
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    '& label': {
      fontSize: '18px',
    }
  }

}));

export default useStyles;