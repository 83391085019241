import { makeStyles } from '@material-ui/core/styles';
import rectangle_9 from '../../assets/images/rectangle_9.webp';

const useStyles = makeStyles((theme) => ({

  test: {
    // border: '2px solid red',
    marginTop: '225px'
  },
  paper: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    margin: '48px 0px',
    minWidth: 280,
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    border: '2px solid #00DD9E',
    borderRadius: 20,
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  paperContainer:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  cardContainer: {
    height: '100%',
    margin: '0px 100px 30px 100px',
    padding: '10px 0px 4px 0px !important',
    // border: '2px solid #f7ff06',

  },
  contentDashboard: {
    maxWidth: '100%',
    marginBottom: 70,
    // border: '2px solid yellow',
    borderRadius: '20px'
  },
  dashboardStyle: {
    marginTop: 80,
    // border: '1px solid white',
  },
  info: {
    marginTop: 10,
    display: 'flex',
    height: '100px',
    marginBottom: '15px',
    // border: '2px solid white',
  },
  infoTitle: {
    fontSize: '14px'
  },
  infoValue: {
    fontSize: '22px'
  },
  text:{
    color: theme.palette.text.nonary,
  },

  subtitleDashboard: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 30,
    marginBottom: 40,
  },
  textSubtitleDashboard: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
    '@media screen and (max-width:280px)': {
      fontSize: '40px',
    },
  },
  textSecundaryDashboard: {
    fontFamily: 'MontserratBold',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
  },
  containerCabonCredits: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 50,
    color: '#fff',
    minHeight: 280,
    maxWidth: '100%',
    backgroundImage: `url(${rectangle_9})`,
    [theme.breakpoints.down('md')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
    },
    '@media screen and (max-width:280px)': {
      maxWidth: '100%',
    },
  },
  subcontainerCabonCredits: {
    marginRight: 50,
  },

  titleMain: {
    fontFamily: 'MontserratBold',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'center',
    marginBottom: 15,
    paddingLeft: 20,
  },
  titleCabonCredits: {
    fontFamily: 'MontserratBold',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingLeft: 20,
  },
  titleCabonCreditsColor: {
    color: '#2CDD00',
  },
  subtitleCabonCredits: {
    fontFamily: 'MontserratBold',
    fontSize: '20px',
    fontWeight: 500,
    // lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingLeft: 20,
  },
  imageCabonCredits: {
    width: 20,
    height: 20,
    margin: -2,
    marginRight: 10,
  },
  carbonCreditsTitle: {
    fontFamily: 'MontserratBold',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '50px',
    letterSpacing: '0em',
    textAlign: 'left',
    // color: '#FFFFFF',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  stepDashboard: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  timeline: {
    background: 'none',
    '& .MuiSvgIcon-root': {
      color: '#00DD9E',
      fontFamily: 'Montserrat !important',
    },
  },
  coinSoon: {
    marginTop: '40px',
    marginBottom: '40px',
  },
  
  // Estilos de adentros
  MoneyBoxAuto: {
    width: '100%',
    border: '2px solid #00DD9E',
    marginTop: '12px',
    padding: '12px',
    borderRadius: '20px'
},
  MoneyBoxInfo: {
    width: '100%',
    border: '2px solid #00DD9E',
    margin: '8px',
    padding: '8px',
    borderRadius: '20px'
},

  //stilos de botongroup
  customButtonGroup: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
    border: 'none',

  },
  // customButton: {
  //   flex: 1,
  //   margin: '0 1px 20px',
  //   fontFamily: 'MontserratBold',
  //   border: '0.1px solid #263238 !important',
  //   borderRadius: '20px',
  //   backgroundColor: '#06E89F',
  //   color: '#041370',
  //   fontSize: '18px',
  //   '&:hover': {
  //     backgroundColor: '#06E89F',
  //     color: '041370',
  //   },
  // },
  customButton: {
    flex: 1,
    margin: '0 0px 20px',
    fontFamily: 'MontserratBold',
    // fontFamily: 'MontserratExtraBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    backgroundColor: '#06E89F',
    color: '#041370',
    fontSize: '18px',
    width: '100%',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '#041370',
    },
    '&:disabled': {
      backgroundColor: '#676767d8',
      color: '#ffffff',
    },
  },
  customButtonBold: {
    flex: 1,
    margin: '0 1px 20px',
    fontFamily: 'MontserratBold',
    // fontFamily: 'MontserratExtraBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    // backgroundColor: '#06E89F',
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '18px',
    '&:hover': {
      backgroundColor: '#06E89F',
      color: '041370',
    },
  },

  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginTop: 15,
    // border: '1px solid red',
  },
  cardBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // border: '1px solid yellow',
  },

}));
export default useStyles;
