 import Translator from '../../classes/Translator/Translator';

const ImpactLogic = () => {
  const { translator } = Translator();

    return {
     translator,
   };
 }

export default ImpactLogic

