import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerOffsetForm: {
    padding: 10,
  },
  rootOffset: {
    borderRadius: '20px',
    border: '4px solid #82c56b',
    borderTopColor: '#82c56b',
    borderBottomColor: '#80ca5a',
  },
  containerTextOffset: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderTop: '4px solid #80ca5a',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },

    [theme.breakpoints.up('sm')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  rootCertificate: {
    borderRadius: '20px',
    border: '4px solid #4afcff',
    borderTopColor: '#4afcff',
    borderBottomColor: '#4dfdfa',
  },
  containerTextCertificate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderTop: '4px solid #4afcff',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },

    [theme.breakpoints.up('sm')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    [theme.breakpoints.up('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },

  urlCarbonCredit: {
    textDecoration: 'none',
    color: 'inherit',
  },
  containerLabelsOffsetForm: {
    // width: '100%',
    paddingBottom: '0px !important',
    marginBottom: '0px !important',
    textAlign: 'left',
  },
  textFieldOffsetForm: {
    width: '100%',
    color: theme.palette.text.tertiary,
    fontFamily: 'Montserrat !important',
    '&:focus': {
      border: `1px solid ${theme.palette.text.tertiary}`,
    },
    '& .MuiInputBase-input': {
      border: `1px solid ${theme.palette.text.tertiary}`,
      borderRadius: 6,
      backgroundColor: '#42c40a00 !important',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  textFieldOffsetDate: {
    width: '100%',
    color: theme.palette.text.tertiary,
    fontFamily: 'Montserrat !important',
  },

  labelsOffsetForm: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.text.tertiary,
  },
  buttonOffsetForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  dateOffsetForm: {
    color: theme.palette.text.tertiary,
    padding: '5px 0px 0px 2px',
    // border: '1px solid #D9D9D9',
    border: `1px solid ${theme.palette.text.tertiary}`,
    borderRadius: 6,
    width: '100%',
  },
  textFieldNotchedOutline: {
    border: 'none',
  },
  iconOffsetForm: {
    color: theme.palette.text.tertiary,
  },
  paper: {
    '&.MuiPaper-root': {
      // color: '#D9D9D9',
      color: theme.palette.text.tertiary,
      fontFamily: 'Montserrat !important',
    },
    '& .MuiPickersDay-day': {
      color: theme.palette.text.tertiary,
      fontFamily: 'Montserrat !important',
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.text.tertiary,
      fontFamily: 'Montserrat !important',
    },
    '& .MuiPickersDay-dayDisabled': {
      color: 'rgba(0, 0, 0, 0.38)',
      fontFamily: 'Montserrat !important',
    },

    '& .MuiSvgIcon-root': {
      // fill: '#D9D9D9',
      fill: theme.palette.text.tertiary,
      fontFamily: 'Montserrat !important',
    },
  },
  customOutlinedInput: {
    marginTop: 3,
    fontFamily: 'Montserrat !important',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.tertiary,
      fontFamily: 'Montserrat !important',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.tertiary,
    },
    '&:focus .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.tertiary,
    },
    '& .Mui-focused': {
      outline: 'none',
    },
    '&:active .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.tertiary,
    },
  },
  disabledDatePicker: {
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: '#888  !important',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: '#888',
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: '#888 !important',
    },
  },
  imgCarbonCarmedia: {
    position: 'relative',
  },
  conteainerCheckboxCarbon: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  customButton: {
    '&.Mui-disabled': {
      color: '#6d6d6d',
      boxShadow: 'none',
      backgroundColor: '#aaaaaa',
    },
  },
  drawerTransparentBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  listDrawerOpen: {
    fontFamily: 'Montserrat !important',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  marginFile: {
    margin: theme.spacing(1),
  },
  marginFileGhg: {
    marginRight: theme.spacing(1),
  },
  marginTextCarbon: {
    width: '90%',
  },
  imgFile: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  imgFtp: {
    width: '25px',
    height: '25px',
    marginRight: '8px',
  },
  circularProgressCustom: {
    color: '#06E89F',
  },
  textBreak: {
    wordBreak: 'break-all',
    fontSize: '16.5px',
    fontFamily: 'Montserrat !important',
    
    [theme.breakpoints.up('sm')]: {
      fontSize: '16.5px',
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '16.5px',
    },

    // Nest Hub
    '@media screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 600px) and (max-height: 600px)': {
      fontSize: '11.5px',
    },
  },
  rootDrawer: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.3),
    },

  },
  gridCarbonCredit:{
    flexGrow: 1 ,
  },
  headCarbonCredit:{
    fontSize: '14.5px',
    fontWeight: 400,
    lineHeight: '1.43',
    letterSpacing:' 0.01071em',
    fontFamily: 'Montserrat !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },

  }
}));

export default useStyles;
