import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    marginTop: 75,
    display: 'flex',
    alignItems: 'center',
    // maxWidth: '1350px',
    justifyContent: 'flex-start',
    marginBottom:-90,
  },
  fontLink: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
  },
  root: {
    marginTop: '100px',
    marginBottom: '50px',
  },
  projectTitle: {
    fontFamily: 'MontserratBold',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '50px',
    lineHeight: '63px',
    color: '#00DD9E;',
    marginBottom: '13px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'left',
      textAlign: 'left',
    },
  },
  countryText: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '30px',
    lineHeight: '36px',
    color: theme.palette.text.secondary,
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'left',
      textAlign: 'left',
    },
  },
  descriptionText: {
    color: theme.palette.text.tertiary,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
    fontStyle: 'normal',
    alignItems: 'center',
    marginBottom: '38px',
    whiteSpace: 'normal', 
    textAlign: 'left',
    display: 'inline', 
  },
  customLink: {
    textDecoration: 'none',
    color: '#00DD9E',
    // display: 'inline', 
    fontWeight: 'bold',
    // marginLeft: '5px', 
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionOtherProjects: {
    fontFamily: 'Montserrat',
    fontWeight: '800',
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '54px',
    color: theme.palette.text.secondary,
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      marginBottom: '34px',
      paddingTop: '2px',
    },
  },

  locationText: {
    fontFamily: 'MontserratRegular',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '25px',
    lineHeight: '32px',
    color: theme.palette.text.tertiary,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'left',
      textAlign: 'left',
      marginBottom: 60,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  locationFootNote: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '26px',
    color: theme.palette.text.tertiary,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
      textAlign: 'left',
    },
  },
  imageGallery: {
    flexDirection: 'column',
    marginTop: 4,
    width: '100%',
    '& img ': {
      borderRadius: '15px',
    },

    '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus': {
      borderRadius: '17px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  image: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
  },

  link: {
    textDecoration: 'none',
  },
  projectContainer: {
    marginTop: '10px',
    marginBottom: '40px',
  },
  chartDmrv: {
    background: '#fff',
    borderRadius: '10px',
    padding:15,
    boxShadow:'none'
  },
  containerAboutEgreen: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems:'flex-start',
  },
  titleIPFS: {
    marginBottom: '48px',
    color: '#00DD9E',
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    lineHeight: '63px',
    textAlign: 'left',
  },
  iconButtonProjectDetail: {
    fontSize: '36.82px !important',
    marginLeft: 3,
  },
  contentButtonProjectDetail: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  fromProject: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  containerTextDocumentation: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '30px',
    },
  },
}));

export default useStyles;
