import { makeStyles } from '@material-ui/core';
import DemoFormImage from '../../assets/images/DemoFormImage.jpg';


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  labels: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#FFFFFF'
  },
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage:  `url(${DemoFormImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '@media screen and (min-width:576px)': {
      backgroundSize: 'cover',
    },
    '@media screen and (max-width:992px)': {
      backgroundSize: '100% 100%',
      height: 790,
      marginTop: 38
    }
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '24px',
    '@media screen and(max - width: 768px)': {
      fontSize: '20px',
    },
  },
  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '39px',
    textAlign: 'center',
    color: '#F5FAFF',
    '@media screen and (min-width:576px)': {
      width: 620,
      lineHeight: '48px',
      marginTop: 175
    },
    '@media screen and (max-width:992px)': {
      width: '100%',
      lineHeight: '32px',
      marginTop: 80
    },
  },
  containerSubtitle: {
    '@media screen and (min-width:576px)': {
      width: 620
    },
    '@media screen and (max-width:992px)': {
      width: '100%'
    },
  },
  subtitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '31px',
    textAlign: 'center',
    color: '#FFFFFF',
    '@media screen and (max-width:992px)': {
      marginTop: 2
    },
  },
  login: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '56px',
    // lineHeight: '68px',
    textAlign: 'center',
    color: '#00DD9E',
    '@media screen and (min-width:576px)': {
      lineHeight: '48px',
    },
    '@media screen and (max-width:992px)': {
      lineHeight: '0px',
      marginTop: 10,
      marginBottom: 40
    },
  },
  circularButton: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  containerLabels: {
    width: '100%',
    textAlign: 'left'
  },
  textField: {
    color: 'white !important',
    fontFamily: 'Montserrat !important',
    '&:focus': {
      border: '1px solid #D9D9D9',
    },
    '& .MuiInputBase-input': {
      height: 3,
      border: '1px solid #D9D9D9',
      borderRadius: 6,
      backgroundColor: '#42c40a00 !important'
    },
    "&.Mui-focused fieldset": {
      border: 'none',
    },
  },
  textFieldPassword: {
    color: 'white !important',
    fontFamily: 'Montserrat !important',
    '&:focus': {
      border: '1px solid #D9D9D9',
    },
    "&.MuiOutlinedInput-root": {
      height: 42,
      border: '1px solid #D9D9D9',
      borderRadius: 6,
      backgroundColor: '#0a35c400 !important'
    },
    "&.Mui-focused fieldset": {
      border: 'none',
    },
    "& .MuiInputBase-input": {
      height: 3,
    },
  },
  buttonLogin: {
    marginTop: 13
  },
  forgot: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    color: '#FFFFFF',
    '@media screen and (min-width:576px)': {
      marginTop: 37,
    },
    '@media screen and (max-width:992px)': {
      marginTop: 17,
    },
  },
  signUp: {
    fontWeight: 700,
  },
  account: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    color: '#FFFFFF',
    marginTop: 13,
    marginBottom: 50
  },
  loginLink: {
    textDecoration: 'none',
    color: '#FFFFFF',
  }
}));
export default useStyles;
