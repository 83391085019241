import React from 'react';
import { Container, Grid } from '@material-ui/core';
import {
  useStyles,
  CarbonManagementLogic,
  banner,
  dashboardImgCarbonManagement,
  dashboardImgCarbonManagement2,
} from './CarbonManagement.module';
import Footer from '../footer/Footer';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import PricesCard from '../shared/PricesCard/PricesCard';
import MoreInformationDemo from '../MoreInformationDemo/MoreInformationDemo';
import InfoQuestions from '../shared/infoQuestions/infoQuestion';

const CarbonManagement = () => {
  const classes = useStyles();
  const { translator } = CarbonManagementLogic();
  const bullets = [
    <li>{translator('carbonManagement.bullet1')}</li>,
    <li>{translator('carbonManagement.bullet2')}</li>,
    <li>{translator('carbonManagement.bullet3')}</li>,
    <li>{translator('carbonManagement.bullet4')}</li>,
    <li>{translator('carbonManagement.bullet5')}</li>,
    <li>{translator('carbonManagement.bullet6')}</li>,
    <li>{translator('carbonManagement.bullet7')}</li>,
    <li>{translator('carbonManagement.bullet8')}</li>,
  ];
  return (
    <>
      <BannerInterface
        text={translator('pricing.plans')}
        subtitle={translator('pricing.subtitle')}
        backgroundImage={banner}
      />
      <Container>
        <Grid container className={classes.containerSpace}></Grid>
        <InfoQuestions
          textInfo={translator('carbonManagement.textInfoquestions')}
          src={dashboardImgCarbonManagement}
          showOneImg={true}
          withoutButton={true}
        />
        <InfoQuestions
          textInfo={bullets}
          src={dashboardImgCarbonManagement2}
          type="reverse"
          showOneImg={true}
          withoutButton={true}
        />
      </Container>
      <Container maxWidth="lg" className={classes.main}>
        <Grid container className={classes.containerSpace}></Grid>
        <div className={classes.gridPricingTitle}></div>
        <div className={classes.gridPricing}>
          <div>
            <PricesCard
              type="Small Entreprise"
              employees={translator('pricing.employees', { number: '1-49' })}
              price="$99 USD"
              plusSetup="$1990 USD"
              payYear="10% off"
              perYear={true}
            />
          </div>
          <div>
            <PricesCard
              type="Medium Entreprise"
              employees={translator('pricing.employees', { number: '50-199' })}
              price="$448 USD"
              plusSetup="$2900 USD"
              payYear="10% off"
              perYear={true}
            />
          </div>
          <div>
            <PricesCard
              type="Large Entreprise"
              employees={translator('pricing.employees', { number: '200+' })}
              price="CUSTOM"
              plusSetup="Custom"
              payYear="10% off"
              perYear={true}
            />
          </div>
        </div>
        <MoreInformationDemo
          key_translator={translator('pricing.for_more_info_and_demo')}
          go_to="/demoForm"
        />
      </Container>
      <Footer />
    </>
  );
};

export default CarbonManagement;
