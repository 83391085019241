import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0px',
    color: '#00DD9E',
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      lineHeight:0.9,
    },

  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '30px',
    lineHeight: '36px',
    color: theme.palette.text.tertiary,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'left',
      textAlign: 'left',
      marginBottom: 15,
      marginTop:15
    },
  },
}));

export default useStyles;
