import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    contentCarbonOffset: {
        marginTop: 70
    }
}));

export default useStyles;



