import { useEffect, useState } from 'react';

const InfoQuestionsLogic = (props) => {
  const { type } = props;
  const [text, setText] = useState([]);
  const [urls, setUrls] = useState([]);
  const [values, setValues] = useState({
    ...props,
    questionType: 'containerCard',
  });

  useEffect(() => {
    if (type === 'reverse') {
      setValues({ ...values, questionType: 'containerCardReverse' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (props) {
      setText(props);
      setUrls(props.urls)
    }
  }, [props]);

  return {
    values,
    text,
    urls
  };
};

export default InfoQuestionsLogic;
