import { useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';

const CodeSentLogic = (props) => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    ...props,
  });

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    values,
    translator
  };
};

export default CodeSentLogic;