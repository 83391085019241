import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const NewCardLogic = (props) => {
  const [values, setValues] = useState(props);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const { translator } = Translator();

  const onChange = (open) => {
    setOpenModal(open);
  };

  useEffect(() => {
    setValues(props);
  }, [props]);

  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });

  return {
    values,
    state,
    setState,
    translator,
    openModal,
    onChange,
    handleOpen,
  };
};

export default NewCardLogic;
