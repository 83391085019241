import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 544,
    height: 689,
    borderRadius: 16,
    backgroundColor: '#E1F9F2',
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 14px rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '40px',
  },
  title: {
    width: 378,
    fontFamily: 'MontserratSemiBold',
    fontWeight: 600,
    fontSize: 32,
    color: '#006F56',
  },
  welcomeText: {
    width: 456,
    height: 52,
    fontFamily: 'MontserratMedium',
    fontWeight: 500,
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
  },
}));

export default useStyles;
