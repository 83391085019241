import { useState, useEffect, useContext } from 'react';
import Translator from '../../classes/Translator/Translator';
import GeneralContext from '../../context/GeneralContext';
import { useHistory } from 'react-router-dom';

const ProjectDetailLogic = (
  callback,
  validate,
  getProject,
  getKeyIndicators
) => {
  const history = useHistory();
  const { setGeneralValues } = useContext(GeneralContext);
  const { translator, language } = Translator();
  const [values, setValues] = useState({
    project: {},
  });
  const [keys, setKeys] = useState([]);
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);
  const [translatedInfo, setTranslatedInfo] = useState({});
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [textForButton, setTextForButton] = useState('projectDetail.forward');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getProject().then((project) => {
      setProject(project);
      setValues((old_values) => ({ ...old_values, project }));
      setTranslatedInfo(project.info[language]);
      setImages(project.images);
      setTextForButton(project.textForButton);
      getKeyIndicators().then((keys) => setKeys(keys));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, callback, isSubmitting]);

  useEffect(() => {
    const { project = {} } = values;
    const { info = {} } = project;
    setTranslatedInfo({ ...translatedInfo, ...info[language] });
  }, [translator]);

  const setPath = (id) => () => {
    if (values.project.only_showing) return;
    setGeneralValues((old_values) => ({
      ...old_values,
      pathAfterLogin: `/investment/${id}`,
    }));
    return history.push(`/investment/${id}`);
  };

  const handleOpen = () => setOpenModal(true);
  const onChange = (open) => {
    setOpenModal(open);
  };

  return {
    errors,
    handleOpen,
    images,
    keys,
    loading,
    onChange,
    openModal,
    project,
    setPath,
    textForButton,
    translatedInfo,
    translator,
    values,
  };
};

export default ProjectDetailLogic;
