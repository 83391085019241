import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';

const RestorePasswordService = () => {
  const resetPassword = async (email) => {
    const api = new API(`${URL.URL_SERVER}/passwords`);
    const http_response = await api.post({ email });
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return response.wasSuccessfull();
    }
  };

  return {
    resetPassword,
  };
};

export default RestorePasswordService;
