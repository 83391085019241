import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useOfferType = () => {
  const query = useQuery();
  let type = query.get('type') || 'SELL';

  return { type, isValid: type === 'SELL' || type === 'BUY' };
};
