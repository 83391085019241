import Api from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import Project from '../../../classes/Project/Project';

const CardProjectsService = () => {
  const getProjects = async ({
    limit = 10,
    field_sort = 'contract_start_date',
    sort = -1,
    id,
  }) => {
    const isPrivate = false;
    const ne_project = id ? `,"ne_project":"${id}"` : '';
    const api = new Api(
      `${URL.URL_SERVER}/projects?sort=${sort}&field_sort=${field_sort}&limit=${limit}&filter={"private":${isPrivate}${ne_project}}`
    );
    const http_response = await api.get();
    const response = new Response(http_response, Project);
    const { resources: projects } = response.wasSuccessfull().multiple();
    return projects;
  };

  return {
    getProjects,
  };
};

export default CardProjectsService;
