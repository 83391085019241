import { useEffect, useState } from 'react';
import {
  aenor_light,
  arauco_light,
  aws_light,
  corfo_light,
  ecota_light,
  ge_light,
  magical_light,
  sme_light,
  stellar_light,
  techstars_light,
  verra_light,
} from './alliesCard.module';

const AlliesCardLogic = (props) => {
  const DICTIONARY = {
    aenor: aenor_light,
    arauco: arauco_light,
    aws: aws_light,
    british: ge_light,
    corfo: corfo_light,
    ecota: ecota_light,
    magical: magical_light,
    sme: sme_light,
    stellar: stellar_light,
    techstars: techstars_light,
    verra: verra_light,
  };
  const { ally } = props;
  const [values, setValues] = useState({
    ...props,
    img: sme_light,
  });

  const setClass = () => {
    const img = DICTIONARY[ally] || sme_light;
    setValues({ ...values, img });
  };

  useEffect(() => {
    setClass();
  }, []);

  return {
    values,
  };
};

export default AlliesCardLogic;
