import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#D9D9D9',
    borderRadius: '12px',
    color: 'black',    
  },
  documentationTitle: {
    marginTop: 28,
    fontFamily: 'MontserratBold',
    fontSize: 24,
    margin: 0,
    padding: 0,
    color: 'black',
  },
  documentationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom:30
  },
  textArrowContainer: {
    margin: 0,
    padding: 0,
    textAlign: 'left',
  },
  documentationSectionTitle: {
    fontFamily: 'montserratBold',
    fontWeight: 700,
    height: 30,
    fontSize: 24,

    borderBottom: '3px solid #00DD9E ',
  },
  arrow: {
    color: '#00DD9E',
    fontSize: 32,
  },
  documentationSectiontext: {
    margin: 0,
    padding: 0,
    fontSize: 24,
    textAlign: 'left',
  },
}));

export default useStyles;
