import React from 'react';
import {
  Grid,
  Typography,
  Container,
  OutlinedInput,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { ConfirmOfferLogic, useStyles } from './confirmOffer.module';
import Footer from '../footer/Footer';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';
const ConfirmOffer = () => {
  const history = useHistory();
  const { id_user, id_offer } = history.location.state || {};
  console.log('id del usuario:', id_user);
  console.log('id de la oferta:', id_offer);
  const {
    translator,
    handleCodeChange,
    isButtonEnabled,
    code,
    handleConfirmOffer,
  } = ConfirmOfferLogic(id_user, id_offer, history);
  const classes = useStyles({ isButtonEnabled });

  return (
    <>
      <Container>
        <Grid container className={classes.confirmOfferRoot}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className={classes.confirmOfferContainer}>
                  <div className={classes.breadcrumbContainer}>
                    <KeyboardArrowLeftIcon
                      onClick={() => history.goBack()}
                      style={{
                        fontSize: 42,
                        fontFamily: 'Montserrat',
                        color: '#05649c',
                        cursor: 'pointer',
                      }}
                    />
                    <Typography className={classes.textBack}>
                      {translator('confirmOffer.back')}
                    </Typography>{' '}
                  </div>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography className={classes.titleNewOffer}>
                        {translator('confirmOffer.title')}
                      </Typography>
                      <Typography className={classes.textNewOffer}>
                        {translator('confirmOffer.text')}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Container maxWidth="xs" style={{ padding: '10px 0px' }}>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                        <OutlinedInput
                          variant="outlined"
                          className={classes.inputConfirmOffer}
                          fullWidth
                          inputProps={{
                            maxLength: 6,
                            inputMode: 'numeric',
                          }}
                          value={code}
                          onChange={handleCodeChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ''
                            );
                          }}
                          // Todo: Label de ayuda al usuario para ingreso de números únicamente
                          // label="Ingrese el código de confirmación de 6 números"
                        />
                        <FormHelperText
                          className={classes.helperTextConfirmOffer}
                        >
                          {translator('confirmOffer.label')}
                        </FormHelperText>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button
                          className={classes.buttonConfirmOffer}
                          // Todo: Verificar el habilitado de los botones y sus estilos
                          disabled={!isButtonEnabled}
                          onClick={handleConfirmOffer}
                        >
                          {translator('confirmOffer.button')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </Grid>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default ConfirmOffer;
