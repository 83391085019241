import { useContext, useEffect, useState } from 'react';
import Session from '../../classes/Session/Session';
import { useLocation } from 'react-router-dom';
import Translator from '../../classes/Translator/Translator';
import GeneralContext from '../../context/GeneralContext';

const LoginSectionLogic = (callback, validate, props, createSession) => {
  const { pathAfterLogin } = useContext(GeneralContext);
  const [values, setValues] = useState({
    ...props,
    client_id: '',
    password: '',
    showPassword: false,
    hasSession: new Session({}).hasSession(),
    showSuccess: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { translator } = Translator();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  const createSharedSession = async (data) => {
    const { id, token } = data;
    await createSession({ id, token });
  };

  /* function postCrossDomainMessage(msg) {
    const win = document.getElementById('ifr').contentWindow;
    win.postMessage(
      msg,
      `${process.env.REACT_APP_PERMITTED_DOMAIN_EGREEN}/session`
    );
  } */

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, callback, isSubmitting]);

  useEffect(() => {
    if (location.state && location.state.callback) {
      setValues({ ...values, callback: location.state.callback });
    }
  }, []);

  return {
    errors,
    values,
    setValues,
    setErrors,
    translator,
    pathAfterLogin,
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmit,
    createSharedSession,
    loading
  };
};

export default LoginSectionLogic;
