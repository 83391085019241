import React from 'react';
import { Box, IconButton, Collapse } from '@material-ui/core';
import { useStyles, ReadMoreLogic } from './readMore.module';

const ReadMore = (props) => {
  const classes = useStyles();
  const { values, expanded, toggleExpanded } = ReadMoreLogic(props);

  return (
    <>
      <IconButton
        onClick={toggleExpanded}
        aria-expanded={expanded}
        aria-label="expand-more"
        disableRipple
        className={classes.expandButton}
      >
        {expanded ? values.contractedText : values.text}
      </IconButton>
      <Collapse in={expanded}>
        {values.expandedText && (
          <Box>
            {typeof values.expandedText === 'string' ? (
              <p
                className={
                  values.sectionStyle
                    ? classes.textInfoSection
                    : classes.textInfo
                }
              >
                {values.expandedText}
              </p>
            ) : (
              values.expandedText.map((item, index) => (
                <p
                  key={index}
                  className={
                    values.sectionStyle
                      ? classes.textInfoSection
                      : classes.textInfo
                  }
                >
                  {item}
                </p>
              ))
            )}
          </Box>
        )}
      </Collapse>
    </>
  );
};

export default ReadMore;
