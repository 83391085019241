import Translator from '../../classes/Translator/Translator';

const KeyIndicatorsLogic = (getKeys) => {
  const { translator } = Translator();

  return {
    translator,
  };
};
export default KeyIndicatorsLogic;
