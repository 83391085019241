import { useState, useEffect } from 'react';
import BlogPostDetailsService from './BlogPostDetails.service';

const BlogPostsDetailsLogic = (props) => {
  const [values, setValues] = useState({});
  const id = props.id;

  useEffect(() => {
    getPostsById();
  }, []);

  const getPostsById = async () => {
    const postDetails = await BlogPostDetailsService().getPostById(id);
    setValues(postDetails);
  };
  return {
    values,
  };
};

export default BlogPostsDetailsLogic;
