import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  infoBarContainer: {
    marginTop: '50px',
    marginBottom: '150px',
  },
  newsletterContainer: {
    marginBottom: '100px',
  },
  verifiedProjects: {
    marginBottom: 100
  }
}));

export default useStyles;
