import { projectKeysLogic, useStyles } from './projectKeys.module';
import keys from '../../classes/Project/ProjectKey';
import ModalComponent from '../shared/Modal/Modal';

export default function ProjectKeys(props) {
  const classes = useStyles();
  const { handleClick, name, data } = projectKeysLogic(props);

  const { infoModal } = {
    ...keys,
    ...props,
  };

  return (
    <>
      <div
        className={classes.container}
        onClick={handleClick}
        justifycontent="left"
      >
        <div className={classes.iconText_container}>
          <p className={classes.Title}>{name}</p>
          <p className={classes.textData}>{data}</p>
        </div>
        <ModalComponent /* open={openModal} onChange={onChange} */>
          {infoModal}
        </ModalComponent>
      </div>
    </>
  );
}
