import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  containerUserAvatar: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  avatar: {
    height: '320px',
    width: '320px',
    [theme.breakpoints.down('md')]: {
      width: '240px',
      height: '240px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '140px',
      height: '140px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  userHello: {
    fontFamily: 'MontserratBold',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'left',
    //color: '#FFFFFF',
    color: theme.palette.text.tertiary,
  },
  userName: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: '70px',
    lineHeight: '60px',
    letterSpacing: '0em',
    textAlign: 'left',
    //color: '#FFFFFF',
    color: theme.palette.text.tertiary,
    marginBottom: 13,
    [theme.breakpoints.up('xs')]: {
      fontSize: '40px',
    },
  },
  email: {
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '10px',
    letterSpacing: '0em',
    textAlign: 'left',
    margin: '12px 0px',
    marginBottom: 24,
    //color: '#FFFFFF',
    color: theme.palette.text.tertiary,
  },
  userData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  editProfile: {
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '10px',
    letterSpacing: '0em',
    textAlign: 'left',
    // color: '#FFFFFF',
    color: theme.palette.text.tertiary,
  },
  iconUser: {
    fontSize: '36.82px !important',
  },
  publicKey: {
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    margin: '12px 0px',
    color: theme.palette.text.tertiary,
    wordBreak: 'break-all',
  },
  iconButton: {
    marginLeft: '6px',
    paddingTop: '6px',
    paddingBottom: '12px',
    paddingLeft: '3px',
    paddingRight: '0px',
    color: theme.palette.text.tertiary,
  },


}));

export default useStyles;
