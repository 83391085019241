import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerMarket: {
    marginTop: 35,
    marginBottom: 10,
    // background: 'blue'

  },
  containerSimple: {
    marginTop: 120,
    marginBottom: 100,
    // background: 'red'
  },
  containerClear: {
    marginTop: 75,
    marginBottom: 0,
    // background: 'green'
  },
  containerVoluntary: {
   
    // background: 'green'
 marginTop: 120,
    marginBottom: 0,
  },
  containerOur: {
    marginTop: 0,
    marginBottom: 0,
    // background: 'orange'
  },
  containerGraphic: {
    marginTop: 0,
    paddingTop: 0,
    marginBottom: 90,
    // background: 'black'
  },
  gridMarket: {
    display: 'grid',
    justifyItems: 'center',
    gap: '20px',
    '@media screen and (min-width:576px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr 1fr',

    },
  },
  gridOnlyCard: {
    display: 'grid',
    gap: '10px',
    gridTemplateColumns: '1fr ',
    justifyItems: 'center'

  },
  gridItem: {
    width: '100%',
    height: 'auto',
    borderRadius: '20px',
    border: '10px solid #FFFFFF',
  },
  titleCenter: {
    // textCenter: {
    display: 'flex',
    justifyContent: 'center',
    // },

  },
  textCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  textLeft: {
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (min-width:576px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    '@media screen and (max-width:992px)': {
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  titleSimple: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,

    textAlign: 'center',
    // color: '#041370'
    color: theme.palette.text.nonary,
    '@media screen and (min-width:576px)': {
      fontSize: '56px',
      // lineHeight: '74px',
    },
    '@media screen and (max-width:992px)': {
      fontSize: '35px',
      lineHeight: '50px',
    }
  },
  textOur: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    // color: '#000F20',
    color: theme.palette.text.denary,
    textTransform: 'capitalize',
    '@media screen and (min-width:576px)': {
      textAlign: 'justify',
    },
    '@media screen and (max-width:992px)': {
      textAlign: 'center',
    }
  },
  textSource: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
    textAlign: 'center',
    // letterSpacing: '0.005em',
    // textTransform: 'capitalize',
    // color: '#000000'
    color: theme.palette.text.secondary,

  },
  // titleLet: {
  //   fontFamily: 'Montserrat',
  //   fontStyle: 'normal',
  //   fontWeight: 700,
  //   fontSize: '62px',
  //   lineHeight: '76px',
  //   textAlign: 'center',
  //   // color: '#041370'
  //   color: theme.palette.text.nonary,

  // },
  titleVoluntary: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'capitalize',
    // color: '#000000'
    color: theme.palette.text.secondary,
  },

  // textSource: {
  //   fontFamily: 'Montserrat',
  //   fontStyle: 'normal',
  //   fontWeight: 400,
  //   fontSize: '18px',
  //   lineHeight: '32px',
  //   textAlign: 'center',
  //   letterSpacing: '0.005em',
  //   textTransform: 'capitalize',
  //   // color: '#000000'
  //   color: theme.palette.text.secondary,

  // },
  titleEgren: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,

    textAlign: 'center',
    // color: '#041370'
    color: theme.palette.text.nonary,
    '@media screen and (min-width:576px)': {
      fontSize: '62px',
      lineHeight: '76px',
    },
    '@media screen and (max-width:992px)': {
      fontSize: '35px',
      lineHeight: '50px',
    }
  },
  // titleWhy: {
  //   fontFamily: 'Montserrat',
  //   fontStyle: 'normal',
  //   fontWeight: 700,
  //   fontSize: '62px',
  //   lineHeight: '76px',
  //   textAlign: 'center',
  //   // color: '#041370'
  //   color: theme.palette.text.nonary,

  // },

  textStellar: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '32px',
    textAlign: 'center',
    letterSpacing: '0.005em',
    textTransform: 'capitalize',
    // color: '#000000'
    color: theme.palette.text.secondary,

  },
  textDisclaimer: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '48px',
    textAlign: 'center',
    letterSpacing: '-1px',
    color: theme.palette.text.secondary,
    // color: '#000000'
  },
  titleForescat: {
    marginTop: 10
  },
 textLeftSubtitle: {
    display: 'flex',
    flexDirection: 'column',

    '@media screen and (min-width:576px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    '@media screen and (max-width:992px)': {
      alignItems: 'center',
      justifyContent: 'center',
marginTop:30
    }
  },

}));
export default useStyles;
