import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 77,
    display: 'flex',
    justifyContent: 'center',
    height: 843,
  },
  welcomeBanner: {
    display: 'block',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  title: {
    fontFamily: 'MontserratBold',
    fontWeight: 800,
    fontSize: 56,
    color: theme.palette.text.secondary,
    marginBottom: 48,
  },
  verifyCodeSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 32,
  },
  textField: {
    width: 544,
    height: 42,
    borderRadius: 8,
    marginBottom: 15,
    border: '1px solid #E0E0E0',
    boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.1)',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.tertiary,
    '&:focus': {
      border: '1px solid rgba(0, 111, 86, 0.5)',
      boxShadow: '1px solid rgba(0, 111, 86, 0.5)',
    },
  },
  labels: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 18,
    color: theme.palette.text.tertiary,
    height: 24,
    width: 544,
    textAlign: 'left',
  },
  [theme.breakpoints.down("sm")]: {
    welcomeBanner: {
      display: 'none',
    },
    form: {
      alignItems: 'center',
    },
    title: {
      width: 400,
    },
    verifyCodeSection: {
      alignItems: 'center',
      marginLeft: 0,
    },
    textField: {
      width: 400,
    },
    labels: {
      width: 400,
    },
  },
  [theme.breakpoints.down("xs")]: {
    root: {
      height: 643,
    },
    title: {
      width: 252,
      textAlign: 'center',
      fontSize: 38,
    },
    textField: {
      width: 252,
    },
    labels: {
      width: 262,
    },
  },
}));

export default useStyles;
