import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import Offer from '../../classes/Offer/Offer';
import CustomError from '../../classes/CustomError/CustomError';

const DICTIONARY = {
  all: '["BUY", "SELL"]',
  BUY: '["BUY"]',
  SELL: '["SELL"]',
};

const OpenMarketService = () => {

  const getOffers = async (id, activeFilter) => {
    const typeFilter = DICTIONARY[activeFilter] || DICTIONARY.all;  
    const api = new API(
      `${URL.URL_SERVER}/offers?filter={"type":${typeFilter},"status":["open"],"ne_user":"${id}"}`
    );
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Offer);
      return response.wasSuccessfull().multiple();
    }
  };

  return {
    getOffers,
  };
};

export default OpenMarketService;
