import Translator from '../../classes/Translator/Translator';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const Slide4Logic = () => {
  const { translator } = Translator();

  const history = useHistory();
  const goTo = (route) => () => {
    return history.push(route);
  };

  return {
    translator,
    goTo,
  };
};

export default Slide4Logic;
