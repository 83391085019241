import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        boxSizing: 'border- box',
        background: 'transparent',
        borderRadius: '20px',
        border: '1px solid #00DD9E',
        boxShadow: "none",
        '@media screen and (min-width:576px)': {
            width: '100%',
        },
        '@media screen and (min-width:768px)': {
            // width:300,
        },
    },
    bullet: {
        display: 'inline-block',
        padding: '0px 6px',
        transform: 'scale(1)',
        fontSize: 18,
        fontWeight: 'bold',
        color: theme.palette.primary,


    },
    bulletWhite: {
        display: 'inline-block',
        padding: '0px 6px',
        transform: 'scale(1)',
        fontSize: 18,
        color: 'white',

    },
    bulletMain: {
        display: 'inline-block',
        padding: '0px 6px',
        transform: 'scale(1)',
        fontSize: 18,
        color: 'transparent',

    },
    pricingMain: {
        background: '#3607A8',
        border: 'none',
    },
    title: {
        fontFamily: 'MontserratBold',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '25px',
        lineHeight: '30px',
        textAlign: 'center',
        color: theme.palette.primary,
        // color: '#041370',
        marginTop: 30,
        width: '80%',
        paddingLeft: '20%'
    },
    subtitle: {
        fontFamily: 'MontserratRegular',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '40px',
        textAlign: 'center',
        color: theme.palette.primary,
        opacity: '0.6',
    },
    amount: {

        fontFamily: 'MontserratBold',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '40px',
        lineHeight: '49px',
        textAlign: 'center',
        color: '#00DD9E',
        marginBottom: 30
    },

    list: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
    },
    button: {
        padding: '1em',
        width: '100%'
    },
    colorWhite: {
        color: '#ffffff !important',
    },
    center: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        color: theme.palette.primary,
        opacity: 0.8,
    },
    cardHovered: {
        transform: 'scale3d(1.05, 1.05, 1)',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
        transition: '0.3s',
    },

}));
export default useStyles;
