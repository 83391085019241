import Joi from 'joi';

const searchSchema = Joi.string();

export default function validator(values) {
  const errors = {};
  const { error: errorSearch } = searchSchema.validate(values.search);

  if (errorSearch) {
    errors.error_search = 'Ingresa un criterio de busqueda valido.';
  }
  return errors;
}
