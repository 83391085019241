import Translator from '../../classes/Translator/Translator';

const CarbonCreditCardLogic = () => {
  const { translator } = Translator();

  return {
    translator,

  };
};
export default CarbonCreditCardLogic;
