import React from 'react';
import { Grid, Container, Box } from '@material-ui/core';
import CardProjects from '../shared/cardProjects/CardProjects';
import { useStyles, OurProjectsLogic } from './ourProjects.module';

const OurProjects = () => {
  const { translator } = OurProjectsLogic();
  const classes = useStyles();

  return (
    <Container>
      <Box p={6}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} xs={12}>
            <Box className={classes.container}>
              <h1 className={classes.title}>
                {translator('ourProjects.title')}
              </h1>
            </Box>
          </Grid>
          <CardProjects type={'Full'} text={'Go To Project'} />
        </Grid>
      </Box>
    </Container>
  );
};

export default OurProjects;
