import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import CustomError from '../../classes/CustomError/CustomError';
import Response from '../../classes/Response/Response';
import Stats from '../../classes/Stats/Stats';

const InfoBarService = () => {
  const getData = async () => {
    const api = new API(`${URL.URL_SERVER}/stats`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Stats);
      return response.wasSuccessfull().single();
    }
  };

  return {
    getData,
  };
};

export default InfoBarService;
