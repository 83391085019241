import { useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import Session from '../../classes/Session/Session';
import Storage from '../../classes/Storage/Storage';
import Transaction from '../../classes/Transaction/Transaction';
import CustomError from '../../classes/CustomError/CustomError';
import Translator from '../../classes/Translator/Translator';
import GeneralContext from '../../context/GeneralContext';

const InvestmentProcedureLogic = (getProject, createTransaction) => {
  const { setGeneralValues } = useContext(GeneralContext);
  const { translator } = Translator();
  let history = useHistory();
  const [values, setValues] = useState({
    coins: 0,
    project: {},
    success: true,
    title: Transaction.DICTIONARY_CONFIRMATION['buy_bonds'].title,
    message: Transaction.DICTIONARY_CONFIRMATION['buy_bonds'].message,
  });
  const [errors, setErrors] = useState({});
  const [coins, setCoins] = useState(0);
  const [type, setType] = useState(Transaction.BUY_BONDS);

  const setCoinsFromChild = (coins) => {
    setCoins(coins);
  };

  const setTypeFromChild = (type) => {
    const dict = Transaction.DICTIONARY_CONFIRMATION[type];
    setValues((old) => ({ ...old, title: dict.title, message: dict.message }));
    setType(type);
  };

  const handleReturn = (e) => {
    e.preventDefault();
    return history.push('/projects');
  };

  const _createTransaction = async () => {
    const session = new Storage(Session.ID);
    const project = await getProject();
    const real_coins = coins * project.forwards_equivalent;
    const transaction = new Transaction({
      type,
      id_user: session.getItem(),
      project_invested: project.project_name,
      project_id: project.id,
      carbon_credit_invested: `${real_coins}`,
      client_coins: `${real_coins}`,
      total_coins: `${real_coins}`,
      co2_emission: `${real_coins}`,
    });
    const transaction_response = await createTransaction(
      transaction.fotCreateBonds
    );
    if (transaction_response instanceof CustomError) {
      setErrors({
        api_error: 'Hemos tenido un problema al comunicarnos con el servidor.',
      });
    } else {
      return transaction_response;
    }
  };

  const goToBilling = async (e) => {
    e.preventDefault();
    const transaction = await _createTransaction();
    setGeneralValues((old_values) => ({
      ...old_values,
      pathAfterLogin: `/billing/${transaction.id}`,
    }));
    return history.push(`/billing/${transaction.id}`);
  };

  useEffect(() => {
    setValues({ ...values, coins, success: Number(coins) > 0 });
  }, [coins]);

  useEffect(() => {
    const { project } = values;
    setCoins(project.forwards_minimum || 0);
  }, [values.project]);

  useEffect(() => {
    getProject().then((project) => setValues({ ...values, project }));
  }, []);

  return {
    values,
    errors,
    coins,
    setCoinsFromChild,
    setTypeFromChild,
    handleReturn,
    goToBilling,
    translator,
  };
};

export default InvestmentProcedureLogic;
