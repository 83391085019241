import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 64,
    margin: 0,
    padding: '0px 24px',
    '& .MuiPaper-elevation4': {
      boxShadow: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  egreenContainer: {
    maxWidth: 250,
    height: 'auto',
    flexGrow: 1,
  },
  egreenLogo: {
    width: '55px',
    height: '55.36px',
    marginLeft: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  IconButton: {
    color: '#041370',
  },
  IconButtonLight: {
    color: '#fff',
  },

  tabsContainer: {
    marginLeft: 'auto',
  },
  selectedText: {
    textTransform: 'none',
    fontFamily: 'MontserratBold',
    fontWeight: '400',
    fontSize: '18px',
    color: theme.text,
  },
  text: {
    textTransform: 'none',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '22.4px',
    padding: 12,
    color: theme.palette.primary.nonary,
  },
  textGreen: {
    color: '#06E89F',
    textTransform: 'none',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '22.4px',
    padding: 16,
  },
  menuTextLang: {
    textTransform: 'none',
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '18px',
    color: theme.text,
  },
  link: {
    textDecoration: 'none',
    textDecorationLine: 'none',
    color: theme.background,
  },
  [theme.breakpoints.down('sm')]: {
    buttonESEN: {
      display: 'none',
    },
    buttonDarkMode: {
      display: 'none',
    },
    title: {
      width: 30,
    },
  },
  [theme.breakpoints.up('sm')]: {
    egreenLogo: {
      width: 200,
    },
  },
  active: {
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: '600px',
      fontStyle: 'normal',
      lineHeight: '32px',
      color: '#333333',
    },
  },
  activeLight: {
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: '600px',
      fontStyle: 'normal',
      lineHeight: '32px',
      color: theme.palette.text.secondary,
    },
  },
  activePersonIcon: {
    marginTop: 12,
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: '600px',
      fontStyle: 'normal',
      lineHeight: '32px',
      color: '#333333',
    },
  },
  activePersonIconLight: {
    marginTop: 12,
    '& .MuiTypography-root': {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: '600px',
      fontStyle: 'normal',
      lineHeight: '32px',
      color: theme.palette.text.secondary,
    },
  },

  flexItem: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  mainLinks: {
    width: 1024,
  },
}));

export default useStyles;
