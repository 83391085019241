import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    margin: 8,
    width: 180,
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    border: '2px solid #00DD9E',
    borderRadius: 20,
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    padding: 12,
  },
  priceBox: {
    width: '100%',
    border: '1px solid #00DD9E',
    borderRadius: 8,
    margin: '4px 0px',
  },
  text: {
    color: '#3f3d56',
  },
  textDate: {
    fontSize: 12,
  },
  textNumber: {
    fontSize: 24,
    fontWeight: 700,
  },
  textSemi: {
    fontSize: 16,
  },
  textBold: {
    fontWeight: 700,
  },
  boxButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  customButton: {
    flex: 1,
    height: 28,
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: 18,
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '041370',
    },
    '&:disabled': {
      backgroundColor: '#676767d8',
      color: '#ffffff',
    },

  },
  customButtonDelete: {
    backgroundColor: '#BB4430',
    flex: 1,
    height: 28,
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: 18,
    color: '#041370',
    fontSize: '14px',
    '&:hover': {
      color: '#fff',
    },
  },
}));

export default useStyles;
