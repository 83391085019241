import Joi from 'joi';

const usdSchema = Joi.number().min(0).required();
export default function validator(values) {
  const errors = {};

  const { error: errorUsd } = usdSchema.validate(values.usd);

  if (errorUsd) {
    errors.error_usd = 'Ingresa un número positivo.';
  }
  return errors;
}
