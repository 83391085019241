import React from 'react';
import { Link } from 'react-router-dom';
import { CardMedia, Container, Grid, Typography } from '@material-ui/core';
import Footer from '../footer/Footer';
import ButtonDefault from '../shared/button/button';

import {
  useStyles,
  CodeSentLogic,
  checkImg,
  PropTypes,
} from './codeSent.module';

const CodeSent = () => {
  const classes = useStyles();
  const { translator } = CodeSentLogic();

  return (
    <React.Fragment>
      <>
        <Container maxWidth="md">
          <Grid container spacing={1}>
            <Grid item lg={12} sm={12} xs={12} align="center">
              <h1 className={classes.title}>{translator('codeSent.title')}</h1>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <CardMedia
                className={classes.checkImg}
                image={checkImg}
                title="code sended check"
              />
            </Grid>
            <Grid item lg={12} sm={12} xs={12} align="center">
              <MessageSent />
            </Grid>
            <Grid
              item
              lg={12}
              sm={12}
              xs={12}
              className={classes.button}
              align="center"
            >
              <Link to="/login" className={classes.link}>
                <ButtonDefault
                  type="blue"
                  text={translator('codeSentBackHome')}
                />
              </Link>
            </Grid>
          </Grid>
        </Container>
      </>
      <Footer />
    </React.Fragment>
  );
};

CodeSent.propTypes = {
  mail: PropTypes.string,
};

export default CodeSent;
const MessageSent = (props) => {
  const classes = useStyles();
  const { values } = CodeSentLogic(props);
  const { translator } = CodeSentLogic();

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.card} item lg={12} sm={12} xs={12}>
        <Typography className={classes.cardTitle} align="center" gutterBottom>
          {translator('codeSent.titleMessageSent')}
        </Typography>
        <Typography className={classes.cardText} align="center" gutterBottom>
          {translator('codeSent.textMessageSentFirst')}
          {values.mail}
          {translator('codeSent.textMessageSentSecond')}
        </Typography>
      </Grid>
    </Grid>
  );
};
