import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import CustomError from '../../classes/CustomError/CustomError';
import Response from '../../classes/Response/Response';

const ResetPasswordService = () => {
  const setPassword = async ({ email, new_password, code }) => {
    const api = new API(`${URL.URL_SERVER}/users/${email}/passwords`);
    const http_response = await api.put({ code, new_password });
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return response.wasSuccessfull();
    }
  };

  return {
    setPassword,
  };
};

export default ResetPasswordService;
