import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 180,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0,
    marginRight: 0,
    marginBottom: 0,
    background: 'rgba(0, 0, 0, 0)',
  },
  media: {
    width: 250,
    height: 200,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      marginRight: 0,
    },
    media: {
      width: 150,
      height: 140,
    },
  },
}));

export default useStyles;
