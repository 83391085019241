import React from 'react';
// import DMRVChart from '../dmrvChart/dmrvChart';
import GraphicExchangePage from '../GraphicExchangePage/GraphicExchangePage';

const Test = () => (
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    {/* <DMRVChart></DMRVChart> */}

    <GraphicExchangePage />
  </div>
);

export default Test;
