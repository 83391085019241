import { createContext } from 'react';

const Context = createContext({
  pathAfterLogin: '/dashboard',
  email: null,
  language: 'en',
  setGeneralValues: () => {},
});

export default Context;
