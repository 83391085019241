import Translator from '../../classes/Translator/Translator';

const AboutUsSectionLogic = (getData) => {
  const { translator } = Translator();

  return {
    translator,
  };
};

export default AboutUsSectionLogic;
