import { StepConnector, } from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
const BillStyles = () => {
    const useStyles = makeStyles((theme) => ({
        breadcrumbContainer: {
            margin: '75px auto -70px auto',
            maxWidth: '1350px',
            display: 'flex',
        },
        customLink: {
            textDecoration: 'none',
            color: theme.palette.text.tertiary,
            '&:hover': {
                color: theme.palette.text.tertiary,
                textDecoration: 'none',
            },
        },
        fontLink: {
            fontFamily: 'MontserratMedium',
            fontWeight: '500',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '26px',
        },
        box: {
            width: '100%'
        },
        formControl: {
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        container: {
            borderRadius: '16px',
            background: '#F7F7F7',
            paddingBottom: 48,
            marginBottom: 36,
            "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
                padding: '5px',
            },
        },
        investment: {
            marginTop: 13
        },
        redonded: {
            borderRadius: '50%',
            behavior: 'url(PIE.htc)',
            width: '20px',
            height: '20px',
            padding: '3px',
            background: '#008b17',
            color: '#FFFFFF',
            textAlign: 'center',
            font: '26px Arial, sans-serif',
        },
        placeholder: {
            color: "#aaa"
        },
        headerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            position: 'relative',
            paddingLeft: 2,
            marginTop: 10,
        },
        textfield: {
            backgroundColor: "#FFFFFF",
            marginTop: 4,
            marginBottom: 10,
            color: '#f44336',
            "&  .MuiFormHelperText-root.Mui-error": {
                backgroundColor: "#F7F7F7",
                margin: 0,
            },
            " & .MuiOutlinedInput-input": {
                padding: '11.5px 7px auto 11px',
            },
        },
        inputLabel: {
            fontWeight: 'bold',
            fontFamily: 'MontserratBold',
            color: '#333333',
            fontSize: 16,
            margin: 0,
            textAlign: 'left',
            marginBottom: 3,
            padding: 0
        },
        inputLabelEmpty: {
            fontWeight: 'bold',
            fontFamily: 'MontserratBold',
            color: '#FFFFFF',
            fontSize: 16,
            margin: 0,
            textAlign: 'left',
            marginBottom: 3,

        },
        subtitle: {
            color: '#04679C',
            fontWeight: 'bold',
            fontSize: '32px',
            fontFamily: 'MontserratBold',
        },
        fields: {
            paddingLeft: 30,
            paddingRight: 30,
        },
        number: {
            fontFamily: 'MontserratBold',
            fontHeight: '21px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontSize: '16px',
            lineHeight: '22px',
            textAlign: 'center',
            color: 'rgba(255, 255, 255, 1)',
        },
        link: {
            textDecoration: 'none'
        },
        formHelperText: {
            color: '#f44336',
        },
        required: {
            color: '#8C8C8C',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '700px',
            fontSize: 12,
            lineHeight: '16px',
            textAlign: 'left'
        },
        containerStepper: {
            // background: 'red',
            with: 100
        },
        // stepper
        root: {
            width: "70%",
            display: "inline-block",
            textAlign: 'center',
            "& .MuiStepLabel-label.MuiStepLabel-completed": {
                color: '#00923D',
                fontSize: 18,
                fontWeight: 'bold',
                fontFamily: 'MontserratBold'
            },

            "& .MuiStepLabel-label.MuiStepLabel-active": {
                color: '#00923D',
                fontSize: 18,
                fontWeight: 'bold',
                fontFamily: 'MontserratBold'
            },
            "& .MuiStepLabel-label": {
                fontSize: 18,
                fontWeight: 'bold',
                fontFamily: 'MontserratBold'

            },


        },
        button: {
            marginRight: theme.spacing(1)
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        moneyConverter: {
            marginBottom: 80,
        },
        buttonNext: {
            textAlign: 'left',
            marginBottom: 40,
        }
    }));

    const useQontoStepIconStyles = makeStyles({
        root: {
            color: "#eaeaf0",
            display: "flex",
            height: 22,
            alignItems: "center"
        },
        active: {
            color: "#784af4"
        },
        circle: {
            width: 8,
            height: 8,
            borderRadius: "50%",
            backgroundColor: "currentColor",

        },
        completed: {
            color: "#784af4",
            zIndex: 1,
            fontSize: 18,
        }
    });
    const ColorlibConnector = withStyles({
        alternativeLabel: {
            top: 22,
            left: 'calc(-50% + -10px)',

        },
        active: {
            "& $line": {
                background: "linear-gradient(90deg, rgba(0,146,61,1) 0%, rgba(0,146,61,1) 72%)",
            }
        },
        completed: {
            "& $line": {
                background: "linear-gradient(90deg, rgba(0,146,61,1) 0%, rgba(0,146,61,1) 72%)",
            }
        },
        line: {
            height: 3,
            border: 0,
            background: "linear-gradient(90deg, rgba(0,146,61,1) 0%, rgba(141,141,141,1) 72%)",
            borderRadius: 1
        }
    })(StepConnector);

    const useColorlibStepIconStyles = makeStyles({
        root: {
            backgroundColor: "#8D8D8D",
            zIndex: 1,
            color: "#fff",
            width: 50,
            height: 50,
            display: "flex",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Montserrat",

        },
        active: {
            background:
                "#00923D",
            fontFamily: "MontserratBold",
        },
        completed: {
            background: "linear-gradient(90deg, rgba(0,146,61,1) 0%, rgba(0,146,61,1) 72%)",
            fontFamily: "MontserratBold",
        }
    });

    return {
        useStyles,
        useQontoStepIconStyles,
        ColorlibConnector,
        useColorlibStepIconStyles
    }
}
export default BillStyles
