/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { MoneyConverterService } from './moneyConverter.module';
import Translator from '../../../classes/Translator/Translator';

const MoneyConverterLogic = (validate) => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    usd: 0,
    result: 0,
    currency: 'CLP',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState(false);

  const getResult = async () => {
    const result = await MoneyConverterService().convert(
      values.usd,
      values.currency
    );
    setResult(result);
  };

  const handleChange = (e) => {
    const { value, name } = e.currentTarget;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const checkErrors = () => {
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    checkErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(async () => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      await getResult();
    }
  }, [errors, getResult, isSubmitting]);

  return { 
    errors, 
    values, 
    handleChange, 
    result,
    translator,
  };
};

export default MoneyConverterLogic;
