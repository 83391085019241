import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    customButtonGroup: {
        width: '100%',
        boxShadow: 'none',
       borderRadius: '0', 
       border: 'none',
       
      },
      customButton:{
        flex: 1,
         margin: '0 1px' ,
        fontFamily: 'Montserrat',
        border: '0.1px solid #263238 !important',
        backgroundColor:'#06E89F',
        color: '#041370',
        // fontSize: '18px',
        '&:hover': {
         backgroundColor: '#06E89F',
         color: '041370',
    },
    },
    customButtonBold:{
        flex: 1,
         margin: '0 1px' ,
        fontFamily: 'MontserratBold',
        // fontFamily: 'MontserratExtraBold',
        border: '0.1px solid #263238 !important',
        backgroundColor:'#06E89F',
        color: '#041370',
        // fontSize: '18px',
        '&:hover': {
         backgroundColor: '#06E89F',
         color: '041370',
    },
    },
    customToolbarMenu: {
        '& .apexcharts-menu-item': {
            color: '#041370',
            fontFamily: 'Montserrat',
        },
        '& .apexcharts-menu-item:hover': {
            backgroundColor:'#06E89F !important',
        },
    },

   
}));
export default useStyles;

