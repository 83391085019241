import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import ProjectCard from '../projectCard/ProjectCard';
import { CardProjectsService, useStyles } from './cardProjects.module';
import { Grid } from '@material-ui/core';
import MinorProjectCard from '../minorProjectCard/minorProjectCard';
const CardProjects = (props) => {
  const { getProjects } = CardProjectsService();
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [selectedSortOption, setSelectedSortOption] = useState('Most Recent');
  const classes = useStyles();
  const DICT = {
    'Most Recent': { field_sort: 'contract_start_date', sort: -1 },
    Alphabetically: { field_sort: 'project_name', sort: 1 },
  };
  const { id } = props;

  const handleSelectedLimitChange = (event) => {
    const newLimit = event.target.value;
    setSelectedLimit(newLimit);
  };

  useEffect(() => {
    getProjects({ id }).then((projectList) => {
      setProjects(projectList);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    getProjects({ limit: selectedLimit, id }).then((projectList) => {
      setProjects(projectList);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLimit]);

  const handleSortOptionChange = (event) => {
    const newSortOption = event.target.value;
    setSelectedSortOption(newSortOption);

    const order = DICT[newSortOption];

    getProjects({
      limit: selectedLimit,
      sort: order.sort,
      field_sort: order.field_sort,
    }).then((projects) => {
      setProjects(projects);
    });
  };

  return (
    <div>
      <div className={classes.selectContainer}>
        <div className={classes.containerFormControlFirst}>
          <FormControl variant="outlined" className={classes.formControlFirst}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={selectedLimit}
              onChange={handleSelectedLimitChange}
              label="10"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.containerTextFormControlFirst}>
            <Typography className={classes.textFormControlFirst}>
              {/* Showing 1.10 of 178 */}
            </Typography>
          </div>
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControlSecond}>
            <Select
              labelId="demo-simple-select-outlined-label-order"
              id="demo-simple-select-outlined-order"
              value={selectedSortOption}
              onChange={handleSortOptionChange}
              label="Más reciente"
            >
              <MenuItem value={'Most Recent'}>Más reciente</MenuItem>
              <MenuItem value={'Alphabetically'}>Alfabéticamente</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <Grid container spacing={2} className={classes.contentProjectCard}>
        {isLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          projects.slice(0, selectedLimit).map((item, i) =>
            props.type === 'Full' ? (
              <Grid item lg={4} md={4} sm={6} xs={12} key={i}>
                <ProjectCard project={item} text={props.text} />
              </Grid>
            ) : (
              <Grid item lg={6} md={6} sm={6} xs={12} key={i}>
                <MinorProjectCard project={item} text={props.text} />
              </Grid>
            )
          )
        )}
      </Grid>
    </div>
  );
};

export default CardProjects;
