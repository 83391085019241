import URL from '../../classes/Url/URL';
import API from '../../classes/Api/Api';
import CustomError from '../../classes/CustomError/CustomError';
import Response from '../../classes/Response/Response';
import Project from '../../classes/Project/Project';
import ProjectIpfs from '../../classes/Project/ProjectIpfs';

const ProjectDetailService = () => {
  const getProject = async (id) => {
    const api = new API(`${URL.URL_SERVER}/projects/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Project);
      return response.wasSuccessfull().single();
    }
  };

  const getProjectIpfs = async (id) => {
    const api = new API(`${URL.URL_SERVER}/projects/${id}/ipfs`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, ProjectIpfs);
      return response.wasSuccessfull().multiple();
    }
  };

  return {
    getProject,
    getProjectIpfs,
  };
};

export default ProjectDetailService;
