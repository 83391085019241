import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const CardCoinsLogic = (props) => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    ...props,
  });

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    raised: false,
    shadow: 1,
  });

  return {
    values,
    state,
    setState,
    translator,
  };
};

export default CardCoinsLogic;
