import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  divContainer: {
    maxWidth: 511,
    position: "fixed",
    bgcolor: "background.paper",
    bottom: 20,
    backgroundColor: '#04679C',
    borderRadius: '16px',
    padding: 4,
    margin: 1,
    [theme.breakpoints.up('lg')]: {
      left: '15px',
    },
    [theme.breakpoints.up('md')]: {
      left: '15px',
    },
    [theme.breakpoints.up('sm')]: {
      left: '15px',
    },
  },
  cookieImg: {
    margin: 35,
    [theme.breakpoints.up('lg')]: {
      width: 102,
      height: 102,
    },
  },
  title: {
    color: '#FFF',
    fontWeight: '700',
    fontSize: '18px',
    paddingTop: 8,
    fontFamily: 'Montserrat',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 10,
    },
  },
  text: {
    color: '#FFF',
    fontWeight: '400',
    fontSize: '12px',
    marginBottom: 20,
    fontFamily: 'Montserrat',
  },
  button: {
    border: '#FFF solid 2px',
    borderRadius: 8,
    width: 110,
    height: 36,
    color: '#FFF',
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 10,
  },

  containerButton: {
    backgroundColor: 'red'
  },

  termsAndConditions: {
    textDecoration: 'none',
    textDecorationLine: 'none',
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    fontWeight: 700,
    fontSize: 14,
    color: '#FFF',
  },
}));

export default useStyles;
