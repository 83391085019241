import cookie from 'js-cookie';
import Formatter from '../Formatter/Formatter';

class User {
  static get VERIFIED_DICT() {
    return {
      es: {
        succeeded: 'Verificado',
        starter: 'Iniciado',
        pending: 'Pendiente',
        data_loaded: 'Información inicial cargada',
        rejected: 'Rechazado',
      },
      en: {
        succeeded: 'Verified',
        starter: 'Starter',
        pending: 'Pending',
        data_loaded: 'Initial data loaded',
        rejected: 'Rejected',
      },
      fr: {
        succeeded: 'Vérifié',
        starter: 'Débutant',
        pending: 'En attente',
        data_loaded: 'Données initiales chargées',
        rejected: 'Rejeté',
      },
    };
  }
  constructor({
    _id,
    id,
    username,
    profile_picture,
    name,
    lastname,
    email,
    type,
    access_level,
    hash,
    publicKey,
    rollback_code,
    enabled,
    verified,
    availableUSD,
    availableCoins,
    totalCoins,
    total_invested_projects = 0,
    availableNFT,
    availableSBT
  }) {
    this._id = _id || id;   
     this.username = username;
    this.profile_picture = profile_picture;
    this.name = name;
    this.lastname = lastname;
    this.email = email;
    this.type = type;
    this.access_level = access_level;
    this.hash = hash;
    this.publicKey = publicKey;
    this.rollback_code = rollback_code;
    this.enabled = enabled;
    this.verified = verified;
    this.availableUSD = Formatter.round(availableUSD);
    this.availableCoins = Formatter.round(availableCoins);
    this.totalCoins = Formatter.round(totalCoins);
    this.total_invested_projects = total_invested_projects;
    this.availableNFT = availableNFT;
    this.availableSBT = availableSBT;
  }

  toRegister({ name, lastname, email, password, type }) {
    this.name = name;
    this.lastname = lastname;
    this.email = email;
    this.password = password;
    this.type = type;
    return {
      name: this.name,
      lastname: this.lastname,
      email: this.email,
      password: this.password,
      type: this.type,
    };
  }

  get verifiedValue() {
    const language = cookie.get('i18next') || 'en';
    return User.VERIFIED_DICT[language][this.verified] || 'N/A';
  }

  get isVerified() {
    return this.verified === 'succeeded';
  }

  get isStarter() {
    return this.verified === 'starter';
  }

  get isPending() {
    return this.verified === 'pending' || this.verified === 'data_loaded';
  }

  get isRejected() {
    return this.verified === 'rejected';
  }
}

export default User;
