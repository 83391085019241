import React from 'react'
import { bannerCarbonOffset, imageCarbonOffset, CarbonOffsetLogic, useStyles } from './carbonOffset.module';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import Footer from '../footer/Footer';
import InfoQuestions from '../shared/infoQuestions/infoQuestion';
import { Container } from '@material-ui/core';
import OurProjects from '../ourProjects/ourProjects'

const CarbonOffset = () => {
    const { translator } = CarbonOffsetLogic();
    const classes = useStyles();

    return (
        <>
            <BannerInterface
                text={translator('carbonOffset.title')}
                subtitle={translator('carbonOffset.subtitle')}
                backgroundImage={bannerCarbonOffset}
            />
            <Container className={classes.contentCarbonOffset}
            >
                <InfoQuestions
                    textInfo2={translator('carbonOffset.textInfo')}
                    srcCarbonOffset={imageCarbonOffset}
                    text={translator('carbonOffset.text')}
                    to=""
                    typeButton="backgroundwhite"
                    children={translator('carbonOffset.children')}
                    showOneImg={true}

                />
                <OurProjects />
            </Container>
            <Footer />

        </>
    )
}

export default CarbonOffset