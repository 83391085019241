import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import {
  useStyles,
  ResetInformationAccordion,
  VerifyInformationAccordion,
} from './profile.module';
import 'antd/dist/antd.css';
import 'date-fns';

const Profile = () => {
  const classes = useStyles();

  return (
    <div className={classes.dashboardStyle}>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                My Profile
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
          <Grid item lg={10} md={10} sm={10} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ResetInformationAccordion />
                <VerifyInformationAccordion />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Profile;
