import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  carrousellContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    minHeight: 500,
    marginBottom: 60,
    marginTop: 110,
    paddingTop: 32,
    paddingLeft: 80,
    textAlign: 'justify',
    fontFamily: 'MonserratBold',
    [theme.breakpoints.down('md')]: {
      height: 400,
      paddingLeft: 4,
      width: '100%',
      paddingTop: 15,
      paddingRight: 10,
    },
    [theme.breakpoints.down('xs')]: {
      height: 400,
      paddingLeft: 4,
      width: '100%',
      paddingTop: 15,
      paddingRight: 10,
    },
  },
  swiperContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  img: {
    width: 210,
    height: 210,
    margin: 60,
    position: 'relative',
    scrollSnapAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 160,
      height: 160,
    },
  },
  title: {
    color: '#010C2B',
    // width: 700,
    fontSize: 30,
    fontWeight: 700,
    marginBottom: 12,
    textAlign: 'left',
    lineHeight: 1.43,
    letterSpacing:'0.01071em',
    [theme.breakpoints.down('md')]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      marginBottom: 4,
      lineHeight: 1,
      marginLeft: 15,
    },
  },
  title2: {
    color: '#010C2B',
    textAlign: 'left',
    lineHeight: 1.43,
    letterSpacing:'0.01071em',
    // width: 700,
    fontSize: 30,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      marginLeft: 15,
    },
    [theme.breakpoints.down('xs')]: {
      // width: '100%',
      fontSize: 20,
      lineHeight: 0.7,
      paddingTop: 7,
      paddingBottom: 7,
      marginLeft: 15,
    },
  },
  slider: (props) => ({
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 12,
    height: 380,
    overflowX: 'scroll',
    overscrollBehaviorX: 'contain',
    scrollSnapType: 'x proximity',

    '&:before': {
      content: 'algo',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 50,
      height: '100%',
    },
    '&:after': {
      content: 'algo',
      position: 'absolute',
      color: 'red',
      top: 0,
      right: 0,
      width: 50,
      height: '100%',
    },
    '&:hover:before': {
      transform: 'translateX(100px)', // Transformación en hover para "before"
    },
    '&:hover:after': {
      transform: 'translateX(-100px)',
    },
  }),
}));

export default useStyles;
