import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textInfo: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    color: theme.palette.text.denary,
    maxWidth: '1093px',
    marginBottom: '40px',
  },
  textInfoSection: {
    fontFamily: 'MontserratMedium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    color: theme.palette.text.denary,
    maxWidth: '1093px',
    marginBottom: '40px',
  },
  expandButton: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'flex-start',
    padding: 0,
    borderRadius: '50%',
    marginTop: '10px',
    marginBottom: '30px',
    fontFamily: 'MontserratBold',
    fontWeight: '700px',
    fontSize: '20px',
    color: theme.palette.text.nonary,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

export default useStyles;
