import React from "react";
import { Typography } from "@material-ui/core";
import { Slide3Logic, useStyles, slide3Img, infoBar } from "./Slide3.module";
import InfoBar from "../infobar/infoBar";
import ButtonDefault from '../shared/button/button';
import { useHistory } from 'react-router-dom';


export default function Slide3() {
    const classes= useStyles();
    const {translator}= Slide3Logic();
    const { push } = useHistory();
    return(
        <>
            <div className={classes.container}>
            <Typography variant="h4" component="h4" className={classes.title}>
            EGRN Coin<br/> The Game Changer
            </Typography>
            <p className={classes.subtitle}>{translator('slide3.subTitle')}</p>
            <div className={classes.buttonContainer}>
                <ButtonDefault
                type="buttonGreen"
                text={translator('learn_more')}
                changeView={() => push('/market')}
                />
                <ButtonDefault
                type="buttonGreen"
                text={translator('buy')}
                changeView={() => push('/projects')}
                />
            </div>
            <div className={classes.infoBarContainer}>
            <InfoBar/>
            </div>
            </div>
        </>
    )
}