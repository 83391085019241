import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import Footer from '../footer/Footer';
import CardProjects from '../shared/cardProjects/CardProjects';

import {
  PricingLogic,
  useStyles,
  banner,
  image1
} from './Pricing.module';

const Pricing = () => {
  const classes = useStyles();
  const { translator } = PricingLogic();

  return (
    <>
      <BannerInterface
        text={translator('investment&trading.title')}
        subtitle={translator('investment&trading.subtitle')}
        backgroundImage={banner}
      />

      <Container maxWidth="md">
        <Grid container spacing={0} className={classes.containerMain}>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.textInfo}>
            <p>{translator('investment&trading.text1')}</p>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <img className={classes.mainImg} src={image1} alt="" />
          </Grid>
        </Grid>

        <Container>
          <div className={classes.flexContainer}>
            <Typography className={classes.textHeader}>
              {translator('projects.textBanner')}
            </Typography>
          </div>
          <CardProjects text={translator('invest')} />
        </Container>

      </Container>

      <Footer />
    </>
  );
};

export default Pricing;
