import { useEffect, useState } from 'react';

const CardDashboardLogic = (card) => {
  const [values, setValues] = useState({
    ...card,
  });
  // const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  async function handleOpen() {
    // setOpenModal(true);
  }

  async function handleClose() {
    console.log('handle Close in Card Dashboard');
    // setOpenModal(false);
  }
  const onChange = (open) => {
    // setOpenModal(open);
  };

  useEffect(() => {
    setValues({ ...card });
  }, [card]);

  // useEffect(() => {
  //   console.log('openModal in useEffect', openModal);
  // }, [openModal]);

  return {
    values,
    // openModal,
    // setOpenModal,
    onChange,
    handleOpen,
    handleClose,
    openDialog, 
    setOpenDialog 
  };
};

export default CardDashboardLogic;
