import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  useStyles,
  // CreditExplanationLogic
} from './creditExplanation.module';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';
const CreditExplanation = (props) => {
  const classes = useStyles();
  // const { translator } = CreditExplanationLogic();

  return (
    <Grid container spacing={1} className={classes.rootCreditExplanation}>
      <Grid item lg={12} md={12} sm={12} xs={12} align="left">
        <Typography className={classes.titleCreditExplanation}>
          {/* {translator('impact.title')} */}
          {props.title}
        </Typography>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12} align="left">
        <List className={classes.listItem}>
          <ListItem className={classes.listItem}>
            <ListItemText
              className={classes.subtitleCreditExplanation}
              // primary={
              //   <React.Fragment>
              //     <Typography
              //       component="span"
              //       variant="body2"
              //       className={classes.subtitleCreditExplanation}
              //       color="textPrimary"
              //     >
              //       {props.nft_title}
              //     </Typography>
              //   </React.Fragment>
              // }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.descriptionCreditExplanation}
                    color="textPrimary"
                  >
                    {props.nft_description}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              className={classes.subtitleCreditExplanation}
              // primary={props.sbt_title}
              // primary={
              //   <React.Fragment>
              //     <Typography
              //       component="span"
              //       variant="body2"
              //       className={classes.subtitleCreditExplanation}
              //       color="textPrimary"
              //     >
              //       {props.sbt_title}
              //     </Typography>
              //   </React.Fragment>
              // }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.descriptionCreditExplanation}
                    color="textPrimary"
                  >
                    {props.sbt_description}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default CreditExplanation;
