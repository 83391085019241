import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  gridPricing: {
    display: 'grid',
    gap: '20px',

    '@media screen and (min-width:576px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },

  gridPricingTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150px',
    textAlign: 'center',
    color: theme.palette.text.nonary,
    '@media screen and (min-width:576px)': {
      fontSize: '50px',
    },
    '@media screen and (max-width:992px)': {
      fontSize: '36px',
      lineHeight: '42px',
    },
  },
  //còdigo
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    minWidth: '320px',
    width: '100%',
    // background: 'linear-gradient(to right top, #4222EF, #060F6E)',
    background: theme.palette.background.default,
  },
  ContainerCards: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: 0,
    flexWrap: 'wrap',
    width: '100%',
  },
  featurestitle: {
    fontSize: '64px',
    fontWeight: 700,
    color: theme.palette.text.nonary,
    margin: '50px',
    marginTop: '100px',
  },
  containerMain: {
    textAlign: 'center',
    justifyContent: 'center',
    padding: '16px',
    marginTop: '12px',
    whiteSpace: 'pre-wrap',
    // width: '280vw',
    // maxWidth: '960px',
  },
  containerCell: {
    textAlign: 'start',
    display: '-webkit-box',
    marginBottom: '100px',
  },
  containerItem: {
    display: 'block',
    paddingLeft: '25px',
  },
  containerImg: {
    display: 'block',
  },
  secondImg: {
    width: '50px',
    // height: '100px',
  },
  containerFeatures: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '16px',
    paddingLeft: '10%',
    margin: '64px',
    marginTop: '12px',
    width: '80vw',
    maxWidth: '900px',
    borderRadius: '48px',
    border: '3px solid #00D6B2',
    textAlign: 'start',
    boxShadow:
      '0 0 3px -1px transparent, 0 0 2px 1px transparent, 0 0 5px 0px transparent, 0px 10px 20px -5px black, 0 2px 15px -5px black, 0 0 20px 0px transparent',
  },
  checkedAndTextBox: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  checkRoundedIcon: {
    width: '1.5rem',
    height: '1.5rem',
    color: '#f5f5f5',
  },
  featuresSpan: {
    margin: '4px',
    color: theme.palette.text.primary,
    fontSize: '1.1rem',
    fontWeight: 300,
    textAlign: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '24px',
    },
    featurestitle: {
      fontSize: '20px',
    },
    featuresSpan: {
      fontSize: '16px',
    },
    checkedAndTextBox: {
      width: '240px',
      margin: 0,
      padding: 0,
    },
    containerFeatures: {
      padding: 4,
    },
  },
  link: {
    textDecoration: 'none',
  },
  mainImg: {
    height: 'auto',
    width: '90%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '305px',
      width: '350px',
      // width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '220px',
      width: '290px',
      // width: '100%',
    },
  },
  textInfo: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'center',
    color: theme.palette.text.denary,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
}));

export default useStyles;
