import React from 'react';

import {
  useStyles,
  WelcomeBannerLogic,
  egreenWorld,
  PropTypes,
} from './welcomeBanner.module';

const WelcomeBanner = (props) => {
  const classes = useStyles();
  const { values, text, greet } = WelcomeBannerLogic(props);
  console.log(values)
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>{greet}</h3>
      <p className={classes.welcomeText}>{text}</p>
      <img src={egreenWorld} alt="world" />
    </div>
  );
};

WelcomeBanner.propTypes = {
  text: PropTypes.string,
};

export default WelcomeBanner;
