import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  Box,
  Breadcrumbs,
  Button,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import Footer from '../footer/Footer';
import ButtonDefault from '../shared/button/button';
import ProjectItem from '../shared/projectItem/projectItem';
import {
  useStyles,
  InvestmentProcedureLogic,
  InvestmentProcedureService,
} from './investmentProcedure.module';
import { ProjectDetailService } from '../ProjectDetail/projectDetail.module';
import { useStyles as useStylesButton } from '../shared/button/button.module';
import AlertMessage from '../shared/alert/alert';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const InvestmentProcedure = () => {
  const classes = useStyles();
  const classesButton = useStylesButton();
  const { id } = useParams();
  const service = InvestmentProcedureService();
  const {
    handleReturn,
    goToBilling,
    setCoinsFromChild,
    setTypeFromChild,
    values,
    errors,
    translator,
  } = InvestmentProcedureLogic(
    () => ProjectDetailService().getProject(id),
    service.createTransaction,
    service.createSessionInStripe
  );

  return (
    <div>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_home')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to="/projects">
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_projects')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to={`/investment/${id}`}>
            <Typography className={classes.fontLink}>
              {translator('breadcrumbs_investment')}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Box>
      <Container>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <Typography className={classes.titleProduct}>
                {translator('investment_title')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.projectItemInvestement}
          >
            <ProjectItem
              project={values.project}
              setCoins={setCoinsFromChild}
              setType={setTypeFromChild}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            align="left"
            className={classes.descriptionInvestement}
          >
            {translator('investment_description')}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} align="left">
            <Typography className={classes.caution} variant="caption" />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.alertMessageContainer}
            align="center"
          >
            {errors && errors.api_error && (
              <AlertMessage type="error" text={errors.api_error} />
            )}
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.buttonsContainer}
            align="right"
          >
            <ButtonDefault
              text={translator('investment_go_back_button')}
              type="whiteborderblue"
              changeView={handleReturn}
            />
            <Button
              variant="outlined"
              className={classesButton['buttonBackgroundGreen']}
              onClick={goToBilling}
              disabled={!values.success}
            >
              {translator('investment_continue_button')}
              <ArrowRightAltIcon className={classes.iconButtonVerified} />
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default InvestmentProcedure;
