import Joi from 'joi';

const nameShema = Joi.string().min(1).max(15).allow(null, '').pattern(/^[A-Z ]+$/i).required();
const lastNameShema = Joi.string().min(1).max(15).allow(null, '').pattern(/^[A-Z ]+$/i).required();
const dniShema = Joi.string().min(1).max(15).allow(null, '').pattern(/^[0-9]+$/).required();
const cityShema = Joi.string().min(1).max(15).allow(null, '').pattern(/^[A-Z ]+$/i).required();
const addressSchema = Joi.string().min(1).max(15).allow(null, '').required();
const codeShema = Joi.string().min(1).max(15).allow(null, '').pattern(/^[0-9]+$/).required();
const phoneShema = Joi.string().min(1).max(15).allow(null, '').pattern(/^[0-9]+$/).required();
const emailShema = Joi.string().allow(null, '')
    .email({ tlds: { allow: false } })
    .required();

export default function validator(values) {
    const errors = {};
    const { error: errorName } = nameShema.validate(values.name);
    const { error: errorLastName } = lastNameShema.validate(values.lastName);
    const { error: errorDni } = dniShema.validate(values.dni);
    const { error: errorCity } = cityShema.validate(values.city);
    const { error: errorAddress } = addressSchema.validate(values.address);
    const { error: errorCode } = codeShema.validate(values.code);
    const { error: errorPhone } = phoneShema.validate(values.phone);
    const { error: errorEmail } = emailShema.validate(values.email);

    if (errorName) {
        errors.errorName = 'Ingresa un nombre válido.';
    }
    if (errorLastName) {
        errors.errorLastName = 'Ingresa un apellido válido.';
    }
    if (errorDni) {
        errors.errorDni = 'Ingresa un dni válido.';
    }
    if (errorCity) {
        errors.errorCity = 'Ingresa una ciudad válida.';
    }
    if (errorAddress) {
        errors.errorAddress = 'Ingresa una dirección válida.';
    }
    if (errorCode) {
        errors.errorCode = 'Ingresa un código de postal válido.';
    }
    if (errorPhone) {
        errors.errorPhone = 'Ingrese un número de teléfono válido.';
    }
    if (errorEmail) {
        errors.errorEmail = 'Ingrese un correo electrónico válido.';
    }
    return errors;
}

