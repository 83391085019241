import React from 'react';
import Alert from '@material-ui/lab/Alert';

import { useStyles, AlertLogic, PropTypes } from './alert.module';

const AlertMessage = (props) => {
  const classes = useStyles();
  const { values } = AlertLogic(props);

  return(
    <Alert
    className={classes[values.alertClass]}
    severity={values.type}
    >
      {values.text}
    </Alert>
  );
};

AlertMessage.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};

export default AlertMessage;
