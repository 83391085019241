import React from 'react';
import { Link } from '@material-ui/core';
import {
  useStyles,
  TeamMemberLogic,
  linkedinIcon,
  PropTypes,
} from './teamMember.module';

const TeamMember = (props) => {
  const classes = useStyles();
  const { values } = TeamMemberLogic(props);

  return (
    <div className={classes.root} key={values.key}>
      <img className={classes.image} src={values.photo} alt="photoMember" />
      <div className={classes.containerMember}>
        <p className={classes.name}>{values.name}</p>
        <h4 className={classes.role}>{values.role}</h4>

        <Link href={values.href} target="_blank" >
          <img className={classes.linkedinIcon} src={linkedinIcon} alt="linkedinIcon" />
        </Link>
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  photo: PropTypes.string,
  role: PropTypes.string,
  name: PropTypes.string,
  href: PropTypes.string,
};

export default TeamMember;
