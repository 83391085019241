import { useStyles, GuaranteeLogic } from './GuaranteeSection.module';
import { Grid, Box } from '@material-ui/core';

export default function KeysIndicatorsList(props) {
  const classes = useStyles();
  const { translator } = GuaranteeLogic();

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <h2 className={classes.title}>
            {translator('egreenGuarantee.title')}
          </h2>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.text}>{translator('egreenGuarantee.text')}</p>
        </Grid>
      </Grid>
    </>
  );
}
