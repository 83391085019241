import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  titleProduct: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
    marginBottom:25
  },
  descriptionInvestement:{
    marginTop: 15,
    textAlign: 'justify',
    textJustify: 'inter-word',
    [theme.breakpoints.up( 'md', 'sm')]: {
      whiteSpace: 'pre-wrap',
      },
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'pre-wrap',
      },

  },
  breadcrumbContainer: {
    margin: '75px auto 0px auto',
    maxWidth: '1350px',
    display: 'flex',
  },
  customLink: {
    textDecoration: 'none',
    color: theme.palette.text.tertiary,
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  fontLink: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
  },

  title: {
    fontFamily: 'MontserratExtraBold',
    color: theme.palette.text.secondary,
    fontSize: '40px',
    fontWeight: '800',
    textAlign: 'center',
    marginTop: '48px',
    marginBottom: '0px',
    margin: 'auto',
  },
  projectItemContainer: {
    margin: 'auto',
  },
  caution: {
    fontSize: '12px',
    fontWeight: '700',
    [theme.breakpoints.up('lg')]: {
      fontSize: '12px',
      fontWeight: '700',
      paddingLeft: theme.spacing(18),
    },
  },
  alertMessageContainer: {
    marginBottom: 30,
    [theme.breakpoints.up('lg')]: {
      marginBottom: 40,
      marginTop: 10,
      marginRight: theme.spacing(7),
    },
  },
  buttonsContainer: {
    marginBottom: 100,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 120,
      marginTop: 10,
      marginRight: theme.spacing(7),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 60,
      justifyContent: 'center',
    },
  },
  projectItemInvestement:{
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
    
  }
}));

export default useStyles;
