import React from 'react';

const PERMITTED_DOMAIN = process.env.REACT_APP_PERMITTED_DOMAIN_EGREEN;

window.addEventListener('message', function (event) {
  if (event.origin === PERMITTED_DOMAIN) {
    if (event.data) {
      const obj = event.data;
      localStorage.setItem('token', obj.token);
      localStorage.setItem('id', obj.id);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('id');
    }
  }
});

const Session = () => {
  return <div />;
};

export default Session;
