import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  // breadcrumbContainer: {
  //   margin: '75px auto 0px auto',
  //   maxWidth: '1350px',
  //   display: 'flex',
  // },
  // customLink: {
  //   textDecoration: 'none',
  //   color: theme.palette.text.tertiary,
  //   '&:hover': {
  //     color: '#000000',
  //     textDecoration: 'none',
  //   },
  // },
  // fontLink: {
  //   fontFamily: 'Montserrat',
  //   fontWeight: '500',
  //   fontStyle: 'normal',
  //   fontSize: '16px',
  //   lineHeight: '26px',
  // },
  flexContainer: {
    display: 'flex',
    justifyContent: 'start',
    margin: '3em 0px 3em 0px'
  },
  textHeader: {
    fontFamily: 'MontserratMedium',
    // fontStyle: 'normal',
    fontSize: '72px',
    lineHeight: '48px',
    letterSpacing: '1.42px',
    textAlign: 'left',
    // color: '#000F20',
    color: theme.palette.text.denary,
    '@media screen and (min-width:576px)': {
    },
    '@media screen and (max-width:992px)': {
      lineHeight: '66px'
    }

  },
  textFooter: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'center',
    textTransform: 'capitalize',
    // color: '#000F20',
    color: theme.palette.text.denary,

    margin: '3em 0px 1em 0px'
  },

}));

export default useStyles;
