import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const CardStandardLogic = (props) => {
  const { translator } = Translator();
  const [values, setValues] = useState({ ...props });

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    translator,
    values,
  };
};

export default CardStandardLogic;
