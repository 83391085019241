import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Card,
  Typography,
  useTheme,
  Grid,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  TransactionsLogic,
  useStyles,
  TransactionsService,
} from './transactions.module';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CardTransactionInfo from '../shared/cardTransactioninfo/cardTransactioninfo';
const Transactions = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { translator } = TransactionsLogic();
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [finishDate, setFinishDate] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const typeOptions = [
    { key: 'buy_bonds', label: translator('transactions.buyBonds') },
    { key: 'buy_nft', label: translator('transactions.buyNft') },
    { key: 'buy_sbt', label: translator('transactions.buySbt') },
    { key: 'load_usd', label: translator('transactions.loadUsd') },
    { key: 'buy_usd', label: translator('transactions.buyUsd') },
  ];

  const stateOptions = [
    { key: 'pending', label: translator('transactions.pending') },
    { key: 'accepted', label: translator('transactions.accepted') },
  ];

  const service = TransactionsService();

  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const fetchFilteredData = async (types, states, startDate, finishDate) => {
    const filters = {};

    if (types.length > 0) {
      filters.type = types;
    }

    if (states.length > 0) {
      filters.state = states;
    }

    if (startDate) {
      filters.start_date = formatDate(new Date(startDate));
    }

    if (finishDate) {
      filters.finish_date = formatDate(new Date(finishDate));
    }

    const filterString = JSON.stringify(filters);

    const response = await service.getData(`?filter=${filterString}`);
    setTransactions(response.resources || []);
  };
  const handleTypeChange = (event) => {
    const newTypes = event.target.value;
    setSelectedTypes(newTypes);
    fetchFilteredData(newTypes, selectedStates, startDate, finishDate);
  };

  const handleStateChange = (event) => {
    const newStates = event.target.value;
    setSelectedStates(newStates);
    fetchFilteredData(selectedTypes, newStates, startDate, finishDate);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    fetchFilteredData(selectedTypes, selectedStates, date, finishDate);
  };

  const handleFinishDateChange = (date) => {
    setFinishDate(date);
    fetchFilteredData(selectedTypes, selectedStates, startDate, date);
  };
  useEffect(() => {
    fetchFilteredData(selectedTypes, selectedStates, startDate, finishDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card
      className={
        theme.palette.mode === 'light'
          ? classes.rootTransactions
          : classes.rootLightTransactions
      }
    >
      <Accordion classes={{ root: classes.MuiAccordionrootTransactions }}>
        <AccordionSummary
          className={classes.accordionSummaryTransactions}
          expandIcon={
            <KeyboardArrowDownIcon
              className={classes.keyboardArrowDownIconTransactions}
            />
          }
        >
          <Typography
            variant="h5"
            align="left"
            className={classes.textTransactions}
          >
            {translator('transactions.title')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetailsTransactions}>
          <Grid
            container
            spacing={2}
            className={classes.gridDetailsTransactions}
          >
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="type-select-label">
                  {translator('transactions.type')}
                </InputLabel>
                <Select
                  className={classes.selectedTransactions}
                  labelId="type-select-label"
                  id="type-select"
                  multiple
                  value={selectedTypes}
                  onChange={handleTypeChange}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((key) => (
                        <Chip
                          key={key}
                          label={
                            typeOptions.find((option) => option.key === key)
                              ?.label
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {typeOptions.map((option) => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                      className={classes.menuItemTransactions}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={3} md={3} sm={12} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="state-select-label">
                  {translator('transactions.state')}
                </InputLabel>
                <Select
                  className={classes.selectedTransactions}
                  labelId="state-select-label"
                  id="state-select"
                  multiple
                  value={selectedStates}
                  onChange={handleStateChange}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((key) => (
                        <Chip
                          key={key}
                          label={
                            stateOptions.find((option) => option.key === key)
                              ?.label
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {stateOptions.map((option) => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                      className={classes.menuItemTransactions}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  className={classes.formControlDate}
                  variant="inline"
                  format="yyyy-MM-dd"
                  // margin="normal"
                  id="start-date-picker"
                  label={translator('transactions.startDate')}
                  value={startDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                  PopoverProps={{
                    classes: {
                      paper: classes.calendarWeek,
                    },
                  }}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <KeyboardDatePicker
                  className={classes.formControlDate}
                  variant="inline"
                  format="yyyy-MM-dd"
                  // margin="normal"
                  id="finish-date-picker"
                  label={translator('transactions.endDate')}
                  value={finishDate}
                  onChange={handleFinishDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change finish date',
                  }}
                  PopoverProps={{
                    classes: {
                      paper: classes.calendarWeek,
                    },
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                className={classes.offersTransactions}
              >
                {transactions.map((offer) => (
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={4}
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CardTransactionInfo key={offer.id} offerData={offer} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default Transactions;
