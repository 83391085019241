import { axios, CustomError, Session } from './Api.module';
import Storage from '../Storage/Storage';
import { object } from 'joi';

class Api {
  constructor(url, headers = {}) {
    const session = new Session({});
    this.url = url;
    this.config = {
      headers: {
        ...headers,
        Authorization: session.hasSession()
          ? `Bearer ${new Storage('token').getItem()}`
          : null,
      },
    };
  }

  async get() {
    try {
      return await axios.get(this.url, this.config);
    } catch (error) {
      return new CustomError(error);
    }
  }

  async post(object) {
    try {
      return await axios.post(this.url, object, this.config);
    } catch (error) {
      return new CustomError(error);
    }
  }

  async put(object) {
    try {
      return await axios.put(this.url, object, this.config);
    } catch (error) {
      return new CustomError(error);
    }
  }

  async delete() {
    try {
      return await axios.delete(this.url, this.config);
    } catch (error) {
      return new CustomError(error);
    }
  }

  async patch(object) {
    try {
      return await axios.patch(this.url, object, this.config);
    } catch (error) {
      return new CustomError(error);
    }
  }
}

export default Api;
