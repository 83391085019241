import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  keyIndicatorsListContainer: {
    fontSize: 24,
    textAlign: 'left',
    borderRadius: '16px',
    border: '2px solid #00DD9E',
    marginTop: 20,
    paddingTop: 32,
    paddingBottom: 15,
    fontWeight: 700,
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
  },
  sectionTitle: {
    fontFamily: 'MontserratBold',
    color: '#00DD9E',
    fontSize: '50px',
    lineHeight: '55px',
    fontWeight: 700,
    textAlign: 'left',
    padding: 0,
    margin: 0,
    [theme.breakpoints.between(710, 784)]: {
      fontSize: '45px',
      lineHeight: '50px'
    },
    [theme.breakpoints.between(635, 710)]: {
      fontSize: '40px',
      lineHeight: '50px'
    },
    [theme.breakpoints.between(600, 635)]: {
      fontSize: '38px',
      lineHeight: '50px'
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '40px',
      lineHeight: '50px'
    },
  },
  iconTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    color: theme.palette.text.tertiary,
    cursor: 'pointer',
  },
  subtitle: {
    margin: 0,
  },
  keysIndicators: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
  keyIndicators: {
    height: '100%',
  },
  infoSpan: {
    fontSize: '15px',
    margin: '40px',
  },
}));
export default useStyles;
