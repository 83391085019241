import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Fab,
  Checkbox,
  Drawer,
  List,
  // Divider,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import {
  useStyles,
  placeholder,
  CarbonCreditCardLogic,
} from './carbonCreditCard.module';
import ButtonDefault from '../shared/button/button';
import OffsetForm from './OffsetForm';
import { NftTableService, csv, ftp } from '../shared/NftTable/NftTable.module';
import NavigationIcon from '@material-ui/icons/Navigation';
import NFT from '../../classes/NFT/NFT';

const CarbonCreditCard = (props) => {
  const classes = useStyles();
  const { translator } = CarbonCreditCardLogic();
  const [loadingCsvItemId, setLoadingCsvItemId] = useState(null);
  const [loadingGhgItemId, setLoadingGhgItemId] = useState(null);
  const {
    mostrarCertificate,
    handleGenerateCertificate,
    // showUrls,
    certificateUrl,
    showOffsetForm,
    handleOffsetClick,
    drawerOpen,
    toggleDrawer,
    carbonFluxData,
    getCsv,
    getGhg,
  } = NftTableService(props);
  const handleCsvClick = async (stationData, createdAt, itemId, event) => {
    event.stopPropagation();
    setLoadingCsvItemId(itemId);
    await getCsv(stationData, createdAt);
    setLoadingCsvItemId(null);
  };

  const handleGhgClick = async (stationData, itemId, event) => {
    event.stopPropagation();
    setLoadingGhgItemId(itemId);
    await getGhg(stationData);
    setLoadingGhgItemId(null);
  };

  const drawerContent = (
    <div
      //
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className={classes.rootDrawer}
    >
      <List>
        {carbonFluxData.map((item, index) => (
          <div className={classes.listDrawerOpen}>
            <div className={classes.marginTextCarbon}>
              <Grid container spacing={1} key={item.id}>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.gridCarbonCredit}
                >
                  <Typography className={classes.headCarbonCredit}>
                    {translator('carbonCreditCard.datetime')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.textBreak}
                  >
                    {`${item.getDateFromStationData()}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.gridCarbonCredit}
                >
                  <Typography className={classes.headCarbonCredit}>
                    {translator('carbonCreditCard.contribution')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.textBreak}
                  >
                    {`${item.getGrossContribution()}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.gridCarbonCredit}
                >
                  <Typography className={classes.headCarbonCredit}>
                    {translator('carbonCreditCard.flux')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.textBreak}
                  >
                    {`${item.getFluxCO2()}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.gridCarbonCredit}
                >
                  <Typography className={classes.headCarbonCredit}>
                    {translator('carbonCreditCard.productive')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.textBreak}
                  >
                    {`${item.getProductiveAreaSizeM2()}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.gridCarbonCredit}
                >
                  <Typography className={classes.headCarbonCredit}>
                    {translator('carbonCreditCard.remainder')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.textBreak}
                  >
                    {`${item.getRemainder()}`}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  lg={2}
                  className={classes.gridCarbonCredit}
                >
                  <Typography className={classes.headCarbonCredit}>
                    {translator('carbonCreditCard.total')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    className={classes.textBreak}
                  >
                    {`${item.getTotalCO2()}`}
                  </Typography>
                </Grid>
              </Grid>
            </div>

            <div
              className={classes.marginFileGhg}
              onClick={(event) => event.stopPropagation()}
            >
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} md={6}>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    size="large"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCsvClick(
                        item.station_data,
                        item.created_at,
                        index,
                        event
                      );
                    }}
                    className={classes.marginFile}
                    disabled={loadingCsvItemId === index}
                  >
                    {loadingCsvItemId === index ? (
                      <CircularProgress
                        className={classes.circularProgressCustom}
                        size={24}
                      />
                    ) : (
                      <>
                        <img src={csv} alt="csv" className={classes.imgFile} />
                        CSV
                      </>
                    )}
                  </Fab>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    size="large"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleGhgClick(item.station_data, index, event);
                    }}
                    className={classes.marginFile}
                    disabled={loadingGhgItemId === index}
                  >
                    {loadingGhgItemId === index ? (
                      <CircularProgress
                        className={classes.circularProgressCustom}
                        size={24}
                      />
                    ) : (
                      <>
                        <img src={ftp} alt="ftp" className={classes.imgFtp} />
                        GHG
                      </>
                    )}
                  </Fab>
                </Grid>
              </Grid>
            </div>
          </div>
        ))}
      </List>
      {/* <Divider /> */}
    </div>
  );

  return (
    <div>
      <Card
        className={props.is_sbt ? classes.rootCertificate : classes.rootOffset}
      >
        {showOffsetForm ? (
          <OffsetForm id={props.id} user={props.user} />
        ) : (
          <>
            <CardActionArea>
              <div className={classes.imgCarbonCarmedia}>
                <CardMedia
                  component="img"
                  alt="Carbon Credit"
                  height="100%"
                  image={props.imageCarbonCredit || placeholder}
                  title="Carbon Credit"
                />
                <div className={classes.conteainerCheckboxCarbon}>
                  {props.certificate == null && (
                    <Checkbox
                      onChange={(event) =>
                        props.handleCheckboxChange(event, props.id)
                      }
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  )}
                </div>
              </div>
              <CardContent
                className={
                  props.is_sbt
                    ? classes.containerTextCertificate
                    : classes.containerTextOffset
                }
              >
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  onClick={toggleDrawer(true, props.id)}
                  style={{ cursor: 'pointer' }}
                >
                  {props.name_project} - {props.name}
                </Typography>
                <div>
                  {props.showUrls && (
                    <>
                      {props.ipfsHash ? (
                        <a
                          href={props.ipfs.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.urlCarbonCredit}
                        >
                          <Typography gutterBottom variant="h5" component="h5">
                            {props.ipfsHash}
                          </Typography>
                        </a>
                      ) : null}
                      {props.blockchainHash ? (
                        <a
                          href={props.blockchain.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.urlCarbonCredit}
                        >
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h5"
                            style={{ marginBottom: 18 }}
                          >
                            {props.blockchainHash}
                          </Typography>
                        </a>
                      ) : null}
                    </>
                  )}
                  {props.is_sbt ? (
                    certificateUrl || NFT.hasCertificate(props) ? (
                      <Fab
                        variant="extended"
                        color="primary"
                        aria-label="add"
                        size="large"
                        className={classes.margin}
                        onClick={() =>
                          mostrarCertificate(
                            certificateUrl || props.certificate
                          )
                        }
                      >
                        <NavigationIcon className={classes.extendedIcon} />
                        VIEW CERTIFICATE
                      </Fab>
                    ) : (
                      <Fab
                        variant="extended"
                        color="primary"
                        aria-label="add"
                        size="large"
                        className={classes.margin}
                        onClick={handleGenerateCertificate}
                      >
                        <NavigationIcon className={classes.extendedIcon} />
                        GENERATE CERTIFICATE
                      </Fab>
                    )
                  ) : (
                    <ButtonDefault
                      type="backgroundSoftGreen"
                      text="OFFSET"
                      changeView={handleOffsetClick}
                    ></ButtonDefault>
                  )}
                </div>
              </CardContent>
            </CardActionArea>
          </>
        )}
      </Card>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        // classes={{ paper: classes.drawerTransparentBackground }}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
};

export default CarbonCreditCard;
