import React from 'react';
import { useStyles, LoadingScreenLogic } from './loading.module';

const LoadingScreen = () => {
  const classes = useStyles();
  const { translator } = LoadingScreenLogic();

  return (
    <React.Fragment>
      <section>
        <h3 className={classes.title}>{translator('loading.title')}</h3>
        <div className={classes.spinner}></div>
        <p>{translator('loading.spinner')}</p>
      </section>
    </React.Fragment>
  )

};

export default LoadingScreen;