import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbContainer: {
        margin: '75px auto -35px auto',
        maxWidth: '1350px',
        display: 'flex',
    },
    customLink: {
        textDecoration: 'none',
        color: '#333333',
        '&:hover': {
            color: '#000000',
            textDecoration: 'none',
        },
    },
    fontLink: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '26px',
    },
    heading: {
        fontFamily: 'MontserratBold',
        fontWeight: '800',
        color: '#04679C',
        fontSize: '32px',
        marginTop: 50,
        [theme.breakpoints.between('sm', 'lg')]: {
            fontSize: '56px',
            lineHeight: '64px',

        },
    },
    body: {
        fontFamily: 'Montserrat',
        fontSize: 18,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '32px',
        color: '#333333',
        textAlign: 'left',
    },
    containerBody: {
        margin: 'auto'
    },
    button: {
        marginBottom: 15
    },
    image: {
        margin: `${theme.spacing(1)}px auto`,
        overflow: 'hidden',
        position: 'relative',
        objectFit: 'contain',
        objectPosition: 'center',
        height: 343,

        [theme.breakpoints.between('sm', 'lg')]: {
            height: 343,
            width: 343,
        },

    },
    link: {
        textDecoration: 'none'
    },

}));
export default useStyles;



