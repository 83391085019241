import React from 'react';
import Footer from '../footer/Footer';
import AlertMessage from '../shared/alert/alert';
import {
  useStyles,
  DemoFormImage,
  DemoFormLogic,
  validator,
} from './DemoForm.module';
import { Grid, OutlinedInput } from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import DemoFormService from './DemoForm.service';
import CustomError from '../../classes/CustomError/CustomError';


export default function DemoForm(props) {
  const classes = useStyles();
  const {
    checked,
    errors,
    handleChange,
    handleCheckbox,
    handleSubmit,
    isValid,
    setErrors,
    setValues,
    translator,
    values,
  } = DemoFormLogic(validator, () => sendData(), props);

  async function sendData() {
    const response = await DemoFormService().send(values);

    if (response instanceof CustomError) {
      setErrors({ ...errors, api_error: response.message });
    } else {
      setValues({
        fullName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        websiteURL: '',
        numberEmployees: 1,
        blogSubscribe: false,
        showSuccess: true,
      });

    }
  }

  return (
    <>
      <div className={classes.root} xs={12}>
        <Grid className={classes.containerDemoSection}>
          <Grid className={classes.formContainer}>
            <h2 className={classes.title}>{translator('demoForm.title')}</h2>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <label className={classes.labels}>
                {translator('demoForm.fullName')}*
              </label>
              <OutlinedInput
                className={classes.textField}
                type="text"
                name={'fullName'}
                value={values.fullName}
                onChange={handleChange}
              />
              {errors && errors.error_fullName && (
                <AlertMessage
                  className={classes.alertMsg}
                  type="error"
                  text={errors.error_fullName}
                />
              )}
              <label className={classes.labels}>
                {translator('signUp.lastname')}*
              </label>
              <OutlinedInput
                className={classes.textField}
                name={'lastName'}
                value={values.lastName}
                onChange={handleChange}
              />
              {errors && errors.error_lastname && (
                <AlertMessage type="error" text={errors.error_lastname} />
              )}
              <div className={classes.shortFieldContainer}>
                <div className={classes.shortField}>
                  <label className={classes.labels}>
                    {translator('signUp.mail')}*
                  </label>
                  <OutlinedInput
                    className={classes.textField}
                    type="email"
                    name={'email'}
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors && errors.error_email && (
                    <AlertMessage
                      className={classes.alertMsg}
                      type="error"
                      text={errors.error_email}
                    />
                  )}
                </div>
                <div>
                  <label className={classes.labels}>
                    {translator('demoForm.phoneNumber')}*
                  </label>
                  <OutlinedInput
                    className={classes.textField}
                    type="number"
                    name={'phoneNumber'}
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                  {errors && errors.error_phoneNumber && (
                    <AlertMessage
                      className={classes.alertMsg}
                      type="error"
                      text={errors.error_phoneNumber}
                    />
                  )}
                </div>
              </div>
              <div className={classes.shortFieldContainer}>
                <div className={classes.shortField}>
                  <label className={classes.labels}>
                    {translator('demoForm.companyName')}*
                  </label>
                  <OutlinedInput
                    className={classes.textField}
                    name={'companyName'}
                    value={values.companyName}
                    onChange={handleChange}
                  />
                  {errors && errors.error_companyName && (
                    <AlertMessage
                      type="error"
                      text={errors.error_companyName}
                    />
                  )}
                </div>
                <div>
                  <label className={classes.labels}>
                    {translator('demoForm.webSiteURL')}*
                  </label>
                  <OutlinedInput
                    className={classes.textField}
                    name={'websiteURL'}
                    value={values.websiteURL}
                    onChange={handleChange}
                  />
                  {errors && errors.error_webSiteURL && (
                    <AlertMessage type="error" text={errors.error_webSiteURL} />
                  )}
                </div>
              </div>
              <label className={classes.labels}>
                {translator('demoForm.howManyEmployees')}*
              </label>
              <OutlinedInput
                className={classes.textField}
                name={'numberEmployees'}
                value={values.numberEmployees}
                onChange={handleChange}
              />
              {errors && errors.error_employees && (
                <AlertMessage type="error" text={errors.error_employees} />
              )}
              <div className={classes.formCheck}>
                <input type="checkbox" onChange={handleCheckbox} />
                <p className={classes.formChecktext}>
                  {translator('demoForm.suscribeCheck')}
                </p>
              </div>
              <div className={classes.buttonContainer}>
                <ButtonDefault
                type='buttonGreenLarge'
                text={translator('demoForm.FormButton')}
                onClick={handleSubmit}
                 />
{/*                 <button
                  className={classes.demoFormButtonDefault}
                  type="blue"
                  text={translator('demoForm.FormButton')}
                  onClick={handleSubmit}
                  //disabled={isValid}
                >
                  {translator('demoForm.FormButton')}
                </button> */}
              </div>
            </form>
            {values.showSuccess && (
              <AlertMessage
                text={'Datos enviados exitosamente.'}
                type={'success'}
              />
            )}
            <p className={classes.privacyInfo}>
              {translator('demoForm.PrivacyAlert')}
            </p>
          </Grid>
          <Grid className={classes.imageContainer}>
            <p className={classes.imgText}>{translator('demoForm.imgText')}</p>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
}
