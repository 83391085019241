class URL {
  static get URL_SERVER() {
    return process.env.REACT_APP_URL_SERVER;
  }

  static get URL_BLOG() {
    return 'https://www.googleapis.com/blogger/v3';
  }
}

export default URL;
