import { useEffect, useState, useContext } from 'react';
import CustomError from '../../../classes/CustomError/CustomError';
import Translator from '../../../classes/Translator/Translator';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import GeneralContext from '../../../context/GeneralContext';
import Transaction from '../../../classes/Transaction/Transaction';

const PaymentMethodLogic = (props, PaymentMethodService) => {
  const { translator } = Translator();
  const { setGeneralValues } = useContext(GeneralContext);
  const [values] = useState({
    ...props,
    title: Transaction.DICTIONARY_CONFIRMATION[props.type || 'buy_bonds'].title,
    message:
      Transaction.DICTIONARY_CONFIRMATION[props.type || 'buy_bonds'].message,
  });
  const [checked, setChecked] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [errors, setErrors] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  let history = useHistory();

  const [paymentMethod, setPaymentMethod] = useState([
    { name: 'credit_card', checked: false },
    { name: 'wallet', checked: false },
  ]);
  const handleChangeInPaymentMethod = (event) => {
    const { value } = event.target;
    const newPaymentMethod = paymentMethod.map((element) => {
      element.checked = element.name === value;
      return element;
    });
    setPaymentMethod(newPaymentMethod);
  };

  const handleChangeInTermsAndConditions = (event) => {
    const { checked } = event.target;
    setChecked(checked);
  };

  useEffect(() => {
    const isPaymentMethodSelected = paymentMethod.some(
      (value) => value.checked
    );
    setIsReady(isPaymentMethodSelected && checked);
  }, [paymentMethod, checked]);

  const _createSessionInStripe = async (id_transaction) => {
    const stripe_response = await PaymentMethodService().createSessionInStripe({
      id_pretransaction: id_transaction,
      new_version: true,
    });
    if (stripe_response instanceof CustomError) {
      setErrors({
        api_error: 'Hemos tenido un problema al comunicarnos con el servidor.',
      });
    } else {
      return stripe_response;
    }
  };

  const payment_method = async () => {
    if (paymentMethod[1].name === 'wallet' && paymentMethod[1].checked) {
      /* Crear o utilizar función para actualizar la pretransaction by ID: payment_method: "wallet" */
      await PaymentMethodService().updateTransaction(values.id, {
        payment_method: 'wallet',
      });
      setGeneralValues((old_values) => ({
        ...old_values,
        pathAfterLogin: `/information/${values.id}`,
      }));
      return history.push(`/information/${values.id}`);
    } else {
      /* Con tarjeta de crédito iremos al checkout de Stripe */
      await checkout();
    }
  };

  const checkout = async () => {
    const stripe_response = await _createSessionInStripe(values.id);
    if (stripe_response instanceof CustomError) {
      setErrors({
        api_error: 'Hemos tenido un problema al comunicarnos con el servidor.',
      });
    } else {
      const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY_STRIPE);
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({
        sessionId: stripe_response.id,
      });
      return stripe_response;
    }
  };

  return {
    checked,
    errors,
    isReady,
    openDialog,
    setOpenDialog,
    values,
    payment_method,
    handleChangeInPaymentMethod,
    handleChangeInTermsAndConditions,
    translator,
  };
};

export default PaymentMethodLogic;
