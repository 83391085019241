import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import CustomError from '../../classes/CustomError/CustomError';

const GuardService = () => {
  const check = async () => {
    const api = new API(`${URL.URL_SERVER}/auth`);
    const http_response = await api.post({});
    return http_response instanceof CustomError;
  };

  return {
    check,
  };
};

export default GuardService;
