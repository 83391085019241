import { auth } from './privateRoute.service';
import Storage from '../../classes/Storage/Storage';
import CustomError from '../../classes/CustomError/CustomError';
import { useEffect, useState } from 'react';
import Session from '../../classes/Session/Session';
import Translator from '../../classes/Translator/Translator';

const PrivateRouteLogic = () => {
  const [state, setState] = useState('loading');
  const { translator } = Translator();

  useEffect(() => {
    (async function () {
      try {
        const isTokenStored = new Storage('token').getItem();
        const authInAPI = await auth();
        const isUserAuth = isTokenStored && !(authInAPI instanceof CustomError);
        if (!isUserAuth) {
          await new Session({}).logout();
        }
        setState(isUserAuth ? 'logged' : 'redirect');
      } catch (error) {
        await new Session({}).logout();
        setState('redirect');
      }
    })();
  }, []);

  return {
    state,
    translator,
  };
};

export default PrivateRouteLogic;
