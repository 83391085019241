import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
 
  mainText: {
    fontWeight: 500,
    fontSize: '1.2em',
    paddingRight: theme.spacing(2), 
    color: '#020C72',
    fontFamily: 'Montserrat',
  },
  chartContainer: {
    fontFamily: 'Montserrat !important',
    backgroundColor: '#ffff', 
    padding: theme.spacing(1), 
    marginBottom: theme.spacing(1), 
    textAlign: 'center',
    fontSize: '0.6em',
    color: '#333',
  },
}));

export default useStyles;
