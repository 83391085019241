import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  image: {
    margin: '98px auto 0px auto',
    overflow: 'hidden',
    position: 'relative',
    objectFit: 'contain',
    height: 170,
    width: 189,
    objectPosition: 'center',
  },
  title: {
    fontFamily: 'MontserratBold',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '32px',
    lineHeight: '40px',
    color: theme.palette.text.secondary,

    marginBottom: '0px',
    '@media only screen and (max-width: 320px)': {
      fontSize: '2em',
    },
  },
  message: {
    fontFamily: 'MontserratSemiBold',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '30px',
    color: theme.palette.text.quaternary,
    marginBottom: '20px',
  },
  errorContainer: {
    marginTop: '20px',
    marginBottom: '100px',
  },
  error: {
    color: '#ff0000',
    fontFamily: 'MontserratSemiBold',
  },
  circularProgress: {
    height: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderText: {
    fontFamily: 'MontserratSemiBold',
    fontWeight: '600',
    fontStyle: 'normal',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#04679C',
    marginTop: '20px',
  },
  text: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
    color: '#333333',
    marginTop: '20px',
    marginBottom: 4,
  },

  projectImage: {
    width: '100%',
    height: '100%',
    borderRadius: '16px',
  },
  projectName: {
    fontFamily: 'MontserratBold',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    // color: '#FFFFFF',
    color: theme.palette.secondary.main,

    marginBottom: theme.spacing(1),
  },

  projectDescription: {
    fontFamily: 'MontserratRegular',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
    // color: '#FFFFFF',
    color: theme.palette.secondary.main,
    letterSpacing: '-1px',

  },

  descriptionContainer: {
    marginLeft: '30px',
    marginTop: '15px',
  },
  pricesContainer: {
    marginLeft: '65px',
    marginTop: '15px',
    textAlign: 'right',
  },
  greenText: {
    fontFamily: 'MontserratBold',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    // color: '#00923D',
    color: theme.palette.text.quinary,

  },

  container: {
    // background: '#04679C',
    background: theme.palette.background.container,
    alignItems: 'center',
    borderRadius: 16,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 16,
      background: theme.palette.background.container,
      alignItems: 'center',
      width: 650,
      margin: 'auto',
    },
  },
  biomassEnergyContainer: {
    // background: '#04679C',
    background: theme.palette.background.container,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderRadius: 16,
  },
  greenTextContainer: {
    // background: '#FFFFFF',
    background: theme.palette.background.default,

    borderRadius: 16,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },

  containerText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: 3,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 3,
    },
  },
  textEgreen: {
    fontFamily: 'MontserratRegular',
    fontWeight: '400',
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    // color: '#333333', 
    color: theme.palette.text.tertiary,

    '@media only screen and (max-width: 320px)': {
      fontSize: '12px',
    },
  },

  containerGreenText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: 16,
    },
  },
  containerButtons: {
    marginTop: 30,
    marginBottom: 95,
  },
  link: {
    textDecoration: 'none',
  },
  footer: {
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
  },
  errorFooter: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
  },
}));
export default useStyles;
