import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
  buttonNft:{
    width:'100%',
    height:'100%',
    display:'flex',
    justifyContent:'flex-end',
    alignItems:'flex-end'
  },
  margin: {
    margin: theme.spacing(1),
    marginRight:21,
    marginBottom:21
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  containerNftTable:{
    height: '100vh'
  },
  containerItemNftTable:{
    flexGrow: 1 
   },
    containerFabNftTable:{
      alignItems: 'flex-end' 
    },
    rootOffset: {
      borderRadius: '20px',
      border: '4px solid #82c56b',
      borderTopColor: '#82c56b',
      borderBottomColor: '#80ca5a',
  },
  paper:{
    marginTop: '-8px', 
    borderRadius: '20px', 
    '@media screen and (min-width:576px)': {
      marginLeft: '-100px',
      width: '600px',

    },
    '@media screen and (max-width:992px)': {
      marginLeft: '0px',
      width: '100%',

    },
  }

}));
export default useStyles;
