import Translator from '../../classes/Translator/Translator';
const BottombannerLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};

export default BottombannerLogic;
