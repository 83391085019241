import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import User from '../../classes/User/User';
import CustomError from '../../classes/CustomError/CustomError';

const SignUpService = () => {
  const signUp = async (user) => {
    const api = new API(`${URL.URL_SERVER}/users`);
    const http_response = await api.post(user);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, User);
      const data = response.wasSuccessfull().created();
      return data;
    }
  };

  return {
    signUp,
  };
};

export default SignUpService;
