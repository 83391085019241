import Translator from '../../classes/Translator/Translator';

const NewOfferLogic = () => {
    const { translator } = Translator();

    return {
        translator
      };
}

export default NewOfferLogic;
