import React from 'react';
import { useHistory } from 'react-router-dom';

import { Grid, OutlinedInput } from '@material-ui/core';

import ButtonDefault from '../shared/button/button';
import WelcomeBanner from '../shared/welcomeBanner/welcomeBanner';
import AlertMessage from '../shared/alert/alert';
import Footer from '../footer/Footer';

import {
  useStyles,
  ConfirmCodeLogic,
  ConfirmCodeService,
  validator,
  emailValidator,
} from './confirmCode.module';
import CustomError from '../../classes/CustomError/CustomError';

export default function ConfirmCode(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    formRef,
    values,
    errors,
    data,
    setValues,
    setErrors,
    setData,
    handleChange,
    handleSubmit,
    translator,
  } = ConfirmCodeLogic(
    () => verifyCode(),
    validator,
    () => resendCode(),
    emailValidator
  );

  async function verifyCode() {
    const response = await ConfirmCodeService().verifyCode({
      email: values.email,
      code: values.code,
    });

    if (response instanceof CustomError) {
      setData({ ...data, resendCode: false });
      setErrors({ ...errors, api_error: response.message });
    } else {
      setValues({ ...values, verifyCode: true });
      history.push('/login');
    }
  }

  async function resendCode() {
    const response = await ConfirmCodeService().resendCode(data.email);

    if (response instanceof CustomError) {
      setData({ ...data, resendCode: false });
      setErrors({ ...errors, api_error: response.message });
    } else {
      setErrors({ ...errors, api_error: false });
      setData({ ...data, resendCode: true });
    }
  }

  return (
    <div>
      <div className={classes.root} xs={12}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid className={classes.root}>
            <Grid item xs={6} className={classes.welcomeBanner}>
              <WelcomeBanner text={translator('confirmCodeWelcome')} />
            </Grid>
            <Grid className={classes.verifyCodeSection} item xs={6} sm={9}>
              <h2 className={classes.title}>
                {translator('confirmCode.verifyCodeTitle')}
              </h2>
              <form
                className={classes.form}
                noValidate
                autoComplete={'off'}
                onSubmit={handleSubmit}
                ref={formRef}
              >
                <label className={classes.labels}>
                  {translator('confirmCode.email')}
                </label>
                <OutlinedInput
                  className={classes.textField}
                  type="email"
                  value={values.email}
                  name={'email'}
                  onChange={handleChange}
                />
                {errors && errors.error_email && (
                  <AlertMessage type="error" text={errors.error_email} />
                )}
                <label className={classes.labels}>
                  {translator('confirmCode.verificationCode')}
                </label>
                <OutlinedInput
                  className={classes.textField}
                  type="code"
                  value={values.code}
                  name={'code'}
                  onChange={handleChange}
                />
                {errors && errors.error_code && (
                  <AlertMessage type="error" text={errors.error_code} />
                )}
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item lg={6} md={6} sm={9} xs={12}>
                    <ButtonDefault
                      name="sendCode"
                      type="blue"
                      text={translator('confirmCodeVerify')}
                      isSubmit={true}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={9} xs={12}>
                    <ButtonDefault
                      name="resendCode"
                      type="blue"
                      text={translator('confirmCodeResendCode')}
                      isSubmit={true}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {data.resendCode && (
                      <AlertMessage type="success" text={'Código reenviado'} />
                    )}
                    {errors && errors.api_error && (
                      <AlertMessage type="error" text={errors.api_error} />
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
