import API from '../../classes/Api/Api';
import CustomError from '../../classes/CustomError/CustomError';
import Response from '../../classes/Response/Response';
import Transaction from '../../classes/Transaction/Transaction';
import URL from '../../classes/Url/URL';

const InvestmentProcedureService = () => {
  const createTransaction = async (transaction) => {
    const api = new API(`${URL.URL_SERVER}/transactions`);
    const http_response = await api.post(transaction);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Transaction);
      return response.wasSuccessfull().single();
    }
  };

  return {
    createTransaction,
  };
};

export default InvestmentProcedureService;
