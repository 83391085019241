import { useState, useEffect } from 'react';

const CounterLogic = (props) => {
  const { type, isBackgroundWhite } = props;
  const [values, setValues] = useState({
    ...props,
    rootClass: 'root',
    isBackgroundWhite: isBackgroundWhite ? 'backgroundWhiteRoot' : null,
  });

  const setClass = () => {
    if (type === 'normal') {
      setValues({
        ...values,
        rootClass: 'root',
        buttonClass: 'button',
        counterClass: 'counter',
      });
    } else if (type === 'small') {
      setValues({
        ...values,
        rootClass: 'smallRoot',
        buttonClass: 'smallButton',
        counterClass: 'smallCounter',
      });
    }
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    values,
  };
};

export default CounterLogic;
