import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';
import User from '../../classes/User/User';
import Transaction from '../../classes/Transaction/Transaction';
import Offer from '../../classes/Offer/Offer';
import NFT from '../../classes/NFT/NFT';

const DashboardService = () => {
  const getUser = async (id) => {
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, User);
      return response.wasSuccessfull().single();
    }
  };

  const getHistoryOfOperations = async (id) => {
    const api = new API(`${URL.URL_SERVER}/users/${id}/transactions`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Transaction);
      return response.wasSuccessfull().multiple();
    }
  };

  const getHistoryOfOffers = async (id) => {
    const api = new API(
      `${URL.URL_SERVER}/users/${id}/offers?filter={"status":["done"]}`
    );
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Offer);
      return response.wasSuccessfull().multiple();
    }
  };

  const getHistoryOfNFTs = async (id, is_sbt = false) => {
    const api = new API(`${URL.URL_SERVER}/users/${id}/nfts?is_sbt=${is_sbt}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, NFT);
      return response.wasSuccessfull().multiple();
    }
  };

  return {
    getUser,
    getHistoryOfOperations,
    getHistoryOfOffers,
    getHistoryOfNFTs,
  };
};
export default DashboardService;
