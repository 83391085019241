import { useEffect, useState } from 'react';
import Translator from "../../classes/Translator/Translator";
import { economy } from "./economy.module";

const EconomyLogic = () => {
  const { translator, isEnglish } = Translator();
  const [values, setValues] = useState({
    economyImg: isEnglish()
    ? economy
    : economy, 
  });
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    setValues({
      ...values,
      economyImg: isEnglish()
        ? economy
        : economy,
    });
  }, [isEnglish()]);

  return {
    values,
    translator,
  };
};

export default EconomyLogic;