import { makeStyles } from '@material-ui/core/styles';
import rectangle_9 from '../../assets/images/rectangle_9.webp';

const useStyles = makeStyles((theme) => ({
  MoneyBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-evenly',
    width: '100%',
    borderRadius: 20,
    border: '2px solid #00DD9E',
    alignItems:'center',
    marginBottom: 10,
    padding: 12,
    [theme.breakpoints.down('xs')]: {
      padding: 0,

    },
  },
  customButton: {
    flex: 1,
    margin: '0 0px 20px',
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '18px',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '#041370',
    },
    '&:disabled': {
      backgroundColor: '#676767d8',
      color: '#ffffff',
    },
  },

  cardContainer: {
    height: '100%',
    margin: '0px 100px 30px 100px',
    padding: '10px 0px 4px 0px !important',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px 5px!important',
      margin:0,
    },
  },

  creditImg: {
    width: '80%',
    margin: 40,
  },

  contentDashboard: {
    maxWidth: '100%',
    marginBottom: 70,


  },
  dashboardStyle: {
    marginTop: 80,
  },

  subtitleDashboard: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 30,
    marginBottom: 40,
  },
  textSubtitleDashboard: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
    '@media screen and (max-width:280px)': {
      fontSize: '40px',
    },
  },
  textSecundaryDashboard: {
    fontFamily: 'MontserratBold',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#00DD9E',
  },
  containerCabonCredits: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    marginBottom: 50,
    color: '#fff',
    minHeight: 280,
    maxWidth: '100%',
    backgroundImage: `url(${rectangle_9})`,
    [theme.breakpoints.down('md')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '100%',
    },
    '@media screen and (max-width:280px)': {
      maxWidth: '100%',
    },
  },
  rootCabonCredits: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
  subcontainerCabonCredits: {
    marginRight: 50,
  },
  titleCabonCredits: {
    fontFamily: 'MontserratBold',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingLeft: 20,
  },
  titleCabonCreditsColor: {
    color: '#2CDD00',
  },
  subtitleCabonCredits: {
    fontFamily: 'MontserratBold',
    fontSize: '20px',
    fontWeight: 500,
    // lineHeight: '63px',
    letterSpacing: '0em',
    textAlign: 'left',
    paddingLeft: 20,
  },
  imageCabonCredits: {
    width: 20,
    height: 20,
    margin: -2,
    marginRight: 10,
  },
  carbonCreditsTitle: {
    fontFamily: 'MontserratBold',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '50px',
    letterSpacing: '0em',
    textAlign: 'left',
    // color: '#FFFFFF',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 50,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  stepDashboard: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  timeline: {
    background: 'none',
    '& .MuiSvgIcon-root': {
      color: '#00DD9E',
      fontFamily: 'Montserrat !important',
    },
  },
  coinSoon: {
    marginTop: '40px',
    marginBottom: '40px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    '@media (max-width: 1024px) and (min-width: 960px) and (orientation: landscape)':
      {
        width: '40%',
      },
    '@media (max-width: 1280px) and (min-width: 1200px) and (orientation: landscape)':
      {
        width: '40%',
      },
    '@media (min-width: 1281px)': {
      width: '40%',
    },
  },
  inputField: {
    width:'95%',
    background: '#FFF',
    margin: '24px 0px',
    height: 42,
    borderRadius: 6,
    color: '#333',
    fontFamily: 'Montserrat !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.borderSelect.borderFocus,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '& .MuiInputBase-input': {
      height: 4,
    },
  
  },
  feeMath:{
    color: '#00DD9E',
    fontFamily: 'Montserrat !important',

  }
}));
export default useStyles;
