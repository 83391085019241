import { Button, Grid } from '@material-ui/core';

const { useStyles, ContractLogic } = require('./contract.module');

const Contract = () => {
  const classes = useStyles();
  const { values, handleButtonCopyClicked, translator } = ContractLogic();

  return (
    <Grid container className={classes.root}>
      <Grid item lg={4} md={12} sm={12} xs={12} className={classes.container}>
        <label className={classes.mainLabel}>
          {translator('contract_title')}
        </label>
      </Grid>
      <Grid
        item
        lg={5}
        md={12}
        sm={12}
        xs={12}
        className={classes.numberLabelContainer}
      >
        <label className={classes.numberLabel}>{values.token}</label>
      </Grid>
      <Grid
        item
        lg={3}
        md={12}
        sm={12}
        xs={12}
        className={classes.buttonContainer}
      >
        <Button
          className={classes.button}
          variant="contained"
          onClick={handleButtonCopyClicked}
        >
          {translator(`${values.buttonLabel}`)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Contract;
