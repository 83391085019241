import { useState, useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';
import { OpenMarketService } from './OpenMarket.module';
import { WalletService } from '../exchange/exchange.module';
import Storage from '../../classes/Storage/Storage';


const OpenMarketLogic = (userId, activeFilter) => {
  const { translator } = Translator();
  const [current, setCurrent] = useState(-1);
  const [children, setChildren] = useState([]);
  const [values, setValues] = useState({ value: '0.00', available: '0.00', selectOrder: false, type: 'BUY' }); 
  const [offers, setOffers] = useState([]);    

  useEffect(() => {
    const fetchOffers = async () => {
      const userId = new Storage('id').getItem();
      const data = await OpenMarketService().getOffers(userId, activeFilter);
      if (Array.isArray(data.resources)) {
        setOffers(data.resources);
      } else {
        console.error('Error fetching offers:', data);
      }

      
      WalletService().getData(userId).then((data) => {
        setValues({ ...values, ...data });
      });
    };
    fetchOffers();
  }, [activeFilter]);

const openCard = (offer) => {
  const totalPrice = Number(offer.amount * offer.price);
  const availableUSD = Number(values.availableUSD);
  values.selectOrder= true;
  values.type = offer.type;
  values.hasFunds = availableUSD >= totalPrice;
  //deshabilitar botón si los USD son mayores a los habilitados
  values.buyable = availableUSD <= totalPrice ? 1 : 2;
  setValues({ ...values, ...offer });
}

  const changeState = (panel) => (e, newValue) => {
    setCurrent(newValue ? panel : -1);
  };
  return {
    current,
    changeState,
    translator,
    children,
    values,
    openCard,
    offers,
  };
};

export default OpenMarketLogic;