import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import User from '../../classes/User/User';
import CustomError from '../../classes/CustomError/CustomError';
import Storage from '../../classes/Storage/Storage';

const ProfileService = () => {
  const changePassword = async (userId, passwords) => {
    const api = new API(`${URL.URL_SERVER}/passwords/${userId}`);
    try {
      const http_response = await api.put(passwords);
      const has_error = http_response instanceof CustomError;
      if (has_error) {
        return http_response;
      }
      const response = new Response(http_response, User);
      return response.wasSuccessfull();
    } catch (error) {
      return new CustomError('Error changing password', error);
    }
  };

  const updateData = async (data) => {
    const id = new Storage('id').getItem();
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    try {
      const response = await api.put(data);
      console.log('API Response:', response);

      if (response.status === 204) {
        console.log('Update successful: No content returned.');
        return true;
      } else if (response.wasSuccessful) {
        console.log('Update successful:', response.data);
        return true;
      } else {
        throw new Error('Error in updatedata (profile service)');
      }
    } catch (error) {
      console.error('Error updating data:', error);
      throw error;
    }
  };

  return {
    changePassword,
    updateData,
  };
};

export default ProfileService;
