import { DocumentationLogic, useStyles } from './documentation.module';
import { Grid, Typography, Box } from '@material-ui/core';
import FullScreenDialog from '../Dialog/FullScreenDialog';
import LockIcon from '@material-ui/icons/Lock';
import AlertMessage from '../shared/alert/alert';

export default function Documentation(props) {
  const classes = useStyles();
  const { values } = props;
  const {
    translator,
    checkOpenNewTab,
    openDialog,
    setOpenDialog,
    children,
    requestPermission,
    permissions,
  } = DocumentationLogic({ project: values.project });

  return (
    <>
      {openDialog && (
        <FullScreenDialog
          openFromParent={openDialog}
          setOpenFromParent={setOpenDialog}
          children={children}
          title={''}
          message={''}
        />
      )}

      <Grid container justifyContent="center" className={classes.container}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3" className={classes.documentationTitle}>
            {translator('projectDetail.documentation')}
          </Typography>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>

        <Grid item lg={10} md={10} sm={10} xs={10}>
          <Box mt={5}>
            {values.project.ipfs?.length === 0 && (
              <p className={classes.documentationSectiontext}>
                {
                  'Project, baseline and dMRV information will be available soon'
                }
              </p>
            )}
            {values.project.ipfs?.map((item, index) => (
              <Box
                className={classes.documentationSectionContainer}
                key={index}
              >
                <Box
                  className={classes.textArrowContainer}
                  onClick={() => checkOpenNewTab(item)}
                  style={{ cursor: 'pointer' }}
                >
                  <span className={classes.documentationSectionTitle}>
                    {item.name}
                  </span>
                  {permissions &&
                    (permissions[item.type] ? (
                      <span className={classes.arrow}>&#8594;</span>
                    ) : (
                      <span className={classes.arrow}>
                        {' '}
                        <LockIcon />
                      </span>
                    ))}
                  {requestPermission[item.type] ? (
                    <AlertMessage
                      text={'Permission required'}
                      type={'success'}
                    />
                  ) : (
                    ''
                  )}
                </Box>
                <p className={classes.documentationSectiontext}>
                  {item.description}
                </p>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      </Grid>
    </>
  );
}
