import { useState } from 'react';
import Session from '../../classes/Session/Session';
import cookie from 'js-cookie';
import i18next from 'i18next';
import Translator from '../../classes/Translator/Translator';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { auth } from '../privateRoute/privateRoute.service';
import CustomError from '../../classes/CustomError/CustomError';
import { useHistory } from 'react-router-dom';

const BarLogic = () => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    menuOpened: false,
    userOpened: false,
    darkModeChecked: false,
    language: cookie.get('i18next') || 'en',
  });
  const [value, setValue] = useState(0);
  const [account, setAccount] = useState(translator('bar.start_session'));
  const [signup, setSignup] = useState(translator('bar.signup'));
  const [path, setPath] = useState('/login');
  const [pathSignup, setPathSignup] = useState('/signup');
  const hasSession = new Session({}).hasSession();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPersonIcon, setAnchorPersonIcon] = useState(null);
  const [anchorLanguageIcon, setAnchorLanguageIcon] = useState(null);
  const history = useHistory();

  const handleClickPersonIcon = (event) => {
    setAnchorPersonIcon(event.currentTarget);
    event.preventDefault();
    if (hasSession) {
      setAccount(translator('bar.account'));
      setPath('/dashboard');
    } else {
      setAccount(translator('bar.start_session'));
      setPath('/login');
      setSignup(translator('bar.signup'));
      setPathSignup('/signup');
    }
    setValues({
      ...values,
      userOpened: !values.userOpened,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenLanguageIcon = (event) => {
    setAnchorLanguageIcon(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClosePersonIcon = () => {
    setAnchorPersonIcon(null);
  };

  const handleCloseLanguageIcon = () => {
    setAnchorLanguageIcon(null);
  };

  const handleClickTab = (e, newValue) => {
    setValue(newValue);
  };

  const onClick = async () => {
    await new Session({}).logout();
  };

  const changeLanguage = (language) => (e) => {
    e.preventDefault();
    i18next.changeLanguage(language);
    setValues({ ...values, language });
    setAnchorLanguageIcon(null);
  };

  const goToExchange = async (e) => {
    e.preventDefault();
    const isAuth = await auth();
    if (isAuth instanceof CustomError) {
      history.push('/login');
    } else {
      window.open(
        `${
          process.env.REACT_APP_PERMITTED_DOMAIN_EGREEN
        }/session?id_session=${localStorage.getItem('id_session')}`,
        '_self'
      );
    }
  };

  return {
    values,
    value,
    account,
    signup,
    path,
    pathSignup,
    hasSession,
    handleClickTab,
    changeLanguage,
    onClick,
    isMobile,
    anchorEl,
    anchorPersonIcon,
    anchorLanguageIcon,
    handleClick,
    handleClose,
    handleClickPersonIcon,
    handleClosePersonIcon,
    goToExchange,
    handleOpenLanguageIcon,
    handleCloseLanguageIcon,
    translator,
  };
};

export default BarLogic;
