import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import CustomError from '../../classes/CustomError/CustomError';
import Response from '../../classes/Response/Response';
import Transaction from '../../classes/Transaction/Transaction';
import User from '../../classes/User/User';

const BillingDetailService = () => {
  const getTransaction = async (id) => {
    const api = new API(`${URL.URL_SERVER}/transactions/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Transaction);
      return response.wasSuccessfull().single();
    }
  };
  const getUser = async (id) => {
    const api = new API(`${URL.URL_SERVER}/users/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, User);
      return response.wasSuccessfull().single();
    }
  };
  return { getTransaction, getUser };
};

export default BillingDetailService;
