import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  OutlinedInput,
  IconButton,
  Grid,
  Typography,
  FormControl,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useStyles, ProfileLogic, validator } from './profile.module';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import ButtonDefault from '../shared/button/button';
import AlertMessage from '../shared/alert/alert';

const ResetPasswordAccordion = () => {
  const classes = useStyles();
  const {
    current,
    changeState,
    handleSubmit,
    handleChange,
    handleClickShowCurrentPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmNewPassword,
    handleMouseDownPassword,
    values,
    errors,
    setErrors,
    setValues,
    buttonDisabled,
  } = ProfileLogic(() => validator);

  return (
    <Accordion onChange={changeState(0)}>
      <AccordionSummary
        className={classes.root}
        expandIcon={
          current === 0 ? (
            <RemoveCircleOutlineIcon
              className={classes.removeCircleOutlineIcon}
            />
          ) : (
            <AddCircleOutlineIcon className={classes.addCircleOutlineIcon} />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="h5"
          color="initial"
          align="left"
          className={classes.questionsWhite}
        >
          Reset Password
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Grid className={classes.registerSection} item xs={12} sm={12} md={12}>
          <form
            className={classes.form}
            noValidate
            autoComplete={'off'}
            //onSubmit={handleSubmit}
          >
            <label className={classes.labels}>Current Password</label>
            <FormControl className={classes.passwordField}>
              <OutlinedInput
                type={values.showCurrentPassword ? 'text' : 'password'}
                value={values.currentPassword}
                name={'currentPassword'}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.icon}
                    >
                      {values.showCurrentPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>

            {errors &&
              errors.error_password &&
              (console.log('errors in the alert message 1', errors),
              (<AlertMessage type="error" text={errors.error_password} />))}

            <label className={classes.labels}>New Password</label>
            <FormControl className={classes.passwordField}>
              <OutlinedInput
                type={values.showNewPassword ? 'text' : 'password'}
                value={values.newPassword}
                name={'newPassword'}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.icon}
                    >
                      {values.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <p className={classes.passwordHelper}>
              The password must contain a minimum of 8 characters, at least one
              number, a capital letter and a special character.
            </p>
            {errors &&
              errors.error_new_password &&
              (console.log('errors in the alert message', errors),
              (<AlertMessage type="error" text={errors.error_new_password} />))}

            <label className={classes.labels}>Confirm New Password</label>
            <FormControl className={classes.passwordField}>
              <OutlinedInput
                type={values.showConfirmNewPassword ? 'text' : 'password'}
                value={values.confirmNewPassword}
                name={'confirmNewPassword'}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.icon}
                    >
                      {values.showConfirmNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <p className={classes.passwordHelper}>
              The password must contain a minimum of 8 characters, at least one
              number, a capital letter and a special character.
            </p>
            {errors &&
              errors.error_confirm_new_password &&
              (console.log('errors in the alert message 1', errors),
              (
                <AlertMessage
                  type="error"
                  text={errors.error_confirm_new_password}
                />
              ))}

            {errors &&
              errors.error_no_match_new_password &&
              (console.log('errors in the alert message 1', errors),
              (
                <AlertMessage
                  type="error"
                  text={errors.error_no_match_new_password}
                />
              ))}
          </form>
          <ButtonDefault
            type="blue"
            text="reset"
            changeView={handleSubmit}
            disabled={buttonDisabled}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ResetPasswordAccordion;
