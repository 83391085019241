import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 48,
    color: theme.palette.text.nonary,
    fontFamily: 'Montserrat',
    fontSize: '60px',
    fontWeight: 700,
    lineHeight: '66px',
    letterSpacing: '-3px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  titleGreen: {
    marginTop: 48,
    color: '#06E89F',
    fontFamily: 'Montserrat',
    fontSize: '60px',
    fontWeight: 700,
    lineHeight: '66px',
    letterSpacing: '-3px',
    textAlign: 'center',
    marginLeft: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  text: {
    margin: 48,
    padding: 48,
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: '40px',
    textAlign: 'left',
    maxWidth: 1050,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      margin: 0,
      padding: 12,
    },
  },
  
  textBold: {
    fontFamily: 'Montserrat',
    fontSize: '25px',
    fontWeight: 600,
    lineHeight: '40px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}));

export default useStyles;
