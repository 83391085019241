import URL from '../../classes/Url/URL';
import BlogPost from '../../classes/Blog/BlogPost';
import axios from 'axios';

const BlogService = () => {
  const getPosts = async ({ limit = 10, sort = -1, pageToken = '' }) => {
    const url = pageToken
      ? `${URL.URL_SERVER}/posts?limit=${limit}&pageToken=${pageToken}`
      : `${URL.URL_SERVER}/posts?limit=${limit}`;
    const response = await axios.get(url);
    let { resources } = response.data;
    let pageTokenResponse = response.data.pageToken;
    resources = resources.map((item) => new BlogPost(item));
    return { resources, pageTokenResponse };
  };

  return {
    getPosts,
  };
};

export default BlogService;
