import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 80,
    marginBottom: 30,
  },
  welcomeBanner: {
    display: 'block',
  },
  containerRestore: {
    marginTop: 60,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'left',
  },
  title: {
    fontFamily: 'MontserratBold',
    fontWeight: 800,
    fontSize: 56,
    textAlign: 'left',
    marginTop: 0,
    color: theme.palette.text.secondary,
  },
  textExplain: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'left',
    marginBottom: 40,

  },
  loginSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: 32,
  },
  textField: {
    height: 42,
    borderRadius: 8,
    marginBottom: 15,
    border: '1px solid #E0E0E0',
    boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.1)',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.tertiary,
    '&:focus': {
      border: '1px solid rgba(0, 111, 86, 0.5)',
      boxShadow: '1px solid rgba(0, 111, 86, 0.5)',
    },
    "& .MuiInputBase-input": {
      height: 3,
    },

  },
  textFieldPassword: {
    height: 42,
    borderRadius: 8,
    // marginBottom: 15,
    border: '1px solid #E0E0E0',
    boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.1)',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.tertiary,
    '&:focus': {
      border: '1px solid rgba(0, 111, 86, 0.5)',
      boxShadow: '1px solid rgba(0, 111, 86, 0.5)',
    },
    "& .MuiInputBase-input": {
      height: 3,
    },

  },
  labels: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
    color: theme.palette.text.tertiary,
    height: 24,
  },
  link: {
    textDecoration: 'none',
    textDecorationLine: 'none',
  },

  [theme.breakpoints.down("md")]: {
    welcomeBanner: {
      display: 'none',
    },
    containerRestore: {
      margin: 'auto'
    },

  },
  [theme.breakpoints.down("sm")]: {
    welcomeBanner: {
      display: 'none',
    },
    containerRestore: {
      margin: 'auto'

    },
  },
  [theme.breakpoints.down("xs")]: {
    welcomeBanner: {
      display: 'none',
    },
    containerRestore: {
      marginTop: 0,
    },
  },
  passwordHelper: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 12,
    textAlign: 'left',
    color: theme.palette.text.tertiary,
    margin: 0,
    padding: 0,
    marginBottom: 16,
    marginLeft: 16,
  },
}));

export default useStyles;
