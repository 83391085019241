import { useEffect, useState } from 'react';
import {
    eGreen_path,
    info_es,
    info_en,
} from './finances.module';
import Translator from '../../classes/Translator/Translator';

const FinancesLogic = () => {
    const { translator, isEnglish } = Translator();
    const [values, setValues] = useState({
        eGreen_path,
        info_image: isEnglish()
            ? info_en
            : info_es,

    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setValues({
            ...values,
            info_image: isEnglish()
                ? info_en
                : info_es,
        });
    }, [isEnglish()]);

    return {
        values,
        translator,
    };
};

export default FinancesLogic;
