import {Typography} from '@material-ui/core';
import { useStyles, ProjectCarrousellLogic, Rectangle4, Rectangle6, Rectangle10, Rectangle11, Rectangle13, Rectangle15 } from "./ProjectCarrousell.module";

function ProjectCarrousell() {
  const classes = useStyles();
  const {  translator } = ProjectCarrousellLogic();
  return (
    <div className={classes.carrousellContainer}>
      <Typography variant="h5" className={classes.title}>
        {translator('projectCarrousel.title')}
      </Typography>
      <Typography variant="h5" className={classes.title2}>
        {translator('projectCarrousel.title2')}
      </Typography>
      <div className={classes.slider} >
        <img src={Rectangle4} alt='dsf' className={classes.img}/>
        <img src={Rectangle6} alt='dsf' className={classes.img}/>
        <img src={Rectangle10} alt='dsf' className={classes.img}/>
        <img src={Rectangle11} alt='dsf' className={classes.img}/>
        <img src={Rectangle13} alt='dsf' className={classes.img}/>
        <img src={Rectangle15} alt='dsf' className={classes.img}/>
      </div>
    </div>
  );
}

export default ProjectCarrousell;