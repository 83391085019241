import Translator from '../../../classes/Translator/Translator';
import { useEffect, useState } from 'react';

const ModalLogic = ({ open: openParent, onChange }) => {
  const { translator } = Translator();
  const [open, setOpen] = useState(openParent);

  const handleClose = (event, reason) => {
    console.log(reason);
    if (reason && reason === 'backdropClick') {
      onChange(false);
      setOpen(false);
    }
    onChange(false);
  };

  useEffect(() => {
    setOpen(openParent);
  }, [openParent]);

  return {
    translator,
    openParent,
    open,
    handleClose,
  };
};

export default ModalLogic;
