import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  title: {
    justifyContent: 'center',
    color: '#04679C',
    fontFamily: 'MontserratExtraBold',
    fontWeight: '800',
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '54px',
  },
});

export default useStyles;
