import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
import { useStyles, logoDark, FooterLogic, facebook } from './footer.module';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Footer = () => {
  const classes = useStyles();
  const { translator } = FooterLogic();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <div className={classes.gridContainer}>
        <div>
          <Grid container style={{ height: '100%' }}>
            <Grid item lg={2} md={2} sm={1} xs={1}></Grid>

            <Grid item lg={9} md={9} sm={10} xs={10}>
              <div className={classes.content}>
                {isMobile ? (
                  <Typography className={classes.title}>
                    {' '}
                    {translator('footer_title')}
                  </Typography>
                ) : (
                  <>
                    <Typography className={classes.title}>
                      <span style={{ display: 'flex' }}>
                        {translator('footer_title_first')}
                      </span>
                      <span>{translator('footer_title_second')}</span>
                    </Typography>
                  </>
                )}

                <img
                  className={classes.logoEgreenDark}
                  src={logoDark}
                  alt="eGreen Logo"
                />
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
          </Grid>
        </div>
        <div>
          <Grid container style={{ height: '100%' }}>
            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <Typography className={classes.subtitle}>
                hello@egreen.com{' '}
              </Typography>
              <div className={classes.start}>
                <div className={classes.gridItemStart}>
                  <div>
                    <Link className={classes.customLink} to="/egreen">
                      <Typography className={classes.fontLink}>
                        {translator('footer_abouts')}
                      </Typography>
                    </Link>
                  </div>
                  <div>
                    <Link className={classes.customLink} to="/CarbonManagement">
                      <Typography className={classes.fontLink}>
                        {translator('footer_carbon')}
                      </Typography>
                    </Link>
                    <Link className={classes.customLink} to="/coins">
                      <Typography className={classes.fontLink}>
                        {' '}
                        {translator('footer_investment')}
                      </Typography>{' '}
                    </Link>
                    <Link className={classes.customLink} to="/projects">
                      <Typography className={classes.fontLink}>
                        {' '}
                        {translator('footer_projects')}
                      </Typography>
                    </Link>
                    <Link className={classes.customLink} to="/blog">
                      <Typography className={classes.fontLink}>
                        {translator('footer_news')}
                      </Typography>
                    </Link>
                  </div>
                  <div>
                    <Link className={classes.customLink} to="/">
                      <Typography className={classes.fontLink}>
                        {translator('footer_map')}
                      </Typography>
                    </Link>
                    <Link className={classes.customLink} to="/questions">
                      <Typography className={classes.fontLink}>
                        {translator('footer_faqs')}
                      </Typography>
                    </Link>
                    <Link className={classes.customLink} to="/termsConditions">
                      <Typography className={classes.fontLink}>
                        {translator('footer_conditions')}
                      </Typography>
                    </Link>
                  </div>
                  <div></div>
                </div>
              </div>

              <div className={classes.end}>
                <div className={classes.gridItemEnd}>
                  <div>
                    <Link className={classes.customLink} to="/privacyPolicy">
                      <Typography className={classes.fontLinkLigth}>
                        {translator('footer_privacy')}
                      </Typography>
                    </Link>
                  </div>
                  <div></div>
                  <div className={classes.network}>
                    <div className={classes.networkIcon}>
                      <a
                        href={
                          'https://www.facebook.com/profile.php?id=100063829637344'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={facebook}
                          alt="facebook"
                          width="30"
                          height="30"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href={'https://www.instagram.com/egreen.finance/'}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <InstagramIcon className={classes.icon} />
                      </a>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Footer;
