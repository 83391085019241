import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    margin: '75px auto -30px auto',
    maxWidth: '1350px',
    display: 'flex',
  },
  customLink: {
    textDecoration: 'none',
    color: '#333333',
    '&:hover': {
      color: '#000000',
      textDecoration: 'none',
    },
  },
  fontLink: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
  },
  subtitle: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: '#333333',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '32px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  heading: {
    fontWeight: '800',
    fontFamily: 'Montserrat',
    color: '#04679C',
    fontStyle: 'normal',
    fontSize: '56px',
    lineHeight: '64px',
    textAlign: 'center',
    '@media only screen and (max-width: 320px)': {
      fontSize: '2em',
    },
  },

  container: {
    maxWidth: 900,
  },
  answers: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: '#333333',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'none'
  },
}));
export default useStyles;
