import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

    bodyAboutEgreen: {
        fontFamily: 'MontserratLight',
        fontSize: '25px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0em',
        textAlign: 'left',
    },
    moreAboutEgreen: {
        fontFamily: 'Montserrat',
        fontSize: '25px',
        fontWeight: 500,
        lineHeight: '32px',
        letterSpacing: '0em',
        textAlign: 'left',
        marginTop: 50
    },
    linkAboutEgreen: {
        color: '#2CDD00',
        marginLeft: 4
    },
    bodyAboutEgreenStrong:{
        fontFamily: 'MontserratSemiBold',

    }

}));

export default useStyles;

