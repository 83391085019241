import { useEffect, useState } from 'react';
import Translator from '../../classes/Translator/Translator';

const InfoBarLogic = (getData) => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    stats: {},
  });

  useEffect(() => {
    getData().then((stats) => setValues({ ...values, stats }));
  }, []);

  return {
    values,
    translator,
  };
};

export default InfoBarLogic;
