import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: '20px',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    background: theme.palette.background.default,
  },
  containerCardReverse: {
    flexDirection: 'row-reverse',
    marginBottom: '20px',
    background: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  title: {
    fontSize: '38px',
    color: theme.palette.text.nonary,
    fontWeight: '800px',
    fontStyle: 'normal',
    lineHeight: '54px',
    fontFamily: 'MontserratExtraBold',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '42px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      lineHeight: '42px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '30px',
      lineHeight: '30px',
    },
  },
  titleClass: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '62px',
    lineHeight: '76px',
    color: theme.palette.text.nonary,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '35px',
      lineHeight: '50px',
    },
  },
  textInfo: {
    fontFamily: 'Montserrat',
    fontWeight: '400px',
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    lineHeight: '26px',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  textInfoClass: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    textAlign: 'left',
    color: theme.palette.text.denary,
  },
  textInfoClassTwo: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    textAlign: 'left',
    color: theme.palette.text.denary,
     marginTop:70,
    [theme.breakpoints.down('xs')]: {
      marginTop:0,
    },
  
  },
  mainImg: {
    height: '243px',
    width: '378px',
    borderRadius: '16px',
    color: '#C4C4C4',
    marginTop: '0px',
    [theme.breakpoints.down('md')]: {
      height: '350px',
      width: '440px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '305px',
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '220px',
      width: '290px',
    },
  },
  secondImg: {
    height: '160px',
    width: '215px',
    borderRadius: '22px',
    color: '#C4C4C4',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      height: '350px',
      width: '440px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '305px',
      width: '350px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '220px',
      width: '290px',
    },
  },
  oneImg: {
    height: '609px',
    width: '580px',
    padding: '40px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      height: '400px',
      width: '390px',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      height: '300px',
      width: '290px',
    },
  },
  root: {
    boxShadow: 'none',
  },
  card: {
    boxShadow: 'none',
    background: theme.palette.background.default,


  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems:'center',
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  containerImage: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  containerCarbonOffset: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },

  },
  rootCarbonOffset: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  imgCarbonOffset: {

  },
  containerChildren: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  childrenCarbonOffset: {
    fontFamily: 'MontserratBold',
    fontSize: '30px',
    lineHeight: '32px',
    textAlign: 'center',
    color: theme.palette.text.denary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
    },

  },
}));

export default useStyles;
