import Joi from 'joi';

const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();

const codeSchema = Joi.string().required();

export function validator(values) {
  const errors = {};
  const { error: errorEmail } = emailSchema.validate(values.email);
  const { error: errorCode } = codeSchema.validate(values.code);

  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido';
  }
  if (errorCode) {
    errors.error_code = 'Ingresa un código válido.';
  }
  return errors;
}

export function emailValidator(values) {
  const errors = {};
  const { error: errorEmail } = emailSchema.validate(values.email);

  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido';
  }

  return errors;
}
