import React, { useState } from 'react';
import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/CustomError/CustomError';
import Certificate from '../../../classes/Certificate/Certificate';
import NFT from '../../../classes/NFT/NFT';

const NftTableService = (props) => {
  const [checkedIds, setCheckedIds] = useState([]);
  const [nfts, setNfts] = useState(props.nfts || []);
  const [errors, setErrors] = useState({});
  const [certificateUrl, setCertificateUrl] = useState(
    props.certificate || null
  );
  const [popoverType, setPopoverType] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUrls, setShowUrls] = useState(true);
  const [showOffsetForm, setShowOffsetForm] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [carbonFluxData, setCarbonFluxData] = useState([]);

  const handleCheckboxChange = (event, id) => {
    setCheckedIds((prev) => {
      const newCheckedIds = event.target.checked
        ? [...prev, id]
        : prev.filter((checkedId) => checkedId !== id);

      return newCheckedIds;
    });
  };

  const handleFabClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setPopoverType(type);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverType('');
  };
  const handleOffsetClick = () => {
    setShowOffsetForm(true);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const sendCertificate = async () => {
    try {
      const ids = { ids: checkedIds };
      const api = new API(`${URL.URL_SERVER}/certificates`);
      const http_response = await api.post(ids);

      if (http_response instanceof CustomError) {
        return http_response;
      }

      if (http_response.data) {
        const updatedNfts = nfts.map((nft) =>
          checkedIds.includes(nft.id)
            ? { ...nft, certificate: http_response.data.url }
            : nft
        );
        setNfts(updatedNfts);
        return http_response.data.url;
      }

      return new Response(http_response, Certificate);
    } catch (error) {
      console.log('error', error);
    }
  };

  const mostrarCertificate = (url) => {
    if (url) {
      window.open(url, '_blank');
    } else {
      setErrors({ certificateView: 'No hay certificado disponible para ver' });
    }
  };

  const handleGenerateCertificate = async () => {
    let url;
    if (checkedIds.length > 0) {
      url = await sendCertificate();
    } else {
      url = await sendCertificateSingle(props.id);
    }

    if (url) {
      setCertificateUrl(url);
      setShowUrls(true);
    } else {
      console.error('No se pudo generó el certificado');
    }
  };
  const sendCertificateSingle = async (id) => {
    try {
      const ids = { ids: [id] };
      const api = new API(`${URL.URL_SERVER}/certificates`);
      const http_response = await api.post(ids);

      const has_error = http_response instanceof CustomError;

      if (!has_error && http_response.data) {
        const updatedNfts = nfts.map((nft) =>
          nft.id === id ? { ...nft, certificate: http_response.data.url } : nft
        );
        setNfts(updatedNfts);
        return http_response.data.url;
      }

      return has_error
        ? http_response
        : new Response(http_response, Certificate);
    } catch (error) {
      console.log('error');
    }
  };
  const getCarbonflux = async (id) => {
    const api = new API(`${URL.URL_SERVER}/nfts/${id}/carbonfluxes`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, NFT);
      const data = response.wasSuccessfull().single();
      setCarbonFluxData(data.carbonflux);
      return data;
    }
  };
  const toggleDrawer = (open, id) => async (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (open) {
      await getCarbonflux(id);
    }

    setDrawerOpen(open);
  };
  const getCsv = async (id) => {
    try {
      const json = new API(`${URL.URL_SERVER}/stationdata/${id}`);
      const response_json = await json.get();
      const error_json = response_json instanceof CustomError;
      if (error_json) {
        return response_json;
      } else {
        const csvName = response_json.data.report_url.split('/').pop();
        const api = new API(`${URL.URL_SERVER}/stationdata/${id}/data/csv`);
        const http_response = await api.get({ responseType: 'blob' });
        const has_error = http_response instanceof CustomError;
        if (has_error) {
          return http_response;
        } else {
          const blob = new Blob([http_response.data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', csvName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    } catch (error) {
      console.log('Error :', error);
    }
  };

  const getGhg = async (id) => {
    try {
      const api = new API(`${URL.URL_SERVER}/stationdata/${id}/data/ghg`);
      const http_response = await api.get({ responseType: 'blob' });

      if (http_response instanceof CustomError) {
        return http_response;
      } else {
        const blob = new Blob([http_response.data], { type: 'text/ghg' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.ghg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.log('Error :', error);
    }
  };

  return {
    sendCertificateSingle,
    checkedIds,
    setCheckedIds,
    nfts,
    setNfts,
    errors,
    setErrors,
    handleCheckboxChange,
    popoverType,
    setPopoverType,
    anchorEl,
    setAnchorEl,
    handleFabClick,
    sendCertificate,
    mostrarCertificate,
    handlePopoverClose,
    isPopoverOpen,
    certificateUrl,
    setCertificateUrl,
    handleGenerateCertificate,
    showUrls,
    showOffsetForm,
    handleOffsetClick,
    toggleDrawer,
    drawerOpen,
    getCarbonflux,
    carbonFluxData,
    getCsv,
    getGhg,
  };
};

export default NftTableService;
