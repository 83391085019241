import { useEffect, useState } from 'react';
import Storage from '../../classes/Storage/Storage';
import Translator from '../../classes/Translator/Translator';
import { TransferToBuyService } from './transferToBuy.module';
import Formatter from '../../classes/Formatter/Formatter';
import Fee from '../../classes/Fee/Fee';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY_STRIPE);

const TransferToBuyLogic = (props, getData) => {
  const { translator } = Translator();
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {}, []);

  const onChange = (e) => {
    let value = e.target.value;
    if (/^\d*(\.\d{0,2})?$/.test(value)) {
      setValues({ ...values, amount: value });
    }
  };

  const buyUsd = async () => {
    try {
      const transaction = await TransferToBuyService().create({
        amount_usd: values.amount,
        id_user: new Storage('id').getItem(),
        type: 'buy_usd',
      });

      const stripe = await stripePromise;

      const pay = Formatter.round(
        values.amount * process.env.REACT_APP_MONEY_FACTOR * Fee.MATH_USD_FEE,
        0
      );
      const sessionStripe = await TransferToBuyService().checkout({
        pay,
        offset: 'Transfer to buy',
        new_version: true,
        id_pretransaction: transaction.id,
      });
      await stripe.redirectToCheckout({
        sessionId: sessionStripe.id,
      });
    } catch (error) {
      console.log({ error });
    }
  };
  const feeMath = Formatter.round(
    values.amount *  Fee.MATH_USD_FEE,
    2
  );
  return {
    values,
    isLoading,
    onChange,
    buyUsd,
    translator,
    setValues,
    feeMath
  };
};
export default TransferToBuyLogic;
