import { useEffect, useState } from 'react';
import Storage from '../../classes/Storage/Storage';
import Translator from '../../classes/Translator/Translator';

const ExchangeLogic = (props, getData) => {
  const { translator } = Translator();
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const id = new Storage('id').getItem();
    getData(id).then((data) => {
      setValues({ ...values, ...data });
      setIsLoading(false);
    });
  }, []);

  return {
    values,
    isLoading,
    translator,
  };
};
export default ExchangeLogic;
