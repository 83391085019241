import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Box,
  Breadcrumbs,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import ButtonDefault from '../shared/button/button';
import Footer from '../footer/Footer';
import CardsCoins from '../shared/cardCoins/cardCoins';
import Source from '../shared/Source/Source';
import { useStyles, CoinsLogic } from './coins.module';

const Coins = () => {
  const { heading, translator, values } = CoinsLogic();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>Home</Typography>
          </Link>
          <Link className={classes.customLink} to="/coins">
            <Typography className={classes.fontLink}>EGRN COIN</Typography>
          </Link>
        </Breadcrumbs>
      </Box>
      <Container maxWidth="md">
        <main>
          <Grid container>
            <Grid item lg={12} sm={12} xs={12}>
              <Box mt={10} mb={10}>
                <Typography
                  className={classes.heading}
                  variant="h2"
                  align="center"
                  paragraph
                >
                  {heading}
                </Typography>
                <Typography
                  className={classes.subtitle}
                  variant="h4"
                  align="center"
                  paragraph
                >
                  {translator('coins.subtitle')}
                </Typography>
              </Box>
              <Cards />
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
              <Box mt={10} mb={10}>
                <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.message1')}
                </Typography>
                <br />
                <br />
                <br />
                {/* imagen1 */}
                <img
                  className={classes.img}
                  alt="info1"
                  src={values.coins_info1}
                />
                <br />
                <br />
                <br />
                {/* imagen 2 */}
                <img
                  className={classes.img}
                  alt="info2"
                  src={values.coins_info2}
                />
                <Source url={translator('coins.source1')} />
                {/* source 2 */}
                <br />
                <br />
                <Typography
                  className={classes.subtitle}
                  variant="h4"
                  align="center"
                  paragraph
                >
                  {translator('coins.subtitle2')}
                </Typography>
                <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.message3')}
                </Typography>
                <br />
                <br />
                <br />
                {/* titulo de imagen */}
                <Typography
                  className={classes.message}
                  variant="h4"
                  align="left"
                  paragraph
                >
                  {translator('coins.subtitle3')}
                </Typography>
                {/* imagen 3 */}
                <img
                  className={classes.img}
                  alt="info3"
                  src={values.coins_info3}
                />
                <Source url={translator('coins.source2')} />
                {/* source 2 */}
                <br />
                <br />
                <Typography
                  className={classes.subtitle}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.subtitle4')}
                </Typography>
                <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.message4')}
                </Typography>
                <br />
                <br />
                <br />
                <Typography
                  className={classes.subtitle}
                  variant="h2"
                  align="center"
                  paragraph
                >
                  {translator('coins.subtitle5')}
                </Typography>
                <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.message5')}
                </Typography>
                <br />
                <br />
                {/* imagen 4 */}
                <img
                  className={classes.img}
                  alt="info4"
                  src={values.coins_info4}
                />
                <Source url={translator('coins.source3')} />
                <br />
                <br />
                {/* source 4 */}
                {/* imagen 5 */}
                <img
                  className={classes.img}
                  alt="info5"
                  src={values.coins_info5}
                />
                <Source url={translator('coins.source4')} />
                {/* source 4 */}
                <br />
                <br />
                <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.wantToKnow')}
                  <a
                    className={classes.customLinkBody}
                    href={values.white_paper}
                    target="_blank"
                  >
                    {translator('coins.whitePaperHere')}
                  </a>
                </Typography>
                {/* <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  {translator('coins.ifYouQuestions')}{' '}
                  {translator('coins.click_here')}
                </Typography> */}
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Link to="/projects" className={classes.customLink}>
                    <ButtonDefault
                      text={translator('invest')}
                      type="buttonPay"
                    />
                  </Link>
                </Grid>
                <Typography
                  className={classes.message}
                  variant="h6"
                  align="center"
                  paragraph
                >
                  <strong>{translator('disclaimer.message')}</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box className={classes.howtoStart}>{/* <HowToStart /> */}</Box>
        </main>
      </Container>
      <Footer />
    </div>
  );
};
export default Coins;

const Cards = () => {
  const classes = useStyles();
  const { mediaCards } = CoinsLogic();

  return (
    <Grid container spacing={2}>
      {mediaCards.map((item, i) => (
        <Grid
          key={i}
          item
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className={classes.cardCoins}
        >
          <CardsCoins {...item} />
        </Grid>
      ))}
    </Grid>
  );
};
