import Translator from '../../classes/Translator/Translator';

const WhatWeDoLogic = (props) => {
  const { translator } = Translator();

  return {
    translator,
  };
};

export default WhatWeDoLogic;
