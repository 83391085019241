import { useEffect, useState } from 'react';
import CustomError from '../../classes/CustomError/CustomError';
import Translator from '../../classes/Translator/Translator';

const ConfirmationViewLogic = (getProject, getTransaction, checkPay) => {
  const { translator } = Translator();
  const [values, setValues] = useState({
    transaction: {},
    project: {},
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function getData() {
      const transaction = await getTransaction();
      const project = await getProject(transaction.project_id);
      const session_stripe = await checkPay({
        idPretransaction: transaction.id,
      });
      console.log({ transaction, project, session_stripe });
      setIsLoading(false);
      setError(session_stripe instanceof CustomError);
      setValues({ ...values, project, transaction });
    }
    getData();
  }, []);

  return { 
    error, 
    isLoading, 
    values,
    translator, 
  };
};

export default ConfirmationViewLogic;
