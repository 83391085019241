import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  modalBox: {
    overflow: 'auto',
    color: theme.palette.text.denary,
    background: theme.palette.background.default,
    border: '2px solid #000',
    borderRadius: 4,
    boxShadow: 24,
    '@media screen and (max-width:992px)': {
      width: '100%',
      maxWidth: '100%',
      padding: 0,
      margin: 0,
      top: '5%',
      position: 'absolute',
    },
    '@media screen and (min-width:576px)': {
      width: '80%',
      maxWidth: 950,
      padding: 32,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

  },
}));

export default useStyles;
