import { useState, useEffect } from 'react';

const AlertLogic = (props) => {
  const { type } = props;
  const [values, setValues] = useState({
    ...props,
    alertClass: 'error',
  });

  const setClass = () => {
    switch (type) {
      case 'error':
        setValues({ ...values, alertClass: 'error' });
        break;
      case 'success':
        setValues({ ...values, alertClass: 'success' });
        break;
      default:
        setValues({ ...values, buttonClass: 'success' });
        break;
    }
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return {
    values,
  };
};

export default AlertLogic;