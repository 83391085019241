import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';
import Wallet from '../../classes/Wallet/Wallet';

const WalletService = () => {

    const getData = async (id) => {
        const api = new API(`${URL.URL_SERVER}/users/${id}/wallets`);
        const http_response = await api.get();
        const has_error = http_response instanceof CustomError;
        if (has_error) {
          return http_response;
        } else {
          const response = new Response(http_response, Wallet);
          return response.wasSuccessfull().single();
        }
      };

  return {
    getData,
  };
};

export default WalletService;
