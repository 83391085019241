import Formatter from '../Formatter/Formatter';

class CarbonFlux {
  constructor({
    constellation,
    created_at,
    gross_contribution,
    last,
    allocated,
    productive_area_size_m2,
    project,
    remainder,
    station,
    station_data,
    station_data_info = {},
    total_co2,
    updated_at,
    processed,
  }) {
    this.constellation = constellation;
    this.created_at = created_at;
    this.gross_contribution = gross_contribution;
    this.last = last;
    this.allocated = allocated;
    this.productive_area_size_m2 = productive_area_size_m2;
    this.project = project;
    this.remainder = remainder;
    this.station = station;
    this.station_data = station_data;
    this.station_data_info = station_data_info;
    this.total_co2 = total_co2;
    this.updated_at = updated_at;
    this.processed = processed;
  }

  getTotalCO2() {
    return Formatter.formatNumber(this.total_co2);
  }

  getGrossContribution() {
    return Formatter.formatNumber(this.gross_contribution);
  }

  getRemainder() {
    return Formatter.formatNumber(this.remainder);
  }

  getIdFromStationData() {
    return this.station_data_info.carbon_real_id;
  }

  getDateFromStationData() {
    return this.station_data_info.datetime;
  }

  getProductiveAreaSizeM2() {
    return Formatter.formatNumber(this.productive_area_size_m2);
  }
  
  getFluxCO2() {
    return Formatter.formatNumber(this.station_data_info.co2_flux_g_m2);
  }

}

export default CarbonFlux;
