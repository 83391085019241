import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
    margin: '24px 0px',
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    border: '2px solid #00DD9E',
    borderRadius: 20,
    padding: 24,
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
}));

export default useStyles;
