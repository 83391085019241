import { useEffect, useState } from 'react';
import Storage from '../../classes/Storage/Storage';
import Translator from '../../classes/Translator/Translator';
import { useHistory } from 'react-router-dom';

const DashboardLogic = (getUser, getHistory, getOffer, getNfts) => {
  const { translator } = Translator();
  const [values, setValues] = useState({ user: {} });
  const [nfts, setNfts] = useState([]);
  const [sbts, setSbts] = useState([]);
  const history = useHistory();

  const goToProjects = () => {
    history.push('/projects');
  };

  useEffect(() => {
    const id = new Storage('id').getItem();
    getUser(id).then((user) => {
      setValues({ ...values, user });
    });
  }, []);

  // historyOfOperations
  const [operations, setOperations] = useState([]);
  useEffect(() => {
    const id = new Storage('id').getItem();
    getHistory(id).then((history) => {
      const { resources } = history;
      setOperations(resources);
    });
  }, [values]);

  useEffect(() => {
    const id = new Storage('id').getItem();
    getOffer(id).then((history) => {
      const { resources } = history;
      setOperations((oldArray) => [...oldArray, ...resources]);
    });
  }, [values]);

  useEffect(() => {
    const id = new Storage('id').getItem();
    getNfts(id, false).then((nfts) => {
      const { resources } = nfts;
      setNfts(resources);
    });
    getNfts(id, true).then((sbts) => {
      const { resources } = sbts;
      setSbts(resources);
    });
  }, [values]);

  // stats
  const [data, setData] = useState([]);
  useEffect(() => {
    getStatistical();
  }, [values]);

  const getStatistical = async () => {
    const data = await fetch('/statisticalDashboard.json');
    const items = await data.json();
    setData(items);
  };

  return {
    data,
    values,
    operations,
    translator,
    nfts,
    sbts,
    goToProjects,

  };
};
export default DashboardLogic;
