import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import AlliesCard from '../shared/alliesCard/alliesCard';
import { useStyles } from './partners.module';

const Partners = () => {
  const classes = useStyles();

  const partners = [
    { ally: 'aenor' },
    { ally: 'arauco' },
    { ally: 'aws' },
    { ally: 'british' },
    { ally: 'corfo' },
    { ally: 'ecota' },
    { ally: 'magical' },
    { ally: 'sme' },
    { ally: 'stellar' },
    { ally: 'techstars' },
    { ally: 'verra' },
  ];

  return (
    <div className={classes.partnersContainer}>
      <div className={classes.swiperContainer}>
        <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            el: '.pagination',
            clickable: true,
          }}
          slidesPerView={3}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3.5,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 25,
            },
            1280: {
              slidesPerView: 5,
              spaceBetween: 20,
            },
            1600: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
        >
          {partners.map((partner, index) => (
            <SwiperSlide key={index}>
              <AlliesCard ally={partner.ally} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* <div className="pagination" /> */}
    </div>
  );
};

export default Partners;
