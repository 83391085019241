import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    margin: 0,
    paddingTop: 0,
    lineHeight: 1,
    marginBottom: 50,
  },
  arrowLeft: {
    fontSize: 42,
    fontFamily: 'Montserrat',
    color: '#05649c',
    cursor: 'pointer',
  },

  textBack: {
    fontFamily: 'MontserratSemiBold',
    color: '#05649c',
    fontSize: '14px',
    padding: 0,
    margin: 0,
  },
}));

export default useStyles;
