import { makeStyles } from '@material-ui/core';
import Background from '../../assets/images/Fondo.webp';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundImage: `url(${Background})`,
    color: theme.palette.common.white,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'contain',
    backgroundSize: 'cover',
    width: '100%',
    height: '880px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '@media only screen and (max-width: 767px)': {
      backgroundImage: `url(${Background})`,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      alignItems: 'center',
      paddingLeft: '10%',
      paddingRight: '10%',
    },
  },
  titleAndDescription: {
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('md')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  mainItem: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(5),
    },
  },
  mainButton: {
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.up('lg', 'md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'center',
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  bannerTitle: {
    color: '#fff',
    fontFamily: 'MontserratBold',
    fontSize: '38px',
    fontWeight: '700',
    marginTop: '110px',
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('lg', 'md', 'sm')]: {
      justifyContent: 'left',
      textAlign: 'left',
      paddingLeft: theme.spacing(5),
      fontSize: '64px',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  bannerSlogan: {
    color: '#fff',
    fontFamily: 'Montserrat',
    fontSize: '21px',
    fontStyle: 'normal',
    marginBottom: '20px',
    whiteSpace: 'pre-wrap',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('lg', 'md')]: {
      justifyContent: 'left',
      textAlign: 'left',
      paddingLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
  },

  bannerLink: {
    textDecoration: 'none',
    textDecorationLine: 'none',
  },
}));
export default useStyles;
