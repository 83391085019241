import React from 'react';
import { Grid, Container, OutlinedInput } from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import WelcomeBanner from '../shared/welcomeBanner/welcomeBanner';
import AlertMessage from '../shared/alert/alert';
import Footer from '../footer/Footer';

import {
  useStyles,
  RestorePasswordLogic,
  validator,
  RestorePasswordService,
} from './restorePassword.module';
import CustomError from '../../classes/CustomError/CustomError';

export default function RestorePassword() {
  const classes = useStyles();
  const {
    handleChange,
    handleSubmit,
    history,
    values,
    errors,
    translator,
    setErrors,
  } = RestorePasswordLogic(() => restorePassword(), validator);

  async function restorePassword() {
    const response = await RestorePasswordService().resetPassword(values.email);
    if (!(response instanceof CustomError)) {
      history.push('/reset');
    } else {
      setErrors({ ...errors, api_error: response.message });
    }
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container align="center" spacing={1} className={classes.root}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.welcomeBanner}
          >
            <WelcomeBanner text={translator('restoreWelcome')} />
          </Grid>
          <Grid
            item
            lg={5}
            md={7}
            sm={9}
            xs={12}
            className={classes.containerRestore}
          >
            <h2 className={classes.title}>
              {translator('restorePassword.title')}
            </h2>
            <p className={classes.textExplain}>
              {translator('restorePassword.text')}
            </p>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <label className={classes.labels}>
                {translator('restorePassword.email')}
              </label>
              <OutlinedInput
                className={classes.textField}
                type="email"
                name={'email'}
                value={values.email}
                onChange={handleChange}
              />
              {errors && errors.error_email && (
                <AlertMessage type="error" text={errors.error_email} />
              )}
              {errors && errors.api_error && (
                <AlertMessage type="error" text={errors.api_error} />
              )}

              <Grid
                justifyContent="space-between"
                container
                spacing={1}
                className={classes.containerButtons}
              >
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ButtonDefault
                    type="whitegreen"
                    text={translator('restoreToReturn')}
                    changeView={history.goBack}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <ButtonDefault
                    type="blue"
                    text={translator('restoreGetInto')}
                    isSubmit={true}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
