import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';

const DemoFormService = () => {
  const send = async (values) => {
    const api = new API(`${URL.URL_SERVER}/demoForm`);
    const http_response = await api.post(values);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response);
      return !response.hasContent();
    }
  };

  return {
    send,
  };
};

export default DemoFormService;
