import { useStyles, SourceLogic } from './Source.module';
import React from 'react';

const Source = ({ url, urlClass }) => {
  const { translator } = SourceLogic();
  const classes = useStyles();

  return (
    <div>
      <span
        className={
          urlClass ? `${classes.main} ${classes.urlClass}` : classes.main
        }
      >
        {translator('source')} :
      </span>{' '}
      <span
        className={
          urlClass ? `${classes.url} ${classes.urlClass}` : classes.url
        }
      >
        {url}
      </span>
      <br />
      <br />
    </div>
  );
};

export default Source;
