import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#04679C',
    alignItems: 'center',
    borderRadius: 16,
    ' & .MuiOutlinedInput-input': {
      padding: '11.5px 7px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 446,
      marginLeft: 36,
    },
  },
  whiteContainer: {
    background: '#FFFFFF',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: 16,
  },
  titleContainer: {
    background: '#04679C',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  title: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: 16,
    color: '#FFFFFF',
  },
  textbox: {
    color: '#000!important',
  },
  subtitle: {
    fontFamily: 'MontserratSemiBold',
    fontWeight: 600,
    fontSize: 16,
    color: '#333333',
    textAlign: 'left',
    marginBottom: 3,
  },
  label: {
    fontFamily: 'MontserratSemiBold',
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    textAlign: 'left',
    marginBottom: 3,
  },
  select: {
    border: '1px solid #b9b9b9',
    fontFamily: 'MontserratRegular',
    fontSize: '14px',
    height: '42px',
    padding: '5px',
    width: '100%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      height: '42px',
      padding: '5px',
      width: '100%',
    },
  },
}));

export default useStyles;
