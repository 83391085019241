import React, { useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Card,
  Typography,
  CardContent,
  TextField,
  Box,
  useTheme,
} from '@material-ui/core';
import DefaultTooltip from '../shared/tooltips/toolTip';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { OpenMarketLogic, useStyles } from './OpenMarket.module';
import CardOpenOffer from '../shared/cardOpenOffers/cardOpenOffers';
import Footer from '../footer/Footer';

const OpenMarket = ({ userId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeFilter, setActiveFilter] = useState('all');
  // const values = {};
  const { current, changeState, translator, openCard, values, offers } =
    OpenMarketLogic(userId, activeFilter);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  return (
    <>
      <div>
        <Container className={classes.test}>
          {/* <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.subtitleDashboard}>
              <Typography className={classes.textSubtitleDashboard}>
                Open Market Orders
              </Typography>
            </div>
          </Grid>
        </Grid> */}

          <Grid container className={classes.dashboardStyle}>
            <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
            <Grid item lg={10} md={10} sm={10} xs={12}>
              <Grid className={classes.contentDashboard}>
                <Card
                  className={
                    theme.palette.mode === 'light'
                      ? classes.root
                      : classes.rootLight
                  }
                >
                  <CardContent className={classes.cardContainer}>
                    <Typography className={classes.titleMain}>
                      {translator('openMarket.title')}
                    </Typography>

                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.MoneyBoxAuto}
                    >
                      {values.type === 'BUY' ? (
                        <>
                          <Typography variant="h5" className={classes.text}>
                            {translator('cardEgrnCoin.current')}
                          </Typography>
                          <Typography variant="h2" className={classes.text}>
                            { values.totalCoins}
                          </Typography>
                          <Typography variant="h5" className={classes.text}>
                          {translator('cardEgrnCoin.available')} <span>{values.availableCoins}</span> EGRN
                          </Typography>
                        </>
                      ) : (
                        ''
                      )}

                      {values.type === 'SELL' ? (
                        <>
                          <Typography variant="h5" className={classes.text}>{translator('cardUsd.current')}</Typography>
                          <Typography variant="h2" className={classes.text}>{values.totalUSD}</Typography>
                          <Typography variant="h5" className={classes.text}>
                          {translator('cardUsd.available')} <span>{values.availableUSD}</span> USD
                          </Typography>
                        </>
                      ) : (
                        ''
                      )}
                    </Grid>

                    {values.selectOrder ? (
                      <>
                        <Grid item lg={12} className={classes.info}>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className={classes.MoneyBoxInfo}
                          >
                            <Typography className={classes.infoTitle}>
                            {translator('openMarket.noCoins')} 
                            </Typography>
                            <Typography className={classes.value}>
                              {values.amount}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className={classes.MoneyBoxInfo}
                          >
                            <Typography className={classes.infoTitle}>
                            {translator('openMarket.coinsPrice')} 
                            </Typography>
                            <Typography className={classes.infoValue}>
                              {values.price}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className={classes.MoneyBoxInfo}
                          >
                            <Typography className={classes.infoTitle}>
                              {translator('openMarket.totalUsd')} 
                            </Typography>
                            <Typography className={classes.infoValue}>
                              {values.total}
                            </Typography>
                            <Typography className={classes.infoTitle}>
                              <span>{2}</span>
                              {'% Fee'}
                            </Typography>
                          </Grid>
                        </Grid>


                        {values.hasFunds === false && (
                          <>
                            {values.buyable === 1 && (
                              <Button className={classes.customButton}>
                                {translator('openMarket.transferNeeded')}
                              </Button>
                            )}
                            <Typography className={classes.title}>
                              {' '}
                              {translator('openMarket.notEnought')}
                            </Typography>
                          </>
                        )}

                        {values.hasFunds === true && (
                          <>
                            {values.buyable === 2 && (
                              <Button className={classes.customButton}>
                                {translator('openMarket.putOrder')}
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <Typography className={classes.title}>
                        {' '}
                        {translator('openMarket.noOrderSelected')}
                      </Typography>
                    )}

                    <AccordionDetails className={classes.accordionDetails}>
                      <Typography
                        variant="body1"
                        color="initial"
                        align="left"
                        className={classes.answers}
                      >
                        <ButtonGroup
                          variant="contained"
                          aria-label="contained primary button group"
                          className={classes.customButtonGroup}
                        >
                          <Button
                            onClick={() => handleFilterChange('all')}
                            className={
                              activeFilter === 'all'
                                ? classes.customButtonBold
                                : classes.customButton
                            }
                          >
                            {translator('orders.all')}
                          </Button>
                          <Button
                            onClick={() => handleFilterChange('BUY')}
                            className={
                              activeFilter === 'BUY'
                                ? classes.customButtonBold
                                : classes.customButton
                            }
                          >
                            {translator('orders.buy')}
                          </Button>
                          <Button
                            onClick={() => handleFilterChange('SELL')}
                            className={
                              activeFilter === 'SELL'
                                ? classes.customButtonBold
                                : classes.customButton
                            }
                          >
                            {translator('orders.sell')}
                          </Button>
                        </ButtonGroup>
                      </Typography>
                      <Box className={classes.cardBox}>
                        {offers.map((offer) => (
                          <CardOpenOffer
                            key={offer._id}
                            offerData={offer}
                            onClick={openCard}
                            openFlag={true}
                            deleteFlag={false}
                          />
                        ))}
                      </Box>
                    </AccordionDetails>
                  </CardContent>
                </Card>
          
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={12}></Grid>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.coinSoon}
            ></Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default OpenMarket;
