import { useState, useEffect } from 'react';

const ReadMoreLogic = (props) => {
  const [values, setValues] = useState(props);
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setValues(props);
  }, [props]);

  return {
    values,
    expanded,
    toggleExpanded,
  };
};

export default ReadMoreLogic;
