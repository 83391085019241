import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  customButton: {
    flex: 1,
    height: 28,
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: 18,
    backgroundColor: '#2CDD00',
    color: '#041370',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#2CDD00',
      color: '041370',
    },
  },
}));

export default useStyles;
