import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    margin: '75px auto -80px auto',
    maxWidth: '1350px',
    display: 'flex',
  },
  customLink: {
    textDecoration: 'none',
    color: theme.palette.text.tertiary,
    '&:hover': {
      color: theme.palette.text.tertiary,
      textDecoration: 'none',
    },
  },
  fontLink: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
  },
  root: {
    marginTop: '110px',
  },
  mainHeading: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 36,
    lineHeight: '64px',
    color: theme.palette.tertiary.main,
    marginBottom: '20px',
    marginTop: '20px',

    [theme.breakpoints.up('md')]: {
      fontSize: 44,
    },
  },
  heading: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 28,
    lineHeight: '64px',
    color: theme.palette.tertiary.main,
    marginBottom: '20px',
    marginTop: '70px',

    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    lineHeight: '26px',
    marginBottom: '40px',
    whiteSpace: 'pre-wrap',
    fontFamily: 'Montserrat',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    padding: 0,
    margin: 0,
  },
  anchorEmail: {
    textDecoration: 'none',
    color: theme.palette.link.default,
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
}));

export default useStyles;