class CustomDate {
  get MONTHS() {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
  }
  get ENGLISH_MONTHS() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  constructor(date) {
    this.date = date;
    this.formatted_date = this.buildDate();
    this.english_formatted_date = this.buildEnglishDate();
  }

  buildDate() {
    if (!this.date) return 'Fecha no procesable';
    const [main_date] = this.date.split(' ');
    const date = new Date(main_date);
    return `${date.getUTCDate()} de ${
      this.MONTHS[date.getMonth()]
    } del ${date.getFullYear()}`;
  }

  buildEnglishDate() {
    const [main_date] = this.date.split(' ');
    const date = new Date(main_date);
    let day = date.getUTCDate();
    let month = this.ENGLISH_MONTHS[date.getMonth()];
    let year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
}

export default CustomDate;
