import Joi from 'joi';

const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();

const passwordSchema = Joi.string().required();

export default function validator(values) {
  const errors = {};
  const { error: errorEmail } = emailSchema.validate(values.client_id);
  const { error: errorPassword } = passwordSchema.validate(values.password);

  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido';
  }
  if (errorPassword) {
    errors.error_password = 'Ingresa un password válido.'
  }
  return errors;
};