import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 27,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 1020,
    paddingBottom: 20,
  },
  welcomeBanner: {
    display: 'block',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  title: {
    fontFamily: 'MontserratExtraBold',
    fontWeight: 800,
    fontSize: 56,
    color: theme.palette.text.secondary,
  },
  registerSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: theme.palette.text.tertiary,
    marginLeft: 32,
  },
  textField: {
    width: 544,
    height: 42,
    borderRadius: 8,
    marginBottom: 15,
    border: '1px solid #E0E0E0',
    boxShadow: '0px 2px 4px rgba(51, 51, 51, 0.1)',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.tertiary,
    '&:focus': {
      border: '1px solid rgba(0, 111, 86, 0.5)',
      boxShadow: '1px solid rgba(0, 111, 86, 0.5)',
    },
    "& .MuiInputBase-input": {
      height: 3,
    },
  },
  passwordField: {
    '&:focus': {
      textDecorationStyle: 'none',
    },
    "& .MuiInputBase-input": {
      height: 3,
    },
    '& .MuiOutlinedInput-root': {
      width: 550,
      height: 42,
      borderRadius: 8,
      marginBottom: 0,
      border: '1px solid #E0E0E0',
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: 16,
      color: theme.palette.text.tertiary,
    },
  },
  labels: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 18,
    textAlign: 'left',
    color: theme.palette.text.tertiary,
    height: 24,
    width: 544,
  },
  termsContainer: {
    width: 544,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  termsText: {
    width: 426,
    height: 32,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    textAlign: 'left',
    fontSize: 12,
    display: 'inline',
    marginLeft: 8,
  },
  boldText: {
    fontFamily: 'MontserratSemiBold',
    color: theme.palette.text.tertiary,
  },
  termsTextBolder: {
    width: 426,
    height: 32,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    fontSize: 12,
  },
  singUpOption: {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 14,
  },
  passwordHelper: {
    width: 544,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 12,
    textAlign: 'left',
    // color: '#8C8C8C',
    color: theme.palette.text.tertiary,
    marginTop: 0,
    marginBottom: 16,
    marginLeft: 16,
  },

  icon: {
    color: theme.palette.text.tertiary,
  },
  singUpLink: {
    textDecoration: 'none',
    textDecorationLine: 'none',
    fontFamily: 'MontserratSemiBold',
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.text.tertiary,
  },
  [theme.breakpoints.down('sm')]: {
    welcomeBanner: {
      display: 'none',
    },
    form: {
      alignItems: 'center',
    },
    registerSection: {
      alignItems: 'center',
      marginLeft: 0,
    },
    textField: {
      width: 400,
    },
    passwordField: {
      '& .MuiOutlinedInput-root': {
        width: 406,
      },
    },
    labels: {
      width: 400,
    },
    termsContainer: {
      width: 400,
    },
    passwordHelper: {
      width: 406,
    },
  },
  [theme.breakpoints.down('xs')]: {
    title: {
      fontSize: 48,
      textAlign: 'start',
    },
    textField: {
      width: 252,
    },
    passwordField: {
      '& .MuiOutlinedInput-root': {
        width: 262,
      },
    },
    labels: {
      width: 262,
    },
    passwordHelper: {
      width: 248,
    },
    termsContainer: {
      width: 262,
    },
    termsText: {
      width: 262,
      marginBottom: 24,
    },
  },
}));

export default useStyles;
