import Translator from '../../classes/Translator/Translator';
import { useEffect, useState } from 'react';

const ProjectsLogic = (props) => {
  const { translator } = Translator();
  const { name: nameFromProps, data: dataFromProps } = props;
  const { projectKey } = props;
  const [name, setName] = useState(
    projectKey ? projectKey.name.en : nameFromProps
  );
  const [data, setData] = useState(
    projectKey ? projectKey.data : dataFromProps
  );

  useEffect(() => {
    setName(props.projectKey ? projectKey.name.en : props.name);
    setData(props.projectKey ? projectKey.data : props.data);
  }, [props]);

  return {
    name,
    data,
    translator,
  };
};

export default ProjectsLogic;
