class ProjectKey {
  constructor(data) {
    this.name = data.name;
    this._id = data._id;
    this.project = data.project;
    this.data = data.data;
    this.isImpact = data.isImpact;
    this.action = data.action;
    this.keys = data.keys || {};
    this.impacts = data.impacts || {};
  }

  static findKeyBasedOnName({ keys = [], name }) {
    console.log({ keys, name });
    return keys.find((key) => key.name.en === name);
  }
}

export default ProjectKey;
