class Graphic {
  static async processData({ resources = [] }) {
    const graphic_length = resources.length;
    const actualValue =
      graphic_length > 0 ? resources[graphic_length - 1].value : 0;
    const data = resources.map((graphic) => graphic.value);
    const dates = resources.map((graphic) => graphic.date);

    const seriesValue = [
      {
        name: 'EGRN',
        data,
      },
    ];
    const options = {
      xaxis: {
        type: 'datetime',
        categories: dates,
        axisBorder: { show: true },
      },
    };
    return { actualValue, seriesValue, options };
  }
}

export default Graphic;
