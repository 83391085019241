import React from "react";
import InfoBar from '../infobar/infoBar';
import ButtonDefault from '../shared/button/button'
import useStyles from './HomeInfoBar.styles'
import { useHistory } from 'react-router-dom';

export default function HomeInfoBar() {
    const classes = useStyles();
    const { push } = useHistory();
    return(
        <>
        <div className={classes.container}>
            <div className={classes.infoBarContainer}>
                <InfoBar/>
            </div>
            <ButtonDefault 
            type="buttonGreen" 
            text= "buy" 
            changeView={() => push('/CarbonManagement')}
            />
        </div>
        </>
    )
}