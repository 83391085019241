import Translator from '../../classes/Translator/Translator';
import slide4 from '../slide4/slide4';

const CarouselLogic = () => {
  const { translator } = Translator();
  const items = [slide4()];

  return {
    items,
    translator,
  };
};

export default CarouselLogic;
