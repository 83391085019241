import React from 'react';
import { Card, Typography, CardContent, useTheme } from '@material-ui/core';
import { useStyles, CardEgrnCoinLogic } from './cardEgrnCoin.module';
import DefaultTooltip from '../tooltips/toolTip';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const CardEgrnCoin = (card) => {
  const classes = useStyles();
  const theme = useTheme();
  const { values, goToNewOffer, translator } = CardEgrnCoinLogic(card);

  return (
    <>
      <Card
        className={
          theme.palette.mode === 'light' ? classes.root : classes.rootLight
        }
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DefaultTooltip tooltip={'Egrn coins'} onClick={card.onClick} />
        </div>

        <CardContent className={classes.cardContainer}>
          <Typography className={classes.title}>
            {translator('cardEgrnCoin.current')}
          </Typography>
          <Typography className={classes.value}>{values.value}</Typography>
          <Typography className={classes.title}>
            {' '}
            {translator('cardEgrnCoin.available')} {values.available} EGRN
          </Typography>

          <ButtonGroup
            variant="contained"
            aria-label="contained primary button group"
            className={classes.customButtonGroup}
          >
            <Button
              onClick={() => {
                goToNewOffer();
              }}
              className={classes.customButton}
              disabled={values.available <= 0}
            >
              {translator('cardEgrnCoin.sell')}
            </Button>
          </ButtonGroup>
        </CardContent>
      </Card>
    </>
  );
};

export default CardEgrnCoin;
