class Response {
  constructor(response, Type) {
    this.response = response;
    this.Type = Type;
  }

  wasSuccessfull() {
    const { status } = this.response;
    if (Number(status) >= 200 && Number(status) <= 299) {
      return this;
    } else {
      throw new Error();
    }
  }

  hasContent() {
    return this.wasSuccessfull() && Number(this.response.status) !== 204;
  }

  multiple() {
    const { data } = this.response;
    let { resources, count } = data;
    resources = resources.map((resource) => new this.Type(resource));

    return {
      resources,
      count,
    };
  }

  created() {
    const { data } = this.response;
    const { id = null, resource = null } = data;
    return {
      id,
      resource,
    };
  }

  single() {
    const { data } = this.response;
    return new this.Type(data);
  }
}

export default Response;
