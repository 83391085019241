import { useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';

const FinancesLogic = () => {
  const { translator } = Translator();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return {
    translator,
  };
};

export default FinancesLogic;
