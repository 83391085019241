import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    article: {
        height: '80vh',
        background: 'none !important',
        fontFamily: 'Montserrat !important',
        color: theme.palette.text.tertiary,
        '& h2': {
            background: 'none !important',
            fontFamily: 'Montserrat !important',

            '& span ': {
                color: theme.palette.text.tertiary,
                background: 'none !important',
                fontFamily: 'Montserrat !important',

                '& b ': {
                    background: 'none !important',
                    fontFamily: 'Montserrat !important',

                    '& span ': {
                        color: theme.palette.text.tertiary,
                        background: 'none !important',
                        fontFamily: 'Montserrat !important',
                    }
                }
            }
        },
        '& img': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            color: theme.palette.text.tertiary,
            fontFamily: 'Montserrat !important',

        },
        '& div ': {
            color: theme.palette.text.tertiary,
            fontFamily: 'Montserrat !important',
            background: 'none !important',

            '& span ': {
                color: theme.palette.text.tertiary,
                background: 'none !important',
                fontFamily: 'Montserrat !important',

                '& b ': {
                    background: 'none !important',
                    fontFamily: 'Montserrat !important',

                },
            },

            '& div ': {
                color: theme.palette.text.tertiary,
                background: 'none !important',
                fontFamily: 'Montserrat !important',
                '& div ': {
                    background: 'none !important',
                    fontFamily: 'Montserrat !important',
                    '& span ': {
                        color: theme.palette.text.tertiary,
                        background: 'none !important',
                        fontFamily: 'Montserrat !important',

                        '& a ': {
                            color: `${theme.palette.text.tertiary}!important`,
                            textDecoration: 'underline !important',
                            fontFamily: 'Montserrat !important',

                        },
                        '& span ': {
                            color: theme.palette.text.tertiary,
                            background: 'none !important',
                            fontFamily: 'Montserrat !important',

                        },
                    },
                },
            },
        },
        '& h2 ': {
            color: theme.palette.text.tertiary,
            background: 'none !important',
            fontFamily: 'Montserrat !important',

            '& span ': {
                background: 'none !important',
                color: theme.palette.text.tertiary,
                fontFamily: 'Montserrat !important',

            }
        },
        '& span ': {
            color: theme.palette.text.tertiary,
            fontFamily: 'Montserrat !important',
            background: 'none !important',

            '& a': {
                background: 'none !important',
                fontFamily: 'Montserrat !important',
                textDecoration: 'none',
                color: '#FFFFFF',
            }
        },
        '& p ': {
            '& a': {
                background: 'none !important',
                fontFamily: 'Montserrat !important',
                textDecoration: 'none',
                color: '#FFFFFF',
            },
            '& span ': {
                background: 'none !important',
                color: theme.palette.text.tertiary,
                fontFamily: 'Montserrat !important',
            },
            '& b ': {
                background: 'none !important',
                color: theme.palette.text.tertiary,
                fontFamily: 'Montserrat !important',
            }
        },
        '& blockquote ': {
            '& table': {
                '& tbody': {
                    '& table': {
                        '& tbody': {
                            '& td': {
                                '& span': {
                                    background: 'none !important',
                                    fontFamily: 'Montserrat !important',
                                    '& a': {
                                        fontFamily: 'Montserrat !important',
                                        textDecoration: 'underline !important',
                                        color: `${theme.palette.text.tertiary}!important`,
                                    },
                                },
                            },
                        }
                    }
                },
            },
        }

    },
}));
export default useStyles;
