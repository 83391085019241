import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles, ImpactLogic } from './impact.module';

const Impact = ({ impacts = [] }) => {
  const classes = useStyles();
  const { translator } = ImpactLogic();

  if (impacts.length === 0) return <></>;

  return (
    <Grid container spacing={3} className={classes.containerImpact}>
      <Grid item lg={12} md={12} sm={12} xs={12} align="left">
        <Typography className={classes.titleImpact}>
          {translator('impact.title')}
        </Typography>
      </Grid>

      {impacts?.map((item, index) => (
        <Grid
          item
          lg={3}
          md={3}
          sm={3}
          xs={12}
          key={index}
          className={
            index === 1
              ? classes.gridImpact
              : index === 2
              ? classes.gridImpactRigth
              : classes.gridRoot
          }
        >
          <div className={classes.containerTextImpact}>
            <Typography className={classes.subtitleImpact}>
              {item.title}
            </Typography>

            <Typography className={classes.bodyImpact}>
              {item.subtitle}
            </Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default Impact;
