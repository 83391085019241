import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';
import OrdersService from '../orders/order.service';

const CardTransactionInfoLogic = (onRefresh) => {
  const { translator } = Translator();
  const [loading, setLoading] = useState(false);

  const handleDelete = async (id) => {
    try {
      setLoading(true);
      const response = await OrdersService().deleteOffer(id);
      if (response && response.status === 204) {
        alert('orden borrada exitosamente');
        setLoading(false);
        if (onRefresh) {
          onRefresh();
        }
      } else {
        alert('No se puedo Borrar');
        setLoading(false);
      }
    } catch (err) {
      alert('error al borrar');
      setLoading(false);
      console.error({ err });
    }
  };

  return {
    translator,
    handleDelete,
    loading,
  };
};
export default CardTransactionInfoLogic;
