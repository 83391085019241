import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width:'100%',
    margin: 0, 
    paddingTop: 0,
    lineHeight: 1, 
    marginBottom:50
  },
  confirmOfferContainer:{
    marginTop: 'calc((100vh - 55vh) / 2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 0 6em 0px',
    borderRadius: '10px',
    border: '8px solid #3abc9e',
  },
  confirmOfferRoot:{
    marginBottom: '80px' 
  },

  textBack:{
    fontFamily: 'MontserratSemiBold',
    color: '#05649c',
    fontSize: '14px',
    padding:0,
    margin:0,

  },
 
   titleNewOffer: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontFamily: 'MontserratMedium',
    fontWeight: '550',
    fontSize: '35px',
    paddingTop: 0,
    margin: 0, 
    lineHeight: 1, 
  },
  textNewOffer:{
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontFamily: 'MontserratRegular',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '18px',
    marginBottom:6,
     marginTop:15
  },
  inputConfirmOffer: {
    background: '#FFF',
    height: 35,
    border:'3px solid #045896',
    borderRadius: 10,
    color: '#333',
    width: '92%',

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.borderSelect.borderFocus,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
  },
  helperTextConfirmOffer:{
    width: '92%',
    marginBottom:27,
    textAlign:'center',
    color:'#3abc9e',
    fontFamily: 'MontserratRegular',
  },
  buttonConfirmOffer: {
    width: '100%',
    height: 40,
    fontFamily: 'MontserratRegular',
    border: 'none',
    borderRadius: '20px',
    fontSize: '18px',
    textTransform: 'capitalize',
    color: 'white !important',
    backgroundColor: (props) =>
      props.isButtonEnabled ? '#2CDD00' : '#b1b1b1', 
    '&:hover': {
      backgroundColor: (props) =>
        props.isButtonEnabled ? '#28C300' : '#b1b1b1', 
    },
  },
}));

export default useStyles;
