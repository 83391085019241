import { useEffect, useState } from 'react';
import Graphic from '../../../classes/Graphic/Graphic';
import Translator from '../../../classes/Translator/Translator';

const GraphicExchangeLogic = ({ foreColor, GraphicsService }) => {
  const { translator } = Translator();

  const _options = {
    chart: {
      toolbar: {
        tools: {
          download: false,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
      id: 'basic-bar',
      background: 'transparent',
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    // colors: ['#06419b'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#5CC882'],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 4,
      colors: ['#FFA41B'],
      strokeColors: '#fff',
      strokeWidth: 0,
      hover: {
        size: 7,
      },
    },

    xaxis: {
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          fontFamily: 'Montserrat',
        },
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      seriesName: undefined,
      logarithmic: false,
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        // align: 'right',
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => `${value}`,
        style: {
          fontFamily: 'Montserrat',
        },
      },
    },
  };
  _options.chart.foreColor = foreColor;
  _options.chart.foreColor = foreColor;
  _options.yaxis.axisBorder = { show: true, color: foreColor };
  _options.grid = {
    show: true,
    borderColor: foreColor,
    strokeDashArray: 2,
    opacity: 0.5,
  };
  const [options, setOptions] = useState(_options);
  const [seriesValue, setSeries] = useState([]);
  const [actualValue, setActualValue] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const graphics = await GraphicsService().getData();
        console.log({ graphics });
        if (graphics.resources) {
          const response = await Graphic.processData(graphics);
          console.log({ response });
          setActualValue(response.actualValue);
          setSeries(response.seriesValue);
          setOptions(response.options);
        } else {
          console.error('No hay datos');
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return {
    options,
    seriesValue,
    actualValue,
    translator,
  };
};

export default GraphicExchangeLogic;
