import React from 'react';
import { Container, Typography, Paper } from '@material-ui/core';
import {
  useStyles,
  CardcurrentBalanceLogic,
} from './cardCurrentBalance.module';

export default function CardCurrentBalance(card) {
  const classes = useStyles();
  const { translator, values } = CardcurrentBalanceLogic(card);

  return (
    <Paper className={classes.paper}>
      <Container className={classes.balance}>
        <Typography variant="h5">
          {translator('cardCurrentBalance.titleCenter')}
        </Typography>
        <Typography variant="h2">{values.value || 0}</Typography>
        <Typography variant="h4">
          {translator('cardCurrentBalance.subtitleCenter')}{' '}
          <span>{values.available}</span> {values.asset}
        </Typography>
      </Container>
    </Paper>
  );
}
