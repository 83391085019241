import React from 'react';
import { InputAdornment, IconButton, Grid, Container, OutlinedInput } from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import WelcomeBanner from '../shared/welcomeBanner/welcomeBanner';
import AlertMessage from '../shared/alert/alert';
import Footer from '../footer/Footer';
import { VisibilityOff, Visibility } from '@material-ui/icons';

import {
  useStyles,
  ResetPasswordLogic,
  validator,
  ResetPasswordService,
} from './restorePassword.module';
import CustomError from '../../classes/CustomError/CustomError';

export default function ResetPassword() {
  const classes = useStyles();
  const {
    handleChange,
    handleSubmit,
    history,
    values,
    errors,
    translator,
    setErrors,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleClickShowRepeatedPassword
  } = ResetPasswordLogic(() => set(), validator);

  async function set() {
    const { email, code, new_password } = values;
    const response = await ResetPasswordService().setPassword({
      email,
      code,
      new_password,
    });
    if (!(response instanceof CustomError)) {
      history.push('/code');
    } else {
      setErrors({ ...errors, api_error: response.message });
    }
  }

  return (
    <div>
      <Container maxWidth="lg">
        <Grid container align="center" spacing={1} className={classes.root}>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.welcomeBanner}
          >
            <WelcomeBanner text={translator('restoreWelcome')} />
          </Grid>
          <Grid
            item
            lg={5}
            md={7}
            sm={9}
            xs={12}
            className={classes.containerRestore}
          >
            <h2 className={classes.title}>
              {translator('resetPassword.title')}
            </h2>
            <p className={classes.textExplain}>
              {translator('resetPassword.text')}
            </p>
            <form
              className={classes.form}
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <label className={classes.labels}>
                {translator('resetPassword.email')}
              </label>
              <OutlinedInput
                className={classes.textField}
                type="text"
                name={'email'}
                value={values.email}
                onChange={handleChange}
              />
              <label className={classes.labels}>
                {translator('resetPassword.code')}
              </label>
              <OutlinedInput
                className={classes.textField}
                type="text"
                name={'code'}
                value={values.code}
                onChange={handleChange}
              />
              <label className={classes.labels}>
                {translator('resetPassword.password')}
              </label>
              <OutlinedInput
                className={classes.textFieldPassword}
                type={values.showPassword ? 'text' : 'password'}
                name={'new_password'}
                value={values.new_password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.icon}
                    >
                      {values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.passwordHelper}>
                  {translator('signUp.securityPassword')}

                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {errors && errors.error_email && (
                    <AlertMessage type="error" text={errors.error_email} />
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {errors && errors.error_code && (
                    <AlertMessage type="error" text={errors.error_code} />
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {errors && errors.error_password && (
                    <AlertMessage type="error" text={errors.error_password} />
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>

                  {errors && errors.api_error && (
                    <AlertMessage type="error" text={errors.api_error} />
                  )}
                </Grid>
              </Grid>
              <label className={classes.labels}>
                {translator('resetPassword.confirmPassword')}
              </label>
              <OutlinedInput
                className={classes.textFieldPassword}
                type={values.showRepeatedPassword ? 'text' : 'password'}
                value={values.repeatedPassword}
                name={'repeatedPassword'}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRepeatedPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      className={classes.icon}
                    >
                      {values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />

              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.passwordHelper}>
                  {translator('resetPassword.securityPassword')}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  {errors && errors.error_repeated_password && (
                    <AlertMessage type="error" text={errors.error_repeated_password} />
                  )}
                </Grid>
              </Grid>
              <Grid
                justify="space-between"
                container
                spacing={1}
                className={classes.containerButtons}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <ButtonDefault
                    type="blue"
                    text={translator('restoreGetInto')}
                    isSubmit={true}
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
}
