import { useState, useEffect } from 'react';
import Translator from '../../../classes/Translator/Translator';

const AccordionLogic = (accordion) => {
  const { translator } = Translator();
  const [current, setCurrent] = useState(-1);
  const [values, setValues] = useState({
    ...accordion,
  });
  const [children, setChildren] = useState([]);

  const setClass = () => {
    setValues({ ...values });
  };

  useEffect(() => {
    setClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accordion.children) {
      setChildren(accordion.children);
    }
  }, [accordion]);

  const changeState = (panel) => (e, newValue) => {
    setCurrent(newValue ? panel : -1);
  };
  return {
    current,
    changeState,
    values,
    translator,
    children,
  };
};

export default AccordionLogic;
