import React from 'react';
import { slide4Logic, useStyles } from './slide4.module';
import ButtonDefault from '../shared/button/button';
import { Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function Slide4() {
  const classes = useStyles();
  const { translator, goTo } = slide4Logic();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container className={classes.containerSlide}>
      <div className={classes.contentSlide}>
        <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
        <Grid item lg={11} md={11} sm={11} xs={10}>
          <Typography style={{ paddingBottom: 3 }}>
            <span className={classes.comingSoon}>
              {translator('slide4.coming_soon_one')}
            </span>
          </Typography>
          <Typography paragraph>
            <span className={classes.comingSoon}>
              {translator('slide4.coming_soon_two')}
            </span>
          </Typography>

          {isMobile ? (
            <div>
              <span className={classes.text}> {translator('slide4.text')}</span>
            </div>
          ) : (
            <div>
              <span className={classes.text}>
                {translator('slide4.textOne')}
              </span>
              <span className={classes.text}>
                {translator('slide4.textTwo')}
              </span>
              <span className={classes.text}>
                {translator('slide4.textThree')}
              </span>
            </div>
          )}
          <div className={classes.buttonSlide}>
            <ButtonDefault
              type="buttonBarnerMain"
              text={translator('slide4.moreInfo')}
              changeView={goTo('/carbonCredits')}
            />
          </div>
        </Grid>
        <Grid item lg={0} md={0} sm={0} xs={1}></Grid>
      </div>
    </Grid>
  );
}
