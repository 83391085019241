import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#04679C',
    fontFamily: 'Montserrat',
    fontWeight: 800,
    fontSize: 56,
    "@media only screen and (max-width: 320px)": {
      fontSize: '2em',
    },
    marginTop: 85
  },

  cardTitle: {
    color: '#006F56',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 32,
    textAlign: 'center',
  },
  cardText: {
    color: '#333333',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: '26px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',

  },
  checkImg: {
    height: 150,
    width: 150,
    marginBottom: 30,
    margin: `${theme.spacing(1)}px auto`,
    overflow: 'hidden',
    position: 'relative',
    objectFit: 'contain',
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(1)}px auto`,
      overflow: 'hidden',
      position: 'relative',
      objectFit: 'contain',
      height: 179,
      width: 179,
      objectPosition: 'center',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 30,
    },

  },
  link: {
    textDecoration: 'none',
    textDecorationLine: 'none',
    '&:hover': {
      textDecoration: 'none',
      textDecorationLine: 'none',
    },
  },
  container: {
    background: '#E1F9F2',
    alignItems: 'center',
    borderRadius: 16,
    boxShadow:
      '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 14px rgba(0, 0, 0, 0.15)',
    [theme.breakpoints.up('md')]: {
      width: 554,
    },
    marginBottom: 38,
  },
  card: {
    background: '#E1F9F2',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: 16,
  },
  button: {
    marginBottom: 60
  }
}));

export default useStyles;
