import { useState, useEffect } from 'react';
import Translator from '../../../classes/Translator/Translator';
import OrdersService from './order.service';

const OrdersLogic = (userId, activeFilter) => {
  const { translator } = Translator();
  const [current, setCurrent] = useState(-1);
  const [children, setChildren] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedStates, setSelectedStates] = useState(['open']);


  const stateOptions = [
    { key: 'open', label: translator('orders.open') },
    { key: 'pending', label: translator('orders.pending') },
    { key: 'done', label:  translator('orders.done')},
    { key: 'close', label: translator('orders.close') },
  ];

  const handleStateChange = (event) => {
    const newStates = event.target.value;
    setSelectedStates(newStates);
  };
  

  const fetchOffers = async () => {
    const service = OrdersService();
    const data = await service.getData(userId, activeFilter, selectedStates);
    if (Array.isArray(data.resources)) {
      setOffers(data.resources);
    } else {
      console.error('Error fetching offers:', data);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [userId, activeFilter, selectedStates]);

  const changeState = (panel) => (e, newValue) => {
    setCurrent(newValue ? panel : -1);
  };

  const handleRefreshOffers = () => {
    fetchOffers();
  };

  return {
    current,
    changeState,
    translator,
    children,
    offers,
    handleRefreshOffers,
    stateOptions,
    selectedStates,
    handleStateChange
  };
};

export default OrdersLogic;
