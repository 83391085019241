import React from 'react';
import FlagJson from './FlagsData.json';
import { Select as AntSelect } from 'antd';

const { Option } = AntSelect;

class Flags {
  static flagData() {
    let flags = [];
    for (let key in FlagJson) {
      if (FlagJson.hasOwnProperty(key)) {
        let value = FlagJson[key];
        flags.push(
          <Option value={`country-${value.code}`}>
            <img src={`/flags/${value.code}.svg`} alt="flag" width={20} />
            &nbsp;
            {value.name}
          </Option>
        );
      }
    }
    return flags;
  }

  static flagDataArray() {
    let flags = [];
    for (let key in FlagJson) {
      if (FlagJson.hasOwnProperty(key)) {
        let value = FlagJson[key];
        flags.push({
          value: `country-${value.code}`,
          label: value.name,
          flag: `/flags/${value.code}.svg`,
        });
      }
    }
    return flags;
  }
}

export default Flags;
