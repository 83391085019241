import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    fontFamily: 'MontserratMedium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '48px',
    textAlign: 'center',
    letterSpacing: '-1px',
  },
  url: {
    fontFamily: 'MontserratMedium',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '48px',
    textAlign: 'center',
    letterSpacing: '-1px',
    textDecoration: 'none',
    color: theme.palette.link.default,
    wordBreak: 'break-word',
  },
  urlClass: {
    color: '#FFFFFF',
  },
}));

export default useStyles;
