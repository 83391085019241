import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { AccordionLogic, useStyles, PropTypes } from './accordions.module';

const Accordions = (accordion) => {
  const classes = useStyles();
  const { current, changeState, values, translator, children } = AccordionLogic(accordion);

  return (
    <div>
      <Accordion
        elevation={0}
        classes={{ root: classes.MuiAccordionroot }}
        expanded={current === 0}
        onChange={changeState(0)}
      >
        <AccordionSummary
          className={classes.root}
          expandIcon={
            current === 0 ? (
              <RemoveCircleOutlineIcon className={classes.removeCircleOutlineIcon} />
            ) : (
              <AddCircleOutlineIcon className={classes.addCircleOutlineIcon} />
            )
          }
          aria-controls={values.ariaControls}
          id={values.id}
          classes={{ expanded: classes.expandedPanel }}
        >
          <Typography
            variant="h5"
            color="initial"
            align="left"
            paragraph
            className={classes.questions}
          >
            {translator(`${values.questions}`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            color="initial"
            align="left"

            className={classes.answers}
          >
            {translator(`${values.answers}`)}

          </Typography>

        </AccordionDetails>
        <AccordionDetails>
          <Link className={classes.link} to="/restore">

            <Typography
              variant="body1"
              color="initial"
              align="left"
              dangerouslySetInnerHTML={{ __html: translator(`${values.answers1}`) }}
              className={classes.answers}
            >

            </Typography>
          </Link>
        </AccordionDetails>
        <AccordionDetails>
          {values.id === 2 ? (

            <div
            >
              {children}
            </div>
          ) : null}

        </AccordionDetails>
        <AccordionDetails>
          <Typography
            variant="body1"
            color="initial"
            align="left"

            className={classes.answers}
          >
            {/* {values.answers2} */}
            {translator(`${values.answers2}`)}

          </Typography>
        </AccordionDetails>
      </Accordion>
    </div >
  );
};
Accordions.propTypes = {
  ariaControls: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  questions: PropTypes.string,
  answers: PropTypes.string,
  answers1: PropTypes.string,
  answers2: PropTypes.string,
};

export default Accordions;
