import { Redirect, Route } from 'react-router-dom';
import PrivateRouteLogic from './privateRoute.logic';

export function PrivateRoute({ component: Component, ...rest }) {
  const { state, translator } = PrivateRouteLogic();

  return (
    <Route
      {...rest}
      render={(props) => {
        switch (state) {
          case 'loading':
            return <div>{translator('privateRoute.loading')}</div>;
          case 'logged':
            return <Component {...props} />;
          case 'redirect':
            return (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            );
          default:
            return <div>{translator('privateRoute.loading')}</div>;
        }
      }}
    />
  );
}
