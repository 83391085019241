import Joi from 'joi';

const emailSchema = Joi.string()
  .email({ tlds: { allow: false } })
  .required();

const codeSchema = Joi.string().required();

const pattern =
  '^(?=.*?[A-Z])(?=.*?[0-9])(?=[^\\d\\s]*\\d)(?=\\w*[\\W_])\\S{8,}$';

const newPasswordSchema = Joi.string()
  .pattern(new RegExp(pattern, 'm'))
  .required();

export default function validator(values) {
  const errors = {};
  const { new_password, repeatedPassword } = values;

  const { error: errorEmail } = emailSchema.validate(values.email);
  const { error: errorCode } = codeSchema.validate(values.code);
  const { error: errorNewPassword } = newPasswordSchema.validate(
    values.new_password
  );

  if (errorEmail) {
    errors.error_email = 'Ingresa un email válido.';
  }

  if (errorCode) {
    errors.error_code = 'Ingresa un código.';
  }

  if (errorNewPassword) {
    errors.error_password = 'Ingresa un password válido.';
  }
  if (
    !new_password ||
    !repeatedPassword ||
    new_password.localeCompare(repeatedPassword) !== 0
  ) {
    errors.error_repeated_password = 'Las contraseñas no coinciden.';
  }
  return errors;
}
