import Joi from 'joi';

const counterSchema = Joi.number().min(1).required();

export default function validator(values) {
  const errors = {};
  const { error: errorCounter } = counterSchema.validate(values.counter);
  if (errorCounter) {
    errors.errorCounter = 'la inversion minima es de un EGRN coin';
  }
  return errors;
}
