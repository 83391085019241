import { useEffect, useState } from 'react';
import Translator from '../../../classes/Translator/Translator';

const ProjectItemLogic = (props, getCommissionByType) => {
  const { translator } = Translator();
  const { project = {} } = props;
  const { setCoins: setCoinsInParent } = props;
  const { setType: setTypeInParent } = props;
  const [values, setValues] = useState({
    project,
    type: 'buy_bonds',
    counter: project.forwards_minimum || 1,
    total: 0,
    subtotal: 0,
    fee: 0,
    forwards_minimum: project.forwards_minimum,
    price: project.forwards_price,
  });

  // commission
  const [commission, setCommission] = useState({
    rateForCalculation: 1 + project.fee_egrn,
    parseRate: project.fee_egrn,
  });
  useEffect(() => {
    const counter =
      values.counter < props.project.forwards_minimum
        ? props.project.forwards_minimum
        : values.counter;

    setCommission({
      rateForCalculation: 1 + project.fee_egrn,
      parseRate: project.fee_egrn,
    });

    setValues({
      ...values,
      project: props.project,
      price: props.project.forwards_price,
      forwards_minimum: props.project.forwards_minimum,
      total:
        props.project.forwards_price * counter * (1 + props.project.fee_egrn),
      fee: props.project.forwards_price * counter * props.project.fee_egrn,
      subtotal: props.project.forwards_price * counter,
      counter,
    });
    setCoinsInParent(counter);
     if (project.is_available_for_egrn) {
      setValues((old) => ({ ...old, type: 'buy_bonds' }));
      setTypeInParent('buy_bonds');
    } else if (project.is_available_for_nft) {
      setValues((old) => ({ ...old, type: 'buy_nft' }));
      setTypeInParent('buy_nft');
    }
  }, [props.project]);
 

  const handleSubtract = (e) => {
    e.preventDefault();
    let counter = values.counter - 1 < 0 ? 0 : values.counter - 1;
    counter =
      counter < props.project.forwards_minimum
        ? props.project.forwards_minimum
        : counter;
    setValues({
      ...values,
      counter,
      total: values.price * counter * commission.rateForCalculation,
      fee: values.price * counter * commission.parseRate,
      subtotal: values.price * counter,
    });
    setCoinsInParent(counter);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const counter = values.counter + 1;
    setValues({
      ...values,
      counter,
      total: values.price * counter * commission.rateForCalculation,
      fee: values.price * counter * commission.parseRate,
      subtotal: values.price * counter,
    });
    setCoinsInParent(counter);
  };

  const handleRadioChange = (event) => {
    const type = event.target.value;
    setTypeInParent(type);
    setValues((old) => ({ ...old, type }));
  };



  return {
    values,
    handleSubtract,
    handleAdd,
    handleRadioChange,
    setCoinsInParent,
    translator,
  };
};

export default ProjectItemLogic;
