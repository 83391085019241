import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Grid,
  Box,
  Breadcrumbs,
  Container,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import Footer from '../footer/Footer';
import { useStyles, EconomyLogic } from './economy.module';

const Economy = () => {
  const classes = useStyles();
  const { values, translator } = EconomyLogic();

  return (
    <div>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>Home</Typography>
          </Link>
          <Link className={classes.customLink} to="/blog">
            <Typography className={classes.fontLink}>Developing a green economy</Typography>
          </Link>
        </Breadcrumbs>
      </Box>
      <Container maxWidth="md">
        <main className={classes.root}>
          <Grid container={classes.root}>
            <Grid item lg={12} sm={12} xs={12}>
              <Typography
                className={classes.mainHeading}
                variant="h2"
                align="center"
                paragraph  
              >
                {translator('economy.firstSectionHeading')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                {translator('economy.text')}
              </Typography>
              <Typography
                className={classes.heading}
                variant="h2"
                align="center"
                paragraph  
              >
                {translator('economy.firstSectionHeadingTwo')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                {translator('economy.firstParagraph')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                {translator('economy.secondParagraph')}
                <strong>{translator('economy.secondBoldParagraph')}</strong>
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                {translator('economy.thirdParagraph')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                <strong>{translator('economy.firstSubtitle')}</strong>
                {translator('economy.firstSubtitleText')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                <strong>{translator('economy.secondSubtitle')}</strong>
                {translator('economy.secondSubtitleText')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                <strong>{translator('economy.thirdSubtitle')}</strong>
                {translator('economy.thirdSubtitleText')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                <strong>{translator('economy.fourthSubtitle')}</strong>
                {translator('economy.fourthSubtitleText')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                <strong>{translator('economy.fifthSubtitle')}</strong>
                {translator('economy.fifthSubtitleText')}
              </Typography>
              <Typography 
              className={classes.text} 
              paragraph 
              align="center"
              >
                {translator('economy.lastParagraph')}
              </Typography>
            </Grid>
            <Grid item lg={12} sm={12} xs={12}>
            <Typography
                className={classes.mainHeading}
                variant="h2"
                align="center"
                paragraph  
              >
                {translator('economy.secondSectionHeading')}
              </Typography>
              <br />
              <br />
              <img 
                className={classes.img}
                alt="eGreen Platform"
                src={values.economyImg}
              />
              <br />
              <Typography className={classes.text} paragraph align="center">
                {translator('economy.secondSectionText')}
                <a
                  className={classes.anchorEmail}
                  href="mailto:hello@egreen.com"
                >
                  {translator('economy.secondSectionEmail')}
                </a>
              </Typography>
              <br />
            </Grid>
          </Grid>
        </main>
      </Container>
      <Footer />
    </div>
  )
};

export default Economy;