import React from "react";
import {useStyles, InfoBarLogic, InfoBarService} from './infobar.module'
import { Grid, Typography } from "@material-ui/core";


export default function InfoBar  (props) {
    const classes = useStyles();
    const { values, translator } = InfoBarLogic((props) =>
    InfoBarService().getData()
  );
    return (
      <Grid container className={classes.infoBarContainer}>
        <Grid item lg={3} md={3} sm={12} xs={12} className={classes.infoBarLeft}>
          <Typography className={classes.infoBar_number}>6.64 USD</Typography>
          <Typography className={classes.infoBar_text}>
            {translator('current_price')}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} className={classes.infoBar}>
          <Typography className={classes.infoBar_number}>
            {values.stats.co2_offset} tons
          </Typography>
          <Typography className={classes.infoBar_text}>
            {translator('co2_impact_investing')}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} className={classes.infoBar}>
          <Typography className={classes.infoBar_number}>
            {values.stats.total_projects}
          </Typography>
          <Typography className={classes.infoBar_text}>
            {values.stats.total_projects > 1
              ? translator('projects')
              : translator('project')}
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12} className={classes.infoBarRight}>
          <Typography className={classes.infoBar_number}>327k tons</Typography>
          <Typography className={classes.infoBar_text}>
            {translator('co2_offset')}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  