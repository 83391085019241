import React from 'react';
import { Card, Typography, CardContent, useTheme } from '@material-ui/core';
import { useStyles, CardDashboardLogic } from './cardDashboard.module';
import FullScreenDialog from '../../Dialog/FullScreenDialog';
import NftTable from '../NftTable/NftTable';
import ButtonDefault from '../button/button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DefaultTooltip from '../tooltips/toolTip';

const CardDashboard = (card) => {
  const classes = useStyles();
  const theme = useTheme();
  const { values, openDialog, setOpenDialog } = CardDashboardLogic(card);
  const is_sbt =
    Array.isArray(values.nfts) && values.nfts.length > 0
      ? values.nfts[0].is_sbt
      : false;

  // console.log(is_sbt);
  return (
    <>
      {openDialog && (
        <FullScreenDialog
          openFromParent={openDialog}
          setOpenFromParent={setOpenDialog}
          children={
            <NftTable
              title={values.title}
              nfts={card.nfts}
              user={card.user}
              is_sbt={is_sbt}
            />
          }
        ></FullScreenDialog>
      )}

      <Card
        onClick={values.titleAmount ? null : () => setOpenDialog(true)}
        className={
          theme.palette.mode === 'light' ? classes.root : classes.rootLight
        }
      >
        {values.title ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <DefaultTooltip tooltip={values.tooltip} />
          </div>
        ) : null}
        <CardContent className={classes.cardContainer}>
          <Typography
            className={values.titleAmount ? classes.titleAmount : classes.title}
          >
            {values.title ? values.title : values.titleAmount} {values.amount}
          </Typography>
          <Typography className={classes.value}>{values.value}</Typography>
          {values.blockchain ? (
            <div className={classes.buyBlockchain}>
              <ButtonDefault type="buttonAutoTransparent">
                {values.blockchain}
              </ButtonDefault>
            </div>
          ) : values.buy ? (
            <div className={classes.buyButton}>
              <div className={classes.buyButtonItem}>
                <ButtonDefault
                  changeView={values.changeView}
                  type="backgroundgreen"
                  text={values.buy}
                >
                  <ArrowRightAltIcon />
                </ButtonDefault>
              </div>
            </div>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
};

export default CardDashboard;
