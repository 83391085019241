import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonDefault from '../button/button';
import { PricesCardLogic, useStyles } from './pricesCard.module';
import { useHistory } from 'react-router-dom';

const PricesCard = (props) => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const bullWhite = <span className={classes.bulletWhite}>•</span>;
  const bullMain = <span className={classes.bulletMain}>•</span>;
  const { state, setState, values, translator } = PricesCardLogic(props);
  const { push } = useHistory();

  return (
    <div>
      <Card
        className={`${classes.root} ${values.main ? classes.pricingMain : ''}`} 
        classes={{ root: state.raised ? classes.cardHovered : '' }}
        onMouseOver={() => setState({ raised: true, shadow: 3 })}
        onMouseOut={() => setState({ raised: false, shadow: 1 })}
        zdepth={state.shadow}
      >
        <CardContent>
          <Typography className={`${classes.title} ${values.main ? classes.colorWhite : ''}`} gutterBottom>
            {values.type}
          </Typography>
          <Typography className={`${classes.subtitle} ${values.main ? classes.colorWhite : ''}`} gutterBottom>
            {/* be{bull}nev{bull}o{bull}lent */}
            {values.employees}
          </Typography>
          <Typography className={classes.amount} gutterBottom>
            {values.price}
          </Typography>
          <div className={classes.list}>
            {values.main ? bullWhite: bull}
            <Typography className={`${classes.center} ${values.main ? classes.colorWhite : ''}`} >
              Plus Setup {values.plusSetup}
            </Typography>
          </div>
          {props.perYear && (
            <>
              <div className={classes.list}>
              {values.main ? bullMain: bull}
                <Typography className={`${classes.center} ${values.main ? classes.colorWhite : ''}`} >
                  {translator('pricing.OtherPaymentsOptions')}
                </Typography>
              </div>

              <div className={classes.list}>
                {values.main ? bullWhite: bull}
                <Typography className={`${classes.center} ${values.main ? classes.colorWhite : ''}`} gutterBottom>
                  {translator('pricingCard.1PayYear')} {values.payYear}
                </Typography>
              </div>
            </>
          )}
        </CardContent>
        {/* <CardActions>
          <div className={classes.button}>
            <ButtonDefault
              type="buttonGreen"
              text={translator('pricing.buttonText')}
              changeView={() => push('/demoForm')}
            />
          </div>
        </CardActions> */}
      </Card>
    </div>
  );
};

export default PricesCard;
