import Storage from '../../../classes/Storage/Storage';
import { useEffect, useState } from 'react';

const UseLogic = (props, uploadProfilePicture, getProfilePicture) => {
  const [values, setValues] = useState({
    ...props,
  });

  const upload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      let data = new FormData();
      data.append('file', file);
      data.append('type', 'profile_picture');
      data.append('id', new Storage().setKey('id').getItem());
      data = await uploadProfilePicture(data);
      const profile_picture = await getProfilePicture(data.id);
      const user = { ...values.user, profile_picture };
      setValues({ ...values, user });
    }
  };

  useEffect(() => {
    setValues({ ...props });
  }, [props]);

  return {
    values,
    upload,
  };
};

export default UseLogic;
