import Formatter from '../Formatter/Formatter';
import CustomDate from '../CustomDate/CustomDate';

class Offer {
  constructor({
    type,
    user,
    status,
    date,
    amount,
    price,
    total_without_fee,
    fee,
    total,
    _id,
    id,
  }) {
    this.type = type;
    this.user = user;
    this.status = status;
    this.date = date;
    this.amount = Formatter.formatNumberExchange(amount);
    this.price = Formatter.formatNumberExchange(price);
    this.total_without_fee = total_without_fee;
    this.fee = fee;
    this.total = total;
    this._id = _id || id;
    this.id = _id || id;
  }

  get formattedDate() {
    return new CustomDate(this.date).formatted_date;
  }

  get totalCoins() {
    return this.amount <= 1
      ? `${this.amount} EGRN COIN`
      : `${this.amount} EGRN COINS`;
  }

  get formattedTypeOfTransaction() {
    return `${this.totalCoins}`;
  }

  get subtotal() {
    return this.total_without_fee;
  }

  get feeValue() {
    return this.fee;
  }

  get isOffer() {
    return true;
  }
}

export default Offer;
