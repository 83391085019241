import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles, eddy, lidar, InfoSensorsLogic } from './infoSensors.module';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const InfoSensors = (props) => {
  const { translatedInfo } = props;
  const classes = useStyles();
  const { translator } = InfoSensorsLogic();

  return (
    <>
      <Grid container spacing={1} className={classes.containerInfoSensors}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography className={classes.titleInfoSensors}>
             {translator('infoSensors.title')}
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.itemInfoSensors}
        >
          <Typography className={classes.subtitleInfoSensors}>
            <ArrowRightAltIcon className={classes.iconInfoSensors} />
            {translatedInfo.first_title_sensors}
          </Typography>
          <Typography className={classes.bodyInfoSensors}>
            {translatedInfo.first_description_sensors}
          </Typography>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.itemImageInfoSensors}
        >
          <img
            src={translatedInfo.first_image_sensors}
            alt="Info Sensors"
            className={classes.imageInfoSensors}
          />
        </Grid>

        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.itemImageInfoSensors}
        >
          <img
            src={translatedInfo.second_image_sensors}
            alt="Info Sensors Project"
            className={classes.imageInfoSensors}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className={classes.itemInfoSensors}
        >
          <Typography className={classes.subtitleInfoSensors}>
            <ArrowRightAltIcon className={classes.iconInfoSensors} />
            {translatedInfo.second_title_sensors}
          </Typography>
          <Typography className={classes.bodyInfoSensors}>
            {translatedInfo.second_description_sensors}
          </Typography>
        </Grid>

        {translatedInfo.third_title_sensors &&
          translatedInfo.third_description_sensors &&
          translatedInfo.third_image_sensors && (
            <>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.itemInfoSensors}
              >
                <Typography className={classes.subtitleInfoSensors}>
                  <ArrowRightAltIcon className={classes.iconInfoSensors} />
                  {translatedInfo.third_title_sensors}
                </Typography>
                <Typography className={classes.bodyInfoSensors}>
                  {translatedInfo.third_description_sensors}
                </Typography>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className={classes.itemImageInfoSensors}
              >
                <img
                  src={translatedInfo.third_image_sensors}
                  alt="Info Sensors"
                  className={classes.thirdImageInfoSensors}
                />
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
};

export default InfoSensors;
