import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    margin: '48px 0px',
    minWidth: 280,
    height: '100%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    border: '2px solid #00DD9E',
    borderRadius: 20,
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  paperContainer:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'space-between',
    flexWrap: 'wrap',
    // padding: '0px',
  },
  MoneyBox: {
    width: 180,
    borderRadius: 20,
    border: '2px solid #00DD9E',
    // margin: 12,
    padding: 12,
  },
  MoneyBoxAuto:{
    width: '100%',
    borderRadius: 20,
    border: '2px solid #00DD9E',
    margin: 12,
    padding: 12,
  },
  customButton: {
    width: '97%',
    height: 46,
    fontFamily: 'MontserratBold',
    border: '0.1px solid #263238 !important',
    borderRadius: '20px',
    color: 'white !important',
    fontSize: '18px',
    backgroundColor: (props) =>
      props.isButtonDisabled ? '#b1b1b1' : '#2CDD00',
    '&:hover': {
      backgroundColor: (props) =>
        props.isButtonDisabled ? '#b1b1b1' : '#28C300',
    },
  },
  inputField: {
    background: '#FFF',
    margin: '24px 0px',
    height: 42,
    borderRadius: 6,
    color: '#333',
    fontFamily: 'Montserrat !important',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.borderSelect.borderFocus,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `0.1px solid ${theme.palette.borderSelect.border}`,
    },
    '& .MuiInputBase-input': {
      height: 4,
    },
  },
  textCardOffer:{
    fontFamily: 'Montserrat',
   marginTop:15,
   fontSize: '1.3rem'
  },
  moneyusdAmount:{
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    maxWidth: '100%',
  }
}));

export default useStyles;
