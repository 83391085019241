import Translator from '../../../classes/Translator/Translator';

const ProjectCarrousellLogic = () => {
  const { translator } = Translator();

  return {
    translator,
  };
};

export default ProjectCarrousellLogic;
