import URL from '../../classes/Url/URL';
import BlogPost from '../../classes/Blog/BlogPost';
import axios from 'axios';

const BlogPostDetailsService = () => {
  const getPostById = async (id) => {
    const url = `${URL.URL_SERVER}/posts/${id}`;
    const response = await axios.get(url);
    const post = new BlogPost(response.data);
    return post;
  };

  return {
    getPostById,
  };
};

export default BlogPostDetailsService;
