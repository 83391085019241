import React, { useState, useEffect } from 'react';
import {
  Card,
  Typography,
  CardContent,
  useTheme,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Chip,
  MenuItem,
} from '@material-ui/core';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  OrdersLogic,
  useStyles,
  PropTypes,
} from './orders.module';
import CardOpenOffer from '../cardOpenOffers/cardOpenOffers';

const Orders = ({ userId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeFilter, setActiveFilter] = useState('all');
  const { current, changeState, translator, offers, handleRefreshOffers, stateOptions, selectedStates, handleStateChange } = OrdersLogic(
    userId,
    activeFilter
  );
  const [isLoading, setIsLoading] = useState(true);
  const [hasOrders, setHasOrders] = useState(offers.length > 0);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const filteredOffers = offers.filter((offer) => {
    if (activeFilter === 'all') return true;
    return offer.type === activeFilter;
  });

  useEffect(() => {
    if (offers.length > 0) {
      setHasOrders(true);
    }
    setIsLoading(false);
  }, [offers]);

  return (
    <Card
      className={
        theme.palette.mode === 'light' ? classes.root : classes.rootLight
      }
    >
      <CardContent className={classes.cardContainer}>
        <Accordion
          elevation={0}
          classes={{ root: classes.MuiAccordionroot }}
          expanded={current === 0}
          onChange={changeState(0)}
        >
          <AccordionSummary
            className={classes.root}
            expandIcon={
              <KeyboardArrowDownIcon
                className={classes.keyboardArrowDownIcon}
              />
            }
            classes={{ expanded: classes.expandedPanel }}
          >
            <Typography
              variant="h5"
              color="initial"
              align="left"
              paragraph
              className={classes.questions}
            >
              {translator('orders.title')}
            </Typography>
          </AccordionSummary>


          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.status}>
              <FormControl className={classes.formControl}>
                <InputLabel id="state-select-label">
                  {translator('transactions.state')}
                </InputLabel>
                <Select
                  className={classes.selectedTransactions}
                  labelId="state-select-label"
                  id="state-select"
                  multiple
                  value={selectedStates}
                  onChange={handleStateChange}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((key) => (
                        <Chip
                          key={key}
                          label={
                            stateOptions.find((option) => option.key === key)
                              ?.label
                          }
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                >
                  {stateOptions.map((option) => (
                    <MenuItem
                      key={option.key}
                      value={option.key}
                      className={classes.menuItemTransactions}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          <AccordionDetails className={classes.accordionDetails}>
            {hasOrders && (
              <ButtonGroup
                variant="contained"
                aria-label="contained primary button group"
                className={classes.customButtonGroup}
              >
                <Button
                  onClick={() => handleFilterChange('all')}
                  className={
                    activeFilter === 'all'
                      ? classes.customButtonBold
                      : classes.customButton
                  }
                >
                  ALL
                </Button>
                <Button
                  onClick={() => handleFilterChange('BUY')}
                  className={
                    activeFilter === 'BUY'
                      ? classes.customButtonBold
                      : classes.customButton
                  }
                >
                  {translator('orders.buy')}
                </Button>
                <Button
                  onClick={() => handleFilterChange('SELL')}
                  className={
                    activeFilter === 'SELL'
                      ? classes.customButtonBold
                      : classes.customButton
                  }
                >
                  {translator('orders.sell')}
                </Button>
              </ButtonGroup>
            )}
            <Box className={classes.cardBox}>
              {filteredOffers.length === 0 ? (
                <Typography variant="body1" color="textSecondary" align="center">
                  {translator('orders.withoutOrders')}
                </Typography>
              ) : (
                filteredOffers.map((offer) => (
                  <CardOpenOffer key={offer._id} offerData={offer} onRefresh={handleRefreshOffers} />
                ))
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

Orders.propTypes = {
  ariaControls: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  questions: PropTypes.string,
  answers: PropTypes.string,
  answers1: PropTypes.string,
  answers2: PropTypes.string,
};

export default Orders;

