import React from 'react';
import { Link } from 'react-router-dom';
import {
  Typography,
  Container,
  Box,
  Breadcrumbs,
  Grid,
  CardMedia,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import ButtonDefault from '../shared/button/button';
import Footer from '../footer/Footer';
import { useStyles, ComingLogic, egreenWorld } from './coming.module';

const Coming = () => {
  const { heading, body, history, translator } = ComingLogic();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.breadcrumbContainer}>
        <Breadcrumbs
          separator={<NavigateNext fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link className={classes.customLink} to="/">
            <Typography className={classes.fontLink}>
              {translator('coming.home')}
            </Typography>
          </Link>
          <Link className={classes.customLink} to="/coming">
            <Typography className={classes.fontLink}>
              {translator('coming.exchange')}
            </Typography>
          </Link>
        </Breadcrumbs>
      </Box>
      <Container maxWidth="md">
        <Grid container align="center" spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography className={classes.heading} paragraph>
              {heading}
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="sm">
        <Grid container align="center" spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CardMedia
              className={classes.image}
              image={egreenWorld}
              title={egreenWorld}
            />
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.containerBody}
          >
            <Typography className={classes.body} gutterBottom>
              {body}
            </Typography>
            <Grid
              justify="space-between"
              container
              spacing={1}
              className={classes.containerButtons}
            >
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Link to="/" className={classes.link}>
                  <ButtonDefault
                    text={translator('coming.go_home')}
                    type="whitegreen"
                  ></ButtonDefault>
                </Link>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <ButtonDefault
                  text={translator('coming.back')}
                  type="blue"
                  changeView={history.goBack}
                ></ButtonDefault>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Coming;
