class Formatter {
  static round(number, fixed = 2) {
    return !number ? 0 : Number(number.toFixed(fixed));
  }

  static truncateString(string, numberOfChars) {
    if (typeof string !== 'string' || typeof numberOfChars !== 'number') {
      return 'No hash...';
    }

    if (numberOfChars <= 0) {
      return 'No hash...';
    }

    const trimmedString = string.trim();
    return trimmedString.length <= numberOfChars
      ? trimmedString
      : `${trimmedString.slice(0, numberOfChars)}...`;
  }

  static formatNumber(number) {
    if (typeof number !== 'number' && typeof number !== 'string' ) {
      return 'N/A'
    }
    if (typeof number === 'string') {
      number = parseFloat(number);
      if (isNaN(number)) {
        return 'N/A';
      }
    }
    if (!isFinite(number)) {
      return 'N/A';
    }
    const [integerPart, decimalPart] = number.toString().split('.');
    const formattedInteger = new Intl.NumberFormat('en-US').format(integerPart);
    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }

  static formatNumberExchange(number, fixed = 2) {
    number = parseFloat(number);
    return !number ? 0 : (number.toFixed(fixed));
    }
    
  }


export default Formatter;
