import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { useStyles, YourInvestmentLogic } from './yourInvestment.module';
import Translator from '../../../classes/Translator/Translator';
import Transaction from '../../../classes/Transaction/Transaction';

const YourInvestment = (props) => {
  const classes = useStyles();
  const { values, translator } = YourInvestmentLogic(props);

  return (
    <React.Fragment>
      <Grid container className={classes.container}>
        <Grid item lg={12} sm={12} xs={12} className={classes.titleContainer}>
          <Typography className={classes.yourInvestementTitle} align="left">
            {translator('billing_your_invesment_title')}
          </Typography>
        </Grid>
        <Grid className={classes.whiteContainer} item lg={12} sm={12} xs={12}>
          <Currency {...values} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xs={12}
          className={classes.titleContainerPayment}
        >
          <Typography className={classes.yourInvestementSubTitle} align="left">
            {translator('billing_your_invesment_first_subtitle')}
          </Typography>
        </Grid>
        <Grid className={classes.whiteContainer} item lg={12} sm={12} xs={12}>
          <Project {...values} />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xs={12}
          className={classes.titleContainerPayment}
        >
          <Typography className={classes.yourInvestementSubTitle} align="left">
            {translator('billing_your_invesment_second_subtitle')}
          </Typography>
        </Grid>
        <Grid
          className={classes.whiteContainerPayment}
          item
          lg={12}
          sm={12}
          xs={12}
        >
          <Payment {...values} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default YourInvestment;

const Currency = (values) => {
  const classes = useStyles();
  const { translator } = Translator();

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography className={classes.header} align="left">
          {/*translator('billing_your_invesment_egrn_text')*/}
          {values.transaction.type === 'buy_bonds'
            ? 'Product - Forward'
            : 'Product - Carbon Credits'}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container className={classes.containerTotal}>
          <p className={classes.total}>
            {translator('billing_your_invesment_total')}
          </p>
          <p className={classes.total}>
            {`${values.transaction.total_coins} ${
              Transaction.DICTIONARY_TYPES[values.transaction.type]
            }`}
            {/*values.transaction.total_coins > 1
              ? `${values.transaction.total_coins} ${translator(
                  'billing_your_invesment_total_coins'
                )}`
              : `${values.transaction.total_coins} ${translator(
                  'billing_your_invesment_total_coin'
                )}` */}
          </p>
        </Container>
      </Grid>
    </Grid>
  );
};

const Project = (values) => {
  const classes = useStyles();
  const { translator } = Translator();

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={12} sm={12} xs={12}>
        <img
          className={classes.projectimg}
          src={values.project.image}
          alt="img_project"
        />
      </Grid>
      <Grid item lg={8} md={12} sm={12} xs={12}>
        <p className={classes.subtitle}>{values.project.title_project}</p>
        <p className={classes.headerProject}>{`${
          values.transaction.co2_emission
        } ${values.transaction.type === 'buy_bonds' ? 'EGRN' : 'Tn'}`}</p>
        <p className={classes.headerProject}>{`USD ${parseFloat(
          values.project.ton_price / 100
        ).toFixed(2)} each`}</p>
      </Grid>
    </Grid>
  );
};

const Payment = (values) => {
  const classes = useStyles();
  const { translator } = Translator();

  return (
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container className={classes.containerTotal}>
          <p className={classes.text}>
            {translator('billing_your_invesment_subtotal')}
          </p>
          <p className={classes.text}>{`USD ${values.transaction.subtotal}`}</p>
        </Container>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container className={classes.containerTotal}>
          <p className={classes.text}>
            {translator('billing_your_invesment_fee')}
          </p>
          <p className={classes.text}>{`USD ${values.transaction.feeValue}`}</p>
        </Container>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container className={classes.containerTotal}>
          <p className={classes.total}>
            {translator('billing_your_invesment_full_payment')}
          </p>
          <p className={classes.total}>{`USD ${values.transaction.total}`}</p>
        </Container>
      </Grid>
    </Grid>
  );
};
