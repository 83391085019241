import API from '../../classes/Api/Api';
import URL from '../../classes/Url/URL';
import Response from '../../classes/Response/Response';
import CustomError from '../../classes/CustomError/CustomError';
import Transaction from '../../classes/Transaction/Transaction';
import Storage from '../../classes/Storage/Storage';

const TransactionsService = () => {
  const id = new Storage('id').getItem();
  const getData = async (queryParams = '') => {
    const api = new API(
      `${URL.URL_SERVER}/users/${id}/transactions${queryParams}`
    );
    try {
      const http_response = await api.get();

      const has_error = http_response instanceof CustomError;
      if (has_error) {
        console.error('Error:', http_response);
        return http_response;
      }

      const response = new Response(http_response, Transaction);
      return response.wasSuccessfull().multiple();
    } catch (error) {
      console.error(' error:', error);
      throw error;
    }
  };

  return {
    getData,
  };
};

export default TransactionsService;
