import React from 'react';
import { Modal } from '@material-ui/core';
import { useStyles, ModalLogic } from './Modal.module';
import { Grid } from '@material-ui/core';

export default function ModalComponent(props) {
  const { children } = props;
  const classes = useStyles();
  const { open, handleClose } = ModalLogic(props);

  return (
    <Grid container spacing={0} align="center">
      <Grid item lg={12} md={12} sm={12} xs={12} >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className={classes.modalBox}>
            {children}
          </div>
        </Modal>
      </Grid>
    </Grid>
  );
}
