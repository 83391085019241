import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import CustomError from '../../../classes/CustomError/CustomError';
import Response from '../../../classes/Response/Response';
import Stripe from '../../../classes/Stripe/Stripe';
import Transaction from '../../../classes/Transaction/Transaction';

const PaymentMethodService = () => {
  const checkPay = async (info) => {
    const api = new API(`${URL.URL_SERVER}/check-pay-session`);
    const http_response = await api.post(info);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Stripe);
      return response.wasSuccessfull().single();
    }
  };

  const createSessionInStripe = async (stripe) => {
    const api = new API(`${URL.URL_SERVER}/create-checkout-session`);
    const http_response = await api.post(stripe);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Stripe);
      return response.wasSuccessfull().single();
    }
  };

  const updateTransaction = async (id, data) => {
    const api = new API(`${URL.URL_SERVER}/transactions/${id}`);
    const http_response = await api.put(data);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Transaction);
      return response.wasSuccessfull();
    }
  };

  return { createSessionInStripe, checkPay, updateTransaction };
};

export default PaymentMethodService;
