
import {
	makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	rootFrameService: {
		maxWidth: '281px',
		height: '457px',
		borderRadius: '44px ',
		opacity: '0px',
		border: '2px solid #BBBBBA',
		background: theme.palette.background.default,
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			maxWidth: '100%',
		},
		[theme.breakpoints.down('md')]: {
			height: '100%',
		}
	},
	imageFrameService: {
		width: '69px',
		height: '63px',
		opacity: '0px',
		marginTop: 20
	},
	subtitleFrame: {
		fontFamily: 'Montserrat',
		fontSize: ' 25px',
		fontWeight: 700,
		lineHeight: '40px',
		textAlign: 'center',
	},
	bodyFrame: {
		opacity: '0px',
		fontFamily: 'Montserrat',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '25.6px',
		textAlign: 'center',
	},
	cardContentSubtitleFrame: {
		height: 100,
		[theme.breakpoints.down('md')]: {
			marginBottom: 20
		},
	},
	cardContentBodyFrame: {
		height: 150,
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			marginBottom: 26
		}
	},
	fixedButton: {
		position: 'absolute',
		bottom: '10px',
		left: '50%',
		transform: 'translateX(-50%)',
	},

	cardContentImage: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

	},
	cardActionsButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-end',
		paddingBottom: '10px',

	}
}));

export default useStyles;