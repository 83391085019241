import React from 'react';
import { useStyles, VerifiedProjectsLogic } from './verifiedProjects.module';
import { Typography } from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const VerifiedProjects = () => {
  const classes = useStyles();
  const { translator, goToProjects } = VerifiedProjectsLogic();

  return (
    <div className={classes.rootVerified}>
      <div>
        <Typography className={classes.titleVerified}>
          {translator('verifiedProjects.title')}
        </Typography>
      </div>
      <div>
        <Typography className={classes.subtitleVerified}>
          {translator('verifiedProjects.subtitle')}
        </Typography>
      </div>
      <div className={classes.contentButtonVerified}>
        <ButtonDefault
          changeView={goToProjects}
          type="greenMint"
          text={translator('verifiedProjects.button')}
        >
          <ArrowRightAltIcon className={classes.iconButtonVerified} />
        </ButtonDefault>
      </div>
    </div>
  );
};

export default VerifiedProjects;
