import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    background:
      'linear-gradient(121deg, rgb(34,10,176) 55%, rgba(3,104,131,1) 100%)',
    borderRadius: '16px',
    marginTop: '5px',
    marginBottom: '45px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    width: '1108px',
    height: '259px',
  },

  gridBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: theme.palette.background.default,
  },
  bannerItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  containerbanner: {
    display: 'flex',
    justifyContent: 'center',
    height: '300px',
    background: theme.palette.background.default,
  },

  message: {
    color: '#fff',
    fontSize: '38px',
    marginTop: '40px',
    marginBottom: '40px',
    fontWeight: '800px',
    fontFamily: 'MontserratExtraBold',
  },

  link: {
    textDecoration: 'none',
    textDecorationLine: 'none',
  },

  [theme.breakpoints.down('md')]: {
    message: {
      fontSize: '34px',
    },
    paperContainer: {
      width: '950px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    message: {
      fontSize: '24px',
    },
    paperContainer: {
      width: '600px',
    },
  },

  [theme.breakpoints.down('xs')]: {
    message: {
      fontSize: '16px',
    },
    paperContainer: {
      width: '300px',
    },
  },
}));

export default useStyles;
