import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: '0px',
  },
  rootCreditExplanation: {
    margin: '50px 0px 10px 0px',
  },
  titleCreditExplanation: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    lineHeight: '36px',
    color: '#00DD9E',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1,
      whiteSpace: 'pre-wrap',
      fontSize: '45px',
    },
  },
  subtitleCreditExplanation: {
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '60px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.text.denary,
    fontFamily: 'Montserrat',
  },
  descriptionCreditExplanation: {
    paddingLeft: '0px',
    fontFamily: 'MontserratRegular',
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: theme.palette.text.denary,
  },
}));

export default useStyles;
