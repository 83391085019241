import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';
import { WhatWeDoLogic, useStyles } from './whatWeDo.module';

const WhatWeDo = () => {
  const { translator } = WhatWeDoLogic();
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} container justifyContent="center">
        <Typography variant="h3" className={classes.title}>
          {translator('whatWeDo.title')}{' '}
        </Typography>
        <Typography variant="h3" className={classes.titleGreen}>
          {' '}
          {translator('whatWeDo.title2')}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Box mt={5} p={2} className={classes.text}>
          <span className={classes.textBold}>
            {' '}
            {translator('whatWeDo.textBold1')}
          </span>{' '}
          {translator('whatWeDo.text1')}
          <span className={classes.textBold}>
            {translator('whatWeDo.textBold2')}
          </span>{' '}
          {translator('whatWeDo.text2')}
          <span className={classes.textBold}>
            {' '}
            {translator('whatWeDo.textBold3')}
          </span>
          {translator('whatWeDo.text3')}
          <span className={classes.textBold}>
            {' '}
            {translator('whatWeDo.textBold4')}
          </span>
        </Box>
      </Grid>
    </>
  );
};

export default WhatWeDo;
