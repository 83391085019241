import { makeStyles } from '@material-ui/core';
import img from '../../assets/images/Fondo.webp';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 77,
    display: 'flex',
    justifyContent: 'center',
    minHeight: '75vh',
  },
  containerDemoSection: {
    display: 'flex',
    justifyContent: 'center',
    width: 1024,
    height: 'auto',
    marginBottom: 48,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 650,
    height: 740,
    padding: '0px 64px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  },
  shortField: {},
  imageContainer: {
    backgroundImage: `url(${img})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'scroll',
    backgroundSize: 'cover',
    width: '100%',
    height: '740px',
    padding: 48,
  },
  imgText: {
    color: '#F5FAFF',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: 32,
    textAlign: 'left',
  },
  title: {
    margin: 0,
    top: 'calc(50% - 118px/2 - 395px)',
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.25rem',
    lineHeight: '1.375rem',
    color: theme.palette.text.nonary,
  },
  form: {
    justifyItems: 'start',
    textAlign: 'start',
    height: 'auto',
  },
  labels: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.text.tertiary,
    height: 24,
    width: 544,
    textAlign: 'left',
  },
  textField: {
    width: '100%',
    height: 42,
    borderRadius: 6,
    marginBottom: 24,
    border: '1px solid #E0E0E0',
    fontFamily: 'MontserratSemiBold',
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.tertiary,
    '&:focus': {
      border: '1px solid rgba(0, 111, 86, 0.5)',
      boxShadow: '1px solid rgba(0, 111, 86, 0.5)',
    },
    '& .MuiInputBase-input': {
      height: 4,
    },
  },
  formCheck: {
    display: 'flex',
  },
  formChecktext: {
    width: 296,
    fontSize: 12,
  },
  shortFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonContainer: {
    margin: '10px 10px 10px 0px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0px',
    },
  },
  demoFormButtonDefault: {
    fontFamily: 'MontserratBold',
    backgroundColor: '#00DD9E',
    border: '2px solid #00DD9E',
    borderRadius: 24,
    color: '#fff',
    fontWeight: '700',
    height: 48,
    fontSize: '18px',
    minWidth: 370,
    lineHeight: '19px',
    '&:hover': {
      color: '#FFF',
    },
    '&:disable': {
      backgroundColor: 'rgba(125, 125, 125, .8)',
      color: '#FFF',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 254,
    },
  },
  privacyInfo: {
    fontSize: 12,
    textAlign: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    root: {
      width: '100%',
      height: 'auto',
    },
    imageContainer: {
      display: 'none',
    },
    formContainer: {
      width: '80%',
    },
  },
}));

export default useStyles;
