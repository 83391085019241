import { React } from 'react';
import BlogSection from '../newsSection/newsSection';
import Partners from '../partners/partners';
import Footer from '../footer/Footer';
import Cookie from '../cookies/cookie';
import Carousel from '../carousel/Carousel';
import OurServices from '../ourServices/ourServices';
import VerifiedProjects from '../verifiedProjects/verifiedProjects';
import { Container } from '@material-ui/core';
import { useStyles } from './Home.module';
import WhatWeDo from '../whatWeDo/whatWeDo';

const Home = () => {
  const classes = useStyles();
  return (
    <div>
      <Carousel />
      <WhatWeDo />
      <Partners />
      <OurServices />
      <BlogSection />
      <Container maxWidth="md" className={classes.verifiedProjects}>
        <VerifiedProjects />
      </Container>
      <Footer />
      <Cookie />
    </div>
  );
};

export default Home;
