import React from 'react'
import { Link } from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Box,
    Breadcrumbs,
    useTheme,
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
    useStyles, FinancesLogic,
} from './finances.module';
import Footer from '../footer/Footer';

const Finances = () => {
    const classes = useStyles();
    const { values, translator } = FinancesLogic();
    const theme = useTheme();
    return (
        <>
            <Box className={classes.breadcrumbContainer}>
                <Breadcrumbs
                    separator={<NavigateNext fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link className={classes.customLink} to="/">
                        <Typography className={classes.fontLink}>Home</Typography>
                    </Link>
                    <Link className={classes.customLink} to="/finances">
                        <Typography className={classes.fontLink}>Finances</Typography>
                    </Link>
                </Breadcrumbs>
            </Box>

            <Container maxWidth="md" className={classes.main}>
                <Grid container align="center" spacing={1} >
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography className={classes.heading} paragraph >
                            {translator('finances.heading')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className={classes.section}>
                            <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}> {translator('finances.section_span_one')}</span> {translator('finances.section_text_one')}
                        </Typography>
                        <Typography paragraph className={classes.section}>
                            <strong className={classes.text} > {translator('finances.section_strong_one')} </strong>
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography paragraph className={classes.section}>
                            <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}>{translator('finances.section_span_two')} </span> {translator('finances.section_text_two')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography paragraph className={classes.section}>
                            <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}> {translator('finances.section_span_three')}</span>{translator('finances.section_text_three')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography paragraph className={classes.section}>
                            <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}>{translator('finances.section_span_four')} </span>{translator('finances.section_text_four')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography paragraph className={classes.section}>
                            <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}> {translator('finances.section_span_five')}</span> {translator('finances.section_text_five')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography className={classes.section}>
                            {translator('finances.section_text_six')}<strong>{translator('finances.section_strong_six')}</strong> {translator('finances.section_text_six_two')}<strong>{translator('finances.section_strong_six_two')}</strong> {translator('finances.section_text_six_three')}
                        </Typography>
                        <Typography paragraph className={classes.section}>
                            {translator('finances.section_text_six_four')}
                        </Typography>
                        <List >
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography className={classes.section}> {translator('finances.section_list_one')}<span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}>{translator('finances.section_list_span_one')}</span> {translator('finances.section_list_text_one')}</Typography>}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography className={classes.section}>{translator('finances.section_list_two')}  <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}>{translator('finances.section_list_span_two')}</span> {translator('finances.section_list_text_two')}</Typography>}
                                />
                            </ListItem>
                            <ListItem >
                                <ListItemIcon>
                                    <ArrowRightIcon className={classes.icon} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography className={classes.section}>{translator('finances.section_list_three')}  <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}>{translator('finances.section_list_span_three')}</span>{translator('finances.section_list_text_three')}</Typography>}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography paragraph className={classes.section}>
                            {translator('finances.section_text_seven')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.separator} align='center'>
                        <ImageResponsive src={values.info_image}
                            width={570}
                            height={480}
                            alt="Info" />
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography paragraph className={classes.section}>
                            {translator('finances.section_text_eight')} <span className={theme.palette.mode === 'light' ? classes.span : classes.spanLight}> {translator('finances.section_span_eight')}</span>  {translator('finances.section_text_eight_two')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography paragraph className={classes.section}>
                            {translator('finances.section_text_nine')}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Typography paragraph className={classes.section}>
                            <strong>{translator('finances.section_strong_then')}   </strong>
                        </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12} align='center' className={classes.separator}>
                        <ImageResponsive src={values.eGreen_path}
                            width={350}
                            height={80}
                            alt="eGreen" />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.separator_footer} >
                        <Typography paragraph className={classes.section}>
                            {translator('finances.section_text_eleven')}   <em className={classes.em}>{translator('finances.section_em_eleven')} </em>
                        </Typography>
                    </Grid>
                </Grid>

            </Container>
            <Footer />

        </>
    )
}

export default Finances

const ImageResponsive = ({ src, width, height, alt }) => {
    const classes = useStyles();

    return (
        <div
            style={{
                width,
            }}
            className={classes.responsive}
        >
            <div style={{
                paddingBottom: (height / width * 100) + '%'
            }} />
            <img
                src={src}
                className={classes.image}
                alt={alt}
            />
        </div>
    )
}
