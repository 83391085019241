import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '505px',
    maxWidth: '100%',
    backgroundImage:
      "url('https://media.istockphoto.com/id/1264885189/photo/aerial-view-of-road-in-beautiful-green-forest-at-sunset-in-summer-colorful-landscape-with.jpg?s=612x612&w=0&k=20&c=3UbdYNxWnPpVXW1BSrSNxJXfxCm5jy3wbMHvOdyET-M=')",
    backgroundSize: 'cover',
  },

  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'flex-start',
    padding: '10%',
    width: '100%',
    // color: '#fff',
    // fontSize: '24px',
    // '@media screen and(max - width: 768px)': {
    //     fontSize: '20px',
    // },
    // display: 'grid',
    // gap: '10px',
    // justifyItems: 'center',
    // gridTemplateColumns: '1fr',
    // justifySelf: 'center'
  },
  title: {
    display: 'flex',
    fontFamily: 'MontserratBold',
    fontSize: '60px',
    fontWeight: '700',
    color: '#06E89F',
    textAlign: 'left',
    lineHeight: '54.36px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px',
      lineHeight: '42.36px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '41px',
      lineHeight: '42.36px',
    },
  },
  subtitle: {
    padding: 0,
    marginTop: 50,
    fontFamily: 'Montserrat',
    fontStyle: 'normal',    
    textAlign: 'left',
    color: '#ffffff',
    width: '60%',
    // '@media screen and(max - width: 768px)x)': {
    //     fontSize: '36px'
    // }
    '@media screen and (min-width:576px)': {
      fontSize: '25px',
      // lineHeight: '74px',
    },
    '@media screen and (max-width:992px)': {
      fontSize: '18px',
      width: '100%',
      // lineHeight: '37px',
    },
  }
    
}));

export default useStyles;
