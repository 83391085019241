import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.text.tertiary,
    // opacity: '0.5',
    // position: 'absolute',
    // top: '30px',
    // left: '140px',
    // zIndex: 2,
  },
  tooltip: {},
}));
export default useStyles;
