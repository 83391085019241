import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  infoBarContainer: {
    gridRowGap: 0,
    gridColumnGap: 0,
    margin: 'auto',
  },
  infoBarLeft: {
    fontSize: 36,
    background: '#FFF',
    borderRadius: '16px 0px 0px 16px',
    '-webkit-border-radius': '16px 0px 0px 16px',
    '-moz-border-radius': '16px 0px 0px 16px',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',

    width: '100%',
    padding: 17,
    paddingBottom: 17,
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'table',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '16px 16px 0px 0px',
      '-webkit-border-radius': '16px 16px 0px 0px',
      '-moz-border-radius': '16px 16px 0px 0px',
      '-webkit-overflow-scrolling': 'touch',
    },
  },
  infoBar: {
    fontSize: 36,
    background: '#FFF',
    padding: 17,
    paddingBottom: 17,
    height: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'table',
      alignItems: 'center',
      justifyContent: 'center',
      borderLeft: '0.1px solid rgba(0,0,0,0.4)',
    },
  },
  infoBarRight: {
    fontSize: 36,
    background: '#FFF',
    borderRadius: '0px 16px 16px 0px',
    '-webkit-border-radius': '0px 16px 16px 0px',
    '-moz-border-radius': '0px 16px 16px 0px',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    padding: 17,
    paddingBottom: 17,
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'table',
      alignItems: 'center',
      justifyContent: 'center',
      borderLeft: '0.1px solid rgba(0,0,0,0.4)',
      '-webkit-overflow-scrolling': 'touch',
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px 0px 16px 16px',
      '-webkit-border-radius': '0px 0px 16px 16px',
      '-moz-border-radius': '0px 0px 16px 16px',
      '-webkit-overflow-scrolling': 'touch',
    },
  },
  infoBar_number: {
    fontFamily: 'MontserratBold',
    fontWeight: 700,
    fontSize: '38px',
    color: '#082F5E',
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  infoBar_text: {
    fontFamily: 'MontserratBold',
    fontWeight: 600,
    fontSize: '18px',
    color: '#848484',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  infoBar_textEmpty: {
    fontFamily: 'MontserratBold',
    fontWeight: 600,
    fontSize: '20px',
    marginTop: 61,
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  bannerLink: {
    textDecoration: 'none',
    textDecorationLine: 'none',
  },
}));
export default useStyles;
