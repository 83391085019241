import React from 'react';
import {
  Grid,
  Box,
  Container,
  TextField,
  InputLabel,
  Typography,
} from '@material-ui/core';
import countries from './data/countries.json';
import prefix from './data/prefix.json';
import ButtonDefault from '../shared/button/button';
import { BillStyles } from './billingDetail.module';

function Form(props) {
  const isValid =
    !props.values.name ||
    !props.values.lastName ||
    props.errors.errorName ||
    props.errors.errorLastName ||
    !props.values.country ||
    !props.values.dni ||
    props.errors.errorDni ||
    !props.values.city ||
    props.errors.errorCity ||
    !props.values.address ||
    props.errors.errorAddress ||
    !props.values.code ||
    props.errors.errorCode ||
    !props.values.prefix ||
    !props.values.phone ||
    props.errors.errorPhone ||
    !props.values.email ||
    props.errors.errorEmail;
  const { useStyles } = BillStyles();
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.container}>
        <form
          id="my-form"
          noValidate
          autoComplete={'off'}
          onSubmit={props.handleSubmit}
        >
          <Grid container spacing={2}>
            <Box pt={1} pb={2.5} className={classes.headerContainer}>
              <Box pt={6} mr={1} className={classes.redonded}>
                <Typography align="right" className={classes.number}>
                  1
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.subtitle}>
                  Detalle de facturación
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.fields} container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                Nombre
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.name}
                name={'name'}
                onChange={props.onHandleChange('name')}
                error={props.errors.errorName}
                helperText={props.errors.errorName}
              />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                Apellidos
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.lastName}
                name={'lastName'}
                onChange={props.onHandleChange('lastName')}
                error={props.errors.errorLastName}
                helperText={props.errors.errorLastName}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                DNI
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.dni}
                name={'dni'}
                onChange={props.onHandleChange('dni')}
                error={props.errors.errorDni}
                helperText={props.errors.errorDni}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} align="left">
              <InputLabel required className={classes.inputLabel}>
                Pais
              </InputLabel>
              <TextField
                id="standard-select-currency-native"
                select
                className={classes.textfield}
                variant="outlined"
                fullWidth
                required
                onChange={props.onHandleChange('country')}
                value={props.values.country}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="" disabled>
                  Selecciona tu país
                </option>

                {countries.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                Ciudad/Localidad
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.city}
                name={'city'}
                onChange={props.onHandleChange('city')}
                error={props.errors.errorCity}
                helperText={props.errors.errorCity}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                Dirección de la calle
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.address}
                name={'address'}
                onChange={props.onHandleChange('address')}
                error={props.errors.errorAddress}
                helperText={props.errors.errorAddress}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                Código Postal
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.code}
                name={'code'}
                onChange={props.onHandleChange('code')}
                error={props.errors.errorCode}
                helperText={props.errors.errorCode}
              />
            </Grid>
            <Grid item lg={5} md={12} sm={12} xs={12} align="left">
              <InputLabel required className={classes.inputLabel}>
                Teléfono
              </InputLabel>
              <TextField
                id="standard-select-currency-native"
                select
                className={classes.textfield}
                variant="outlined"
                fullWidth
                required
                onChange={props.onHandleChange('prefix')}
                value={props.values.prefix}
                SelectProps={{
                  native: true,
                }}
                name="prefix"
              >
                {prefix.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.prefix}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <InputLabel className={classes.inputLabelEmpty} required>
                {' '}
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.phone}
                name={'phone'}
                onChange={props.onHandleChange('phone')}
                error={props.errors.errorPhone}
                helperText={props.errors.errorPhone}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <InputLabel required className={classes.inputLabel}>
                Correo Electrónico
              </InputLabel>
              <TextField
                className={classes.textfield}
                placeholder=""
                variant="outlined"
                fullWidth
                required
                type="text"
                value={props.values.email}
                name={'email'}
                onChange={props.onHandleChange('email')}
                error={props.errors.errorEmail}
                helperText={props.errors.errorEmail}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography className={classes.required}>
                *Campos requeridos para su facturación
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box className={classes.buttonNext} mb={2}>
        <ButtonDefault
          form="my-form"
          type="blue"
          disabled={isValid}
          text="SIGUIENTE"
          isSubmit={true}
          changeView={props.handleNext}
        />
      </Box>
    </div>
  );
}
export default Form;
