import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  container: {
    maxWidth: '1200px',
    borderRadius: '8px',
    [theme.breakpoints.up( 'md', 'sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  iconContainer: {
    marginLeft: '54px',
    marginRight: '27px',
    padding: '0px',
    color: 'rgba(51, 51, 51, 1)',
  },
  projectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.projectItem,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    borderRadius: '15px 0px 0px 15px ',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.background.projectItem,
      height: '227px',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  projectImg: {
    width: '91px',
    height: '91px',
    borderRadius: '16px',
  },
  projectName: {
    width: '200px',
    height: '48px',
    textAlign: 'center',
    marginTop: '4px',
    color: theme.palette.text.tertiary,
  },
  productContainer: {
    [theme.breakpoints.up('lg')]: {
      width: '89px',
      height: '100px',
    },
  },
  container2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.projectItem,
    width: '100%',
    height: '227px',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up( 'md', 'sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      background: theme.palette.background.projectItem,
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      height: '227px',    },
   
  },
  container3: {
    borderRadius: '0px 15px 15px 0px',
  },
  pricesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  price: {
    fontSize: '16px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
  },
  priceItem: {
    fontSize: '16px',
    fontWeight: '400',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
  },
  totalPrice: {
    fontSize: '16px',
    fontWeight: '600',
    textAlign: 'center',
    color: theme.palette.text.tertiary,
  },
  columnTitles_container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.secondary.main,
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  columnTitles_projects: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#04679C',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: 'white',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'right',
      color: '#04679C',
      marginTop: '25px',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: 42,
    },
  },

  columnTitles_price: {
    fontWeight: '600',
    fontSize: '20px',
  },
  columnTitles_subtotal: {
    fontWeight: '600',
    fontSize: '20px',
  },
  columnTitles_fee: {
    fontWeight: '600',
    fontSize: '20px',
  },
  columnTitles_total: {
    fontWeight: '600',
    fontSize: '20px',
  },
  columnTitles_project: {
    fontWeight: '600',
    fontSize: '20px',
  },
  columnTitles_product: {
    fontWeight: '600',
    fontSize: '20px',
  },
  columnTitles_amount: {
    fontWeight: '600',
    fontSize: '20px',
  },
  select: {
    border: '1px solid #b9b9b9',
    borderRadius: '0px',
    fontFamily: 'MontserratRegular',
    fontSize: '14px',
    height: '32px',
    padding: '3px',
    minWidth: '110px',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      height: '32px',
      minWidth: '110px',
      padding: '5px',
      width: '100%',
    },
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  imgRadioBtn: {
    width: '80px',
    height: '50px',
  },
  radiolabel: {
    display: 'flex',
    alignItems: 'center',
    /*     color: 'red',
    '&.Mui-checked': {
      color: 'red',
    }, */
  },
}));

export default useStyles;
