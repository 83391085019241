import React from 'react';
import {
  Grid,
  Container,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';
import ButtonDefault from '../shared/button/button';
import {
  useStyles,
  newsSectionLogic
} from './newsSection.module';
import { useHistory } from 'react-router-dom';


const BlogSection = () => {
  const classes = useStyles();
  const { translator, posts } = newsSectionLogic();
  const { push } = useHistory();
  return (
    <Container maxWidth="md">
      <Grid container spacing={3}>
        <Grid item lg={12} sm={12} xs={12} className={classes.titleBlog}>
          <h1 className={classes.title}>{'News'} <span className={classes.title2}>&</span> {'Blog'}</h1>
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.containerRoot}
        >

          {posts && posts.map((post) => (
             <Card className={classes.root}>
             <CardActionArea>
               <CardMedia
                 className={classes.img}
                 image={post.image}
                 title="news"
               />
               <CardContent className={classes.content}>
                 <Typography
                   gutterBottom
                   variant="h5"
                   component="h2"
                   className={classes.subtitle}
                 >
                {post.title.substr(0, 45)}...
                 </Typography>
               </CardContent>
             </CardActionArea>
             <CardActions className={classes.button}>
               <ButtonDefault
                 type="greenBlueFont"
                 text={translator('learn_more')}
                 changeView={() => push('/blog')}
               />
             </CardActions>
           </Card>
          ))}
         
        </Grid>
      </Grid>
    </Container>
  );
};

export default BlogSection;

