import CustomDate from '../../classes/CustomDate/CustomDate';

class BlogPost {
  constructor({
    id,
    title = '',
    content,
    description = 'Sin descripción',
    images = [],
    labels = [],
    url = null,
    published,
  }) {
    const [
      image = {
        url: 'https://cdn.impactinit.com/resizenp/600x600/x@7c62feb0ce/smss53/smsimg27/pv1000/isignstockcontributors/iss_2756_00053.jpg',
      },
    ] = images;
    this.id = id;
    this.description = description;
    this.image = image.url;
    this.url = url;
    this.labels = labels;
    this.published = new CustomDate(published);
    this.setTitle(title);
    this.content = content;
  }

  setTitle(title) {
    if (title === '') {
      this.title = `Blog del día ${this.published.formatted_date}`;
    } else {
      this.title = title;
    }
  }
}

export default BlogPost;
