import { useStyles, ProjectSpecsTableLogic } from './projectSpecsTable.module';
import { Grid } from '@material-ui/core';
import CustomDate from '../../classes/CustomDate/CustomDate';
import ProjectKey from '../../classes/Project/ProjectKey';
import ProjectKeys from '../ProjectKeys/projectkeys';
import React, { useEffect, useState } from 'react';

export default function KeysIndicatorsList(props) {
  const classes = useStyles();
  const { translator } = ProjectSpecsTableLogic();
  const { info, keys } = props;
  const formattedStartDate = new CustomDate(info.project.contract_start_date)
    .english_formatted_date;

  const [projectKeys, setProjectKeys] = useState(keys);

  useEffect(() => {
    setProjectKeys(keys);
  }, [keys]);

  return (
    <Grid container className={classes.impactContainer}>
      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
      <Grid item lg={10} md={10} sm={10} xs={10}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h2 className={classes.title}>
              {translator('ProjectSpecsTable.title')}
            </h2>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.dataTable}>
              <ProjectKeys
                key={'responsible'}
                projectKey={ProjectKey.findKeyBasedOnName({
                  keys: projectKeys,
                  name: 'Responsible:',
                })}
              />

              <ProjectKeys
                key={'project_type'}
                name={'Project Type:'}
                data={info.project.green_project_type}
              />

              <ProjectKeys
                key={'financing'}
                projectKey={ProjectKey.findKeyBasedOnName({
                  keys: projectKeys,
                  name: 'Financing:',
                })}
              />

              <ProjectKeys
                key={'project_type'}
                name={'CO₂ Outlook (year):'}
                data={info.project.co2_annual_estimated}
              />

              <ProjectKeys
                key={'project_type'}
                name={'Start Date:'}
                data={formattedStartDate}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
    </Grid>
  );
}
