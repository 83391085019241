import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    breadcrumbContainer: {
        margin: '75px auto -80px auto',
        paddingLeft: 2,
        maxWidth: '1350px',
        display: 'flex',
    },
    customLink: {
        textDecoration: 'none',
        color: theme.palette.text.tertiary,
        '&:hover': {
            color: theme.palette.text.tertiary,
            textDecoration: 'none',
        },
    },
    fontLink: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '26px',
    },
    heading: {
        fontFamily: 'MontserratBold',
        fontWeight: '800',
        fontSize: '32px',
        marginTop: 80,
        marginBottom: 60,
        fontStyle: 'normal',
        lineHeight: '40px',
        color: theme.palette.text.secondary,

    },
    main: {
        color: theme.palette.text.primary,
    },
    section: {
        fontFamily: 'Montserrat',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontSize: 16,
        textAlign: 'center',
        lineHeight: '26px',
        whiteSpace: 'pre-wrap',
    },
    span: {
        color: theme.palette.text.senary,
        fontFamily: 'MontserratBold',
        fontSize: 23,
    },
    spanLight: {
        color: theme.palette.text.senary,
        fontFamily: 'Montserrat',
        fontSize: 23,
    },
    em: {
        color: '#006F56',
        fontFamily: 'MontserratBold',
        fontSize: 16,
        marginBottom: 50
    },
    separator: {
        marginTop: 20,
        marginBottom: 60
    },
    separator_footer: {
        marginBottom: 40
    },
    icon: {
        color: theme.palette.text.primary,
    },
    // Imagen responsive**
    responsive: {
        position: 'relative',
        maxWidth: '100%'
    },

    image: {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%'
    },


}));
export default useStyles;



