import React from 'react';
import { Button, Typography, Grid, Box } from '@material-ui/core';
import { useStyles, cookieImg, cookieLogic } from './cookie.module';

export default function Cookie() {
  const classes = useStyles();
  const { cookie, translator, values, acceptCookie, rejectCookie } = cookieLogic();
  return (
    <React.Fragment>
      {cookie ? (
        ''
      ) : (
        <Box className={classes.divContainer}>
          <Grid container>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <img
                src={cookieImg}
                alt={'accept cookie'}
                className={classes.cookieImg}
                width="100"
                height="100"
              />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                className={classes.title}
              >
                {translator('cookie.title')}
              </Typography>
              <Typography className={classes.text}>
                {translator('cookie.text1')}
                <b>&quot;{translator('cookie.text_accept')}&quot;</b>
                {translator('cookie.text2')}
                <b>
                  <a href={values.pdf} className={classes.termsAndConditions}>
                    {translator('cookie.termsAndConditions')}
                  </a>
                </b>
              </Typography>
              <ContainerButton
                acceptCookie={acceptCookie}
                rejectCookie={rejectCookie}
                decline={translator('cokkie.declineButton')}
                accept={translator('cokkie.aceptButton')}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

const ContainerButton = (props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item lg={6} md={6} sm={6} xs={6} align="right">
        <Button className={classes.button} onClick={props.rejectCookie}>
          {props.decline}
        </Button>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6} align="left">
        <Button className={classes.button} onClick={props.acceptCookie}>
          {props.accept}
        </Button>
      </Grid>
    </Grid>
  );
};
