import React from 'react';
import { useStyles } from './bannerInterface.module';
import { Container } from '@material-ui/core';
const BannerInterface = (props) => {
    const classes = useStyles();
    const { backgroundImage, text, subtitle } = props;

    return (
        <div style={{
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'center',
            // backgroundImage: `url(${backgroundImage})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'center',
            // '@media screen and (min-width:576px)': {
            //     backgroundSize: 'cover',
            //     height: '505px',
            // },
            // '@media screen and (max-width:992px)': {
            //     backgroundSize: '100% 100%',
            //     height: '505px',
            //     marginTop: 8
            // }
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '505px',
            maxWidth: '100%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',

            // '@media screen and (max-width:992px)': {
            //     backgroundSize: 'contain',
            // }

        }}>
            <div className={classes.bannerContent}>
                <h1 className={classes.title}>{text}</h1>
                <p className={classes.subtitle}>{subtitle}</p>
            </div>
        </div>
    )
}

export default BannerInterface