import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import CustomError from '../../../classes/CustomError/CustomError';
import Response from '../../../classes/Response/Response';
import Document from '../../../classes/Document/Document';

const UserService = () => {
  const uploadProfilePicture = async (data) => {
    const api = new API(`${URL.URL_SERVER}/documents`);
    const http_response = await api.post(data);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Document);
      return response.wasSuccessfull().single();
    }
  };

  const getProfilePicture = async (id) => {
    const api = new API(`${URL.URL_SERVER}/documents/${id}`);
    const http_response = await api.get();
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Document);
      const data = response.wasSuccessfull().single();
      return data.url || 'https://i.stack.imgur.com/34AD2.jpg';
    }
  };

  return { uploadProfilePicture, getProfilePicture };
};

export default UserService;
