import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import {
  useStyles,
  VoluntaryMarketLogic,
  carbonCreditImg,
  CDRProject,
  adobeStock,
} from './VoluntaryMarket.module';
import Footer from '../footer/Footer';
import BannerInterface from '../shared/bannerInterface/bannerInterface';
import InfoQuestions from '../shared/infoQuestions/infoQuestion';
import MoreInformationDemo from '../MoreInformationDemo/MoreInformationDemo';
import CardProjects from '../shared/cardProjects/CardProjects';

const VoluntaryMarket = () => {
  const classes = useStyles();
  const { translator } = VoluntaryMarketLogic();

  return (
    <>
      <BannerInterface
        text={translator('carbonCredits.title')}
        subtitle={translator('carbonCredits.subtitle')}
        backgroundImage={carbonCreditImg}
      />
      <Container>
        <Grid container spacing={0} style={{ marginTop: 5 }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            //
          >
            <InfoQuestions
              textInfo={translator('carbonCreditInfoQuestionsText')}
              src={CDRProject}
              withoutButton={true}
              showOneImg={true}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            // style={{ background: 'green' }}
          >
            <InfoQuestions
              textInfo={translator('carbonCreditInfoQuestionsText2')}
              src={adobeStock}
              type="reverse"
              withoutButton={true}
              showOneImg={true}
            />
          </Grid>
        </Grid>

        <MoreInformationDemo
          key_translator={translator('VoluntaryMarket.for_more_info_and_demo')}
          go_to="/demoForm"
        />
      </Container>

      <Container className={classes.voluntaryMarketProjects}>
        <div className={classes.flexContainer}>
          <Typography className={classes.textHeader}>
            {translator('projects.textBanner')}
          </Typography>
        </div>
        <CardProjects text={translator('invest')} />
      </Container>
      <Footer />
    </>
  );
};

export default VoluntaryMarket;
