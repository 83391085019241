import API from '../../../classes/Api/Api'
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/CustomError/CustomError';

const GraphicExchangeService = () => {
  const getData = async () => {
    const api = new API(`${URL.URL_SERVER}/graphics`);
    const http_response = await api.get();

    // Verifica si la respuesta fue exitosa directamente
    if (http_response.status === 200) {
      const data = http_response.data;
      return data;
    } else {
      // Manejar el error en caso de fallo en la API
      return new CustomError(http_response);
    }
  };

  return {
    getData
  };
};

export default GraphicExchangeService;
