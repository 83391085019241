

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootTransactions: {
    borderRadius: '20px',
    transition: '0.3s',
    padding:0,
    margin:0,
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  rootLightTransactions: {
    padding:0,
    margin:0,
    border: '2px solid #00DD9E',
    borderRadius: '20px',
    transition: '0.3s',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      transition: '0.3s',
    },
  },
  textTransactions: {
    color: ` ${theme.palette.text.tertiary} !important`,
    fontFamily: 'MontserratRegular',
     marginLeft: '12px !important',
  },
  MuiAccordionrootTransactions: {
   padding:'15px 0px',
  },

  accordionSummaryTransactions:{
  marginRight: '12px'
  },
  keyboardArrowDownIconTransactions: {
    color: ` ${theme.palette.text.tertiary} !important`,
  },
 
  accordionDetailsTransactions:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '15px',
    height: '100%',
  },
  formControlDate: {
    minWidth: '100%',
    fontFamily: 'Montserrat',
    '& .MuiInput-underline:before': {
      borderBottom:  `0.1px solid ${theme.palette.text.tertiary}`, 
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom:  `1px solid ${theme.palette.text.tertiary}`, 
    },
    '& .MuiInput-underline:after': {
      borderBottom:  `1px solid ${theme.palette.text.tertiary}`, 
    },
    '& .MuiIconButton-root': {
    color: theme.palette.text.tertiary, 
    '&:hover': {
      color: theme.palette.text.tertiary, 
    },
    
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.tertiary, 
    fontFamily: 'MontserratRegular',
    fontSize: '15.3px',
    '&.Mui-focused': {
      color: theme.palette.text.tertiary, 
    },
  },
  },

  formControl: {
    minWidth: '100%',
    '& .MuiInputLabel-root': {
      fontFamily: 'Montserrat',
      color: theme.palette.text.tertiary, 
      fontSize: '16px',
      '&.Mui-focused': {
        color: theme.palette.text.tertiary, 
      },
    },  
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(0.3),

  },
  chip: {
    fontFamily: 'Montserrat',
    margin: 2,
    backgroundColor: '#06E89F !important', 
    color: '#041370', 
    '&:hover': {
      backgroundColor: '#06E89F !important', 
      color: 'white',
    },
  },

  cardBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  selectedTransactions: {
    fontFamily: 'Montserrat !important',
    '& .MuiSelect-select ': {
      borderBottom: `1px solid ${theme.palette.text.tertiary}`  },
    '&:hover': {
      borderBottom: `1px solid transparent`, 

    },
    '&.Mui-focused': {
   
          borderBottom: `1px solid ${theme.palette.text.tertiary}`

    },
    '& .MuiSelect-icon': {
      color: theme.palette.text.tertiary 
    },
  },
  menuItemTransactions: {
    fontFamily: 'Montserrat',

    '&:hover': {
      backgroundColor: '#b1b1b1', 
      color: 'white', 

    },
    '&.Mui-selected': {
      backgroundColor: '#06E89F ', 
      color: '#041370', 

      '&:hover': {
        backgroundColor: '#06E89F ' ,
        color: '#041370', 

      },
    },
  },
  calendarWeek: {
    fontFamily: 'Montserrat',
  
    '& .MuiPickersToolbarText-toolbarTxt': {
      color: '#06E89F' ,
      fontFamily: 'MontserratRegular !important',
    },
    '& .MuiPickersToolbarText-toolbarBtnSelected': {
  fontFamily: 'MontserratRegular !important',
},
 '& .MuiTypography-body1': {
  fontFamily: 'MontserratRegular !important',
},
 '& .MuiPickersCalendarHeader-switchHeader': {
  color: theme.palette.text.tertiary ,
},
'& .MuiPickersCalendarHeader-iconButton': {
  color: theme.palette.text.tertiary ,
  fontFamily: 'Montserrat',

  '&:hover': {
    backgroundColor:'#06E89F', 
  },
},

    '& .MuiPickersMonth-Month': {
      fontFamily: 'Montserrat',
    },
    '& .MuiPickersDay-day': {
      color: theme.palette.text.tertiary 
    },
    '& .MuiPickersDay-current': {
      color: '#06E89F', 
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: '#06E89F', 
      color: '#041370', 
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: '#06E89F', 
      fontFamily: 'MontserratRegular',
      fontSize: '1rem', 
    },
  },
  gridDetailsTransactions:{
    padding: '0px 15px'
  },
  offersTransactions:{
    marginTop: 2 
  }
}));

export default useStyles;
