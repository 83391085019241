import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '80px',
  },
  workBased: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '140px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '200px',
    },
  },
  centralTitle_section: {
    color: theme.palette.text.nonary,
    fontSize: '56px',
    fontFamily: 'MontserratBold',
    fontWeight: '700',
    lineHeight: '74px',
    textAlign: 'center',
    marginBottom: '77px',
    margin: '0px',
  },
  cardsContainer: {
    padding: '0px 50px 0px 50px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 5px 0px 5px',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  aboutUs_text: {
    fontFamily: 'MontserratRegular',
    fontWeight: '400',
    fontSize: '28px',
    fontStyle: 'normal',
    lineHeight: '39px',
    marginBottom: '110px',
    marginTop: '-30px',
    textAlign: 'center',
  },
  teamCards: {
    marginBottom: '100px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '170px',
    },
  },
  bannerContainer: {
    marginBottom: '60px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '80px',
    },
  },
}));
export default useStyles;
