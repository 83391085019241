import Formatter from '../Formatter/Formatter';

class Wallet {
  static get DEFAULT_BLOCKCHAIN_BALANCE() {
    return { balance: 0 };
  }

  constructor({
    id,
    balances,
    data,
    availableUSD,
    availableCoins,
    totalCoins,
    totalUSD,
  }) {
    const { co2 } = data;
    const [
      egreenCoinBalance = Wallet.DEFAULT_BLOCKCHAIN_BALANCE,
      usdBalance = Wallet.DEFAULT_BLOCKCHAIN_BALANCE,
    ] = balances;
    this.egreenCoin = Formatter.formatNumberExchange(egreenCoinBalance.balance);
    this.usd = Formatter.formatNumberExchange(usdBalance.balance);
    this.id = id;
    this.balances = balances;
    this.data = data;
    this.co2 = co2;
    this.availableUSD = Formatter.formatNumberExchange(availableUSD);
    this.availableCoins = Formatter.formatNumberExchange(availableCoins);
    this.totalCoins = Formatter.formatNumberExchange(totalCoins);
    this.totalUSD = Formatter.formatNumberExchange(totalUSD);
  }
}

export default Wallet;
