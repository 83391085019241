import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Translator from '../../classes/Translator/Translator';

const RestorePasswordLogic = (callback, validate) => {
  const [values, setValues] = useState({
    email: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { translator } = Translator();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback();
    }
  }, [errors, callback, isSubmitting]);

  let history = useHistory();

  return {
    errors,
    handleChange,
    handleSubmit,
    values,
    history,
    translator,
    setErrors,
  };
};

export default RestorePasswordLogic;
