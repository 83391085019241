import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: theme.palette.background.default,
    // content:' #041370',
  },
  readMore: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, .35)',
    borderRadius: 4,
    color: 'white',
    fontWeight: 'bolder',
    height: 35,
    width: 200,
    visibility: 'hidden',
  },
  eyeIcon: {
    marginRight: '5px',
  },
  media: {
    height: '268px',
    borderRadius: 19,
    margin: 8,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'end',
  },

  cardProjectTitle: {
    height: '100%',
    marginTop: 20,
    [theme.breakpoints.up('sm')]: {
      // height: '150px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  projectTitle: {
    fontFamily: 'MontserratMedium',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '29px',
    lineHeight: '36px',
    textAlign: 'left',
    color: theme.palette.text.nonary,
    // color: theme.palette.opinions.service,
    wordBreak: 'break-word',
  },

  country: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '22.4px',
    textAlign: 'left',
    color: '#FFFFFF',
    paddingLeft: 4,
  },
  cardProjectText: {
    fontFamily: 'MontserratRegular',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '32px',
    textAlign: 'left',
    color: theme.palette.opinions.service,
    wordBreak: 'break-word',
  },
  cardActions: {
    marginTop: 'auto',
    display: 'flex',
    alignItems: 'flex-end',
  },
  iconButtonVerified: {
    fontSize: '36.82px !important',
  },
  cardContent: {
    padding: '0px 5px 0px 5px',
  },
  focus: {
    '&&&': {
      opacity: 0,
    },
  },
  ripple: {
    color: 'transparent',
  },
}));
export default useStyles;
