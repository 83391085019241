import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'MontserratBold',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150px',
    textAlign: 'center',
    color: theme.palette.text.nonary,
    '@media screen and (min-width:576px)': {
      fontSize: '50px',
    },
    '@media screen and (max-width:992px)': {
      fontSize: '36px',
      lineHeight: '42px',
    },
  },
  main: {
    color: theme.palette.text.primary,
  },
  // Imagen responsive**
  image: {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '100%',
  },
  containerSpace: {
    padding: '50px',
  },
  // plans
  gridPricing: {
    display: 'grid',
    gap: '20px',
    '@media screen and (min-width:576px)': {
      gridTemplateColumns: '1fr 1fr',
    },
    '@media screen and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: '24px',
    },
    carbonCredit: {
      marginTop: '123px',
      paddingBottom: 0,
      marginBottom: 0,
    },
  },
}));
export default useStyles;
