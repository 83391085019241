import { useEffect } from 'react';
import Translator from '../../classes/Translator/Translator';

const TransactionInformationLogic = (getTransaction, checkPay) => {
  const { translator } = Translator();

  useEffect(() => {
    async function getData() {
      const transaction = await getTransaction();
      await checkPay({
        idPretransaction: transaction.id,
      });
    }
    getData();
  }, []);

  return {
    translator,
  };
};

export default TransactionInformationLogic;
