import API from '../../../classes/Api/Api';
import URL from '../../../classes/Url/URL';
import Response from '../../../classes/Response/Response';
import CustomError from '../../../classes/CustomError/CustomError';
import Offer from '../../../classes/Offer/Offer';

const CardOfferSelectionService = () => {
  const sendOffers = async (values) => {
    const api = new API(`${URL.URL_SERVER}/offers`);
    const http_response = await api.post(values);
    const has_error = http_response instanceof CustomError;
    if (has_error) {
      return http_response;
    } else {
      const response = new Response(http_response, Offer);
      return response.single();
    }
  };

  return {
    sendOffers,
  };
};

export default CardOfferSelectionService;
